import ActiveEngagement from './EngagementItem';
import ArchiveEngagement from './EngagementItem';
import {EngagementStatus} from '../../../util/enumerations';
import ErrorEngagement from './EngagementItem';
import IncompleteEngagement from './EngagementItem';
import PropTypes from 'prop-types';
import React from 'react';
import ReviewEngagement from './EngagementItem';
import {showLinks} from '../../../util/uiconstants';
import styled from 'styled-components';

const EngagementList = ({engagements, refreshDashboard, searchType}) => {
	const renderEngagementList = (engagementList) => {
		return engagementList?.engagementReadModel.map((engagement) => {

			const { id: clientId, name: clientName } = engagement.clientReadModel;
			
			const { id: workspaceID, name: workspaceName } = engagement.workspaceReadModel;

			const {
				engagementId: id,
				engagementStatus,
				engagementIsProfileComplete,
				isEngagementAccessible,
				hasengagementusernotification,
			} = engagement;

			const IsProfileComplete = engagementIsProfileComplete;
			const IsEngagementAccessible = isEngagementAccessible;
			const EngStatusId = engagementStatus;
			const isValidEngStatus =
				EngStatusId === EngagementStatus.Active ||
				EngStatusId === EngagementStatus.Restored ||
				EngStatusId === EngagementStatus.MarkedForDeletion ||
				EngStatusId === EngagementStatus.ContentDeliveryInProgress;
			if (isValidEngStatus && (!IsProfileComplete || !IsEngagementAccessible)) {
				let showLink = showLinks.HideLink;

				if (
					!IsProfileComplete &&
					EngStatusId != EngagementStatus.ContentDeliveryInProgress
				) {
					showLink = showLinks.ProfileSubmit;
				} else if (!IsEngagementAccessible) {
					showLink = showLinks.CompleteIndependence;
				}

				return (
					<IncompleteEngagement
						key={id}
						workspace={workspaceName}
						workspaceID={workspaceID}
						clientId={clientId}
						clientName={clientName}
						clientCode={clientName}
						incomplete={showLink}
						isReviewWorksapce={false}
						data={engagement}
						engagementId={id}
						refreshDashboard={refreshDashboard}
					/>
				);
			} else {
				switch (engagementStatus) {
					case EngagementStatus.ArchiveError:
						return (
							<ErrorEngagement
								key={id + engagementStatus}
								workspace={workspaceName}
								workspaceID={workspaceID}
								clientId={clientId}
								clientName={clientName}
								clientCode={clientName}
								hasengagementusernotification={hasengagementusernotification}
								data={engagement}
								engagementId={id}
								refreshDashboard={refreshDashboard}
							/>
						);
					case EngagementStatus.Archived:
						return (
							<ArchiveEngagement
								key={id + engagementStatus}
								workspace={workspaceName}
								workspaceID={workspaceID}
								clientId={clientId}
								clientName={clientName}
								clientCode={clientName}
								data={engagement}
								engagementId={id}
								refreshDashboard={refreshDashboard}
							/>
						);
					case EngagementStatus.ExternalReviewEngagement:
					case EngagementStatus.CanvasExternalAccessPortalV2:
						return (
							<ReviewEngagement
								key={id}
								workspace={workspaceName}
								workspaceID={workspaceID}
								clientId={clientId}
								clientName={clientName}
								clientCode={clientName}
								isReviewWorksapce
								data={engagement}
								engagementId={id}
								refreshDashboard={refreshDashboard}
							/>
						);
					default:
						return (
							<ActiveEngagement
								key={id + engagementStatus}
								workspace={workspaceName}
								workspaceID={workspaceID}
								clientId={clientId}
								clientName={clientName}
								clientCode={clientName}
								data={engagement}
								engagementId={id}
								searchType={searchType}
								refreshDashboard={refreshDashboard}
							/>
						);
				}
			}
		});
	};

	return (
		<EngagementContainer className="customScrollbar">
			{engagements && renderEngagementList(engagements)}
		</EngagementContainer>
	);
};

const EngagementContainer = styled.section`
	display: flex;
	flex-flow: row wrap;
	padding: 0 var(--px-17);
	height: 100%;
	.motif-card {
		margin: var(--px-20);
		height: var(--px-243);
		width: calc((100% / 2) - var(--px-40));
		position: relative;
		@media only screen and (min-width: 992px) and (max-width: 1366px) {
			height: var(--px-257);
		}
		.engagement-ellipsis {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.engagementinfo-container {
			display: inline-block;
			width: 60%;
			.clientname-wrapper {
				line-height: normal;
				vertical-align: top;
				.client-name {
					font-size: var(--px-12);
					font-weight: 400;
					color: var(--neutrals-700);
					max-width: 99%;
					display: inline-block;
				}
			}
			.engagement-description {
				font-size: var(--px-16);
				font-weight: 700;
				line-height: normal;
				vertical-align: top;
				padding: var(--px-5) 0;
				color: var(--neutrals-900);
				max-width: 99%;
				display: block;
			}
			.workspace-name-wrapper {
				line-height: normal;
				vertical-align: top;
				.workspace-name {
					font-size: var(--px-12);
					font-weight: 400;
					line-height: var(--px-20);
					vertical-align: text-bottom;
					color: var(--neutrals-700);
					max-width: 40%;
					display: inline-block;
				}
				.engagement-type {
					font-size: var(--px-12);
					font-weight: 400;
					line-height: var(--px-20);
					vertical-align: text-bottom;
					color: var(--neutrals-700);
					max-width: 40%;
					display: inline-block;
					padding-left: var(--px-4);
				}
				.engagementid {
					font-size: var(--px-12);
					font-weight: 400;
					line-height: var(--px-20);
					vertical-align: text-bottom;
					color: var(--neutrals-700);
					max-width: 20%;
					display: inline-block;
					padding-left: var(--px-4);
				}
			}
		}
		.engagementactions-container {
			display: inline-block;
			width: 40%;
			text-align: right;
			vertical-align: top;
			.engagement-typelinked {
				display: inline-block;
				max-width: 65%;
				margin-right: var(--px-25);
				@media screen and (max-width: 1366px) {
					max-width: 55%;
				}
				.helixlinked-tag {
					display: inline-flex;
					background-color: rgba(39, 172, 170, 0.2);
					border-radius: var(--px-32);
					width: 100%;
					margin-top: calc(var(--px-12) * -1);
					padding: var(--px-5) var(--px-10);
					vertical-align: middle;
					line-height: normal;
					.motif-tooltip-wrapper,
					.motif-tooltip-trigger-wrap {
						width: 100%;
					}
					button {
						font-size: var(--px-12);
						font-weight: normal;
						color: var(--teal-500);
						border: none;
						box-shadow: none;
						padding-right: var(--px-2);
						width: 100%;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						display: block;
						svg {
							width: var(--px-24) !important;
							height: var(--px-16) !important;
							vertical-align: text-top;
							margin-right: var(--px-3);
							color: var(--teal-600) !important;
						}
						&:hover {
							border: none;
							margin-bottom: 0;
							color: var(--teal-600);
						}
					}
					.motif-text-button:hover {
						margin-bottom: 0;
					}
				}
			}
			.engagement-optionsdropdown {
				.motif-dropdown-menu {
					margin-right: var(--px-1);
				}
			}
			.engagement-alertsdropdown {
				margin-right: var(--px-4);
				.motif-dropdown-trigger {
					.motif-icon-button {
						.motif-icon {
							display: inline-block;
						}
						.motif-icon:first-of-type {
							svg {
								width: var(--px-16);
								height: var(--px-16);
							}
						}
						.motif-icon:last-of-type {
							svg {
								display: inline-block;
								margin-top: calc(var(--px-10) * -1);
								vertical-align: middle;
							}
						}
						.alerts-count {
							font-size: var(--px-12);
							font-weight: 400;
							display: inline-block;
							vertical-align: super;
							margin-left: var(--px-3);
							line-height: var(--px-12);
							@media screen and (max-width: 1366px) {
								vertical-align: text-top;
								margin-top: calc(var(--px-2) * -1);
							}
						}
					}
				}
				.motif-dropdown-menu {
					margin-right: var(--px-5);
					.motif-dropdown-item {
						.motif-icon {
							width: var(--px-27);
							margin-top: calc(var(--px-2) * -1);
							svg {
								fill: var(--red-600) !important;
								width: var(--px-16);
								height: var(--px-16);
							}
						}
						.alerts-count {
							display: inline-block;
							font-size: var(--px-10);
							font-weight: 700;
							margin-right: var(--px-10);
							padding: 0 var(--px-4);
							width: var(--px-27);
							text-align: center;
							background-color: var(--neutrals-900);
							color: var(--neutrals-00white);
						}
						.alert-name {
							display: inline-block;
							max-width: 99%;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}
			}
			.engmetrics-container {
				display: inline-block;
				margin-right: var(--px-10);
				svg {
					max-width: var(--px-16);
					max-height: var(--px-16);
				}
			}
		}
		.engagementstats-container {
			margin-top: var(--px-20);
			display: flex;
		}
		.engagementstatus-container {
			width: 60%;
			.engprogress-unavailable {
				background: var(--neutrals-25);
				padding: var(--px-10);
				margin-top: var(--px-56);
				line-height: normal;
				.engprogress-unavailabletxt {
					font-size: var(--px-12);
					font-weight: 300;
					color: var(--neutrals-900);
				}
			}
			.engagement-progress.motif-progress-bar-wrapper {
				margin-bottom: var(--px-10);
			}
			.engprogress-available {
				.engprogress-availabletxt,
				.engprogress-completion {
					font-size: var(--px-12);
					font-weight: 300;
					color: var(--neutrals-700);
				}
				.engprogress-value,
				.engprogress-complete {
					font-size: var(--px-12);
					font-weight: 700;
					padding: 0 var(--px-3);
					color: var(--neutrals-700);
				}
			}
		}
		.engagementdates-container {
			width: 40%;
			padding-left: var(--px-40);
			.expiry-label {
				background: var(--neutrals-25);
				width: 50%;
				margin-top: var(--px-65);
				.deadlinedate-icon {
					display: inline-block;
				}
				.deadlinedays-count {
					display: inline-block;
				}
				.deadline-message {
					display: inline-block;
				}
			}
		}
		.engagementfavourite-container {
			position: absolute;
			right: 0;
			bottom: 0;
			@media screen and (max-width: 1366px) {
				right: calc(var(--px-1) * -1);
				bottom: calc(var(--px-1) * -1);
			}
			@media screen and (min-width: 1367px) and (max-width: 1600px) {
				right: calc(var(--px-1) * -1);
				bottom: calc(var(--px-1) * -1);
			}
			.engagementfavourite-wrapper {
				text-align: right;
				background-image: linear-gradient(
					319deg,
					var(--neutrals-200) var(--px-30),
					transparent var(--px-0)
				);
				height: var(--px-40);
				width: var(--px-50);
				margin-left: auto;
				&.favourite-inprogress {
					background-image: linear-gradient(
						319deg,
						var(--neutrals-300) var(--px-30),
						transparent var(--px-0)
					);
					pointer-events: none;
					svg {
						cursor: default;
						pointer-events: none;
					}
				}
				svg {
					margin-top: var(--px-19);
					margin-right: var(--px-5);
					cursor: pointer;
				}
				.mark-favourite svg {
					fill: var(--neutrals-700);
				}
				.marked-favourite svg {
					fill: var(--neutrals-900);
				}
			}
		}
	}
`;

EngagementList.propTypes = {
	engagements: PropTypes.arrayOf(
		PropTypes.shape({
			workspaceID: PropTypes.string,
			workspace: PropTypes.object,
			engagements: PropTypes.object,
			client: PropTypes.object,
			clientId: PropTypes.string,
			clientName: PropTypes.string,
			clientCode: PropTypes.string
		})
	)
};

export default EngagementList;
