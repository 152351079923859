import styled from 'styled-components';

const Userlistwrapper = styled.section`
	&.inactive mgt-person {
		--color: var(--neutrals-300);
		--line2-color: var(--neutrals-300);
		//--font-weight: normal;
	}
	mgt-person {
		--color: var(--neutrals-900);
		--line2-color: var(--neutrals-900);
		//--font-weight: normal;
	}

	&:first-child .userlistitem.contextmenu .show-pop-content.top {
		bottom: auto;
		top: var(--px-15);

		& .show-popup-arrow {
			bottom: auto;
			transform: rotate(225deg);
		}
	}

	& > .userlistitem {
		& .contextmenu,
		&.contextmenu {
			font-size: var(--px-20);
			color: var(--neutrals-700);

			.context {
				display: inline-block;
				button {
					border: 0;
					background: none;
				}
				svg {
					height: var(--px-22);
					vertical-align: middle;
				}
			}

			.show-pop-content {
				margin: var(--px-10) 0 var(--px-15) 0;
			}

			.show-pop-content-body {
				width: var(--px-200);
				ul {
					line-height: normal;
					li {
						width: 100%;
						display: inline-block;
						font-size: var(--px-14);
						cursor: pointer;
						line-height: normal;

						svg {
							vertical-align: middle;
							margin-right: 1em;
						}

						a {
							color: var(--neutrals-700);
							font-weight: normal;
							text-decoration: none;
							padding: var(--px-10);
							width: 100%;
							display: inline-block;

							&:hover {
								background: var(--neutrals-100);
							}
						}
					}
				}
			}
		}

		.userdata {
			max-width: 50%;
			display: inline-block;
			vertical-align: inherit;

			mgt-person {
				max-width: 100%;
			}
		}
	}

	// internal item view specific
	&.manageaccessitemview {
		&.inactive,
		&.inactive .userlistitem {
			.userdetail,
			.useremail,
			.contextmenu {
				color: var(--neutrals-300);
			}

			&.userdetail {
				.ms-user mgt-person {
					--color: var(--neutrals-300);
				}
			}
		}
	}
`;
export default Userlistwrapper;
