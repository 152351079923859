/**
 * @module AddNewEngagementCode
 */

import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import env from '../../../util/env';
import {EYForm, FormTextInput, FormRadio} from '@ey/form';
import {Radio} from 'react-bootstrap';
import {getEngagementCodeName} from '../../../actions/engagementcodenameaction';
import {labels, urls} from '../../../util/uiconstants';
import Button from '@ey/button';
import {useEffect} from 'react';
import uuidv4 from 'uuid/v4';

const AddNewEngagementCode = (props) => {
	const dispatch = useDispatch();

	const {
		engagementcodeobject,
		getLatestEngagementCode,
		updatePrimaryEngagementCode,
		clientid,
		engagementcodeindex,
		isEngagementCodeDisabled,
		isDeletedDisabled,
		deleteEngagementCodeClick,
		updateStateOnBlur
	} = props;

	const clientengagement = useSelector((store) => store.clientengagement);
	const [engagementcodename, setEngagementcodename] = useState(
		engagementcodeobject?.name
	);
	const [isComponentActive, setIsComponentActive] = useState(false);
	const [engagementcode, setEngagementcode] = useState(
		engagementcodeobject?.code
	);

	let allClasses = 'clientname input-group';
	let error =
		engagementcodename &&
		engagementcodename.includes(labels.engagementcodevalidationmessage)
			? true
			: false;
	let invalid =
		engagementcodename &&
		engagementcodename.includes(labels.invalidengagementcode)
			? true
			: false;

	useEffect(() => {
		if (error) {
			allClasses += ' error disabled';
		}
		if (invalid) {
			allClasses += ' disabled';
		}
	}, []);

	const radioIsPrimaryChanged = (key, value) => {
		if (!_.isEmpty(value)) {
			updatePrimaryEngagementCode(value);
		}
	};

	const onBlurEngagementCode = (key, value) => {
		fetchEngagementCodeNameFromDB(value, engagementcodeindex);
	};

	useEffect(() => {
		if (isComponentActive) {
			configEngagementCode(engagementcode, engagementcodeindex);
			setIsComponentActive(false);
		}
	}, [clientengagement]);

	const fetchEngagementCodeNameFromDB = async (engcode, engcodeindex) => {
		let fetchEngagementCodeNameUrl =
			env.getURL('serviceUrl') +
			'/' +
			urls.CLIENTENGAGEMENT_URL +
			"?filters=clientengagementcode eq '" +
			engcode +
			"',clientid eq '" +
			clientid +
			"'";
		setIsComponentActive(true);
		setEngagementcode(engcode);

		if (engcodeindex === undefined) {
			const clientID =
				clientid || (engagementcodeobject && engagementcodeobject.clientid);
			const currentEngagementCodeObject = {
				code: engcode,
				name: labels.invalidengagementcode,
				clientengagementid: '',
				isprimary: engagementcodeobject?.isprimary,
				cross: '',
				invalidengagementcodeerror: 1,
				hidedeletebutton: '',
				canremove: false,
				clientcodevalid: true,
				engagementcode: engcode,
				clientcodeid: clientID,
				clientid: clientID,
				engcodename: labels.invalidengagementcode,
				engagementCodeId: uuidv4()
			};
			// incase of edit
			const engagementcodeid = engagementcodeobject && engagementcodeobject.id;
			const clientcodeid_edit =
				engagementcodeobject && engagementcodeobject.clientcodeid;
			const clientid_edit =
				engagementcodeobject && engagementcodeobject.clientid;
			if (engagementcodeid) {
				currentEngagementCodeObject.id = engagementcodeid;
				currentEngagementCodeObject.clientcodeid = clientcodeid_edit;
				currentEngagementCodeObject.clientid = clientid_edit;
			}
			setEngagementcode(engcode);
			setEngagementcodename(labels.invalidengagementcode);
			updateStateOnBlur(currentEngagementCodeObject, engcodeindex);
			if (engcode !== '') {
				await dispatch(
					getEngagementCodeName(
						fetchEngagementCodeNameUrl,
						fetchEngagementCodeNameUrl.split('/api/v1')[0]
					)
				);
			}
		}
		if (engcode != engagementcode) {
			await dispatch(
				getEngagementCodeName(
					fetchEngagementCodeNameUrl,
					fetchEngagementCodeNameUrl.split('/api/v1')[0]
				)
			);
		}
	};

	const configEngagementCode = (engcode, engcodeindex) => {
		const engagementcodenamefromDB = clientengagement && clientengagement.data;
		const clientID =
			clientid || (engagementcodeobject && engagementcodeobject.clientid);
		const currentEngagementCodeObject = {
			code: _.isEmpty(engcode) ? '' : engcode,
			name:
				engagementcodenamefromDB == null
					? labels.invalidengagementcode
					: engagementcodenamefromDB.isclientengagementvalid
					? engagementcodenamefromDB.clientengname
					: engagementcodenamefromDB.clientengname +
					  ' - ' +
					  labels.engagementcodevalidationmessage,
			clientengagementid:
				engagementcodenamefromDB == null
					? ''
					: (clientengagement && clientengagement.id) || '',
			isprimary: engagementcodeobject?.isprimary,
			cross: '',
			invalidengagementcodeerror: engagementcodenamefromDB == null ? 1 : 0,
			hidedeletebutton: '',
			canremove: false,
			clientcodevalid: true,
			engagementcode: _.isEmpty(engcode) ? '' : engcode,
			clientcodeid:
				engagementcodenamefromDB == null
					? clientID
					: engagementcodenamefromDB.clientid,
			clientid:
				engagementcodenamefromDB == null
					? clientID
					: engagementcodenamefromDB.clientid,
			engcodename:
				engagementcodenamefromDB == null
					? labels.invalidengagementcode
					: engagementcodenamefromDB.isclientengagementvalid
					? engagementcodenamefromDB.clientengname
					: engagementcodenamefromDB.clientengname +
					  ' - ' +
					  labels.engagementcodevalidationmessage,
			engagementCodeId: engcodeindex
		};
		// incase of edit
		const engagementcodeid = engagementcodeobject && engagementcodeobject.id;
		const clientcodeid_edit =
			engagementcodeobject && engagementcodeobject.clientcodeid;
		const clientid_edit = engagementcodeobject && engagementcodeobject.clientid;

		if (engagementcodeid) {
			currentEngagementCodeObject.id = engagementcodeid;
			currentEngagementCodeObject.clientcodeid = clientcodeid_edit;
			currentEngagementCodeObject.clientid = clientid_edit;
		}

		if (_.isEmpty(clientengagement)) {
			setEngagementcodename(labels.invalidengagementcode);
			updateStateOnBlur(currentEngagementCodeObject, engcodeindex);
		} else {
			setEngagementcodename(currentEngagementCodeObject.name);
			setEngagementcode(engagementcodenamefromDB.mercuryengagementcode ? engagementcodenamefromDB.mercuryengagementcode : engcode);
			updateStateOnBlur(currentEngagementCodeObject, engcodeindex);
		}
	};

	const EngagementCodeName = () => {
		return (
			<section className={allClasses}>
				<FormTextInput
					key={'engagementcodename_' + engagementcodeindex}
					placeholder={labels.engagementcodename}
					name={'engagementcodename_' + engagementcodeindex}
					value={engagementcodename}
					rules={[]}
					is_required
					help
					disabled
					maxLength="50"
					onChange={() => {}}
				/>
			</section>
		);
	};

	const EngagementCode = () => {
		return (
			<EYForm
				key={'addEngagementCode_' + engagementcodeindex}
				id={'addEngagementCode_' + engagementcodeindex}
				onChange={(k, v) => {
					getLatestEngagementCode(k, v);
				}}
				onSubmit={() => {}}
				action="\"
			>
				<FormTextInput
					key={'engagementcode_' + engagementcodeindex}
					placeholder={labels.engagementcode}
					name={'engagementcode_' + engagementcodeindex}
					value={engagementcode}
					rules={[]}
					help
					disabled={isEngagementCodeDisabled}
					maxLength="50"
					onBlur={(e) => {
						onBlurEngagementCode(e.currentTarget.name, e.currentTarget.value);
					}}
				/>
			</EYForm>
		);
	};

	let PrimaryRadioButton = () => {
		let engagementCodeAttributes = {
			disabled: isEngagementCodeDisabled
		};
		return (
			<section className="primaryradio">
				<FormRadio
					label=""
					name={'isprimaryform'}
					help
					{...engagementCodeAttributes}
					is_required
					onChange={radioIsPrimaryChanged}
				>
					<Radio
						value={engagementcodeindex}
						disabled={isEngagementCodeDisabled}
						checked={engagementcodeobject?.isprimary}
					/>
					<span className="engagement-label">{labels.isprimary} </span>
				</FormRadio>
			</section>
		);
	};

	return (
		<AddEngagementCode>
			<div className="addnewengagement" key={engagementcodeindex}>
				{EngagementCode()}
				{EngagementCodeName()}
				{PrimaryRadioButton()}
				<section className="removeengagement">
					{isDeletedDisabled ? (
						<div />
					) : (
						<Button
							name={'deleteengagementcode_' + engagementcodeindex}
							value={'deleteengagementcode_' + engagementcodeindex}
							key={'deleteengagementcode_' + engagementcodeindex}
							buttonType="link"
							disabled={isDeletedDisabled}
							label="X"
							onClick={(e) => {
								deleteEngagementCodeClick(
									e,
									engagementcodeindex,
									engagementcodeobject?.isprimary
								);
							}}
						/>
					)}
				</section>
			</div>
		</AddEngagementCode>
	);
};
export default AddNewEngagementCode;

const AddEngagementCode = styled.section`
	& .addnewengagement {
		& .form-group .radio label {
			padding: 0;
			font-weight: normal;
		}

		& .clientname,
		& .primaryradio {
			display: inline;
		}

		& .form-group .radio + .engagement-label {
			margin: var(--px-14) 0 0 var(--px-35);

			&[disabled] {
				color: var(--neutrals-700);
			}
		}

		& .clientname .form-group input.input-group {
			width: var(--px-600);
			height: var(--px-48);
			border: var(--px-1) solid var(--neutrals-300);
			display: inline;
			padding: var(--px-6) 0 0 var(--px-10);
			color: var(--neutrals-700);
		}

		&
			.clientname.input-group.disabled
			.form-group
			input.form-control[disabled]:not([type='radio']):not([type='checkbox']) {
			border: var(--px-1) solid var(--neutrals-200);
			color: var(--neutrals-200);
		}

		&
			.clientname.input-group.error.disabled
			.form-group
			input.form-control[disabled]:not([type='radio']):not([type='checkbox']) {
			color: var(--red-300);
			border: var(--px-1) solid var(--neutrals-200);
		}

		& .removeengagement {
			float: left;
			& .btn {
				color: var(--neutrals-900);
				font-weight: bold;
				text-decoration: none;
				opacitiy: 1;
				margin: var(--px-5) 0 0 var(--px-10);
				padding: 0;
				border: 0;
				background: none;

				&:active {
					box-shadow: none;
				}
			}
		}
	}
`;

/**
 * This part of the Redux lifecycle comes right after the Reducers have
 * been called, passing the new "state" values into this function, where
 * we tell Redux what portion of our Redux state to expose to this
 * React component.
 * You can think of this function as injecting data from Redux into the
 * React component.
 * The name "state.forms" refers to the "forms" name we assigned
 * in the root reducer, located in reducers/index.js
 * @param {Object} storeState - The current Redux store state
 * //@param {Object} ownProps - reference to the component's own props, which
 *      can be useful for dealing with prop values like those coming from
 *      react-router.
 * @return {{forms: (Array|*|HTMLCollection)}} The object describing the
 *      piece of the state being exposed.
 */

/**
 * The mapDispatchToProps just chooses which actions you want to expose
 * directly as props, instead of having to refer to them using the
 * .dispatch(types.SOME_ACTION_NAME) syntax.
 * The signature for bindActionCreators is (Object, Function), so if
 * you need to bind multiple actions object, use Object.assign to create
 * a new Object containing all your actions.
 * @param {Function} dispatch - dispatch Redux function
 * @return {{actions: Object}} An object containing all the actions
 *  passed as parameters, plus the dispatch function.
 */
