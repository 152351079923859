/**
 * Remember: always add new reducers to the /reducers/index.js file!
 * Also, add your initialState to './initialstate'!
 */
import * as types from '../actions/actiontypes'; //just externalizes action name constants
import initialState from './initialstate';
import {applicationErrorCode, currentResource} from '../util/uiconstants';

/**
 * This reducer function handles all the actions related to the top-level objects.
 * @param {Object} state - A portion of the overall Redux state, specifically the
 *      store.<%=names.camel%> object, representing the current data.
 * @param {Object} action - Action object containing type and other values
 * @return {*} current state or array of language objects
 */
export default function errorReducer(
	//state = initialState.<%=names.camel%>,//TODO: uncomment this. Get from initialState.
	state = initialState.errors, //{Workspaces: {}},
	action
) {
	switch (action.type) {
		case types.API_ERRORS:
			// 	// Currenly this API_ERRORS reducer is called twice...
			console.log('API_ERRORS action from error reducer:', action);

			// 	// Formatting of error responses (from APIs) does not adhere to a common pattern.
			// 	// Depending on contents of http response the following may need to be expanded.
			// 	// Also, returning serialized objects will not trigger subsequent errors of the same type.
			// 	// See Errors.js component for expected error format.

			//If application gateway is rejecting input messages due to some suspicious special characters(forbidden by EY Information Security).
			if (action.error?.response?.status === 403 && action?.error?.response?.data?.ErrorCode === undefined) {
				return {
					message: currentResource.errors[200001],
					level: 'error'
				};
			}

			// timeline errors should be ignored alone, as we will try to sync status.
			if (
				action.error &&
				action.error?.config &&
				action.error?.config?.url &&
				action.error?.config?.url.indexOf('timeline.json') > 0
			) {
				return true;
			}
			if (
				action.error?.response &&
				action.error?.response?.data &&
				Array.isArray(action.error?.response?.data?.ErrorCode)
			) {
				let errors = [];
				action.error.response.data.ErrorCode.some((errorCode) => {
					if (isNaN(parseInt(errorCode))) {
						errors = [];
						return true; // exit some()
					}

					errors.push({
						ErrorCode: errorCode,
						Message: action.error.response.data.ErrorMessage,
						Timestamp: Date.now()
					});
				});

				if (errors.length > 0) {
					return JSON.stringify(errors);
				}
			}

			return action.error;

		case types.CLIENT_VALIDATION_ERROR:
			console.log('CLIENT_VALIDATION_ERROR action from error reducer:', action);
			return JSON.stringify({
				ErrorCode: action.errorCode,
				Timestamp: Date.now()
			});

		case types.CLEAR_ERRORS:
			console.log('Clear error action called.');
			return {};

		default:
			return state;
	}
}
