import React, { useState } from 'react';
import { useEffect } from 'react';
import env from '../../../util/env';
import { getPermissionGroupUsers, addPermissionGroupUsers } from '../../../actions/admin/permissiongroupsactions';
import { pagingOptions } from '../../../util/uiconstants';
import Pagination from '@ey/pager';
import { useSelector, useDispatch } from 'react-redux';
import Tooltip from '@ey/motif-react-wrapper/Tooltip';
import Button from '@ey/motif-react-wrapper/Button';import Loader from '@ey/loader';
import { useParams } from 'react-router-dom';
import { labels } from '../../../util/uiconstants';
import { UserLookupType, successMessageDisplayDuration } from '../../../util/uiconstants';
import { setIsModalOpen } from '../../../actions/modalstateactions';
import { clearErrors } from '../../../actions/erroractions';
import AccessDeniedMessage from '../../Common/AccessDeniedMessage/AccessDeniedMessage';
import ModalControl from '../../Common/Modal/Modal';
import AddUser from '../../Common/AddUser/AddUser';
import Toast from '../../Common/Toast/Toast';
import { getLocalizedAPIError } from '../common/util';
import PermissionGroupUsersTable from './permissiongroupsuserslist/permissiongroupuserstable';
import Wrapper from '../permissiongroups/styles/permissiongroupuserlistwrapperstyle';
import { deletePermissionGroupUsers } from '../../../actions/admin/permissiongroupsactions';
import TicketModal from '../common/ticketmodal';

function PermissionGroupUserList() {
	const permissionGroupUsers = useSelector((store) => store.permissionGroupUsers);
	const userPermissions = useSelector((store) => store.userPermissions);
	const [optionPerPage, setOptionPerPage] = useState(pagingOptions.options[0]);
	const [activeGroupDescription, setActiveGroupDescription] = useState();
	const [activeGroupName, setActiveGroupName] = useState();
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState();
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const activeDirectoryGroupID = useParams();
	const [showAddMember, setShowAddMember] = useState(false);
	const [addUserSuccessMessage, setAddUserSuccessMessage] = useState('');
	const [addUserErrorMessage, setAddUserErrorMessage] = useState([]);
	const [addUserLoading, setAddUserLoading] = useState(false);
	const duration = successMessageDisplayDuration;
	const [errorMessage, setErrorMessage] = useState('');
	const [showDeleteTicketModal, setShowDeleteTicketModal] = useState(false);
	const [memberToBeDeleted, setMemberToBeDeleted] = useState({});

	useEffect(() => {
		loadPermissionGroupUserList();
	}, [page, optionPerPage]);

	const loadPermissionGroupUserList = () => {
		setLoading(true);
		let permissionGroupUrl = env.getURL('serviceUrlV2') + 'admin/PermissionGroups/' +
			activeDirectoryGroupID?.activedirectorygroupid + '/users?page=' +
			page + '&pageSize=' + optionPerPage;

		dispatch(getPermissionGroupUsers(permissionGroupUrl))
			.then(() => {
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		var pages =
			permissionGroupUsers?.totalEntityCount % optionPerPage == 0
				? permissionGroupUsers?.totalEntityCount / optionPerPage
				: (permissionGroupUsers?.totalEntityCount -
					(permissionGroupUsers?.totalEntityCount % optionPerPage)) / optionPerPage + 1;

		setTotalPages(pages);
	}, [optionPerPage, permissionGroupUsers]);

	const paginationHandler = (value) => {
		if (page !== value && page > 0) {
			setPage(value);
		}
	};

	const handlerOptionPage = (value) => {
		if (value !== optionPerPage) {
			setOptionPerPage(value);
			setPage(1);
		}
	};

	useEffect(() => {
		for (let i = 0; i < permissionGroupUsers?.activeDirectoryGroupData?.length; i++) {
			setActiveGroupDescription(
				permissionGroupUsers?.activeDirectoryGroupData[0]
					?.activeDirectoryGroupDescription
			);
			setActiveGroupName(
				permissionGroupUsers?.activeDirectoryGroupData[0]
					?.activeDirectoryGroupName
			);
		}
	}, [permissionGroupUsers]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setAddUserSuccessMessage('');
		}, duration);
		return () => clearTimeout(timer);
	});

	useEffect(() => { }, [showAddMember]);

	const openAddMemberModal = () => {
		setShowAddMember(!showAddMember);
	};

	const closeAddMemberModal = () => {
		setShowAddMember(!showAddMember);
		setAddUserErrorMessage([]);
	};

	const saveUsers = (selectedUsers, ticketNumber) => {
		if (selectedUsers?.length <= 0 || !ticketNumber) {
			return;
		}

		setAddUserLoading(true);
		let permissionGroupUserUrl = env.getURL('serviceUrlV2') + 'admin/PermissionGroups/' +
			activeDirectoryGroupID?.activedirectorygroupid + '/users';

		let errorMessages = [];
		selectedUsers.forEach((user, index) => {
			dispatch(
				addPermissionGroupUsers(
					permissionGroupUserUrl,
					{
						ticketNumber: ticketNumber,
						emailAddress: user.emailAddress
					}
				)
			)
				.then(() => {
					if (selectedUsers.length == index + 1) {
						setAddUserLoading(false);
						setAddUserSuccessMessage(labels.addMemberSuccessMessage);
						closeAddMemberModal();
						loadPermissionGroupUserList();
					}
				})
				.catch((result) => {
					setAddUserLoading(false);
					let errorText = getLocalizedAPIError(result);
					if (errorText === labels.insufficientPermission) {
						setAddUserErrorMessage([errorText]);
					} else {
						errorMessages.push(user.emailAddress + ' : ' + errorText);
						if (selectedUsers.length === index + 1) {
							setAddUserErrorMessage(errorMessages);
						}
					}
				});
		});
	};

	const onDeleteClick = (ticketNumber) => {
		setLoading(true);
		let deletePermissionGroupUsersUrl = env.getURL('serviceUrlV2') + 'admin/permissiongroups/' +
			activeDirectoryGroupID?.activedirectorygroupid + '/users/' +
			memberToBeDeleted?.activeDirectoryGroupUserId + '?ticketNumber=' +
			encodeURIComponent(ticketNumber);

		dispatch(deletePermissionGroupUsers(deletePermissionGroupUsersUrl))
			.then(() => {
				setLoading(false);
				loadPermissionGroupUserList();
			})
			.catch((result) => {
				setLoading(false);
				let errorText = getLocalizedAPIError(result);
				setErrorMessage(errorText);
			});
	}

	useEffect(() => {
		clearError(true);
		return () => {
			clearError(false);
		};
	}, [onDeleteClick]);

	const clearError = (isModelOpen) => {
		dispatch(clearErrors()).then(() => {
			dispatch(setIsModalOpen(isModelOpen));
		});
	};

	const getToastMessage = () => {
		return (
			<>
				<section
					className={
						'toastMessage ' +
						(addUserSuccessMessage.length > 0 ? 'block' : 'none')
					}
				>
					<Toast
						variant="success"
						duration={duration}
						onClose={() => setAddUserSuccessMessage('')}
					>
						{addUserSuccessMessage}
					</Toast>
				</section>
			</>
		);
	};

	function adminPermissions() {
		let permissionGroupPermission = userPermissions?.permissions;
		return (
			permissionGroupPermission &&
			(permissionGroupPermission.canReadPermissionsGroups ||
				permissionGroupPermission.canSyncPermissionsGroups ||
				permissionGroupPermission.canCreateUpdatePermissionsGroups)
		);
	}

	return (
		<Wrapper>
			<section className="mainwrapper">
				{adminPermissions() ? (
					<>
						<section className="mainResponsiveWrapper">
							{getToastMessage()}

							<section
								className="toastMessage"
								style={{ display: errorMessage?.length > 0 ? 'block' : 'none' }}
							>
								<Toast
									variant="error"
									onClose={() => {
										setErrorMessage('');
									}}
								>
									{errorMessage}
								</Toast>
							</section>
							<section className="headerdetail">
								<section className="title">
									<section className="activediretory title">
										{loading ? '' : activeGroupName}
									</section>

									<section className="activediretory">
										{loading ? '' : activeGroupDescription}
									</section>
								</section>
								<Tooltip
									placement={'bottom'}
									hide={userPermissions?.permissions?.canCreateUpdatePermissionsGroups}
									trigger={
										<section className="addmember button">
											<Button
												aria-label="Add Member"
												role="button"
												type="button"
												variant="primary-alt"
												disabled={!userPermissions?.permissions?.canCreateUpdatePermissionsGroups}
												onClick={openAddMemberModal}
											>
												{labels.permissionGroupAddMember}
											</Button>
										</section>
									}
								>
									{labels.insufficientPermission}
								</Tooltip>
							</section>
						</section>
						{loading ? (
							<Loader show view="blockView" styleName="loadingstyle" />
						) : permissionGroupUsers?.totalEntityCount > 0 ? (
							<section>
								<section className="permissiongroup mainResponsiveWrapper">
									<section className="managepermissionTable">
										<PermissionGroupUsersTable
											data={permissionGroupUsers?.data}
											onDeleteClick={
												(member) => {
													setMemberToBeDeleted(member);
													setShowDeleteTicketModal(true);
												}
											}
										/>
									</section>
								</section>
							</section>
						) : (
							<section className="norecord">{labels.noResultsFound}</section>
						)}

						<section className="pagerholder">
							<Pagination
								styleName="permissiongroup-paging mainResponsiveWrapper"
								options_per_page={pagingOptions.options}
								default_dropdown_value={optionPerPage}
								currentPage={page}
								TotalPages={totalPages}
								dropup
								onInputChange={(e) => {
									let inputValue = Number.parseInt(e.target.value);
									paginationHandler(inputValue);
								}}
								onDropdownChange={(e) => {
									handlerOptionPage(e.value);
								}}
								onFirstPageClick={() => {
									paginationHandler(1);
								}}
								onPrevPageClick={() => {
									paginationHandler(page - 1);
								}}
								onNextPageClick={() => {
									paginationHandler(page + 1);
								}}
								onLastPageClick={() => {
									paginationHandler(totalPages);
								}}
								dropdown_position="left"
							/>
						</section>
						<section>
							{
								showDeleteTicketModal && <TicketModal
									modalHeaderLabel={labels.permissionGroupUserDeleteHeader}
									confirmationMessage={labels.confirmationMessage}
									confirmButtonLabel={labels.permissionGroupUserDeleteLabel}
									onHide={setShowDeleteTicketModal}
									onConfirmClick={onDeleteClick}
								/>
							}
						</section>
						<ModalControl
							className="adduser-modal"
							title={labels.permissionGroupAddMember}
							onOkClick={() => { }}
							onHide={closeAddMemberModal}
							isOkButtonDisabled={false}
							show={showAddMember}
							modalsize="small"
							showButtons={false}
							closeTitle={labels.close}
							role="dialog"
							focusTrapActive={false}
						>
							<AddUser
								onNewUserModalClose={closeAddMemberModal}
								addUserErrorMessage={addUserErrorMessage}
								userLookupType={UserLookupType.Internal}
								onSaveUsersClick={saveUsers}
								addUserLoading={addUserLoading}
								addUserDescription={labels.addMemberDescription}
							/>
						</ModalControl>
					</>
				) : (
					<AccessDeniedMessage
						permissionMesage={labels.insufficientPermission}
					/>
				)}
			</section>
		</Wrapper>
	);
}

export default PermissionGroupUserList;
