import './RichTextEditorWrapper.less';
import React, { useEffect, useState } from 'react';
import { RichTextEditor } from '@ey/canvascoreservices';
import { labels, defaultMaxCharCountKendo } from '../../../util/uiconstants';
import messages from './LoadMessages';
import ErrorMessage from '@ey/motif-react-wrapper/ErrorMessage';

const RichTextEditorWrapper = (props) => {
	const {
		charactersLabel,
		dataAutoID,
		disabled,
		getPlainText,
		label,
		maxCharCount,
		ofLabel,
		onChange,
		onCtrlSave,
		optional,
		optionalText,
		placeholder,
		required,
		requiredMessageText,
		resizable,
		showCharacterCount,
		showMaxCharacterCount,
		setRef,
		maxCharErrorMessageText,
		useMinimalToolset,
		value
	} = props;

	const [errorMessage, setErrorMessage] = useState('');
	const [showErrorMessage, setShowErrorMessage] = useState(false);

	useEffect(() => {
		let plainText = richTextToPlainText(value);
		if (getPlainText) { 
			getPlainText(plainText); 
		}

		if (value?.length > (maxCharCount || defaultMaxCharCountKendo)) {
			setShowErrorMessage(true);
			setErrorMessage(maxCharErrorMessageText || labels.kendoTextLimitMessage);
		}
		else if (required && plainText.length === 0) {
			setShowErrorMessage(true);
			setErrorMessage(requiredMessageText || labels.kendoRequiredMessage);
		}
		else {
			setShowErrorMessage(false);
			setErrorMessage('');
		}
	}, [value?.length]);

	const richTextToPlainText = (richText) => {
		let divElement = document.createElement('div');
		divElement.innerHTML = richText;
		return divElement.innerText.replace(/\uFEFF/g, '').trim();
	}

	return (
		<>
			<RichTextEditor
				charactersLabel={charactersLabel}
				characterCountPrefix={charactersLabel || labels.characters}
				dataAutoID={dataAutoID}
				disabled={disabled}
				isTranslate={false}
				label={label}
				maxCharCount={maxCharCount}
				messages={messages}
				ofLabel={ofLabel}
				onChange={onChange}
				onCtrlSave={onCtrlSave}
				optional={optional}
				optionalText={optionalText}
				placeholder={placeholder || labels.placeholder}
				required={required}
				resizable={resizable}
				showCharacterCount={showCharacterCount}
				showMaxCharacterCount={showMaxCharacterCount}
				setRef={setRef}
				userLanguage='en-us'
				useMinimalToolset={useMinimalToolset}
				value={value || ''}
			/>
			{showErrorMessage && (
				<ErrorMessage>
					{errorMessage}
				</ErrorMessage>
			)}
		</>
	);
};
RichTextEditorWrapper.displayName = 'RichTextEditorWrapper';

export default RichTextEditorWrapper;
