import React, { useState } from 'react';
import Input from '@ey/motif-react-wrapper/Input';
import ErrorMessage from '@ey/motif-react-wrapper/ErrorMessage';
import FormField from '@ey/motif-react-wrapper/FormField';
import Label from '@ey/motif-react-wrapper/Label';
import Button from '@ey/motif-react-wrapper/Button';
import { labels } from '../../../util/uiconstants';
import Loader from '../../Common/Loaders/loadingindicator';
import styled from 'styled-components';
import ModalControl from '../../Common/Modal/Modal';



const TicketInputWrapper = styled.section`
.ticketdetail{
    .loadingstyle {
		height: calc(100vh - var(--px-780)) !important;
	}

	.modal-center{
        .message{
            padding: var(--px-20) var(--px-20) var(--px-80);
            h5{
                font-size: var(--px-14);
                color: var(--neutrals-900);
            }
        }

		.confirmationmessage {
			h5 {
				font-size: var(--px-14);
				font-weight: normal;

			}	
		}

		.motif-form-field{
			width: var(--px-290);
		}

		.motif-form-field:not(.motif-form-field-has-value),
		.motif-form-field:not(.motif-form-field-focused){
			.motif-label{
				margin-top: var(--px-6);
			}
		}

		.motif-form-field-has-value,
		.motif-form-field-focused{
			.motif-label{
				margin-top: var(--px-2)!important;
			}
		}
		
		.motif-input {			
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.modal-footer {
			width: 100%;
			display: inline-flex;
			button {
				margin-right: var(--px-10);
			}
		}
        }
	} 


`;

function TicketInput(props) {
	const [ticketNumber, setTicketNumber] = useState('');
	const [isSubmitClicked, setIsSubmitClicked] = useState(false);

	const closeTicketModal = () => {
		props.onHide(false);
	};

	const onConfirmClick = () => {
		if (!ticketNumber || ticketNumber.trim().length == 0) {
			setIsSubmitClicked(true);
		} else {
			props.onConfirmClick(ticketNumber.trim());
			props.onHide(false);
		}
	};

	return (
		<TicketInputWrapper>
			<section className="ticketdetail">
				<section className="modal-center">
					<section className="message">
						<section className="confirmationmessage" aria-label="message">
							<h5>
								{props.confirmationMessage
									? props.confirmationMessage
									: labels.confirmationMessage}
							</h5>
						</section>
						<section>
							<section>
								<FormField>
									<Label>{labels.ticketNumber}</Label>
									<Input
										value={ticketNumber}
										maxLength={100}
										clearButtonTitle={labels.clear}
										onChange={(event) => {
											setTicketNumber(event.target.value);
										}}
									></Input>
									{(!ticketNumber || ticketNumber.trim().length == 0) && isSubmitClicked && (
										<ErrorMessage>
											{labels.validationMessage}
										</ErrorMessage>
									)}
								</FormField>
							</section>
						</section>
					</section>
					<section className="modal-footer">
						<Button
							type="button"
							variant="primary-alt"
							disabled={(!ticketNumber || ticketNumber.trim().length == 0) ? true : false}
							onClick={() => {
								onConfirmClick();
							}}
						>
							{props.confirmButtonLabel
								? props.confirmButtonLabel
								: labels.save}
						</Button>
						<Button
							type="button"
							variant="secondary"
							onClick={() => closeTicketModal()}
						>
							{props.cancelButtonLabel
								? props.cancelButtonLabel
								: labels.cancelButtonLabel}
						</Button>
					</section>
				</section>
			</section>
		</TicketInputWrapper>
	);
}


function TicketModal(props) {
	const [isLoading, setIsLoading] = useState(false);

	return (
		<>
			{isLoading ? (
				<Loader show view="blockView" styleName="loadingstyle" />
			) : (
				<ModalControl
					title={props.modalHeaderLabel}
					onOkClick={() => { }}
					onHide={() => props.onHide(false)}
					isOkButtonDisabled={true}
					show={true}
					modalsize="message"
					showButtons={false}
					closeTitle={labels.close}
					role="dialog"
					focusTrapActive={false}
				>
					<TicketInput
						onHide={props.onHide}
						confirmationMessage={props.confirmationMessage}
						confirmButtonLabel={props.confirmButtonLabel}
						cancelButtonLabel={props.cancelButtonLabel}
						onConfirmClick={props.onConfirmClick} />
				</ModalControl>
			)}
		</>
	);
}
export default TicketModal;