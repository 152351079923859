import React, {useState} from 'react';
import styled from 'styled-components';
import {labels} from '../../util/uiconstants';
import ModalControl from '@ey/modal-control';
import Loader from '@ey/loader';

const EngagementInvalidModal = (props) => {
	const {show, hide, clientName, clientCode, data, workspaceID, id} = props;
	const {
		engagementcodename,
		engagementcode,
		isclientcodevalid,
		isengagementcodevalid,
		hasengagementpassedalloweddays
	} = data;

	const [isActing, setIsActing] = useState(false);

	const handleInvalidEngagementConfirm = async () => {
		setIsActing(true);
		let url = '';

		if (
			!isclientcodevalid &&
			!isengagementcodevalid &&
			hasengagementpassedalloweddays
		) {
			url = '/editworkspace?workspaceid=' + workspaceID;
		} else if (!isengagementcodevalid && hasengagementpassedalloweddays) {
			url =
				'/editengagement?workspaceid=' + workspaceID + '&engagementid=' + id;
		} else if (!isclientcodevalid && hasengagementpassedalloweddays) {
			url = '/editworkspace?workspaceid=' + workspaceID;
		}
		window.location.href = url;
		hide();
	};

	return (
		<div>
			{isActing ? (
				<section>
					<Loader view="fullscreen" />
				</section>
			) : (
				<ModalControl
					title={labels.invalidEngagementTitle}
					closeTitle={labels.close}
					confirmBtnTitle={labels.continue}
					closeBtnTitle={labels.cancel}
					modalsize="small"
					onOkClick={handleInvalidEngagementConfirm}
					show={show}
					onHide={hide}
					showButtons={true}
					focusTrapActive={false}
					theme={
						document
							.getElementsByTagName('body')[0]
							.getAttribute('motif-theme') === 'light'
							? null
							: 'dark'
					}
				>
					<InvalidEngagementStyle>
						<div id="message">
							<h6 className="inval-engmsg">
								{labels.invalidEngagementMessage}
							</h6>
							<section className="invalmsg-container">
								<span className="invalmsg-lbl">{labels.clientCode}: </span>
								<span className="inval-msg">
									{clientCode === '!!!'
										? labels.invalidclientcode
										: clientName + ' | ' + clientCode}
								</span>
							</section>
							<section className="invalmsg-container">
								<span className="invalmsg-lbl">
									{labels.primaryEngagementCode}:{' '}
								</span>
								<span className="inval-msg">
									{engagementcodename}
									{engagementcode ? ' | ' + engagementcode : ''}
								</span>
							</section>
						</div>
					</InvalidEngagementStyle>
				</ModalControl>
			)}
		</div>
	);
};
export default EngagementInvalidModal;

const InvalidEngagementStyle = styled.section`
	.inval-engmsg {
		font-size: var(--px-14);
		font-weight: normal;
		line-height: normal;
		margin-bottom: var(--px-25);
		color: var(--neutrals-900);
	}

	.invalmsg-container {
		color: var(--neutrals-900);
		.invalmsg-lbl {
			font-size: var(--px-16);
			font-weight: bold;
		}
		.inval-msg {
			font-size: var(--px-14);
			font-weight: normal;
		}
	}
`;
