import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ViewAccessItem from './viewaccessitem';
import EllipsesControl from '@ey/ellipses-control';
import styled from 'styled-components';
import $ from 'jquery';
import Loader from '@ey/loader';
import env from '../../util/env';
import * as ViewAccessListActions from '../../actions/viewaccesslistaction';
import {urls, labels} from '../../util/uiconstants';

const Acceslist = styled.section`
	padding: var(--px-10) var(--px-24) var(--px-30) var(--px-24);
	height: calc(100vh - var(--px-200));
	overflow: auto;

	.access-list-engname {
		display: block;
		.ellipses {
			font-size: var(--px-16);
			line-height: normal;
		}
	}

	.access-listtable {
		border: var(--px-1) solid var(--neutrals-300);
		border-top: 0;
		width: 100%;

		& thead.adjustHeaderLeft {
			width: calc(calc(var(--px-17) * -1) + 100%);
			display: table;
			background: var(--neutrals-900);

			& .teammembers-table-title {
				width: calc(var(--px-18) + 100%);
			}

			& .teammembers-header {
				border-right: var(--px-1) solid var(--neutrals-00white);

				& th:last-child {
					border-right: 0;
				}
			}
		}

		& thead tr.teammembers-header:last-child {
			border-bottom: 0;
		}

		& tbody {
			display: block;
			max-height: calc(100vh - var(--px-373));
			overflow-y: auto;
			width: 100%;
			border: 0;
			border-top: var(--px-1) solid var(--neutrals-300);

			& tr:first-child {
				border-top: 0;

				& td {
					border-top: 0;
				}
			}
		}

		& tr {
			display: table;
			width: 100%;
			box-sizing: border-box;
		}

		& thead tr:last-child,
		& thead tr:last-child th {
			border-top: 0;
		}

		& thead tr:last-child {
			border-bottom: var(--px-1) solid var(--neutrals-300);
		}

		// & thead tr,
		// & thead tr th:last-child,
		// & tbody tr,
		// & tbody tr td:last-child {
		// 	border-right: 0;
		// }

		& th,
		& td {
			width: 20%;
			padding: var(--px-5);
			word-break: break-all;
			border-right: var(--px-1) solid var(--neutrals-300);
		}

		& .teammembers-table-title th {
			background: var(--neutrals-900);
			color: var(--neutrals-00white);
			font-weight: bold;
		}

		& tr,
		& td,
		& th {
			background: var(--neutrals-00white);
			border-top: var(--px-1) solid var(--neutrals-300);
			line-height: normal;
			padding: var(--px-12);
			margin: 0;
			font-size: var(--px-15);
			&.access-list-loader {
				padding: 0 0 18%;
			}
		}

		& tr.teammembers-table-title,
		& tr.teammembers-table-title th {
			border-top: 0;
		}

		& tr.teammembers-table-title th {
			border-right: var(--px-1) solid var(--neutrals-900);
		}

		& td:last-child,
		& th:last-child {
			border-right: var(--px-1) solid var(--neutrals-00white);
		}

		.list-title.user_name > .ellipses,
		.list-title.user_initial > .ellipses,
		.list-title.user_role > .ellipses,
		.list-title.user_email > .ellipses,
		.list-title.user_contact > .ellipses {
			width: 100%;
			display: inline-grid;
		}

		.list-title .ellipses {
			display: block;
			line-height: normal;
			vertical-align: middle;
		}
	}
`;

class ViewAccessList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false
		};
	}

	componentDidMount = () => {
		let getViewAccessListurl =
			env.getURL('serviceUrl') +
			'/' +
			urls.ENGAGEMENTUSERS_URL +
			'/' +
			this.props.engagementId;
		//console.log(getViewAccessListurl);

		this.setState({isLoading: true});

		this.props.actions
			.getViewAccessList(
				getViewAccessListurl,
				getViewAccessListurl.split('/api/v1')[0]
			)
			.then(
				(() => {
					this.setState({
						isLoading: false
					});
				}).bind(this)
			)
			.catch((error) => {
				console.log(error);
				this.setState({isLoading: false});
			});
	};

	componentDidUpdate = () => {
		var scrollContainer = $('#tableContainer').prop('scrollHeight');
		var maxHeight = $('#access-listtable tbody').outerHeight(true);
		if (scrollContainer > maxHeight) {
			$('#tableContainer').css('overflow', 'auto');
			$('#access-listtable thead').addClass('adjustHeaderLeft');
		} else {
			$('#tableContainer').css('overflow', 'hidden');
			$('#access-listtable thead').removeClass('adjustHeaderLeft');
		}
	};

	render() {
		return (
			<Acceslist>
				<h6 className="access-list-engname">
					<EllipsesControl
						id="engagementname"
						content={this.props.engagementName}
						tooltip={this.props.engagementName}
						isTooltipAvailable
					/>
				</h6>

				<table className="access-listtable" id="access-listtable">
					<thead>
						<tr className="teammembers-table-title">
							<th colSpan="5">{labels.teammembers} </th>
						</tr>
						<tr className="teammembers-header">
							<th className="user_name list-title">{labels.name} </th>
							<th className="user_initial list-title">{labels.initials} </th>
							<th className="user_role list-title">{labels.role} </th>
							<th className="user_email list-title"> {labels.email} </th>
							<th className="user_contact list-title">{labels.contact} </th>
						</tr>
					</thead>

					<tbody className="tableContainer" id="tableContainer">
						{this.state.isLoading ? (
							<tr>
								<td colSpan="5" className="access-list-loader">
									<Loader />
								</td>
							</tr>
						) : (
							this.props.accessList &&
							this.props.accessList.collections &&
							this.props.accessList.collections.teammembers &&
							this.props.accessList.collections.teammembers.map(
								(teammember) => (
									<ViewAccessItem
										key={teammember.data.userid}
										{...teammember}
									/>
								)
							)
						)}
					</tbody>
				</table>
			</Acceslist>
		);
	}
}

const mapStateToProps = (storeState) => {
	return {
		accessList: storeState.viewAccessList
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(
			Object.assign({}, ViewAccessListActions),
			dispatch
		)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAccessList);
