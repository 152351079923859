import React, {useState, useEffect, useRef} from 'react';
import {labels} from '../../../util/uiconstants';
import styled from 'styled-components';

export default function ShowMoreLess(props) {
	const {children} = props;

	const [showMoreLink, setShowMoreLink] = useState(false);
	const [showLessLink, setShowLessLink] = useState(false);

	let SeeMoreRef = useRef(null);

	useEffect(() => {
		checkBodyItemHeight();
	}, []);

	const showLessRichtext = () => {
		setShowMoreLink(true);
		setShowLessLink(false);
		SeeMoreRef.current.style.height = '120px';
	};

	const showMoreRichtext = () => {
		setShowMoreLink(false);
		setShowLessLink(true);
		SeeMoreRef.current.style.height = SeeMoreRef.current.scrollHeight + 'px';
	};

	const checkBodyItemHeight = () => {
		let getBodyItemHeight = SeeMoreRef.current.clientHeight;

		if (getBodyItemHeight > 130) {
			setShowMoreLink(true);
		}
	};

	return (
		<StyledShowMoreLess
			className={`body-item-showless 
				${showMoreLink ? 'showMoreLink' : ''}${showLessLink ? 'showLessLink' : ''}`}
		>
			<section className="richText-Body" ref={SeeMoreRef}>
				{children}
			</section>

			<section>
				<a
					className="show-more-body"
					onClick={showMoreRichtext}
					title={labels.showMore}
				>
					{labels.showMore}
				</a>
				<a
					className="show-less-body"
					onClick={showLessRichtext}
					title={labels.showLess}
				>
					{labels.showLess}
				</a>
			</section>
		</StyledShowMoreLess>
	);
}

const StyledShowMoreLess = styled.section`
	transition: all 0.3s ease-out;
	word-wrap: break-word;
	.show-more-body,
	.show-less-body {
		display: none;
		cursor: pointer;
	}
	&.showMoreLink {
		.richText-Body {
			height: 100px;
			overflow: hidden;
			position: relative;
			&:after {
				left: 0;
				content: '';
				background-image: -moz-linear-gradient(
					top,
					transparent,
					var(--neutrals-1000)
				);
				background-image: -webkit-gradient(
					linear,
					left top,
					left bottom,
					color-stop(0, transparent),
					color-stop(1, var(--neutrals-00white))
				);
				position: absolute;
				bottom: 0;
				width: 100%;
				height: 50px;
			}
		}
		.show-more-body {
			display: inline-block;
			color: #0070c0;
			font-weight: 700;
			padding: inherit;
		}
	}
	&.showLessLink {
		.richText-Body {
			height: auto;
		}
		.show-less-body {
			display: inline-block;
			color: #0070c0;
			font-weight: 700;
			padding: inherit;
		}
	}
`;
