import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import FormField from '@ey/motif-react-wrapper/FormField';
import Label from '@ey/motif-react-wrapper/Label';
import Select from '@ey/motif-react-wrapper/Select';
import Option from '@ey/motif-react-wrapper/Option';
import Input from '@ey/motif-react-wrapper/Input';
import ToggleSwitch from '@ey/motif-react-wrapper/ToggleSwitch';
import Button from '@ey/motif-react-wrapper/Button';
import { labels } from '../../../../util/uiconstants';
import { getRegion } from '../../../../actions/admin/masterdataactions';
import { editTemplate } from '../../../../actions/admin/templatemanagementaction';
import { clearErrors } from '../../../../actions/erroractions';
import { setIsModalOpen } from '../../../../actions/modalstateactions';
import Loader from '../../../Common/Loaders/loadingindicator';
import env from '../../../../util/env';
import Toast from '../../../Common/Toast/Toast';
import EllipsesControl from '@ey/ellipses-control';
import { getLocalizedAPIError } from '../../common/util';
import TicketField from '../../common/ticketfield';

function TemplateDetails(props) {
	// hooks
	var editTemplateData = props.editTemplateData;
	const [templateName, setTemplateName] = useState(
		editTemplateData?.templateName
	);
	const [status, setStatus] = useState(editTemplateData?.isPublished);
	const [selectedRegionIds, setRegionIds] = useState(
		editTemplateData?.regions?.map((x) => x.regionId.toString())
	);
	const [isAssignButonDisable, setIsAssignButonDisable] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const regions = useSelector((store) => store.regions);
	const [errorMessage, setErrorMessage] = useState(false);
	const [ticketNumber, setTicketNumber] = useState('');
	const [isSubmitClicked, setIsSubmitClicked] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		clearError(true);
		if (Object.keys(regions).length === 0) {
			getRegions();
		}
	}, []);

	useEffect(() => {
		if (
			templateName == editTemplateData?.templateName &&
			selectedRegionIds.every((r) =>
				editTemplateData?.regions?.some((er) => er.regionId == r)
			) &&
			editTemplateData.regions.length == selectedRegionIds.length &&
			status == editTemplateData?.isPublished ||
			ticketNumber.trim().length <= 0
		) {
			setIsAssignButonDisable(true);
		} else if (
			templateName?.trim() &&
			selectedRegionIds &&
			selectedRegionIds?.length > 0
		) {
			setIsAssignButonDisable(false);
		} else {
			setIsAssignButonDisable(true);
		}
	}, [templateName, selectedRegionIds, status, ticketNumber]);

	const onTicketChanged = (e) => {
		setTicketNumber(e.target.value);
	}

	const clearError = (isModelOpen) => {
		dispatch(clearErrors()).then(() => {
			dispatch(setIsModalOpen(isModelOpen));
		});
	};

	const handlerOnEditTemplateName = (e) => {
		setTemplateName(e.target.value);
	};

	const saveTemplate = () => {
		setIsSubmitClicked(true);
		if (ticketNumber.trim().length <= 0) {
			return;
		}

		setIsLoading(true);
		const regionIds = selectedRegionIds?.map((regionId) => {
			return Number(regionId);
		});

		var templateDetails = {
			templateName: templateName,
			isTemplatePusblished: status,
			areaRegionList: regionIds ? regionIds : [],
			ticketNumber: ticketNumber
		};

		let editTemplateUrl =
			env.getURL('serviceUrlV2') +
			`admin/engagementtemplates/${editTemplateData.copyRequestId}`;
		dispatch(editTemplate(editTemplateUrl, templateDetails))
			.then(() => {
				props.setIsTemplateEdited(true);
				props.closeEditTemplateModal();
				props.setEditTemplateSuccessMessage(labels.templateEditSuccessMessage);
			})
			.catch((result) => {
				var localError = getLocalizedAPIError(result);
				setErrorMessage(localError);
			})
			.finally(() => {
				setIsLoading(false);
				setIsSubmitClicked(false);
			});
	};

	const formatOptionLabel = ({ value, label }) => (
		<EllipsesControl content={label} tooltip={label} noOfLines={1} />
	);

	const dropDownOnChange = (option) => {
		setRegionIds(option);
	};

	const getRegions = () => {
		setIsLoading(true);
		let getRegionUrl = env.getURL('serviceUrlV2') + 'Regions';
		dispatch(getRegion(getRegionUrl))
			.then(() => { })
			.catch((e) => {
				setErrorMessage(e.toString());
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const getRegionsForDropDown = () => {
		let regionCollection = [];
		if (Object.keys(regions).length > 0) {
			_.forEach(regions, (item) => {
				regionCollection.push({
					label: item.regionName,
					value: item.regionID.toString()
				});
			});
		}
		return regionCollection;
	};

	const createDropDown = (collection) => {
		return (
			<FormField>
				<Label id="select-label-search">
					{labels.templateAreaRegion}
				</Label>
				<Select
					id={'region'}
					onChange={(e) => dropDownOnChange(e)}
					filter
					arialabelledby="select-label-search"
					multiple={true}
					showselectallbutton={true}
					value={selectedRegionIds}
				>
					{collection?.map((item) => (
						<Option
							key={item.value}
							value={item.value}
							label={item.label}
							formatOptionLabel={formatOptionLabel(item.value)}
						>
							{item.label}
						</Option>
					))}
				</Select>
			</FormField>
		);
	};

	const hideEditTemplateDrawer = () => {
		setTemplateName(null);
		setRegionIds(null);
		setErrorMessage(false);
		props.closeEditTemplateModal();
	};

	return (
		<Mainwrapper>
			{isLoading ? (
				<Loader show view="blockView" styleName="loadingstyle" />
			) : (
				<section>
					<section
						className="toastMessage"
						style={{ display: errorMessage ? 'block' : 'none' }}
					>
						<Toast
							variant="error"
							onClose={() => {
								setErrorMessage(false);
							}}
						>
							{errorMessage}
						</Toast>
					</section>
					<section className="edittemplate">
						<FormField>
							<Label>{labels.templateName}</Label>
							<Input
								value={templateName}
								maxLength={500}
								clearButtonTitle={labels.clear}
								onChange={handlerOnEditTemplateName}
							/>
						</FormField>
						{createDropDown(getRegionsForDropDown())}
						<section className="status">
							<section className="showinactive">
								<Label className={!status ? 'bold' : ''}>
									{labels.templateUnpublished}
								</Label>
								<ToggleSwitch
									aria-describedby="showstatus"
									checked={status}
									onChange={(event) => {
										setStatus(event.target.checked);
									}}
									id="toggle1"
									hideLabels={true}
								/>
								<Label className={status ? 'bold' : ''}>
									{labels.templatePublished}
								</Label>
							</section>
						</section>
						<TicketField
							ticketNumber={ticketNumber}
							onTicketChanged={onTicketChanged}
							showValidation={isSubmitClicked}
						/>
					</section>
				</section>
			)}
			<section className="modal-footer">
				<Button
					type="button"
					variant="primary-alt"
					onClick={() => saveTemplate()}
					disabled={isAssignButonDisable || isLoading}
				>
					{labels.templateEditSave}
				</Button>
				<Button
					type="button"
					variant="secondary"
					onClick={() => hideEditTemplateDrawer()}
					disabled={isLoading}
				>
					{labels.templateEditCancel}
				</Button>
			</section>
		</Mainwrapper>
	);
}

const Mainwrapper = styled.section`

	.edittemplate {
		padding: var(--px-20);
		height: var(--px-340);
		//ticketstart
		.ticketdetail{
			margin: 0;
			padding-bottom: var(--px-20);
			h5{
				font-size: var(--px-14);
				font-weight: normal;
				color: var(--neutrals-900);
				border: 0!important;
			}

			.motif-form-field{
				width: var(--px-290);
			}

			.motif-form-field:not(.motif-form-field-has-value),
			.motif-form-field:not(.motif-form-field-focused){
				.motif-label{
					margin-top: var(--px-6);
				}
			}

			.motif-form-field-has-value,
			.motif-form-field-focused{
				.motif-label{
					margin-top: var(--px-2)!important;
				}
			}
			
			
			.motif-input {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
		//ticketend

		.motif-input-clear-button:hover {
			
		}

		& > * {
			font-size: var(--px-14);
		}

		.status {
			display: flex;
			padding: 0 0 var(--px-20) 0;

			.motif-label {
				margin-top: var(--px-8);
			}

			& > .showinactive .motif-label:not(.bold){
			}

			& > .showinactive > * {
				&:not(.motif-toggle-switch-wrapper) {
					margin-top: var(--px-8);
				}
			}

			& > .showinactive,
			& > .motif-label {
				display: inline-flex;
			}
		}

		.bold {
			font-weight: bold;
			font-size: var(--px-14);
		}

		//modal field
		.motif-form-field-select-input .motif-select-field-list {
			overflow: hidden !important;
			.motif-select-list-options {
				max-height: var(--px-160) !important;
			}
		}

		.labeltext {
			font-size: var(--px-14);
			margin: 0;
			padding: 0 0 var(--px-20) 0;
		}
		.motif-form-field {

			.motif-select-input{
				height: var(--px-40);
			}

			.motif-input-component {
				input.motif-input {
					height: var(--px-40);
				}
			}

			.motif-error-message {
				margin-top: var(--px-5);
				svg {
					width: var(--px-18);
					height: var(--px-18);
				}
			}

			.motif-select-field {
				.motif-checkbox-label-wrap {
					margin: 0;
				}

				button {
					height: var(--px-40);

					svg.motif-checkbox-checked-icon {
						margin-top: 0;
					}

					svg {
						margin-top: var(--px-5);
					}
				}
			}

			.motif-label {
				margin-top: var(--px-2);
			}
		}
	}

	.modal-footer {
		display: inline-flex;
		width: 100%;
		padding: var(--px-20) !important;
		button {
			font-size: var(--px-14);
			margin-right: var(--px-10);
			padding: var(--px-12) var(--px-15);
			line-height: normal;
		}
	}

	.loadingstyle {
		height: var(--px-340) !important;
	}

	//heading
	.templatemanagement {
		.pageheading {
			display: flex;
			padding: var(--px-20) 0;
			svg {
				min-width: var(--px-25) !important;
				min-height: var(--px-25) !important;
				margin-right: var(--px-10);
			}

			h3 {
				margin: 0;
				line-height: var(--px-25);
			}
		}
	}

	.errorRow {
		color: var(--red-600) !important;
	}
`;

export default TemplateDetails;
