/**
 * @module CreateEditWorkspace
 * Component CreateEditWorkspace
 */

import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import {
	getCreateWorkspace,
	getCountriesDetails,
	getEditWorkspace,
	resetEditWorkspace
} from '../../../actions/createworkspaceaction';
import {updateWorkspace} from '../../../actions/workspacesactions';
import {getEngagementCodeName} from '../../../actions/engagementcodenameaction';
import env from '../../../util/env';
import Loader from '@ey/loader';
import {EYForm, FormTextInput, FormComboBox} from '@ey/form';
import {
	labels,
	standardStrings,
	urls
} from '../../../util/uiconstants';
import clonedeep from 'lodash/cloneDeep';
import {useDidMountEffect} from '../../../util/customHooks';
import Utility from '../../../util/utils';

const CreateEditWorkspace = (props) => {
	const {
		mode,
		getDependencyData,
		stepHeaderText,
		updateWizardData,
		isComponentValid,
		onComponentDisplayed,
		onFinishClick,
		nextClicked,
		closeWizard,
		advanceToNextStep
	} = props;

	const dispatch = useDispatch();

	const updatedClientName = useSelector((state) => state.createEditWorkspace);
	const workspaceData = useSelector((state) => state.individualWorkspaceData);
	const countriesDetailsData = useSelector((state) => state.countriesDetails);
	const clientEngagement = useSelector((state) => state.clientengagement);
	const featureConfiguration = useSelector(
		(state) => state.featureConfiguration
	);
	const userPermissions = useSelector((state) => state.userPermissions);

	let _mode = {
		create: 'create',
		edit: 'edit',
		restore: 'restore',
		rollForward: 'rollForward',
		now: mode,
		isCreate: () => _mode.now === _mode.create,
		isEdit: () => _mode.now === _mode.edit,
		isRestore: () => _mode.now === _mode.restore,
		isRollForward: () => _mode.now === _mode.rollForward
	};

	const OPERATION_TYPE_EDIT_WORKSPACE = 'EditWorkspace';
	const countriesUrl =
		env.getURL('serviceUrl') +
		'/' +
		urls.COUNTRIES_URL +
		'?filters=languageid eq 1,defaultlanguage eq english';
	const workspacesUrl = env.getURL('serviceUrl') + '/' + urls.WORKSPACES_URL;
	const clientsUrl = env.getURL('serviceUrl') + '/' + urls.CLIENTS_URL;
	const clientEngagementUrl =
		env.getURL('serviceUrl') + '/' + urls.CLIENTENGAGEMENT_URL;
	let locationComboBoxAttributes = {};

	const [isLoading, setIsLoading] = useState(false);
	const [clientCode, setClientCode] = useState(
		getDependencyData('domainClientCode') || standardStrings.EMPTY
	);
	const [clientId, setClientId] = useState(
		getDependencyData('domainClientId') || null
	);
	const [clientName, setClientName] = useState(
		getDependencyData('domainClientName') || labels.workspacecodenameplaceholder
	);
	const [countryId, setCountryId] = useState(
		_mode.isRestore() || _mode.isRollForward()
			? getDependencyData('domainOriginLocation')
			: getDependencyData('domainLocation')
	);
	const workspaceId = getDependencyData('domainWorkspaceId');
	const [workspaceName, setWorkspaceName] = useState(
		getDependencyData('domainWorkspaceName') || standardStrings.EMPTY
	);
	const [validationTriggerCounter, setValidationTriggerCounter] = useState(0);
	const [fullscreenLoad, setFullscreenLoad] = useState(false);
	const [shouldUpdateWizard, setShouldUpdateWizard] = useState(0);
	
	const updateState = (key, value) => {
		try {
			switch (key) {
				case 'workspaceName':
					setWorkspaceName(value);
					break;
				case 'countryId':
					setCountryId(value);
					break;
				default:
					break;
			}
		} finally {
			setShouldUpdateWizard((prev) => prev + 1);
		}
	};

	const handleClientCodeChangeAsync = async (value) => {
		setClientCode(value);
	};

	useEffect(() => {
		getClientDetailsByClientCodeAsync();
	}, [clientCode]);

	const getClientDetailsByClientCodeAsync = async () => {
		if (clientCode) {
			await getClientAsync();
		} else {
			setClientId(null);
			setClientName(labels.workspacecodenameplaceholder);
		}
	};

	const getClientAsync = async () => {
		let url = clientsUrl + '?filters=clientcode eq ' + "'" + clientCode + "'";
		try {
			await dispatch(getCreateWorkspace(url));
		} finally {
			setShouldUpdateWizard((prev) => prev + 1);
		}
	};

	const getClientEngagementsAsync = async (workspaceDataModel) => {
		let clientEngagements = [];

		if (
			workspaceDataModel.collections &&
			Array.isArray(workspaceDataModel.collections.engagements)
		) {
			const engagementCodes = workspaceDataModel.collections.engagements
				.map((engagement) => {
					// 'extract' engagementCode
					if (
						engagement.collections &&
						Array.isArray(engagement.collections.engagementcodes)
					) {
						return engagement.collections.engagementcodes.map(function (
							engagementCode
						) {
							if (engagementCode.data /* && engagementCode.data.isprimary*/) {
								return engagementCode.data.code;
							}
						});
					}
				})
				.flat()
				.reduce((unique, engagementCode) => {
					if (engagementCode) {
						// remove duplicates
						if (
							unique.some(
								(item) =>
									String(item).toUpperCase() ===
									String(engagementCode).toUpperCase()
							) == false
						) {
							return [...unique, engagementCode];
						}
					}

					return unique;
				}, []);

			let fetchPromises = [];
			// fetch ClientEngagements
			engagementCodes.forEach((engagementCode) => {
				let url =
					clientEngagementUrl +
					"?filters=clientengagementcode eq '" +
					engagementCode +
					"',clientid eq '" +
					clientId +
					"'";
				let result = dispatch(getEngagementCodeName(url))
					.then(() => {
						return clientEngagement;
					})
					.catch((e) => {
						throw e;
					});

				fetchPromises.push(result);
			});

			await Promise.all(fetchPromises).then((values) => {
				clientEngagements = values;
			});
		}

		return clientEngagements;
	};

	const updateWizardFromComponent = () => {
		updateWizardData('domainClientId', clientId);
		updateWizardData('domainClientCode', clientCode);
		updateWizardData('domainClientName', clientName);
		updateWizardData('domainWorkspaceName', workspaceName);
		if (_mode.isEdit()) {
			updateWizardData('domainWorkspaceId', workspaceId);
		} else {
			// In case of new workspace this will be always -1.
			updateWizardData('domainWorkspaceId', -1);
		}
		updateWizardData('domainLocation', countryId);
		isComponentValid(isValid());
	};

	const getCountryandWorkspaceData = async () => {
		try {
			setIsLoading(true);
			await dispatch(getCountriesDetails(countriesUrl));
			if (_mode.isEdit()) {
				let url = workspacesUrl + '/' + workspaceId;
				await dispatch(getEditWorkspace(url));
			}
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getCountryandWorkspaceData();
	}, []);

	useEffect(() => {
		updateWizardFromComponent();
	}, [shouldUpdateWizard]);

	useDidMountEffect(() => {
		try {
			if (updatedClientName && updatedClientName.data) {
				setClientId(updatedClientName.id);
				setClientName(updatedClientName.data.clientname);
			} else {
				setClientId(null);
				setClientName(labels.invalidclientcode);
			}
		} finally {
			setShouldUpdateWizard((prev) => prev + 1);
		}
	}, [updatedClientName]);

	useEffect(() => {
		try {
			if (workspaceData && workspaceData.data && mode == _mode.edit) {
				setClientCode(workspaceData.data.clientcode);
				setClientId(workspaceData.data.clientid);
				setClientName(workspaceData.data.clientname);
				setCountryId(workspaceData.data.countryid);
				setWorkspaceName(workspaceData.data.workspacedesc);
			}
		} finally {
			setShouldUpdateWizard((prev) => prev + 1);
		}
	}, [workspaceData]);

	useEffect(() => {
		isComponentValid(isValid());
	}, [onComponentDisplayed]);

	useDidMountEffect(() => {
		handleFinishClickedAsync();
	}, [onFinishClick]);

	useDidMountEffect(() => {
		handleNextClickedAsync();
	}, [nextClicked]);

	const validateAndUpdateEngagementCodesAsync = async (workspaceDataModel) => {
		let clientEngagements = await getClientEngagementsAsync(workspaceDataModel);

		if (
			workspaceDataModel.collections &&
			Array.isArray(workspaceDataModel.collections.engagements)
		) {
			workspaceDataModel.collections.engagements.forEach((engagement) => {
				if (
					engagement.collections &&
					Array.isArray(engagement.collections.engagementcodes)
				) {
					engagement.collections.engagementcodes.forEach((engagementCode) => {
						if (engagementCode.data) {
							clientEngagements.some((clientEngagement) => {
								if (
									clientEngagement.data &&
									String(clientEngagement.data.engagementcode).toUpperCase() ===
										String(engagementCode.data.code).toUpperCase()
								) {
									engagementCode.data.invalidengagementcodeerror =
										clientEngagement.data.isclientengagementvalid === false
											? 1
											: 0;
									return true;
								}
							});
						}
					});
				}
			});
		}
	};

	const handleNextOrFinishClickedAsync = async () => {
		try {
			setValidationTriggerCounter(validationTriggerCounter + 1);
			await getClientDetailsByClientCodeAsync();
		} finally {
			setShouldUpdateWizard((prev) => prev + 1);
		}

		if (!isValid()) {
			return false;
		}
		if (_mode.isEdit()) {
			await saveWorkspaceAsync();
			dispatch(resetEditWorkspace());
		}
		return true;
	};

	const handleFinishClickedAsync = async () => {
		if ((await handleNextOrFinishClickedAsync()) === true) {
			closeWizard();
		}
	};

	const handleNextClickedAsync = async () => {
		if ((await handleNextOrFinishClickedAsync()) === true) {
			advanceToNextStep();
		}
	};

	const saveWorkspaceAsync = async () => {
		if (workspaceData && workspaceData.data) {
			const isClientValid = !Number.isNaN(Number.parseInt(clientId));
			let workspaceDataModel = {
				id: workspaceData.id,
				data: {
					clientcode: clientCode,
					clientname: isClientValid ? clientName : null,
					workspacedesc: workspaceName,
					isclientvalidated: isClientValid,
					clientid: clientId,
					countryid: workspaceData.data.countryid,
					operationtype: OPERATION_TYPE_EDIT_WORKSPACE
				},
				collections: clonedeep(workspaceData.collections)
			};

			setIsLoading(true);
			setFullscreenLoad(true);

			try {
				await validateAndUpdateEngagementCodesAsync(workspaceDataModel);

				let url = workspacesUrl + '/' + workspaceId;

				await dispatch(updateWorkspace(url, workspaceDataModel));
			} finally {
				setIsLoading(false);
				setShouldUpdateWizard((prev) => prev + 1);
			}
		}
	};

	const isValid = () => {
		let isValid = false;

		if (_mode.isCreate()) {
			isValid =
				(clientCode || standardStrings.EMPTY).length > 0 &&
				!Number.isNaN(Number.parseInt(countryId)) &&
				(workspaceName || standardStrings.EMPTY).length > 0;
		} else if (_mode.isEdit() || _mode.isRestore() || _mode.isRollForward()) {
			isValid =
				(clientCode || standardStrings.EMPTY).length > 0 &&
				(workspaceName || standardStrings.EMPTY).length > 0;
		}
		return isValid;
	};

	if (_mode.isEdit() || _mode.isRestore() || _mode.isRollForward()) {
		locationComboBoxAttributes.disabled = true;
	}

	const ClientName = (props) => {
		return (
			<FormTextInput
				onChange={() => {}}
				placeholder={labels.clientname}
				name="clientName"
				value={props.clientName}
				rules={[]}
				help
				maxLength="50"
				disabled={true}
			/>
		);
	};

	return (
		<>
			{isLoading ? (
				<Loader view={fullscreenLoad ? 'fullscreen' : null} />
			) : (
				<Createeditworkspaceholder>
					<section className="createeditworkspacewrapper">
						<p>
							<h5>{stepHeaderText}</h5>
						</p>
						<section className="createnewworkspace">
							<EYForm
								id="createworkspaceform"
								onChange={updateState}
								onSubmit={(e) => {
									e.preventDefault();
									setShouldUpdateWizard((prev) => prev + 1);
								}}
								action="\"
								validationTriggerCounter={validationTriggerCounter}
							>
								<FormTextInput
									placeholder={labels.clientcode}
									name="clientCode"
									value={workspaceData?.data?.clientcode}
									rules={[]}
									is_required
									help
									onBlur={(e) => {
										handleClientCodeChangeAsync(e.currentTarget.value);
									}}
									maxLength="50"
									errormessage={labels.isRequired.replace(
										'{0}',
										labels.clientcode
									)}
									clearable="true"
								/>
								<ClientName clientName={clientName} />
								<FormTextInput
									placeholder={labels.workspacename}
									name="workspaceName"
									value={workspaceData?.data?.workspacedesc}
									rules={[]}
									is_required
									onChange={updateState}
									help
									maxLength="255"
									errormessage={labels.isRequired.replace(
										'{0}',
										labels.workspacename
									)}
									clearable="true"
								/>
								<FormComboBox
									{...locationComboBoxAttributes}
									id="countryId"
									name="countryId"
									value={workspaceData?.data?.countryid}
									is_required
									help
									searchable
									placeholder={labels.location}
									options={
										Utility.getCountriesByGroupPermission(countriesDetailsData, userPermissions, featureConfiguration)
									}
									errormessage={labels.isRequired.replace(
										'{0}',
										labels.location
									)}
									clearable={false}
								/>
							</EYForm>
						</section>
						{_mode.isEdit() ? (
							<p className="editworkspacefooter">
								<h6>{labels.editworkspacestepfooter}</h6>
							</p>
						) : (
							''
						)}
					</section>
				</Createeditworkspaceholder>
			)}
		</>
	);
};

export default CreateEditWorkspace;

const Createeditworkspaceholder = styled.section`
	width: 100%;
	& .createeditworkspacewrapper {
		display: inline-block;
		width: 100%;
		& .editworkspacefooter {
			position: absolute;
			bottom: var(--px-100);

			& h6 {
				font-size: var(--px-14);
				font-weight: bold;
			}
		}

		& .createnewworkspace {
			& .form-group .select-value .select-label {
				font-size: var(--px-10);
			}

			& .clientName {
				height: var(--px-48);
				border: var(--px-1) solid var(--neutrals-300);
				display: inline;
				padding: var(--px-6) 0 0 var(--px-10);
				color: var(--neutrals-900);
				opacity: 0.35;
			}
		}

		& .form-group {
			margin: 0;
			padding: 0;
			display: inline;
		}

		& .form-group label.control-label {
			vertical-align: bottom;
			display: inline;
		}

		& > .form-group * {
			font-size: var(--px-16);
			line-height: normal;
			padding: 0;
			margin: 0;
		}

		& .form-control-static {
			float: right;
			line-height: var(--px-48);
			font-size: var(--px-14);
		}

		& div.form-group:nth-child(1) {
			width: auto;
		}

		& div.form-group:nth-child(2) {
			width: auto;
			margin-left: var(--px-20);
		}

		& div.form-group:nth-child(3) .input-group {
			width: var(--px-820);
		}

		& div.form-group {
			display: inline-flex;
			width: 100%;
			font-size: var(--px-14);
			height: var(--px-75);
			margin: 0 0 1em 0;

			& .input-group .input-label {
				line-height: normal;
			}

			& span.input-group {
				width: var(--px-400);
				float: left;

				& input.form-control {
					margin: 0 var(--px-20) 0 0;
					vertical-align: middle;
					border: var(--px-1) solid var(--neutrals-300);
					font-size: var(--px-14);
				}
			}

			& > section {
				width: var(--px-400);
				float: left;

				& .select-label {
					line-height: normal;
					color: var(--neutrals-900);
				}

				& .Select-control {
					margin: 0 var(--px-20) 0 0;
					vertical-align: middle;
					border: var(--px-1) solid var(--neutrals-300);
					line-height: var(--px-46);
				}
			}

			& .select-value {
				width: var(--px-400);
				float: left;

				& .Select-control {
					border: var(--px-1) solid var(--neutrals-300);
				}
			}
		}
	}
`;
