import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import {
	labels,
	dateFormatsListItems,
	displayGroupings,
	dateFormats,
	settingsPreferences
} from '../../util/uiconstants';
import {EngagementStatus} from '../../util/enumerations.js';
import moment from 'moment';
import {setCurrentGroupingTypeInMyEngSection} from '../../actions/dashboardactions';
import {updateGroupPrefUserSettings} from '../../actions/usersettingsaction';
import CogWheelReview from './cogwheelreview';
import WorkspaceGroupAudit from './workspaceGroupAudit';
import MotifCard from '@ey/motif-react-wrapper/Card';

const ReviewEngagementItem = (props) => {
	const dispatch = useDispatch();

	const {data, id, clientName, workspace, refreshDashboard} = props;
	const {
		incountryuri,
		createdate,
		engagementdeletedate,
		engagementdesc,
		engagementstatus
	} = data;

	const currentGrouping = useSelector((store) => store.dashboard.groupingType);
	const preferredlandingpageview = useSelector(
		(store) => store?.userSettings?.data?.preferredlandingpageview
	);
	const isEyUser = useSelector((store) => store?.userSettings?.data?.iseyuser);

	const engagementurl = incountryuri + '/dashboard?' + 'engagementid=' + id;

	let defaultGroupingUserSetting =
		preferredlandingpageview === settingsPreferences.WorkspaceList
			? displayGroupings.MODULE
			: displayGroupings.LIST;
	let groupingUserSetting =
		defaultGroupingUserSetting === displayGroupings.MODULE
			? settingsPreferences.WorkspaceList
			: settingsPreferences.EngagementList;
	let createDate = createdate
		? moment.utc(createdate).format(dateFormatsListItems[dateFormats].label)
		: '';

	let expiresOnDate = engagementdeletedate
		? moment
				.utc(engagementdeletedate)
				.format(dateFormatsListItems[dateFormats].label)
		: '';

	useEffect(() => {
		dispatch(setCurrentGroupingTypeInMyEngSection(defaultGroupingUserSetting));
		dispatch(updateGroupPrefUserSettings(groupingUserSetting));
	}, []);

	useEffect(() => {
		dispatch(setCurrentGroupingTypeInMyEngSection(currentGrouping));
	}, [currentGrouping]);

	const isGuestUser = () => {
		return isEyUser !== null && isEyUser !== undefined ? !isEyUser : true;
	};

	const dblClick = () => {};

	return (
		<EngagementItemContainer
			className="eng-listwrapper"
			onDoubleClick={dblClick}
		>
			<EngagementItemDetails className="eng-listholder arcstatus-wrapper">
				<MotifCard orientation="horizontal">
					<section className="engagementInfo">
						<WorkspaceContent>
							<section className="workspacecontent-wrap">
								<section className="clientname" title={clientName}>
									{(clientName === '!!!'
										? labels.invalidclientcode
										: clientName) || <Skeleton width={250} height={20} />}
								</section>
								<WorkspaceGroupAudit workspace={workspace} engagement={data} />
							</section>
							<section className="pull-right review-menu">
								<section className="pull-right">
									<section className="engagementid">
										{labels.id}: {id || <Skeleton width={250} height={20} />}
									</section>
									{!isGuestUser() && (
										<WSEdit>
											<CogWheelReview
												doRefreshDashboard={refreshDashboard}
												{...props}
											/>
										</WSEdit>
									)}
								</section>
								<section className="pull-right dates-menu">
									<section className="createdate">
										{labels.createdOn}: <b>{createDate}</b>
									</section>
									{!isGuestUser() && (
										<section className="expiredate">
											{labels.expiresOn}: <b>{expiresOnDate}</b>
										</section>
									)}
								</section>
							</section>
						</WorkspaceContent>
						<EngagementDetails className="engagementname">
							<h4>
								<section className="anchor" data-autoid={id}>
									<a
										title={engagementdesc}
										className={
											engagementstatus ===
											EngagementStatus.CanvasExternalAccessPortalV2
												? 'engagementname-status33' // class name for allowing access
												: 'engagementname-status27' // no need to access / disable it
										}
										href={
											engagementstatus ===
											EngagementStatus.CanvasExternalAccessPortalV2
												? engagementurl
												: '#'
										}
									>
										{engagementdesc}
									</a>
								</section>
							</h4>
						</EngagementDetails>
					</section>
				</MotifCard>
			</EngagementItemDetails>
		</EngagementItemContainer>
	);
};
export default ReviewEngagementItem;

const WSEdit = styled.div`
	display: inline-block;
	margin-right: var(--px-10);
	svg {
		margin-top: calc(var(--px-4) * -1);
		transform: rotate(90deg);
	}
`;

const EngagementItemDetails = styled.section`
	.motif-card-border.motif-card-orientation-horizontal {
		width: 100%;
		height: auto;
		gap: 0;
		padding: 0;		
		border-left: var(--px-5) solid var(--blue-300);
		min-width: 0;
		min-height: 0;
	}
	&.engagementerror-indicator {
		.motif-card-border.motif-card-orientation-horizontal {
			border-left: var(--px-5) solid var(--red-600);
		}
	}
	&.engagementextuser-indicator {
		border-left: var(--px-5) solid var(--neutrals-900);
	}
	& .modal-wrapper {
		height: 300px !important;
		width: 675px !important;
	}

	& .editList {
		& ul {
			background-color: var(--neutrals-00white);
			padding: 0;
			margin: 0;
			line-height: normal;
		}

		& li {
			width: 100%;
			margin: 0;
			padding: 0;
			display: inline-block;
			font-size: var(--px-14);
			line-height: normal;

			& a,
			& span {
				text-decoration: none;
				color: var(--neutrals-900);
				width: 100%;
				display: inline-block;
				outline: none;
				font-weight: normal;
				padding: var(--px-8) var(--px-16);
			}

			&:hover {
				background-color: var(--neutrals-200);
				cursor: pointer;
			}
		}
	}

	& .engagementInfo {
		width: 100%;
		padding: var(--px-10) 0 var(--px-10) 0;
		position: relative;

		& .engagementname .anchor.newengagement {
			color: var(--neutrals-300);
			pointer-events: none;
			cursor: default;
		}
	}

	& .timelinecontrol {
		float: left;
		width: 100%;
		padding-bottom: var(--px-15);
	}

	& .engagementUpdates {
		display:flex;
		align-items: center;
		justify-content: space-between;
		border-top: var(--border-width-small) solid var(--neutrals-200);
		& .faviconholder {
			float: right;
			position: relative;
			display: block;
		}

		& .arrow-right {
			width: 0;
			height: 0;
			border-top: var(--px-45) solid transparent;
			border-bottom: 0 solid transparent;
			border-right: var(--px-45) solid var(--neutrals-900);
			float: right;
			margin-right: calc(var(--px-2) * -1);
			margin-bottom: calc(var(--px-2) * -1);
			box-shadow: 3px 3px 5px 0px var(--neutrals-300);
			&.favourite-inprogress {
				border-bottom: 0 solid transparent;
				border-right: var(--px-45) solid var(--neutrals-700);
				pointer-events: none;
				opacity: 0.5;
				+ section svg {
					cursor: default;
					pointer-events: none;
				}
			}
		}
	}
`;

const EngagementDetails = styled.section`
	width: 100%;
	display: inline-block;
	& svg {
		vertical-align: middle;
		cursor: pointer;
	}

	& h4 {
		display: inline-block;
		font-size: var(--px-16);
		margin: 0;
		font-weight: bold;
		color: var(--neutrals-900);
		padding-left: var(--px-30);
		width: 100%;
		margin-bottom: 0;

		& section.anchor {
			color: var(--neutrals-900);
			vertical-align: middle;
			width: 45%;
			display: inline-flex;
			line-height: var(--px-40);
			max-width: 52%;
			pointer-events: auto;

			& a {
				display: inline-block;
				color: var(--neutrals-300);
				max-width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				&.engagementname-status33 {
					color: var(--neutrals-900);
				}
				&.engagementname-status27 {
					text-decoration: none;
					cursor: default;
				}
			}
		}

		& .eng-rightmenu {
			line-height: var(--px-40);
			width: 35%;
			& .restored-eng {
				color: var(--neutrals-300);
				font-size: var(--px-16);
			}

			& > section {
				display: inline-block;
			}
		}

		& .ellipses {
			width: auto;
			max-width: none;
			display: inline-flex;
			color: var(--neutrals-900);
			& div {
				max-width: none;
			}
		}
	}

	& h4 .eng-rightmenu .engagementid {
		color: var(--neutrals-700);
		width: var(--px-110);
	}

	& .engagementid,
	& .createdate,
	& .expiredate {
		display: inline-block;
		font-size: var(--px-14);
		line-height: var(--px-40);
		margin: 0 var(--px-30);
		color: var(--neutrals-900);
		font-weight: normal;
	}
`;

const WorkspaceContent = styled.div`
	padding-left: var(--px-30);
	width: 100%;
	.workspacecontent-wrap {
		width: 59%;
		float: left;
		@media screen and (max-width: 1366px) {
			width: 52%;
		}
	}
	.clientname,
	.workspace-name,
	.engagement-type,
	.engagement-typelinked {
		display: inline-block;
		font-size: var(--px-14);
		color: var(--neutrals-700);
		line-height: normal;
		max-width: 35%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		vertical-align: middle;
		padding-right: var(--px-3);
	}
	.workspace-name {
		max-width: 35%;
		padding-left: var(--px-3);
	}
	.engagement-type {
		max-width: 19%;
		padding-left: var(--px-3);
	}
	.engagement-typelinked {
		display: inline-block;
		max-width: 15%;
		padding-left: var(--px-3);
		.motif-icon svg {
			margin-top: calc(var(--px-3) * -1);
			vertical-align: middle;
			margin-right: var(--px-3);
		}
		.engagementtype-linkedtxt {
			font-size: var(--px-14);
			color: var(--neutrals-700);
			line-height: normal;
		}
	}

	.review-menu {
		display: inline-block;
		padding-right: var(--px-10);
		width: 40%;
		float: right;
		line-height: normal;
		padding-top: var(--px-5);
		@media screen and (max-width: 1366px) {
			width: 47%;
		}
		.engagementid {
			display: inline-block;
			font-size: var(--px-14);
			margin: 0 var(--px-50) 0 var(--px-10);
			width: var(--px-110);
			color: var(--neutrals-900);
			font-weight: normal;
			vertical-align: top;
			text-align: right;
		}
		.icon svg {
			width: var(--px-20);
			height: var(--px-20);
			vertical-align: top;
		}
		.dates-menu {
			display: inline-block;
			line-height: var(--px-20);
			.createdate,
			.expiredate {
				display: inline-block;
				font-size: var(--px-14);
				margin: 0 var(--px-30);
				color: var(--neutrals-900);
				font-weight: normal;
			}
		}
	}
`;

const EngagementItemContainer = styled.section`
	margin: var(--px-15) 0;
`;
