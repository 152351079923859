import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import IconPopOver from '../Notifications/iconpopover';

const MenuItemsWrapper = styled.section`
	&.editList {
		& ul {
			background-color: var(--neutrals-00white);
			padding: 0;
			margin: 0;
			line-height: normal;

			& > li {
				width: 100%;
				margin: 0;
				padding: 0;
				display: inline-block;
				font-size: var(--px-14);
				line-height: normal;
				white-space: nowrap;

				& a,
				& span {
					text-decoration: none;
					color: var(--neutrals-900);
					width: 100%;
					display: inline-block;
					outline: none;
					font-weight: normal;
					padding: var(--px-8) var(--px-16);
				}

				&:hover {
					background-color: var(--neutrals-200);
					cursor: pointer;
				}
			}
		}
	}
`;

class MenuItems extends React.Component {
	constructor(props) {
		super(props);
		//console.log('@@MenuItems', props.items);
	}

	itemClicked = () => {
		this.props.itemClicked();
	};
	render() {
		let itemList =
			this.props.items() &&
			this.props.items().map((item, index) => {
				if (item.linkTo == null) {
					return (
						<li title="" key={index} onClick={this.itemClicked}>
							<span onClick={item.onClick}>{item.label}</span>
						</li>
					);
				} else {
					return (
						<li title="" key={index} onClick={this.itemClicked}>
							<Link to={item.linkTo}>{item.label}</Link>
						</li>
					);
				}
			});

		return (
			<MenuItemsWrapper className="editList">
				<ul>{itemList}</ul>
			</MenuItemsWrapper>
		);
	}
}

class ContextMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			menuClicked: 0
		};
	}

	itemClicked = () => {
		this.setState((state) => ({menuClicked: state.menuClicked + 1}));
	};

	render() {
		return (
			<span title={this.props.iconHover}>
				<IconPopOver
					iconHover={this.props.iconHover}
					iconName={this.props.iconName}
					iconstyleName={this.props.iconstyleName || 'large'}
					popOverComponent={
						<MenuItems
							items={this.props.menuItems}
							itemClicked={this.itemClicked}
						/>
					}
					disabled={this.props.disabled || false}
					closePopover={this.state.menuClicked}
				/>
			</span>
		);
	}
}

export default ContextMenu;
