import styled from 'styled-components';

const DashboardStyledComp = styled.div`
    position:relative;
    height:calc(100vh - var(--px-65));
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
    max-width: 2048px;
    margin: 0 auto;

    & .menu-nav{
        display:inline-flex;
		padding-top:var(--px-10);
		padding-bottom:var(--px-10);
    }

    & .page-filter .createeng{
		float: right;
		right: var(--px-160);
		position: absolute;
        top: calc(var(--px-52) * -1);
        
        & a{
            margin-right:1em;
            text-decoration:none;
        }
    }
    
    & .empty-message{
        align-items:center;
        justify-content:center;
        height: calc(100vh - var(--px-155));
        color:var(--neutrals-900);
        font-size:var(--px-14);
        text-align:center;
        font-weight:normal;
        display:flex;
        padding:var(--px-10) var(--px-40);

        & .norecords{
            margin-bottom:var(--px-50);
        }

        & p{
            float:left;
            width:100%;
            margin:0;
            line-height:var(--px-26);
            font-size: var(--px-14);
            color: var(--neutrals-700);

            &.try-message{
                font-size:var(--px-20);
            }
        }
    }

    & ul{
        padding:0 var(--px-40);
        & li{
            margin:0 0 var(--px-20) 0;
            list-style:none;
        }
		 & li:last-child {
            margin:0 0 var(--px-20) 0;
            list-style:none;
			padding-bottom:var(--px-8);
        }
    }
    .engfavtabs {
        font-size: var(--px-14);
        color: var(--neutrals-900);
        width:50%;
        float:left;

        & .nav-tabs{
            border:0;
        }

        .empty-message {
            align-items: center;
            justify-content: center;
            height: calc(100vh - var(--px-155));
            color: var(--neutrals-900);
            font-size: var(--px-14)
            text-align: center;
            font-weight: normal;
            display:flex;
            padding:var(--px-10) var(--px-40);

            & .norecords{
                margin-bottom:var(--px-50);
            }

            & p {
                float: left;
                width: 100%;
                margin: 0;
                line-height: var(--px-26);
                font-size: var(--px-14);
                color: var(--neutrals-700);

                &.try-message {
                    font-size: var(--px-20);
                }
            }
        }

        & li {
            padding: var(--px-5) var(--px-8) 0 var(--px-8);
            margin: 0;
            & a,
            & a:hover,
            & a:focus {
                color: var(--neutrals-900);
                background: none;
                border: none;
                outline: none;
                cursor: pointer;
                padding: var(--px-18) var(--px-3) 0 var(--px-3);
                margin: 0;
            }
            
            &.active {
                font-weight: bold;
                padding: var(--px-5) var(--px-8) 0 var(--px-8);
                & a:link {
                    background: none;
                    border-bottom: var(--px-4) solid ${(props) =>
		props.theme.color[`grey500`]};
                    border-left: 0;
                    border-right: 0;
                    border-top: 0;
                    color:var(--neutrals-900);
                }
            }

            &:first-child{
                padding-left:0;
            }
        }
    }
    & .page-filter{		
        padding: 0 var(--px-40) var(--px-10) var(--px-40);
        display:inline-block;
        width:100%;    
        position:relative;

        & .search-filter{
            display:inline-block;
            width:var(--px-350);
			 & > .searchControl{
                height:var(--px-40);
                background:transparent;
            }
        }
        & .pagination{
            margin:0;
            line-height: var(--px-24);
            float:right;	
            font-size: var(--px-14);
            min-width: auto;
            .Dropdown-root.is-open .Dropdown-arrow {
                top:var(--px-18) !important;
            }
			.Dropdown-root .Dropdown-control:hover {
				box-shadow:none;
			}
            & > *, 
			& input[type='text']{
				font-size: var(--px-14);
            }

            & .Dropdown-arrow{
                top:var(--px-13) !important;
            }
            
            & .search-filter{
                float:left;
            }	
            & .Dropdown-root .Dropdown-control,
            & input {
                background:none;
            }	
            & .Dropdown-root .Dropdown-menu {
                border:var(--px-1) solid var(--neutrals-900);
                & .Dropdown-option{
                    color:var(--neutrals-900);
                    &:hover{
                        background:var(--neutrals-900);
                        color:var(--neutrals-00white);
                    }	
                }			
            }			
        }
    }
    .filterrow{		
        width:50%;
        float:right;
        text-align:right;
		.addengagement-dropdown {
			margin-top:var(--px-4);
		}
		.workspaces_view {
			display:flex;
			margin: 0 var(--px-40) 0 var(--px-10);
		}
        }
    }
    }
`;

export default DashboardStyledComp;
