import React, {useEffect, useState} from 'react';
import ChooseNewExisting from './chooseneworexisting';
import SelectExisting from './selectworkspace';
import CreateNewEngagement from './createeditengagement';
import CreateEditWorkspace from './createeditworkspace';
import {labels} from '../../../util/uiconstants';
import {useHistory} from 'react-router-dom';
import WizardWrapper from '../Common/WizardWrapper';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { resetEditWorkspace } from '../../../actions/createworkspaceaction';

const EngagementWizardLoader = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [activeStepIndex, setActiveStepIndex] = useState(0);
	const [step2NextChng, setstep2NextChng] = useState(1);
	const [step3FinishChng, setstep3FinishChng] = useState(1);
	const [canGoToNextStep, setcanGoToNextStep] = useState(true);
	const [domain, setDomain] = useState({
		domainChooseExisting: false
	});
	const [wizardSteps, setWizardSteps] = useState([
		{
			stepName: labels.step01,
			stepDescription: labels.choosenewexistingstepheader,
			active: true
		},
		{
			stepName: labels.step02,
			stepDescription: domain.domainChooseExisting
				? labels.selectexistingworkspacestepheader
				: labels.createworkspacestepheader,
			active: false
		},
		{
			stepName: labels.step03,
			stepDescription: labels.createengagementheader,
			active: false
		}
	]);

	let oldPath = props.location?.state?.oldPath
		? props.location?.state?.oldPath
		: '';
	const wizLoaderName = labels.createengagementheader;

	const mode = {
		create: 'create',
		edit: 'edit'
	};

	const buttons = [
		{
			label: activeStepIndex == 0 ? labels.cancel : labels.back, // Change label based on active step.
			onClick: () => {
				switch (activeStepIndex) {
					case 0:
						//On first step, returns to Dashboard.
						navigateBack();
						break;
					case 1:
						setActiveStepIndex((prev) => prev - 1);
						break;
					case wizardSteps.length - 1:
						setActiveStepIndex((prev) => prev - 1);
						break;
				}
			},
			isVisible: true,
			disabled: false
		},
		{
			label: activeStepIndex == wizardSteps.length - 1 ? labels.finish : labels.continue,
			onClick: () => {
				switch (activeStepIndex) {
					case 0:
						// Step always valid, so go to the next one and reset possibly saved values.
						setActiveStepIndex((prev) => prev + 1);
						resetDomainValues([
							'domainClientId',
							'domainClientName',
							'domainClientCode',
							'domainWorkspaceId',
							'domainLocation',
							'domainWorkspaceName'
						]);
						dispatch(resetEditWorkspace());
						break;
					case 1:
						// Step can be invalid, so validate with the state canGoToNextStep,
						// and then, go to the next step, or trigger the validations to show.

						//Step2NextChng state is what triggers the validation, being sent as a prop to the respective component.
						canGoToNextStep
							? setActiveStepIndex((prev) => prev + 1)
							: setstep2NextChng((prev) => prev + 1);
						break;
					case wizardSteps.length - 1:
						// Final Step, only trigger the change, component take care of showing the validations if needed,
						// or finish correctly the workflow.
						setstep3FinishChng((prev) => prev + 1);
						break;
				}
			},
			isVisible: true,
			disabled: false
		}
	];

	useEffect(() => {
		// If the "selecting new or create workspace" state is changed, update the step labels as needed.
		setWizardSteps((prev) => {
			return prev.map((step) => {
				return step.stepName === labels.step02
					? {
							...step,
							stepDescription: domain.domainChooseExisting
								? labels.selectexistingworkspacestepheader
								: labels.createworkspacestepheader
					  }
					: step;
			});
		});
	}, [domain.domainChooseExisting]);

	// Step valid functions are triggered from inside components, thats how we manage canGoToNextStep local state.
	const isStep2Valid = (isComponentDataValid) => {
		if (activeStepIndex == 1) {
			if (isComponentDataValid == undefined || isComponentDataValid == null)
				setcanGoToNextStep(false);
			else setcanGoToNextStep(isComponentDataValid);
		}
	};
	const isStep3Valid = (isComponentDataValid) => {
		if (activeStepIndex == 2) {
			if (isComponentDataValid == undefined || isComponentDataValid == null)
				setcanGoToNextStep(false);
			else setcanGoToNextStep(isComponentDataValid);
		}
	};

	const navigateBack = () => {
		history.push(oldPath);
	};

	const updateWizardData = (fieldName, fieldValue) => {
		//Adds or update a prop of the state object domain.
		setDomain((prev) => {
			return {
				...prev,
				[fieldName]: fieldValue
			};
		});
	};

	const getWizardData = (domainFieldName) => {
		return domain[domainFieldName];
	};

	const resetDomainValues = (valuesArray) => {
		valuesArray.forEach((valueName) => {
			updateWizardData(valueName, undefined);
		});
	};
	
	// Step functions returns jsx corresponding to each step, which are send as prop to WizardWrapper
	const step1 = () => {
		return (
			<ChooseNewExisting
				updateWizardData={updateWizardData}
				getDependencyData={getWizardData}
			/>
		);
	};

	const step2 = () => {
		return domain.domainChooseExisting ? (
			<SelectExisting
				isComponentValid={isStep2Valid}
				updateWizardData={updateWizardData}
				nextClicked={step2NextChng}
				getDependencyData={getWizardData}
			/>
		) : (
			<CreateEditWorkspace
				isComponentValid={isStep2Valid}
				updateWizardData={updateWizardData}
				nextClicked={step2NextChng}
				getDependencyData={getWizardData}
				mode={mode.create}
			/>
		);
	};

	const step3 = () => {
		return (
			<CreateNewEngagement
				isComponentValid={isStep3Valid}
				updateWizardData={updateWizardData}
				onFinishClick={step3FinishChng}
				getDependencyData={getWizardData}
				mode={mode.create}
				closeWizard={navigateBack}
			/>
		);
	};

	return (
		<>
			<StyledWizardWrapper>
				<WizardWrapper
					wizLoaderName={wizLoaderName}
					currentStepIndex={activeStepIndex}
					wizardSteps={wizardSteps}
					setWizardSteps={setWizardSteps}
					buttons={buttons}
					theme={
						document
							.getElementsByTagName('body')[0]
							.getAttribute('motif-theme') === 'light'
							? null
							: 'dark'
					}
					stepContent={[step1(), step2(), step3()]}
				/>
			</StyledWizardWrapper>
		</>
	);
};

export default EngagementWizardLoader;

const StyledWizardWrapper = styled.section`
	width: 100%;
	padding: 0 var(--px-40);
	header h5 {
		font-size: var(--px-20);
		font-weight: bold;
		width: 100%;
		padding: var(--px-15) 0;
	}
	.componentBodyWrapper {
		height: calc(100vh - var(--px-280));
	}
`;
