/**
 * Created by calhosh on 7/13/2017.
 */
import React from 'react';
import styled from 'styled-components';
import FormField from '@ey/motif-react-wrapper/FormField';
import Search from '@ey/motif-react-wrapper/Search';
import {getTheme} from '../../../util/uiconstants';
export default function EYMotifSearch(props) {
	const {
		value,
		placeholder,
		onChange,
		onKeyDown,
		onEnter,
		onSearchButtonClick,
		onClear,
		styleName,
		darkBg,
		clearHoverText,
		searchHoverText,
		hideClearButton,
		maxlength
	} = props;
	return (
		<Wrapper className={`${styleName} ${darkBg ? 'darkBgSearch' : ''}`}>
			<FormField>
				<Search
					aria-label="Search"
					value={value}
					placeholder={placeholder}
					onChange={onChange}
					onClear={onClear}
					onKeyDown={onKeyDown}
					onEnter={onEnter}
					maxlength={maxlength}
					onSearchButtonClick={onSearchButtonClick}
					clearButtonTitle={clearHoverText}
					searchButtonTitle={searchHoverText}
					hideClearButton={hideClearButton}
					theme={getTheme()}
				/>
			</FormField>
		</Wrapper>
	);
}

const Wrapper = styled.section`
	.motif-form-field {
		margin-bottom: 0;
		width: 100%;
	}
	.motif-search-input-wrapper {
		width: 100%;
		display: flex;
		.motif-input-component {
			width: 100%;
		}
		.motif-input-component {
			.motif-input:hover:not(:disabled),
			.motif-input:focus:not(:disabled) {
				margin-bottom: 0;
			}
		}
		.motif-search-input-icon {
			top: 0;
			height: 100%;
		}
		.motif-typeahead .motif-typeahead-close-button {
			top: var(--px-2);
			box-shadow: none;
			height: 100%;
		}
		input {
			color: var(--neutrals-900);
			// background-color: transparent;
			padding: var(--px-2) var(--px-5);
			font-size: var(--px-11);
			height: 100%;
			font-weight: normal;
			// border-bottom: var(--px-1) solid transparent;
			&::placeholder {
				opacity: 1;
			}
		}
		.motif-select-open {
			display: none;
		}
	}
	&.darkBgSearch {
		input {
			color: var(--neutrals-00white);
			border-bottom-color: var(--neutrals-00white);
		}
		input::-ms-input-placeholder,
		input::placeholder {
			color: var(--neutrals-00white);
		}
		.motif-search-input-wrapper {
			.motif-icon-button path {
				fill: var(--neutrals-00white);
			}
		}
	}
`;
