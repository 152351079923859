/**
 * Scot.js
 * Created by zl952fc on 02/24/2020.
 */

import React, {useState, useEffect} from 'react';
import {SVGICONS} from '@ey/icons';
import {labels, currentResource} from '../../../util/uiconstants';
import {ActionTypes} from '../../../util/enumerations';
import Notifications from './Notifications';
import styled from 'styled-components';

export default function NotificationList(props) {
	const {notification, setCloseNotification, close} = props;
	const [isExpanded, setIsExpanded] = useState(false);
	useEffect(() => {
		if (
			notification.actionType == ActionTypes.SystemMessages &&
			notification.notifications.length > 0 &&
			(close == undefined || close == false)
		) {
			setIsExpanded(true);
		}
	}, [close]);

	// useEffect(() => {
	// 	if (
	// 		notification.actionType == ActionTypes.SystemMessages &&
	// 		notification.notifications.length > 0
	// 	) {
	// 		setIsExpanded(true);
	// 	}
	// }, [notification]);

	const onNotifyClick = (e) => {
		setCloseNotification(true);
		setIsExpanded(!isExpanded);
	};

	const getFirstButtonLabel = (actionType) => {
		switch (actionType) {
			case ActionTypes.DeletedEngagement:
			case ActionTypes.DeactivatedEngagements:
			case ActionTypes.ArchivedEngagement:
			case ActionTypes.ArchiveDeadlineDateOverride:
				return labels.clearAll;
			default:
				return '';
		}
	};

	const getSecondButtonLabel = (actionType) => {
		switch (actionType) {
			case ActionTypes.EngagementInvitation:
				return labels.rejectall;
			default:
				return '';
		}
	};

	const onFirstButtonClick = () => {
		notification.isFromList = true;
		props.onFirstButtonClick(notification);
	};

	const onSecondButtonClick = () => {
		notification.isFromList = true;
		props.onSecondButtonClick(notification);
	};

	const title1 = getFirstButtonLabel(notification.actionType),
		title2 = getSecondButtonLabel(notification.actionType);

	return (
		<NotificationListWrapper className="NotificationListContainer">
			<section
				className={`${isExpanded ? 'active' : ''} NotificatonListHeader`}
			>
				<span onClick={onNotifyClick} className="NotificationListLeftGroup">
					<span
						className="PlusMinusIcon"
						title={isExpanded ? labels.collapse : labels.expand}
					>
						<SVGICONS
							hoverText={isExpanded ? labels.collapse : labels.expand}
							styleName="medium"
							name={isExpanded ? 'minus' : 'plus'}
							alt="collapsed|expanded"
						/>
					</span>
					<span className="NotificatonListHeaderName">
						{currentResource.actionTypes[notification.actionType]}
					</span>
				</span>
				<span className="Count">{notification.notifications.length}</span>
				{(notification.actionType === ActionTypes.DeactivatedEngagements ||
					notification.actionType === ActionTypes.ArchivedEngagement ||
					notification.actionType ===
						ActionTypes.ArchiveDeadlineDateOverride) && (
					<span className="ButtonsGroup">
						<button title={title1} label={title1} onClick={onFirstButtonClick}>
							<span className="btnLabel">{title1}</span>
						</button>
					</span>
				)}
			</section>
			{isExpanded &&
				notification &&
				notification.notifications &&
				notification.notifications.length > 0 && (
					<>
						<section className="PlaceholderText">
							{notification.actionType === ActionTypes.DeletedEngagement && (
								<span className="WarningWrapper">
									<SVGICONS
										hoverText="Warning"
										styleName="medium"
										name="report_problem"
										alt="Warning"
									/>
									<>
										<span className="warning-message2">
											{labels.deletedEngagementWarning}
										</span>
										<span className="ButtonsGroup2">
											<button
												title={title1}
												label={title1}
												onClick={onFirstButtonClick}
											>
												<span className="btnLabel">{title1}</span>
											</button>
										</span>
									</>
								</span>
							)}
							{notification.actionType === ActionTypes.DeleteRequest && (
								<span className="WarningWrapper">
									<SVGICONS
										hoverText="Warning"
										styleName="medium"
										name="report_problem"
										alt="Warning"
									/>
									<span className="warning-message">
										{labels.searchEngagementWarning}
									</span>
								</span>
							)}
							{notification.actionType === ActionTypes.CreateError && (
								<span className="WarningWrapper">
									<SVGICONS
										hoverText="Warning"
										styleName="medium"
										name="report_problem"
										alt="Warning"
									/>
									<span className="warning-message">
										{labels.engCreationFailedWarning}
									</span>
								</span>
							)}
						</section>
						<Notifications
							notifications={notification.notifications}
							onFirstButtonClick={props.onFirstButtonClick}
							onSecondButtonClick={props.onSecondButtonClick}
							acceptedEngagementList={props.acceptedEngagementList}
							acceptedArchiveEngagementList={
								props.acceptedArchiveEngagementList
							}
							isInviteAcceptedOrRejected={props.isInviteAcceptedOrRejected}
							isArchiveInviteAcceptedOrRejected={
								props.isArchiveInviteAcceptedOrRejected
							}
							setisInviteAcceptedOrRejectedToDone={
								props.setisInviteAcceptedOrRejectedToDone
							}
							setisArchiveInviteAcceptedOrRejectedToDone={
								props.setisArchiveInviteAcceptedOrRejectedToDone
							}
						/>
					</>
				)}
		</NotificationListWrapper>
	);
}

const NotificationListWrapper = styled.section`
	background-color: var(--neutrals-100);
	border-top: var(--px-1) solid var(--neutrals-200);
	&:last-child {
		border-bottom: var(--px-1) solid var(--neutrals-200);
	}
	ul {
		padding-left: 0 !important;
	}
	.NotificatonListHeader {
		padding: var(--px-10) var(--px-15);

		& > * {
			line-height: var(--px-32);
		}

		&.active {
			border-bottom: var(--px-1) solid var(--neutrals-200);
			.NotificatonListHeaderName {
				font-weight: bold;
			}
		}
		.NotificationListLeftGroup {
			display: inline-block;
			width: calc(100% - var(--px-240));
			font-size: var(--px-14);
			.PlusMinusIcon {
				display: inline-block;
				margin-right: var(--px-10);
				cursor: pointer;
				vertical-align: top;
				svg {
					fill: var(--neutrals-900) !important;  
					vertical-align: middle;
				}
			}
			.NotificatonListHeaderName {
				display: inline-block;
				color: var(--neutrals-900);
				width: calc(100% - var(--px-40));
				cursor: pointer;
			}
		}
		.Count {
			display: inline-block;
			float: right;
			border-radius: var(--px-10);
			color: var(--neutrals-00white);
			background-color: var(--neutrals-900);
			padding: var(--px-2) var(--px-6);
			cursor: pointer;
			line-height: initial;
			vertical-align: middle;
			text-align: center;
			margin: var(--px-7) 0 0 var(--px-20);
			font-size: var(--px-12);
			min-width: var(--px-32);
		}
		.ButtonsGroup {
			display: inline-block;
			float: right;
			font-size: var(--px-14);
			vertical-align: middle;
			button {
				border: 0;
				margin-right: var(--px-10);
				font-weight: bold;
				background: transparent;
				color: var(--neutrals-900);
				&:hover {
					text-decoration: underline;
				}
			}
			button:last-child {
				margin-right: 0;
			}
		}
	}
	.PlaceholderText {
		.WarningWrapper {
			border-bottom: var(--px-1) solid var(--neutrals-200);
			padding: var(--px-5) var(--px-15);
			display: block;
			background-color: var(--neutrals-00white);
			svg {
				fill:  var(--orange-200);
				margin-top: var(--px-6);
			}
		}
		.warning-message {
			display: inline-block;
			margin-left: var(--px-9);
			font-weight: bold;
			width: 90%;
			line-height: var(--px-15);
			vertical-align: middle;
			font-size: var(--px-13);
			padding: var(--px-5) 0;
			color: var(--neutrals-900);
		}
	}
	.ButtonsGroup2 {
		display: inline-block;
		float: right;
		font-size: var(--px-14);
		vertical-align: middle;
		button {
			border: 0;
			margin-right: var(--px-10);
			font-weight: bold;
			background: transparent;
			color: var(--neutrals-900);
			&:hover {
				text-decoration: underline;
			}
		}
		button:last-child {
			margin-right: 0;
		}
	}
	.warning-message2 {
		display: inline-block;
		margin-left: var(--px-9);
		font-weight: bold;
		line-height: var(--px-15);
		vertical-align: middle;
		font-size: var(--px-13);
		padding: var(--px-5) 0;
		color: var(--neutrals-900);
	}
}

`;
