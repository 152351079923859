import styled from 'styled-components';

const Wrapper = styled.section`
	.loadingstyle {
		height: calc(100vh - var(--px-250)) !important;
	}

	.workspace_menu {
		width: 80%;
		top: 4.3rem;
		background: var(--neutrals-bg-gradient1) !important;
		.motif-table {
			height: calc(100vh - var(--px-330)) !important; 
			.ellipses {
				display: flex;
			}
			.ag-row {
				border-bottom: none;
				.ag-cell-wrapper {
					display: grid;
					width: 100%;
				}
			}
		}
		.header {
			display: flex;
			justify-content: end;
			padding: var(--px-14);
		}
		.footer_button {
			display: flex;
			justify-content: end;
			padding: var(--px-14);
			.previousBtn {
				margin-right: var(--px-14);
			}
		}
		.motif-menu-header-information {
			width: 100%;
		}
		.add-workspace {
			.motif-icon {
				svg {
					fill: none;
				}
			}
		}
		.mainwrapper {
			padding: var(--px-14) !important;
		}
	}

	.workspace_menuTable {
		width: 100% !important;
	}
	//pagination

	.pagerholder {
		margin: 0 auto;
		width: 100%;
		position: absolute;
		bottom: var(--px-0);
	}

	.sharepointWorkspace-paging {
		justify-content: flex-end;
		line-height: normal;
		min-width: auto !important;
		font-size: var(--px-14);
		background: ${(props) => props.theme.color[`white`]};
		border-top: 1px solid ${(props) => props.theme.color[`grey200`]};
	}

	.mainwrapper {
		overflow: hidden;
		padding-top: var(--px-90);
		background: var(--neutrals-bg-gradient1);

		.message {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: calc(100vh - var(--px-180));

			&h5 {
				color: ${(props) => props.theme.color[`grey500`]};
			}
		}

		//heading
		.sharepointWorkspace {
			.pageheading {
				display: flex;
				padding: var(--px-20) 0;
				svg {
					min-width: var(--px-25) !important;
					min-height: var(--px-25) !important;
					margin-right: var(--px-10);
				}

				h3 {
					margin: 0;
					line-height: var(--px-25);
				}
			}
		}

		//addon

		.add-on {
			display: flex;
			margin-right: auto;
			padding: 0 0 var(--px-20) 0;

			.adduser {
				.motif-button {
					height: var(--px-40);
					width: var(--px-100);
				}
			}

			.search {
				width: var(--px-400);
				line-height: var(--px-40);
				margin-right: auto;

				.motif-icon-button {
					top: var(--px-5);
				}

				.motif-typeahead-close-button {
					top: var(--px-4);
				}

				.motif-input {
					height: var(--px-40);
				}
			}
		}

		.norecord {
			height: calc(100vh - var(--px-300));
			display: inline-block;
			width: 100%;
			display: flex;
			justify-content: center;
			flex: 0 auto;
			align-items: center;
			font-size: var(--px-16);
			color: ${(props) => props.theme.color[`grey400`]};
		}

		.sharepointWorkspaceTable {
			/* height: 100%; */
			overflow-y: auto;
			user-select: none;
			.motif-table {
				height: calc(100vh - var(--px-270)) !important;

				.ag-header-cell .ag-header-select-all {
					visibility: hidden;
				}

				.ag-body-viewport {
					/* height: calc(100vh - var(--px-300)); */

					&:after {
						content: none;
					}
				}

				.ag-header-viewport {
					/* background: ${(props) => props.theme.color[`grey100`]};
                font-size: var(--px-14); */
				}
				.ag-root.ag-layout-normal {
					overflow-y: auto;
					width: 100%;
				}

				.ag-root.ag-layout-auto-height {
					display: block;
				}

				.ag-center-cols-viewport {
					overflow-x: hidden;
				}

				.ag-row-hover {
					/* border-width: 0 0 var(--px-1) 0 !important;		
                border-style: solid !important;
                border-color: ${(props) =>
						props.theme.color[`grey250`]} !important; */
				}

				.ag-row {
					/* border-width: 0 0 var(--px-1) 0 !important;		
                border-style: solid !important;
                border-color: ${(props) =>
						props.theme.color[`grey250`]} !important;
                color: ${(props) => props.theme.color[`grey400`]}; */

					.ag-cell-value {
						/* font-weight: 100; */

						a.groupname {
							width: 100%;
							display: inline-block;
							color: ${(props) => props.theme.color[`blue600`]};

							.ellipses {
								display: flex;
								color: ${(props) => props.theme.color[`blue600`]}!important;
							}
						}
					}
				}

				.ag-row,
				.ag-row-hover {
					/* background: ${(props) => props.theme.color[`white`]} !important; */
					/* font-weight: normal; */

					.ag-cell-wrapper {
						width: 100%;
						display: grid !important;
					}

					.ag-column-hover {
						/* background: ${(props) =>
							props.theme.color[`white`]} !important; */
					}
				}

				.ag-header-row .ag-header-cell {
					&:hover {
						/* background: ${(props) => props.theme.color[`grey100`]} */
					}
				}

				.ag-cell {
					/* line-height: normal;
				background: ${(props) => props.theme.color[`white`]} !important; */
				}

				.ag-cell,
				.ag-header-cell {
					/* font-size: var(--px-14);
                padding: 0 var(--px-20); */
					display: block;

					.ag-react-container {
						[role='button'] {
							/* cursor: default; */
						}
					}

					.ag-header-cell-text {
						/* font-size: var(--px-14); */
					}
				}

				.ag-header-cell:last-child,
				.ag-cell:last-child {
					/* border-right: none; */
				}

				.roleType.disabled,
				.roleType.disabled * {
					color: ${(props) => props.theme.color[`grey300`]}!important;
					fill: ${(props) => props.theme.color[`grey300`]};
				}
				.noptr {
					pointer-events: none;
				}
				.disabled {
					color: ${(props) => props.theme.color[`grey300`]}!important;
					fill: ${(props) => props.theme.color[`grey300`]};
				}

				.ag-row {
					border-bottom: none;
				}
				.roleType {
					display: inline-block;
					width: 100%;
					align-items: center;
					cursor: default;

					&.enable.icon {
						fill: ${(props) => props.theme.color[`black`]}!important;
					}
					.columnName {
						/* font-size: var(--px-14);
                    color: ${(props) => props.theme.color[`grey500`]}; */
					}
				}
			}

			.errorRow {
				color: ${(props) => props.theme.color[`red600`]} !important;
			}
		}

		.ag-body-horizontal-scroll {
			overflow: auto;
		}

		.ag-layout-normal {
			&::-webkit-scrollbar {
				width: var(--px-6);
			}
			&::-webkit-scrollbar-track {
				/* background: ${(props) => props.theme.color[`grey225`]}; */
				width: var(--px-6);
			}
			&::-webkit-scrollbar-thumb {
				/* background: ${(props) => props.theme.color[`grey300`]}; */
				width: var(--px-6);
				/* cursor: pointer; */
			}
			&::-webkit-scrollbar-thumb:hover {
				/* background: ${(props) => props.theme.color[`grey400`]}; */
			}
		}
	}
`;

export default Wrapper;
