import React from 'react';
import {SVGICONS} from '@ey/icons';
import styled from 'styled-components';
import { lightColor} from '@ey/styled-theme';

const Iconpopover = styled.section`
	position: relative;

	& .count {
		background: white;
		border-radius: var(--px-50);
		border: var(--px-1) solid var(--neutrals-900);
		border-image: none;
		top: var(--px-8);
		width: var(--px-25);
		height: var(--px-25);
		right: calc(var(--px-4) * -1);
		vertical-align: middle;
		position: absolute;
		font-size: var(--px-12);
	}

	& .countin {
		text-align: center;
		color: var(--neutrals-900);
		line-height: normal;
		font-weight: bold;
		margin-top: var(--px-4);
		vertical-align: middle;
		display: block;
	}

	& .popblock {
		min-width: var(--px-120);
		border: var(--px-1) solid var(--neutrals-1000);
		border-radius: 0;
		z-index: 100;
		background-color: var(--neutrals-25);
		position: absolute;
		right: 0;
		margin: 0;
		padding: 0;
		.editList {
			ul li {
				padding-bottom:0;
				margin-bottom:0;
			}
		}
	}

	& .iconDisabled {
		fill: var(--neutrals-300);
		cursor: default;
		pointer-events: none;
	}

	& .icon {
		cursor: pointer;
		display: inline-block;
		margin: 0 var(--px-10);

		&.iconActiveYellow > svg{
        	fill:var(--brand-500);
        } 
	}

	
		
	& .tooltip-uparrow {

		/* Stroke */
		&:before {
			content: '';
			display: block;
			position: absolute;
			right: var(--px-10);
			bottom: 100%;
			width: 0;
			height: 0;
			border: var(--px-10) solid transparent;
			border-bottom-color: var(--neutrals-1000);
		}

		/* Fill */
		&:after {
			content: '';
			display: block;
			position: absolute;
			right: var(--px-11);
			bottom: 100%;
			width: 0;
			height: 0;
			border: var(--px-9) solid transparent;
			border-bottom-color: var(--neutrals-00white);
		}
	}
`;

//count cmponent
function Count(props) {
	return (
		<section className="count">
			<span className="countin"> {props.count}</span>
		</section>
	);
}

//popover component
function PopOver(props) {
	return (
		<section className="popblock">
			<section className="tooltip-uparrow" />
			{props.popOverComponent}
		</section>
	);
}

//icon pop over main cmp
export default class IconPopOver extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showPopover: false
		};
		this.handleClick = this.handleClick.bind(this);
	}

	componentWillMount() {
		document.addEventListener('mousedown', this.handleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClick, false);
	}

	handleClick = (e) => {
		if (this.node.contains(e.target)) {
			return;
		}
		this.setState({showPopover: false});
	};

	notifClicked = () => {
		this.setState({showPopover: !this.state.showPopover});
	};

	closePopover = () => {
		this.setState({showPopover: false});
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.closePopover != this.props.closePopover) {
			this.setState({showPopover: false});
		}
	}
	render() {
		let iconstyle = 'icon';
		if (this.props.disabled && this.props.disabled === true) {
			iconstyle += ' iconDisabled';
		}

		if (
			this.props.activeYellow &&
			this.props.activeYellow === true &&
			this.state.showPopover === true
		) {
			iconstyle += ' iconActiveYellow';
		}

		return (
			<div ref={(node) => (this.node = node)}>
				<Iconpopover>
					<section className={iconstyle}>
						<SVGICONS
							hoverText={this.props.iconHover}
							name={this.props.iconName}
							styleName={this.props.iconstyleName}
							onIconClick={(e) => {
								e.stopPropagation();
								this.notifClicked();
							}}
						/>
					</section>
					{this.props.count > 0 ? <Count count={this.props.count} /> : null}
					{this.state.showPopover ? (
						<PopOver popOverComponent={this.props.popOverComponent} />
					) : null}
				</Iconpopover>
			</div>
		);
	}
}
