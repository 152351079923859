import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import {labels, currentResource} from '../../../util/uiconstants';
import styled from 'styled-components';
import {SVGICONS} from '@ey/icons';
import EngagementMetric from './engagementMetric';
import uuidv4 from 'uuid/v4';
import Dropdown from '@ey/motif-react-wrapper/Dropdown';
import DropdownItem from '@ey/motif-react-wrapper/DropdownItem';

const EngagementMetricsDetails = (props) => {
	const {timeline} = props;

	/* ----------------- State variables ----------------- */

	const [overdueTotals, setOverdueTotals] = useState([]),
		[showWhatsNewDropdown, setShowWhatsNewDropdown] = useState(false),
		[showComingDueDropdown, setShowComingDueDropdown] = useState(false),
		[comingDueTotals, setcomingDueTotals] = useState([]),
		[newTotals, setNewTotals] = useState([]),
		[metricsTotals, setMetricsTotals] = useState([]),
		[comingDueFilterValue, setComingDueFilterValue] = useState(5),
		[newFilterValue, setNewFilterValue] = useState(5),
		[comingDueOptionSelected, setComingDueOptionSelection] = useState(
			currentResource.metricFilterComingDue.find(function (item) {
				return item.isDefault;
			}).value
		),
		[whatsNewOptionSelected, setWhatsNewOptionSelection] = useState(
			currentResource.metricFilterWhatsNew.find(function (item) {
				return item.isDefault;
			}).value
		);

	/* ----------------- /State variables ----------------- */

	let overdueMetrics = [],
		comingDueMetrics = [],
		newMetrics = [];

	if (
		timeline.metrics &&
		timeline.metrics.engagementMetrics &&
		timeline.metrics.engagementMetrics.length > 0
	) {
		overdueMetrics = timeline.metrics.engagementMetrics.filter(
			(item) => item.category === 'Overdue'
		);
		comingDueMetrics = timeline.metrics.engagementMetrics.filter(
			(item) => item.category === 'ComingDue'
		);
		newMetrics = timeline.metrics.engagementMetrics.filter(
			(item) => item.category === 'New'
		);
	}

	// Returns a boolean value indicating whether the metrics list has any non-zero metric counter
	// If a new metric is shown, add it to this function!!
	const haveMetricsToShow = (metricsList) => {
		let result = false;
		let metricsTotal = 0;

		if (metricsList) {
			for (let metricItem of metricsList) {
				metricsTotal =
					metricsTotal +
					(metricItem.preparer || 0) +
					(metricItem.forMyReview || 0) +
					(metricItem.upcomingReview || 0) +
					(metricItem.reviewNotesAssigned || 0) +
					(metricItem.reviewNotesAuthored || 0) +
					(metricItem.clientRequests || 0) +
					(metricItem.timephases || 0) +
					(metricItem.groupTasks || 0) +
					(metricItem.groupDeliverables || 0) +
					(metricItem.automationDocuments || 0);

				if (metricsTotal >= 0) {
					result = true;
					break;
				}
			}
		}

		return result;
	};

	// Returns a boolean value indicating whether the totals received as a parameter contains all 0 values or not.
	// true --> The metricTotals object has all its counters with a value of 0
	// false --> The metricTotals object has at least 1 non zero counter
	const isTotalEmpty = (metricTotals) => {
		let totalCount = 0;

		if (metricsTotals) {
			totalCount =
				(metricTotals.preparer || 0) +
				(metricTotals.forMyReview || 0) +
				(metricTotals.upcomingReview || 0) +
				(metricTotals.reviewNotesAssigned || 0) +
				(metricTotals.reviewNotesAuthored || 0) +
				(metricTotals.clientRequests || 0) +
				(metricTotals.timephases || 0) +
				(metricTotals.groupTasks || 0) +
				(metricTotals.groupDeliverables || 0) +
				(metricTotals.automationDocuments || 0);

			return totalCount === 0;
		} else {
			// null or undefined --> return true (emtpy object).
			return true;
		}
	};

	// This determines whether the API call returned metric values (any non 0) for each section
	let haveOverdueMetrics = haveMetricsToShow(overdueMetrics),
		haveComingDueMetrics = haveMetricsToShow(comingDueMetrics),
		haveNewMetrics = haveMetricsToShow(newMetrics);

	// Returns the total for each metric in the metricsList collection, applying the filter function if it's sent from the calling component
	// If a new metric is shown, add it to this function!!
	const calculateMetricsTotals = (metricsList, filterFunction, filterValue) => {
		// Apply filter selected from the dropdown on the metrics list
		const haveFilter = filterFunction && typeof filterFunction === 'function';

		let metricsTotal = {};

		for (let metric of metricsList) {
			if (!haveFilter || filterFunction(metric, filterValue)) {
				metricsTotal = {
					preparer: (metricsTotal.preparer || 0) + metric.preparer,
					forMyReview: (metricsTotal.forMyReview || 0) + metric.forMyReview,
					upcomingReview:
						(metricsTotal.upcomingReview || 0) + metric.upcomingReview,
					reviewNotesAssigned:
						(metricsTotal.reviewNotesAssigned || 0) +
						metric.reviewNotesAssigned,
					reviewNotesAuthored:
						(metricsTotal.reviewNotesAuthored || 0) +
						metric.reviewNotesAuthored,
					clientRequests:
						(metricsTotal.clientRequests || 0) + metric.clientRequests,
					timephases: (metricsTotal.timephases || 0) + metric.timephases,
					groupTasks: (metricsTotal.groupTasks || 0) + metric.groupTasks,
					groupDeliverables:
						(metricsTotal.groupDeliverables || 0) + metric.groupDeliverables,
					automationDocuments:
						(metricsTotal.automationDocuments || 0) + metric.automationDocuments
					// groupDocumentReturned: (metricsTotal.groupDocumentReturned || 0) + metric.groupDocumentReturned,
				};
			}
		}

		metricsTotal = metricsTotal || {
			preparer: 0,
			forMyReview: 0,
			upcomingReview: 0,
			reviewNotesAssigned: 0,
			reviewNotesAuthored: 0,
			clientRequests: 0,
			timephases: 0,
			groupTasks: 0,
			groupDeliverables: 0,
			automationDocuments: 0
			// groupDocumentReturned: 0,
		};

		return metricsTotal;
	};

	const mergeMetricsTotals = () => {
		const metricTitles = currentResource.engagementMetricNames.map((metric) => {
			return metric.value;
		});

		const mergedTotals = _.zip(
			metricTitles,
			haveOverdueMetrics ? Object.values(overdueTotals) : 0,
			haveComingDueMetrics ? Object.values(comingDueTotals) : 0,
			haveNewMetrics ? Object.values(newTotals) : 0
		);

		// This is where the columns that are empty are filtered out.
		// The first position of the "columnItem" array is skipped because it's the title of the column, and it should never be empty.
		// That's why the counter variable declared in the for cycle is initialized as 1.
		const filteredTotals = mergedTotals.filter((columnItem) => {
			let notEmpty = false;
			for (let i = 1; i < columnItem.length; ++i) {
				if (typeof columnItem[i] !== 'undefined' && columnItem[i] > 0) {
					notEmpty = true;
				}
			}
			return notEmpty;
		});

		setMetricsTotals(filteredTotals);
	};

	const comingDueFilter = (element, days) => {
		let result;

		result = element.age <= days;

		return result;
	};

	const newFilter = (element, days) => {
		let result;

		result = element.age <= days;

		return result;
	};

	// Executed when the user selects a filter from the "Coming due" drop-down
	const onComingDueFilterChange = (option) => {
		setShowComingDueDropdown(false);
		setComingDueOptionSelection(option.value);
		option && setComingDueFilterValue(option.value);
	};

	// Executed when the user selects a filter from the "What's New" drop-down
	const onWhatsNewFilterChange = (value) => {
		setShowWhatsNewDropdown(false);
		setWhatsNewOptionSelection(value.value);
		value && setNewFilterValue(value.value);
	};

	useEffect(() => {
		setOverdueTotals(calculateMetricsTotals(overdueMetrics));

		setcomingDueTotals(
			calculateMetricsTotals(
				comingDueMetrics,
				comingDueFilter,
				comingDueFilterValue
			)
		);

		setNewTotals(calculateMetricsTotals(newMetrics, newFilter, newFilterValue));
	}, []);

	useEffect(() => {
		mergeMetricsTotals();
	}, [overdueTotals, comingDueTotals, newTotals]);

	// Coming due filter
	useEffect(() => {
		let filteredTotals = calculateMetricsTotals(
			comingDueMetrics,
			comingDueFilter,
			comingDueFilterValue
		);
		setcomingDueTotals(filteredTotals);
	}, [comingDueFilterValue, showComingDueDropdown]);

	// What's new filter
	useEffect(() => {
		let filteredTotals = calculateMetricsTotals(
			newMetrics,
			newFilter,
			newFilterValue
		);
		setNewTotals(filteredTotals);
	}, [newFilterValue]);

	const handleComingDueDropdownToggle = (value) => {
		let toggle = showComingDueDropdown ? false : true;
		setShowComingDueDropdown(toggle);
	};
	const setClickOutsideComingDue = () => {
		setShowComingDueDropdown(false);
	};
	const setClickOutsideWhatsNew = () => {
		setShowWhatsNewDropdown(false);
	};
	const handleWhatsNewToggle = () => {
		let toggle = showWhatsNewDropdown ? false : true;
		setShowWhatsNewDropdown(toggle);
	};
	// The API call returned no metrics --> Hide the whole section
	if (!haveOverdueMetrics && !haveComingDueMetrics && !haveNewMetrics) {
		return null;
	} else {
		return (
			<StyledMetricsDetails>
				<section className="metrics-filters">
					{
						<span className={`filter-title`}>
							<span className="emitemHeader">{labels.overdue}</span>
						</span>
					}
					{
						<span className={`filter-title`}>
							<span className="emitemHeader" title={labels.comingDue}>
								{labels.comingDue}
							</span>
							<Dropdown
								open={showComingDueDropdown}
								placement="bottom-end"
								className="engagement-metrics-dropdown"
								closeOnOutsideClick={() => setClickOutsideComingDue()}
								trigger={
									<span
										role="button"
										onClick={() => handleComingDueDropdownToggle()}
										title={labels.comingDue}
									>
										<SVGICONS name="more-dots" styleName="medium" />
									</span>
								}
							>
								{currentResource.metricFilterComingDue.map((option, index) => {
									return (
										<DropdownItem
											onClick={() => onComingDueFilterChange(option)}
											title={option.label}
											key={index}
											className={
												option.value === comingDueOptionSelected
													? 'selected'
													: ''
											}
										>
											{option.label}
										</DropdownItem>
									);
								})}
							</Dropdown>
						</span>
					}
					{
						<span className={`filter-title`}>
							<span className="emitemHeader" title={labels.whatsNew}>
								{labels.whatsNew}
							</span>
							<Dropdown
								open={showWhatsNewDropdown}
								placement="bottom-end"
								className="engagement-metrics-dropdown"
								closeOnOutsideClick={() => setClickOutsideWhatsNew()}
								trigger={
									<span
										role="button"
										onClick={() => handleWhatsNewToggle()}
										title={labels.whatsNew}
									>
										<SVGICONS name="more-dots" styleName="medium" />
									</span>
								}
							>
								{currentResource.metricFilterWhatsNew.map((option, index) => {
									return (
										<DropdownItem
											onClick={() => onWhatsNewFilterChange(option)}
											title={option.label}
											key={index}
											className={
												option.value === whatsNewOptionSelected
													? 'selected'
													: ''
											}
										>
											{option.label}
										</DropdownItem>
									);
								})}
							</Dropdown>
						</span>
					}
				</section>
				{/* Metrics counters */}
				<section className="metrics-counters-wrapper">
					<section className="customScrollbar metrics-item">
						{metricsTotals &&
							metricsTotals.map((metric) => {
								return (
									<EngagementMetric
										key={uuidv4()}
										metricName={metric[0]}
										metricValues={metric.slice(1)}
										haveComingDueMetrics={haveComingDueMetrics}
										haveNewMetrics={haveNewMetrics}
										haveOverdueMetrics={haveOverdueMetrics}
									/>
								);
							})}
					</section>
				</section>
			</StyledMetricsDetails>
		);
	}
};

export default EngagementMetricsDetails;

const StyledMetricsDetails = styled.section`
	font-size: var(--px-13);
	padding: var(--px-10) var(--px-20);
	.metrics-filters {
		display: flex;
		width: 100%;
		font-weight: normal;
		padding-left: 40%;
		line-height: var(--px-24);
		.filter-title {
			width: 33.3%;
			display: flex;
			position: relative;
			.emitemHeader {
				max-width: calc(100% - 48px);
				width: auto;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.engagement-metrics-dropdown {
				display: flex;
				align-items: center;
				.motif-dropdown-trigger {
					span {
						display: flex;
					}
				}
					.motif-dropdown-menu[x-placement^='bottom-right'] {
						right: calc(var(--px-20) * -1);
						top: var(--px-15);
						max-width: var(--px-200);
						&:before {
							top: calc(var(--px-6) * -1);
							right: 2ren;
						}
					}
					.motif-dropdown-item {
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
						display: block;
						&:focus {
							width: 100%;
							margin-left: 0;
						}
					}
					.motif-dropdown-item:first-child {
						&:focus {
							margin-top: 0;
						}
					}
				}
			}
		}
	}
	.metrics-counters-wrapper {
		padding: 0;
		display: flex;
		flex-direction: column;
		.metrics-item {
			height: var(--px-250);
			overflow-y: auto;
		}
	}
`;
