import React, {useState} from 'react';
import {useEffect} from 'react';
import env from '../../../../util/env';
import {useSelector, useDispatch} from 'react-redux';
import Table from '@ey/motif-react-wrapper/Table';
import EllipsesControl from '@ey/ellipses-control';
import Loader from '../../../Common/Loaders/loadingindicator';
import {labels} from '../../../../util/uiconstants';
import AccessDeniedMessage from '../../../Common/AccessDeniedMessage/AccessDeniedMessage';
import Wrapper from '../Styles/sharepointWorkspacesbasestyle';
import {clearErrors} from '../../../../actions/erroractions';
import {setIsModalOpen} from '../../../../actions/modalstateactions';
import { getWorkspaceDetails } from '../../../../actions/admin/sharepointworkspacesactions';

function WorkSpaceDetails() {
	const columnDefs = [
		{
			minWidth: 50,
			width: 50,
			flex: 6,
			headerName: labels.workspaceName,
			field: 'WorkspaceName',
			cellRenderer: (params) => workSpaceNameCellRenderer(params),
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			minWidth: 25,
			width: 25,
			flex: 4,
			headerName: labels.documentCount,
			field: 'DocumentCount',
			cellRenderer: (params) => documentCountCellRenderer(params),
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			minWidth: 25,
			width: 25,			
			flex: 4,
			headerName: labels.percentCapacity,
			field: 'PercentageCapacity',
			cellRenderer: (params) => percentageCapacityCellRenderer(params),
			lockPosition: true,
			suppressSizeToFit: true
		}
	];

	const workSpaceNameCellRenderer = (e) => {
		return (
			<>
				<section className="roleType">
					<section>
						<EllipsesControl
							content={e.data?.WorkspaceName}
							tooltip={e.data?.WorkspaceName}
							displayId={''}
							noOfLines={1}
							ellipsisClass="columnName"
						/>
					</section>
				</section>
			</>
		);
	};

	const percentageCapacityCellRenderer = (e) => {
		return (
			<>
				<section className="roleType" style={e.data?.PercentageCapacity>80 ?{color:'red'}:{color:'black'}}>
						{JSON.stringify(e.data?.PercentageCapacity)}%
				</section>
			</>
		);
	};
	
	const documentCountCellRenderer = (e) => {
		return (
			<>
				<section className="roleType">
					<section>
						{JSON.stringify(e.data?.DocumentCount)}
					</section>
				</section>
			</>
		);
	};

	const userPermissions = useSelector((store) => store.userPermissions);
	const workSpaceDetails = useSelector((store) => store.workSpaceDetails);
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	useEffect(() => {
		setLoading(true);
		let workSpaceDetailsUrl =
			env.getURL('serviceUrlV2') + 'admin/WorkspaceDetails';
		dispatch(getWorkspaceDetails(workSpaceDetailsUrl))
			.then(() => {
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	}, []);

	const clearError = (isModelOpen) => {
		dispatch(clearErrors()).then(() => {
			dispatch(setIsModalOpen(isModelOpen));
		});
	};

	useEffect(()=>{
		clearError(true);
	},[])

	function adminPermissions() {
		let sharepointWorkSpacesPermission = userPermissions?.permissions;
		return true;
		// (
		// 	sharepointWorkSpacesPermission &&
		// 	(sharepointWorkSpacesPermission.canReadPermissionsGroups ||
		// 		sharepointWorkSpacesPermission.canSyncPermissionsGroups ||
		// 		sharepointWorkSpacesPermission.canCreateUpdatePermissionsGroups)
		// );
	}

	return (
		<Wrapper>
			{loading == true ? (
				<Loader show view="blockView" styleName="loadingstyle" />
			) : (
				<section className="mainwrapper">
					{adminPermissions() ? (
						workSpaceDetails ? (
							<section>
								<section className="workspace_menuTable mainResponsiveWrapper">
									<section className="managepermissionTable">
										<Table
											defaultColDef={{
												flex: 1,
												sortable: false,
												filter: false,
												resizable: false,
												lockPosition: true
											}}
											onGridReady={(params) => {
												params.api.sizeColumnsToFit();
											}}
											zebra={true}
											rowHeight={32}
											enableCellTextSelection
											suppressRowClickSelection
											suppressCellSelection
											columnDefs={columnDefs}
											rowSelection="multiple"
											rowData={workSpaceDetails?.data}
										/>
									</section>
								</section>
							</section>
						) : (
							<section className="message">
								<h5>{labels.serverErrorMessage}</h5>
							</section>
						)
					) : (
						<section className="message">
							<AccessDeniedMessage
								permissionMesage={labels.insufficientPermission}
							/>
						</section>
					)}
				</section>
			)}


		</Wrapper>
	);
}

export default WorkSpaceDetails;
