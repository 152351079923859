import * as types from '../actiontypes';

export function getSharepointWorkspaces(url) {
	return {
		type: types.GET_ALL_DAAS_WORKSPACES,
		meta: {
			api: {
				url: url,
				method: 'GET'
			}
		}
	};
}
export function getWorkspaceDetails(url) {
	return {
		type: types.GET_ALL_WORKSPACE_DETAILS,
		meta: {
			api: {
				url: url,
				method: 'GET'
			}
		}
	};
}

export function resetsharepointWorkSpaces(){
	return {
		type: types.RESET_SHAREPOINT_WORKSPACES,
	}
}