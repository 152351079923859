import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import EllipsesControl from '@ey/ellipses-control';
import {SVGICONS} from '@ey/icons';
import {
	employmentStatusTypes,
	labels
} from '../../../../util/uiconstants';
import {clearErrors} from '../../../../actions/erroractions';
import {setIsModalOpen} from '../../../../actions/modalstateactions';
import {getEmployeeADStatus} from '../../../../actions/admin/engagement/engagementActions';
import Tooltip from '@ey/motif-react-wrapper/Tooltip';
import Toast from '@ey/motif-react-wrapper/Toast';
function PermissionGroupUserRow(props) {
	const dispatch = useDispatch();
	const userPermissions = useSelector((store) => store.userPermissions);

	const getAdStatus = (e) => {
		let defaultStatus = {
			key: employmentStatusTypes.DefaultStatus,
			text: '...'
		};

		const [employeeADStatus, setEmployeeADStatus] = useState(defaultStatus);
		const employeeADStatuses = useSelector(
			(store) => store.employeeADStatuses.statuses
		);

		let onErrorStatus = {
			key: employmentStatusTypes.InActive,
			text: labels.inactive
		};

		useEffect(() => {
			clearError(true);
			return () => {
				clearError(false);
			};
		}, []);

		const clearError = (isModelOpen) => {
			dispatch(clearErrors()).then(() => {
				dispatch(setIsModalOpen(isModelOpen));
			});
		};

		//Load AD staus on UI
		useEffect(() => {
			var filteredStatus = employeeADStatuses[e.data?.emailAddress];
			if (filteredStatus?.length && filteredStatus != 'error') {
				if (employmentStatusTypes.Active !== employeeADStatus?.key) {
					let empStatus = {
						key: employmentStatusTypes.Active,
						text: labels.active
					};
					setEmployeeADStatus(empStatus);
				}
			}
		}, [employeeADStatuses[e.data?.emailAddress]]);

		//Get AD stauses
		useEffect(() => {
			dispatch(getEmployeeADStatus(e.data?.emailAddress))
				.catch((error) => {
					setEmployeeADStatus(onErrorStatus);
					clearError(true);
				})
				.finally(() => {
					clearError(true);
				});
			clearError(true);
		}, []);

		return (
			<section className="userlistitem grid status">
				<section className="description">
					<section className="detail">
						<EllipsesControl
							content={employeeADStatus?.text}
							tooltip={employeeADStatus?.text}
							displayId={''}
							noOfLines={1}
							ellipsisClass="columnName"
						/>
					</section>
				</section>
			</section>
		);
	};

	return (
		<section className="permissionitemview">
			<section className="userlistitem grid">
				<section className="description">
					<section className="detail">
						<EllipsesControl
							content={props.data.firstName}
							tooltip={props.data.firstName}
							displayId={''}
							noOfLines={1}
							ellipsisClass="columnName"
						/>
					</section>
				</section>
			</section>
			<section className="userlistitem grid">
				<section className="description">
					<section className="detail">
						<EllipsesControl
							content={props.data.lastName}
							tooltip={props.data.lastName}
							displayId={''}
							noOfLines={1}
							ellipsisClass="columnName"
						/>
					</section>
				</section>
			</section>
			<section className="userlistitem grid title">
				<section className="description">
					<section className="detail">
						<EllipsesControl
							content={props.data.title}
							tooltip={props.data.title}
							displayId={''}
							noOfLines={1}
							ellipsisClass="columnName"
						/>
					</section>
				</section>
			</section>
			<section className="userlistitem grid email">
				<section className="description">
					<section className="detail">
						<EllipsesControl
							content={props.data.emailAddress}
							tooltip={props.data.emailAddress}
							displayId={''}
							noOfLines={1}
							ellipsisClass="columnName"
						/>
					</section>
				</section>
			</section>
			{/* <section className="userlistitem grid">
				<section className="description">
					<section className="detail">
						<EllipsesControl
							content={props.data.phone}
							tooltip={props.data.phone}
							displayId={''}
							noOfLines={1}
							ellipsisClass="columnName"
						/>
					</section>
				</section>
			</section> */}
			<section className="userlistitem grid gui">
				<section className="description">
					<section className="detail">
						<EllipsesControl
							content={props.data.gui}
							tooltip={props.data.gui}
							displayId={''}
							noOfLines={1}
							ellipsisClass="columnName"
						/>
					</section>
				</section>
			</section>
			<section className="userlistitem grid userid">
				<section className="description">
					<section className="detail">
						<EllipsesControl
							content={props.data.userID}
							tooltip={props.data.userID}
							displayId={''}
							noOfLines={1}
							ellipsisClass="columnName"
						/>
					</section>
				</section>
			</section>
			{getAdStatus(props)}
			<section className="userlistitem grid delete">
				<section className="description">
				<section className="detail editicon-button">
						<Tooltip
							flip
							id={'deleteButtonTooltipId'}
							placement={'top'}
							contentClassName="delete"
							hide={
								userPermissions?.permissions?.canCreateUpdatePermissionsGroups
							}
							trigger={
								<section>
									<button
										role="button"
										aria-label="delete-icon"
										disabled={
											!userPermissions?.permissions
												?.canCreateUpdatePermissionsGroups
										}
										onClick={() => {
											props.onDeleteClick(props.data);
										}}
										className="edit-user-edit-btn"
									>
										<SVGICONS
											role="button"
											styleName="medium deleteicon"
											name="delete"
											hoverText={labels.permissionGroupUserDeleteLabel}
										/>
									</button>
								</section>
							}
						>
							<Toast variant="error">
								{labels.insufficientPermission}
							</Toast>
						</Tooltip>
					</section>
				</section>
			</section>
		</section>
	);
}

export default PermissionGroupUserRow;
