/**
 * Created by rastwe on 3/1/2017.
 */
export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';
export const CLIENT_VALIDATION_ERROR = 'CLIENT_VALIDATION_ERROR';

export const LOAD_WORKSPACES = 'LOAD_WORKSPACES';
export const LOAD_MY_WORKSPACES = 'LOAD_MY_WORKSPACES';
export const LOAD_MY_ENGAGEMENTS = 'LOAD_MY_ENGAGEMENTS';
export const LOAD_ENGAGEMENTS_V2 = 'LOAD_ENGAGEMENTS_V2';
export const LOAD_ENGAGEMENTMETADATA = 'LOAD_ENGAGEMENTMETADATA';
export const GET_ARCHIVES = 'GET_ARCHIVES';
export const USER_SETTINGS = 'USER_SETTINGS';
export const USER_PERMISSIONS = 'USER_PERMISSIONS';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const CREATE_USER_SETTINGS = 'CREATE_USER_SETTINGS';
export const PREF_TAB_USER_SETTINGS = 'PREF_TAB_USER_SETTINGS';
export const PREF_GROUPING_USER_SETTINGS = 'PREF_GROUPING_USER_SETTINGS';
export const PREF_PAGING_USER_SETTINGS = 'PREF_PAGING_USER_SETTINGS';
export const CREATE_WORKSPACE = 'CREATE_WORKSPACE';
export const UPDATE_WORKSPACE = 'UPDATE_WORKSPACE';
export const GET_WORKSPACE_DATA = 'GET_WORKSPACE_DATA';
export const RESET_WORKSPACE_DATA = 'RESET_WORKSPACE_DATA';
export const GET_COUNTRIES_DATA = 'GET_COUNTRIES_DATA';
export const SET_CREATE_WORKSPACE = 'SET_CREATE_WORKSPACE';
export const CLEAR_ALL_ARCHIVES = 'CLEAR_ALL_ARCHIVES';
export const PATCH_ENGUSER = 'PATCH_ENGUSER';
export const SET_CURRENT_ACTIVETAB_IN_MYENG = 'SET_CURRENT_ACTIVETAB_INMYENG';
export const SET_CURRENT_GROUPING_TYPE_IN_MYENG =
	'SET_CURRENT_GROUPING_TYPE_IN_MYENG';
export const REFRESH_DASHBOARD = 'REFRESH_DASHBOARD';
export const SEARCH_ENGAGEMENT = 'SEARCH_ENGAGEMENT';
export const API_ERRORS = 'API_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const DISPLAY_VIEW = 'DISPLAY_VIEW';
export const LOAD_VIEW_ACCESS_LIST = 'LOAD_VIEW_ACCESS_LIST';
export const POST_REQUEST_ACCESS = 'POST_REQUEST_ACCESS';
export const LOAD_FAVORITES_ENGAGEMENTS = 'LOAD_FAVORITES_ENGAGEMENTS';
export const LOAD_FAVORITES_WORKSPACES = 'LOAD_FAVORITES_WORKSPACES';

export const LOAD_REVIEW_ENGAGEMENTS = 'LOAD_REVIEW_ENGAGEMENTS';
export const LOAD_REVIEW_WORKSPACES = 'LOAD_REVIEW_WORKSPACES';

export const GET_SPOTFIRE_DATACENTER_URL = 'GET_SPOTFIRE_DATACENTER_URL';
export const GET_CLIENT_AND_WORKSPACES = 'GET_CLIENT_AND_WORKSPACES';
export const GET_FILTERED_CLIENT_AND_WORKSPACES =
	'GET_FILTERED_CLIENT_AND_WORKSPACES';
export const GET_PRIMARY_OFFICES = 'GET_PRIMARY_OFFICES';
export const SAVE_NEW_ENGAGEMENT = 'SAVE_NEW_ENGAGEMENT';
export const GET_SERVICE_LINES = 'GET_SERVICE_LINES';
export const POST_ENGAGEMENT = 'POST_ENGAGEMENT';
export const GET_ENGAGEMENT_TIMELINE = 'GET_ENGAGEMENT_TIMELINE';
export const POST_SYNC_ENGAGEMENT_USER = 'POST_SYNC_ENGAGEMENT_USER';
export const GET_CLIENTENGAGEMENT = 'GET_CLIENTENGAGEMENT';
export const PUT_ENGAGEMENT = 'PUT_ENGAGEMENT';

export const GET_RESTOREENGAGEMENTS = 'GET_RESTOREENGAGEMENTS';
export const GET_GROUPAUDITLINK = 'GET_GROUPAUDITLINK';
export const GET_ARC_ENG_DETAILS_FOR_RESTORE =
	'GET_ARC_ENG_DETAILS_FOR_RESTORE';
export const SET_ARC_ENG_DETAILS_FOR_RESTORE =
	'SET_ARC_ENG_DETAILS_FOR_RESTORE';
export const GET_ENG_DETAILS_FOR_IMPORT = 'GET_ENG_DETAILS_FOR_IMPORT';
export const GET_KNOWLEDGETEMPLATE = 'GET_KNOWLEDGETEMPLATE';
export const GET_GENERIC = 'GET_GENERIC';
export const POST_GENERIC = 'POST_GENERIC';
export const PATCH_GENERIC = 'PATCH_GENERIC';
export const GET_NULL = 'GET_NULL';
export const GET_ROLLFORWARDENGAGEMENTS = 'GET_ROLLFORWARDENGAGEMENTS';
export const SET_ROLLFORWARD_ENGAGEMENT_DATA =
	'SET_ROLLFORWARD_ENGAGEMENT_DATA';
export const GET_SELECTTEMPLATE = 'GET_SELECTTEMPLATE';

export const GET_COPY_REQUEST = 'GET_COPY_REQUEST';
export const GET_ENGAGEMENTS_TO_COPY_TO = 'GET_ENGAGEMENTS_TO_COPY_TO';
export const PATCH_COPY_REQUEST = 'PATCH_COPY_REQUEST';
export const GET_ENGAGEMENT_FOR_COPY_REQUEST =
	'GET_ENGAGEMENT_FOR_COPY_REQUEST';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SET_CLOSE_NOTIFICATION = 'SET_CLOSE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const GET_EYUSERS = 'GET_EYUSERS';
export const SET_SUCCESS_TOAST_MESSAGE = 'SET_SUCCESS_TOAST_MESSAGE';

export const DELETE_ENGAGEMENT = 'DELETE_ENGAGEMENT';
export const PATCH_ENGAGEMENT = 'PATCH_ENGAGEMENT';
export const GET_ENGAGEMENT = 'GET_ENGAGEMENT';
export const GET_ENGAGEMENT_FOR_COMPLETITION_URL =
	'GET_ENGAGEMENT_FOR_COMPLETITION_URL';
export const SHOW_LOADING_INDICATOR = 'SHOW_LOADING_INDICATOR';
export const HIDE_LOADING_INDICATOR = 'HIDE_LOADING_INDICATOR';
export const GET_LANGUAGES = 'GET_LANGUAGES';
export const GET_ARC_FILES = 'GET_ARC_FILES';
export const POST_CRP = 'POST_CRP';
export const PUT_CRP = 'PUT_CRP';
export const GET_CRP = 'GET_CRP';
export const CLEAR_CRP = 'CLEAR_CRP';
//Timeout actions
export const START_IDLE_TIMER = 'START_IDLE_TIMER';
export const START_LOGOUT = 'START_LOGOUT';
export const SHOW_IDLETIME_POPUP = 'SHOW_IDLETIME_POPUP';
export const RESET_IDLE_TIMER = 'RESET_IDLE_TIMER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FEATURE_CONFIGURATION = 'FEATURE_CONFIGURATION';

// Modal
export const SET_IS_MODAL_OPEN = 'SET_IS_MODAL_OPEN';

// Admin tool
export const GET_ALL_ENGAGEMENTS = 'GET_ALL_ENGAGEMENTS';
export const RESET_ENGAGEMENT_DATA = 'RESET_ENGAGEMENT_DATA';
export const GET_ALL_ENGAGEMENT_COUNTRIES = 'GET_ALL_ENGAGEMENT_COUNTRIES';
export const GET_ALL_ENGAGEMENT_USERS = 'GET_ALL_ENGAGEMENT_USERS';
export const GET_ENGAGEMENT_USER_PERMISSIONS =
	'GET_ENGAGEMENT_USER_PERMISSIONS';
export const GET_ENGAGEMENT_BY_ID = 'GET_ENGAGEMENT_BY_ID';
export const GET_STATUS = 'GET_STATUS';
export const GET_ALL_ADMIN_USERS = 'GET_ALL_ADMIN_USERS';
export const GET_ALL_DAAS_WORKSPACES = 'GET_ALL_DAAS_WORKSPACES';
export const GET_ALL_WORKSPACE_DETAILS = 'GET_ALL_WORKSPACE_DETAILS';
export const GET_ALL_SUB_SERVICE_LINE = 'GET_ALL_SUB_SERVICE_LINE';
export const GET_ENGAGEMENT_USER_STATUSES = 'GET_ENGAGEMENT_USER_STATUSES';
export const GET_INDEPENDENCE_STATUSES = 'GET_INDEPENDENCE_STATUSES';
export const GET_EMPLOYEE_AD_STATUS = 'GET_EMPLOYEE_AD_STATUS';
export const GET_SERVICE_LINE = 'GET_SERVICE_LINE';
export const GET_USER_SEARCH = 'GET_USER_SEARCH';
export const ADD_ADMIN_USERS = 'ADD_ADMIN_USERS';
export const GET_ALL_ADMINUSER_ROLES = 'GET_ALL_ADMINUSER_ROLES';
export const DELETE_ADMINUSER_ROLE = 'DELETE_ADMINUSER_ROLE';
export const GET_ALL_Module = 'GET_ALL_Module';
export const GET_ALL_ADMIN_ROLE = 'GET_ALL_ADMIN_ROLE';
export const ADD_ADMIN_ROLE = 'ADD_ADMIN_ROLE';
export const PERMISSION_GROUP = 'PERMISSION_GROUP';
export const RESET_PERMISSION_GROUP = 'RESET_PERMISSION_GROUP';
export const GET_ALL_RBAC_USER_ROLES = 'GET_ALL_RBAC_USER_ROLES';
export const GET_ALL_ENGAGEMENT_TEMPLATES = 'GET_ALL_ENGAGEMENT_TEMPLATES';
export const EDIT_ENGAGEMENT_TEMPLATE = 'EDIT_ENGAGEMENT_TEMPLATE';
export const GET_ALL_REGION = 'GET_ALL_REGION';
export const GET_ALL_ENGAGEMENT_ROLES = 'GET_ALL_ENGAGEMENT_ROLES';
export const PUT_DEACTIVATE_USER = 'PUT_DEACTIVATE_USER';
export const UPDATE_INTERNAL_USER = 'UPDATE_INTERNAL_USER';
export const PERMISSION_GROUP_USER = 'PERMISSION_GROUP_USER';
export const ADD_PERMISSION_GROUP_USERS = 'ADD_PERMISSION_GROUP_USERS';
export const INVITE_ENGAGEMENT_MEMBERS = 'INVITE_ENGAGEMENT_MEMBERS';
export const TRIGGER_ARCHIVE_RETRY = 'TRIGGER_ARCHIVE_RETRY';
export const EDIT_ENGAGEMENT = 'EDIT_ENGAGEMENT';
export const UNDO_EDIT_ENGAGEMENT = 'UNDO_EDIT_ENGAGEMENT';
export const SET_UPDATE_ENGAGEMENT_SUCCESS_MESSAGE =
	'SET_UPDATE_ENGAGEMENT_SUCCESS_MESSAGE';
export const SET_ENGAGEMENT_SEARCH_PARAMETERS =
	'SET_ENGAGEMENT_SEARCH_PARAMETERS';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
export const SYNC_DATABASELOCATOR = 'SYNC_DATABASELOCATOR';
export const GET_CACHE = 'GET_CACHE';
export const DELETE_CACHE = 'DELETE_CACHE';
export const CLEAR_CACHE_RESULT = 'CLEAR_CACHE_RESULT';
export const GET_ALL_MESSAGES = 'GET_ALL_MESSAGES';
export const DELETE_ADMIN_MESSAGE = 'DELETE_ADMIN_MESSAGE';
export const DELETE_PERMISSION_GROUP_USERS = 'DELETE_PERMISSION_GROUP_USERS';
export const GET_ALL_DATACENTERS = 'GET_ALL_DATACENTERS';
export const SAVE_MESSAGE = 'SAVE_MESSAGE';
export const GET_MESSAGE_BY_ID = 'GET_MESSAGE_BY_ID';
export const RESET_MESSAGE_DETAILS = 'RESET_MESSAGE_DETAILS';
export const EDIT_MESSAGE = 'EDIT_MESSAGE';
export const GET_ADMIN_ENGAGEMENT_DOCUMENTS_LIST = 'GET_ADMIN_ENGAGEMENT_DOCUMENTS_LIST';
export const RESET_ADMIN_ENGAGEMENT_DOCUMENTS_LIST = 'RESET_ADMIN_ENGAGEMENT_DOCUMENTS_LIST';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

// Engagement Overview
export const GET_ENGAGEMENT_OVERVIEW = 'GET_ENGAGEMENT_OVERVIEW';
