/**
 * Created by rastwe on 1/16/2017.
 * @module $Name$
 * @extends React.Component
 */
import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import './main.less';
import { connect } from 'react-redux';
import { SVGICONS } from '@ey/icons';
import { labels } from '../../util/uiconstants';
import env from '../../util/env';
import ModalControl from '@ey/modal-control';
import * as VersionUpgradeAction from '../../actions/versionupgradeaction';
import config from '../../../src/auth/config';
import * as TimeoutAction from '../../actions/timeoutactions';
import Icon from '@ey/motif-react-wrapper/Icon';
import { alertIcWarning24px } from '@ey/motif-react-wrapper/assets/icons/index';
import './index.scss';
import '@ey/motif-react-wrapper/ey-asu-motif.css';

createGlobalStyle`
	main {display:block;}
`;

const OfflineContainerwrapper = styled.section`
	box-shadow: 0 0 0 0 #21852e;
	position: absolute;
	font-weight: 700;
	vertical-align: middle;
	text-align: center;
	left: 45%;
	display: flex;
	height: auto;
	padding: var(--px-5);
	background: #009999;
	z-index: 100;
	font-size: var(--px-12);
	border: 0;

	&.training {
		background: var(--purple-400);
	}

	span {
		text-align: center;
		margin: var(--px-3);
		padding: 0;
		font-weight: bold;
		width: auto;
		vertical-align: top;
		line-height: initial;
		color: var(--neutrals-00white);
	}
	svg {
		fill: var(--neutrals-00white);
		margin: var(--px-3) 0;
	}
`;

const PopupContent = styled.section`
	font-size: var(--px-12);
	margin: var(--px-25) var(--px-25) var(--px-25) 0;
`;

function Main(props) {
	const [showLogoutModal, setShowLogoutModal] = useState(false);
	const [timerMessage, setTimerMessage] = useState(labels.sessionTimerMsg3);
	const [intervalID, setIntervalID] = useState(null);
	const [istraining, setIstraining] = useState(env.getIsTraining());

	const calculateRemainingTime = () => {
		setTimerMessage(
			labels.sessionTimerMsg3.replace('{0}', () => {
				let startTime = props.sessionTimer.logoutTimestamp;
				let endTime = startTime + config.logout_time;
				return Math.round((endTime - Date.now()) / 60000);
			})
		);
	};
	useEffect(() => {
		if (props.sessionTimer.showPopup) {
			setShowLogoutModal(true);
			calculateRemainingTime();
			let _intervalID = setInterval(calculateRemainingTime, 60000);
			setIntervalID(_intervalID);
		} else if (props.sessionTimer.startLogout) {
			props.logoutUserAction();
		}
	}, [props.sessionTimer]);

	useEffect(() => {
		if (props.appVersionState.upgrade) {
			localStorage.setItem('app.version', props.appVersionState.version);
			window.location.reload();
		}
	}, [props.appVersionState.upgrade]);

	const logoutUser = (value) => {
		if (intervalID) {
			clearInterval(intervalID);
			setIntervalID(null);
		}
		props.startLogoutAction();
	};

	var classes = props.apponlinestate ? 'main key' : 'main key offline';
	if (props.apponlinestate && istraining) {
		classes = 'main key training mainResponsiveWrappers';
	}
	return (
		<React.Fragment>
			<section className={classes}>
				{!props.apponlinestate && (
					<OfflineContainerwrapper>
						<SVGICONS
							styleName="small"
							name="wifi-off"
							hoverText=""
							alt="offline"
						/>
						<span>
							{istraining ? labels.trainingoffline : labels.workingoffline}
						</span>
					</OfflineContainerwrapper>
				)}
				{props.apponlinestate && istraining && (
					<OfflineContainerwrapper className="training">
						<span>{labels.training}</span>
					</OfflineContainerwrapper>
				)}
				{props.children}
			</section>
			<ModalControl
				title={labels.sessionTimerHeader}
				closeTitle={labels.close}
				closeBtnTitle={labels.logout}
				confirmBtnTitle={labels.continue}
				modalContainerClass="logout-modal"
				modalsize="small"
				onOkClick={() => {
					if (intervalID) {
						clearInterval(intervalID);
						setIntervalID(null);
					}
					props.resetIdleTimerAction();
					setShowLogoutModal(false);
				}}
				onHide={logoutUser}
				setModalVisibility={showLogoutModal}
				show={showLogoutModal}
				showButtons
				focusTrapActive={false}
			>
				<PopupContent>
					<p className="msg1">
						<Icon src={alertIcWarning24px} />
						{labels.sessionTimerMsg1}
					</p>
					<p className="msg2">{labels.sessionTimerMsg2}</p>
					<p className="msg3">{timerMessage}</p>
					<p className="msg4">
						<span>{labels.sessionTimerMsg4}</span>
						<span className="msg42"> {labels.continue}</span>
					</p>
				</PopupContent>
			</ModalControl>
		</React.Fragment>
	);
}

const mapStateToProps = (storeState) => {
	return {
		apponlinestate: storeState.apponlinestate,
		appVersionState: storeState.appVersion,
		sessionTimer: storeState.sessionTimer
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		VersionUpgradeAction: (upgrade) => dispatch(VersionUpgradeAction(upgrade)),
		logoutUserAction: () => dispatch(TimeoutAction.logoutUser()),
		startLogoutAction: () => dispatch(TimeoutAction.startLogout()),
		resetIdleTimerAction: () => dispatch(TimeoutAction.resetIdleTimer())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
