import styled from 'styled-components';

//documenttablestyle main page
const Documenttablestyle = styled.section`
	// common for table UI start

    .noresult{
        position: relative;
        overflow: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--px-14);
        height: calc(100vh - var(--px-330));
        color: var(--neutrals-700);
        border: var(--px-1) solid var(--neutrals-200);
    }

	.documentwrapper {
		display: inline-block;
		width: 100%;

		.documentitemview {
			width: 100%;
			font-size: var(--px-14);
			display: flex;
			border-collapse: collapse;
			align-items: center;
			justify-content: center;
			border-bottom: var(--px-1) solid var(--neutrals-200);

			&.inactive mgt-person {
				color: var(--neutrals-300);
			}

			&:not(.inactive) .userName {
				color: var(--neutrals-900);
			}

			.grid {
				display: grid;
				grid-template-columns: auto 1fr;
				grid-gap: 0 10px;
			}

			&:last-child {
				border-bottom: none;
				padding-bottom: var(--px-10);
			}
		}

		.document-header {
			width: 100%;
			display: inline-block;
			.documentheader {
				width: 100%;
				font-size: var(--px-14);
				color: var(--neutrals-700);
				font-weight: bold;
				display: flex;
				border-collapse: collapse;
				background: var(--neutrals-100);
				border: var(--px-1) solid var(--neutrals-200);

				.documentitem:last-child {
					// text-align: center;
					// width: 1%;
				}
			}
		}

		.document-body {
			overflow: auto;
			height: calc(100vh - var(--px-355));
			display: inline-block;
			width: 100%;
			border: var(--px-1) solid var(--neutrals-200);
			@media screen and (max-width: 1366px){
				height: calc(100vh - var(--px-370));
			}
			.documentholder {			
				width: 100%;
				display: inline-block;
				border-bottom: var(--px-1) solid var(--neutrals-200);
			}
		}

		.document-header,
		.document-body {
			.documentitem {
				padding: var(--px-10);
				flex: 1 1 auto;
				width: 20%;
				vertical-align: middle;
				display: block;

				.description {
					display: flex;

					svg.delete {
						cursor: pointer;
					}
				}

				.ellipses {
					vertical-align: middle;
					line-height: normal;
				}

                &.uri{
                    width: 30%;
					.ellipses {
						vertical-align: middle;
						line-height: normal;
					}
                }
				
				&.version,
                &.filesize,
                &.fileexist {
					width: 10%;
					.ellipses {
						vertical-align: middle;
						line-height: normal;
					}
				}

                .engname {
					width: 100%;
					display: inline-grid;

					.ellipses {
						line-height: normal;
					}
				}
			}
		}
	}

	// common for table UI end
`;

export default Documenttablestyle;
