import React from 'react';
import { useSelector } from 'react-redux';
import { labels, currentResource } from '../../../../util/uiconstants';
import ReactMoment from 'react-moment';
import { dateAdminFormats } from '../../../../util/uiconstants';
import { Link } from 'react-router-dom';
import EllipsesControl from '@ey/ellipses-control';

function EngagementItem(props) {
	console.log(props.data);
	const userPreferences = useSelector((state) => state.userPreferences);

	let profileattributes = props.data.isProfileComplete ? (
		<EllipsesControl
			content={labels.attributesProfileComplete}
			tooltip={labels.attributesProfileComplete}
			noOfLines={1}
		/>
	) : (
		''
	);
	let independenceattributes = props.data.isIndependenceTemplateAvailable ? (
		<EllipsesControl
			content={labels.attributesIndpendenceApplicable}
			tooltip={labels.attributesIndpendenceApplicable}
			noOfLines={1}
		/>
	) : (
		''
	);

	return (
		<section
			className={`manageaccessitemview ${props.data.isActive ? '' : 'inactive'
				}`}
		>
			<section className="userlistitem grid">
				{/* no elipse */}
				<section>
					<section className="description">
						<section className="engname">
							<EllipsesControl
								content={props.data.engagementDescription}
								tooltip={props.data.engagementDescription}
								noOfLines={1}
							/>
						</section>
					</section>
					<section>
						<Link
							to={{
								pathname: '/admin/engagements/' + props.data?.engagementId,
								search:
									'countryid=' +
									props.data?.countryID +
									'&ssl=' +
									props.data?.subServiceLineID,
								state: {
									customLabel:
										props.data?.engagementId +
										' ' +
										props.data?.engagementDescription,
									CountryID: props.data?.countryID,
									SubServiceLineID: props.data?.subServiceLineID,
									EngagementID: props.data?.engagementId
								}
							}}
						>
							<span>{props.data.engagementId} </span>
						</Link>
					</section>
				</section>
			</section>
			<section className="userlistitem grid">
				{/* no elipse */}
				<section>
					<section className="description">
						<section className="engname">
							<EllipsesControl
								content={props.data.workspaceDescription}
								tooltip={props.data.workspaceDescription}
								noOfLines={1}
							/>
						</section>
					</section>
					<section>
						<span>{props.data.workspaceID}</span>
					</section>
				</section>
			</section>
			<section className="userlistitem grid">
				{/* no elipse */}
				<section>
					<section className="description">
						<section className="engname">
							<EllipsesControl
								content={props.data.clientName}
								tooltip={props.data.clientName}
								noOfLines={1}
							/>
						</section>
					</section>
					<section>
						<span>{props.data.clientCode}</span>
					</section>
				</section>
			</section>
			<section className="userlistitem grid">
				{/* no elipse */}
				<section>
					<section className="description">
						<section className="engname">
							<EllipsesControl
								content={props.data.primaryEngagementCodeName}
								tooltip={props.data.primaryEngagementCodeName}
								noOfLines={1}
							/>
						</section>
					</section>
					<section>
						<span>{props.data.primaryEngagementCode}</span>
					</section>
				</section>
			</section>
			<section className="userlistitem date">
				<section>
					<ReactMoment
						format={
							userPreferences &&
							dateAdminFormats[userPreferences.dateFormat] &&
							dateAdminFormats[userPreferences.dateFormat].label
						}
					>
						{props.data.clientYearEnd}
					</ReactMoment>
				</section>
			</section>
			<section className="userlistitem status">
				<EllipsesControl
					content={
						currentResource.engagementStatuses[props.data.engagementStatus]
					}
					tooltip={
						currentResource.engagementStatuses[props.data.engagementStatus]
					}
					noOfLines={1}
				/>
			</section>
			<section className="userlistitem attribute">
				{profileattributes}
				{independenceattributes}
			</section>
		</section>
	);
}

export default EngagementItem;
