import React from 'react';
import styled from 'styled-components';
import {SVGICONS} from '@ey/icons';
const Wrapper = styled.section`
	display: inline-flex;
	margin-left: auto;
	line-height: var(--px-48);

	h3 {
		margin: auto;
	}

	svg {
		height: var(--px-48);
		margin-right: var(--px-5);
	}
`;
function PageHeader(props) {
	return (
		<Wrapper classname="title">
			<SVGICONS styleName="large" name={props.iconName} />
			<h3>{props.moduleName}</h3>
		</Wrapper>
	);
}

export default PageHeader;
