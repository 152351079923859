import React, {useState} from 'react';
import {
	currentResource,
	dateFormats,
	dateFormatsListItems,
	labels,
	standardStrings,
	urls
} from '../../../util/uiconstants';
import {useDispatch, useSelector} from 'react-redux';

import EllipsesControl from '@ey/ellipses-control';
import {FormRadio} from '@ey/form';
import {FormStaticText} from '@ey/form';
import Importscot from './importscot';
import Loader from '@ey/loader';
import {Radio} from 'react-bootstrap';
import {SVGICONS} from '@ey/icons';
import Search from '@ey/search';
import env from '../../../util/env';
import {getEYUsersList} from '../../../actions/eyusersaction';
import {getNotifications} from '../../../actions/notificationsactions';
import moment from 'moment';
import {patchCopyRequest} from '../../../actions/copyhubactions';
import styled from 'styled-components';
import {useDidMountEffect} from '../../../util/customHooks';

const DelegatePackage = (props) => {
	const {
		onFinishClick,
		isGroupAudit,
		copyRequestId,
		isComponentValid,
		closeWizard
	} = props;

	const dispatch = useDispatch();

	const copyRequest = useSelector((state) => state.copyHub.copyRequest || {});
	const eyusers = useSelector((state) => state.eyusers);
	const linkRequest = useSelector((state) => state.copyHub.linkRequest || {});

	const [searchString, setSearchString] = useState(standardStrings.EMPTY);
	const [targetGui, setTargetGui] = useState(standardStrings.EMPTY);
	const [targetDelegateName, setTargetDelegateName] = useState(
		standardStrings.EMPTY
	);
	const [isLoading, setIsLoading] = useState(false);
	const [isUserListLoading, setIsUserListLoading] = useState(false);
	const [showUserSearchValidation, setShowUserSearchValidation] =
		useState(false);
	const [showSelectUserValidation, setShowSelectUserValidation] =
		useState(false);
	const [eyUsersList, setEyUsersList] = useState([]);
	const [showUserSearchPlaceholderText, setShowUserSearchPlaceholderText] =
		useState(true);
	const [shouldLoadSearchData, setShouldLoadSearchData] = useState(0);

	let operationType = 'delegateIEPRequest';
	let operationtypeGA = 'delegateRequest' || 'linkRequest';

	let getNotificationsUrl =
		env.getURL('serviceUrl') + '/' + urls.LANDINGPAGENOTIFICATION_URL;

	useDidMountEffect(() => {
		if (isDataValid() && onFinishClick) {
			if (isGroupAudit) {
				delegateGroupAudit();
			} else {
				delegatePackage();
			}
		} else {
			setShowSelectUserValidation(true);
		}
	}, [onFinishClick]);

	useDidMountEffect(() => {
		loadUserSearchData();
	}, [shouldLoadSearchData]);

	useDidMountEffect(() => {
		setEyUsersList(eyusers && eyusers.collections?.user);
		setIsUserListLoading(false);
	}, [eyusers?.collections]);

	useDidMountEffect(() => {
		setShouldLoadSearchData((prev) => prev + 1);
	}, [searchString]);

	const isDataValid = () => {
		if (searchString === standardStrings.EMPTY) {
			setShowUserSearchValidation(true);
			return false;
		} else if (targetGui === standardStrings.EMPTY) {
			setShowSelectUserValidation(true);
			return false;
		} else {
			return true;
		}
	};

	const onKeyDownInSearchBox = (e) => {
		if (e && e.keyCode === 13) {
			setSearchString(e.target.value);
			e.preventDefault();
		}
	};

	const delegatePackage = async () => {
		var copyHubModelData = createCopyHubModelData();
		setIsLoading(true);
		let copyHubRequestUrl =
			env.getURL('serviceUrl') +
			'/' +
			urls.GROUPAUDITENGAGEMENTS_URL +
			'?id=' +
			copyRequestId +
			'&phase=copyhubRequest';

		try {
			await dispatch(
				patchCopyRequest(copyHubRequestUrl, JSON.stringify(copyHubModelData))
			);
		} catch {
			setIsLoading(false);
			isComponentValid(true);
		} finally {
			dispatch(getNotifications(getNotificationsUrl));
			closeWizard();
		}
	};

	const updateGADelegateRequest = async () => {
		try {
			var GALinkModelData = createGALinkModelData();
			let GALinkDelegateRequestUrl =
				env.getURL('serviceUrl') +
				'/' +
				urls.GROUPAUDITENGAGEMENTS_URL +
				'?id=' +
				linkRequest.id +
				'&phase=linkRequest';
			await dispatch(
				patchCopyRequest(
					GALinkDelegateRequestUrl,
					JSON.stringify(GALinkModelData)
				)
			);
		} catch {
			isComponentValid(true);
		}
	};

	const delegateGroupAudit = async () => {
		setIsLoading(true);
		try {
			await updateGADelegateRequest();
			await dispatch(getNotifications(getNotificationsUrl));
		} finally {
			setIsLoading(false);
			closeWizard();
		}
	};

	const createCopyHubModelData = () => {
		var copyhubModel = {
			collections: {
				iepnotifications: [
					{
						id: copyRequest.id,
						data: {
							operationtype: operationType,
							targetcomment: '',
							targetpocgui: targetGui,
							targetpoctext: targetDelegateName
						}
					}
				]
			}
		};

		return copyhubModel;
	};

	const createGALinkModelData = () => {
		var GALinkModel = {
			collections: {
				linknotifications: [
					{
						id: linkRequest.id,
						data: {
							operationtype: operationtypeGA,
							linkcomment: linkRequest.linkcomment,
							pocgui: targetGui,
							poctext: targetDelegateName,
							concurrencytoken: linkRequest.concurrencytoken
						}
					}
				]
			}
		};

		return GALinkModel;
	};

	const loadUserSearchData = () => {
		let searchKey = searchString;
		setIsUserListLoading(true);
		setShowUserSearchValidation(searchKey.length > 0 ? false : true);
		setShowSelectUserValidation(false);
		setTargetGui(standardStrings.EMPTY);
		setTargetDelegateName(standardStrings.EMPTY);
		setEyUsersList([]);
		setShowUserSearchPlaceholderText(searchKey.length > 0 ? false : true);

		searchKey = encodeURIComponent(searchKey);

		let eyUsersUrl =
			env.getURL('serviceUrl') +
			'/' +
			urls.EYUSERS_URL +
			'?searchcriteria=' +
			searchKey;
		try {
			dispatch(getEYUsersList(eyUsersUrl, eyUsersUrl.split('/api/v1')[0], 0));
		} catch {
			isComponentValid(true);
		}
	};

	const handleEYUserSelection = (data) => {
		if (data) {
			setTargetGui(data.gui);
			setTargetDelegateName(data.name);
			setShowSelectUserValidation(false);
		}
	};

	const getHeaderForAction = () => {
		const packageRecieveDate = moment
			.utc(copyRequest.iepreceivedate)
			.format(dateFormatsListItems[dateFormats].label);

		if (isGroupAudit) {
			let linkRequestData = linkRequest;
			return (
				<Importscot>
					<header>
						<h5>{labels.gadelegatequestionlabel}</h5>
					</header>
					<section className="importscot">
						<section className="packagesourcedetail">
							<section className="packagedetailscolumn">
								<FormStaticText label={labels.sourceengagement + ':'}>
									<EllipsesControl
										id="sourceengagementname"
										content={linkRequestData.primaryengagementname}
										tooltip={linkRequestData.primaryengagementname}
										isTooltipAvailable
									/>
								</FormStaticText>
								<FormStaticText label={labels.garegioncomponent + ':'}>
									<EllipsesControl
										id="regionComponent"
										content={linkRequestData.componentshortname}
										tooltip={linkRequestData.componentshortname}
										isTooltipAvailable
									/>
								</FormStaticText>
								<FormStaticText label={labels.gascope + ':'}>
									<EllipsesControl
										id="Scope"
										content={linkRequestData.gascopename}
										tooltip={linkRequestData.gascopename}
										isTooltipAvailable
									/>
								</FormStaticText>
							</section>
						</section>
					</section>
				</Importscot>
			);
		} else {
			return (
				<Importscot>
					<header>
						<h5>
							{copyRequest && copyRequest.id
								? labels.delegate +
								  ' ' +
								  currentResource.copyObjectGroupType[
										copyRequest.copyobjectgrouptypeid
								  ]
								: labels.delegate}
						</h5>
					</header>
					<section className="importscot">
						<section className="packagesourcedetail">
							<FormStaticText label={labels.package + ':'}>
								<EllipsesControl
									id="packagename"
									content={copyRequest.copyname}
									tooltip={copyRequest.copyname}
									isTooltipAvailable
								/>
							</FormStaticText>
							<FormStaticText label={labels.sourceengagement + ':'}>
								<EllipsesControl
									id="sourceengagementname"
									content={copyRequest.sourceengagementdescription}
									tooltip={copyRequest.sourceengagementdescription}
									isTooltipAvailable
								/>
							</FormStaticText>
							<section className="sentbydetails">
								<FormStaticText label={labels.sentby + ':'}>
									{copyRequest.id
										? copyRequest.sendername +
										  ' ' +
										  labels.on +
										  ' ' +
										  packageRecieveDate
										: ''}
								</FormStaticText>
							</section>
						</section>
					</section>
				</Importscot>
			);
		}
	};

	const EYUsersRadioList = () => {
		let result = [];
		if (eyUsersList) {
			eyUsersList.forEach((eyuserdata, index) => {
				eyuserdata &&
					eyuserdata.data &&
					result.push(
						<section>
							<Radio
								value={index}
								key={index}
								name="radio"
								onClick={() => handleEYUserSelection(eyuserdata.data)}
							>
								<section className="delegateusername">
									<EllipsesControl
										id=""
										content={eyuserdata.data.name}
										tooltip={eyuserdata.data.name}
										isTooltipAvailable
									/>
								</section>
								<section className="delegateuserid">
									{eyuserdata.data.internetemailaddress}
								</section>
							</Radio>
						</section>
					);
			});
		}
		return (
			<ul>
				<li className="delegateuserlist">
					<label className="username">{labels.username}</label>
					<label className="emailid">{labels.email}</label>
				</li>
				<li className="delegateuseritems">
					<FormRadio name="radioStack" onChange={() => {}}>
						{result}
					</FormRadio>
				</li>
			</ul>
		);
	};

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<form>
					<TopLevelStyle>
						{getHeaderForAction()}

						<section className="importscotdata">
							<Search
								name="name"
								clearHoverText={labels.clearSearch}
								value={searchString}
								placeholder={labels.searchPlaceholder}
								onBlur={() => {}}
								onKeyDown={(val) => {
									onKeyDownInSearchBox(val);
								}}
								onClearClick={(val) => {
									setSearchString(standardStrings.EMPTY);
								}}
								onSearchClick={(val) => {
									setSearchString(val);
								}}
								isDisabled={false}
							/>
						</section>
						<section className="import-error">
							{showSelectUserValidation === true ? (
								<section>
									<SVGICONS styleName="small" id="icon" name="error" />
									<span>{labels.selectdelegateuser}</span>
								</section>
							) : showUserSearchValidation == true ? (
								<section>
									<SVGICONS styleName="small" id="icon" name="error" />
									<span>{labels.delegaterequired}</span>
								</section>
							) : (
								standardStrings.EMPTY
							)}
						</section>
						{isUserListLoading ? (
							<Loader view="inlineView" />
						) : (
							<section className="delegateuserlistwrapper">
								{eyUsersList && eyUsersList.length > 0 ? (
									EYUsersRadioList()
								) : showUserSearchPlaceholderText ? (
									<section className="noresult">
										{labels.eyusersearchplaceholder}
									</section>
								) : (
									<section className="noresult">{labels.noresults}</section>
								)}
							</section>
						)}
					</TopLevelStyle>
				</form>
			)}
		</>
	);
};
export default DelegatePackage;

const TopLevelStyle = styled.section`
	& .importscotdata {
		width: var(--px-400);
		margin: 0.5em 0;
	}
	& .import-error {
		font-size: var(--px-12);
		line-height: normal;
		height: var(--px-20);
		padding-top: var(--px-10);
		padding-bottom: var(--px-20);

		& svg {
			fill: var(--red-600);
			vertical-align: text-top;
			margin-right: 0.5em;
		}
	}

	& .delegateuserlistwrapper {
		& .noresult {
			margin: var(--px-20) 0;
			color: var(--neutrals-700);
			font-size: var(--px-14);
		}

		& .form-group .control-label {
			display: none;
		}

		& .form-group .radio {
			margin: 0.5em 0;
			line-height: normal;
			padding-bottom: 0.5em;
			border-bottom: var(--px-1) solid var(--neutrals-200);
		}

		& .delegateuseritems .form-group > section:last-child .radio {
			border: 0;
		}

		& .delegateuserlist {
			& label.username {
				width: 42%;
				max-width: 42%;
				margin: 0 2% 0 4%;
				display: inline-block;
			}
			& label.emailid {
				width: 33%;
				max-width: 35%;
				margin: 0 2%;
				display: inline-block;
			}
		}

		& li {
			list-style: none;

			& .delegateuserid,
			& .delegateusername {
				max-width: 42%;
				width: 42%;
				display: inline-block;
				margin: 0 2%;
			}

			& .radio {
				display: block;
				margin: 0 2%;
				position: relative;

				& .delegateusername {
					.ellipses {
						display: inline-flex;

						& div {
							width: 100%;
						}
					}
				}

				& label {
					padding: 0;
					width: 100%;
				}

				& input[type='radio'] {
					margin: 0;
					position: relative;
					vertical-align: text-top;
				}
			}

			&.delegateuserlist,
			&.delegateuseritems {
				width: var(--px-1000);
				font-size: var(--px-14);
			}

			&.delegateuserlist {
				margin-bottom: 0;
			}
		}
	}
`;
