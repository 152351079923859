import * as types from '../actions/actiontypes';
import initialState from './initialstate';

export function ArcFilesReducer(state = initialState.arcFiles, action) {
	switch (action.type) {
		case types.GET_ARC_FILES:
			return action.response;
		default:
			return state;
	}
}

export function CrpReducer(state = initialState.crp, action) {
	switch (action.type) {
		case types.GET_CRP:
			return action.response;
		case types.PUT_CRP:
			return action.response;
		case types.CLEAR_CRP:
			return {};
		default:
			return state;
	}
}
