import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {SVGICONS} from '@ey/icons';

function CustomTooltip(props) {
	const [popupVisible, setPopupVisible] = useState(false);
	const [popupTopPosition, setPopupTopPosition] = useState('top');
	const [popupLeftPosition, setPopupLeftPosition] = useState(
		props.direction || 'right'
	);
	const node = useRef();

	const {validatePosition} = props;

	useEffect(() => {
		document.addEventListener('mousedown', handleOutsideClick);
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, []);

	const handleClick = (e) => {
		if (!popupVisible) {
			setPopupVisible(true);
			if (props.onPopupShow) {
				props.onPopupShow(e);
			}
		} else {
			setPopupVisible(false);
			if (props.onPopupHide) {
				props.onPopupHide(e);
			}
		}

		let bound = e.currentTarget.getBoundingClientRect();
		let winHeight = window.innerHeight;
		let rBottom = winHeight - bound.bottom;
		let winWidth = window.innerWidth;
		let rLeft = winWidth - bound.left;

		if (rBottom > 250) {
			setPopupTopPosition('bottom');
		} else {
			setPopupTopPosition('top');
		}

		if (rLeft > 300 && !direction) {
			setPopupLeftPosition('left');
		}

		if (validatePosition) {
			const newDirection = rLeft < 620 ? 'right' : 'left';
			setPopupLeftPosition(newDirection);
		}
	};

	const handleDivClick = (e) => {
		// if click is performed on the search input of the TimePhase ToolTip, the ToolTip will not hide
		let clickedElement = e.target;
		if (
			clickedElement.classList.contains('closePopup') ||
			clickedElement.id == 'fileOptions'
		) {
			setPopupVisible(false);
		} else {
			if (props.callBackParent) {
				props.callBackParent(e);
			}
		}
	};

	const handleOutsideClick = (e) => {
		if (node.current.contains(e.target)) {
			// inside click
			return;
		} else {
			// outside click
			setPopupVisible(false);
			if (props.onPopupHide) {
				props.onPopupHide();
			}
		}
	};

	const {
		containerClass,
		styleName,
		label,
		children,
		title,
		dueDate,
		userInitials,
		iconName,
		iconClassName,
		position,
		popupArrowColor,
		buttonType,
		defaultLoad,
		disabled,
		direction,
		showPopup,
		id,
		ariaLabel
	} = props;

	const renderChildren = () => {
		if (typeof children === 'function') {
			return children(setPopupVisible);
		}
		return children;
	};

	return (
		<StyledPopOver className={containerClass}>
			<span ref={node}>
				<button
					className={styleName}
					title={title}
					disabled={disabled}
					onClick={handleClick}
					aria-label={ariaLabel}
				>
					{iconName && (
						<SVGICONS
							styleName={iconClassName}
							name={iconName}
							hoverText={title}
							id={id ? id : ''}
						/>
					)}
					{label && (
						<span className="btnLabel" id={id ? id : ''}>
							{label}
						</span>
					)}
					{dueDate && <>{dueDate}</>}
					{userInitials && <>{userInitials}</>}
				</button>
				{!defaultLoad && showPopup && popupVisible && (
					<section
						className={`
							${'show-pop-content'}
							${position ? position : `${popupTopPosition} ${popupLeftPosition}`}
							${buttonType ? 'ey-button' : ''}
						`}
					>
						<section className={`${popupArrowColor} show-popup-arrow`} />
						<section
							className="showPopupWrapper customScrollbar"
							onClick={handleDivClick}
						>
							{renderChildren()}
						</section>
					</section>
				)}
				{defaultLoad && showPopup && (
					<section
						className={`
							${'show-pop-content'}
							${popupVisible ? 'showPopUp' : 'hidePopUp'}
							${position ? position : `${popupTopPosition} ${popupLeftPosition}`}
							${buttonType ? 'ey-button' : ''}
						`}
					>
						<section className={`${popupArrowColor} show-popup-arrow`} />
						<section className="showPopupWrapper" onClick={handleDivClick}>
							{renderChildren()}
						</section>
					</section>
				)}
			</span>
		</StyledPopOver>
	);
}

export default CustomTooltip;

CustomTooltip.propTypes = {
	containerClass: PropTypes.string,
	styleName: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	children: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array,
		PropTypes.func
	]),
	title: PropTypes.string,
	dueDate: PropTypes.string,
	userInitials: PropTypes.string,
	iconName: PropTypes.string,
	iconClassName: PropTypes.string,
	position: PropTypes.string,
	popupArrowColor: PropTypes.string,
	buttonType: PropTypes.string,
	defaultLoad: PropTypes.bool,
	disabled: PropTypes.bool,
	direction: PropTypes.string,
	showPopup: PropTypes.bool,
	ariaLabel: PropTypes.string
};

CustomTooltip.defaultProps = {
	showPopup: true
};

const StyledPopOver = styled.span`
	position: relative;
	button {
		padding: 0;
		svg {
			fill: var(--neutrals-900);
		}
	}
	.ey-primary-button {
		padding: var(--px-9) var(--px-12);
		border: var(--px-1) solid var(--neutrals-900);
		font-size: var(--px-14);
		svg {
			&.right {
				float: right;
			}
			&.left {
				margin-right: var(--px-5);
			}
			&.deg90 {
				transform: rotate(90deg);
			}
		}
		&:hover {
			background: var(--neutrals-900);
			color: var(--neutrals-00white);
			svg {
				fill: var(--neutrals-00white);
			}
		}
		.btnLabel {
			line-height: var(--px-20);
		}
	}

	.ey-secondary-button {
		padding: var(--px-9) var(--px-15);
		border: var(--px-1) solid var(--neutrals-900);
		color: var(--neutrals-900);
		font-size: var(--px-14);
		svg {
			fill: var(--neutrals-900);
			&.right {
				float: right;
			}
			&.left,
			&.center {
				margin-right: var(--px-5);
			}
			&.deg90 {
				transform: rotate(90deg);
			}
		}
		&:hover {
			background: var(--neutrals-900);
			color: var(--neutrals-00white);
			svg {
				fill: var(--neutrals-00white);
			}
		}
		.btnLabel {
			line-height: var(--px-20);
			margin-right: var(--px-5);
		}
	}

	.show-pop-content {
		background: var(--neutrals-00white);
		border: var(--px-1) solid var(--neutrals-300);
		width: auto;
		padding: 0;
		position: absolute;
		z-index: 999;
		box-shadow: var(--px-4) var(--px-4) var(--px-4) var(--neutrals-200);
		&.ey-button {
			top: var(--px-35);
		}
		&.showPopUp {
			display: block;
		}
		&.hidePopUp {
			display: none;
		}

		.closePopup.disabled {
			pointer-events: none;
		}

		&.bottom {
			&.left {
				left: 0;
				.show-popup-arrow {
					top: 0%;
					left: var(--px-8);
				}
			}

			&.center {
				left: -50%;
				.show-popup-arrow {
					left: 50%;
				}
			}
			&.right {
				right: calc(var(--px-10) * -1);
				.show-popup-arrow {
					right: var(--px-15);
					margin: calc(var(--px-6) * -1);
				}
			}
		}

		&.top {
			bottom: var(--px-30);
			.show-popup-arrow {
				bottom: 0;
				transform: rotate(45deg);
			}

			&.left {
				.show-popup-arrow {
					left: 10%;
					bottom: 0;
				}
			}

			&.center {
				left: -50%;
				.show-popup-arrow {
					left: 50%;
				}
			}

			&.right {
				right: calc(var(--px-20) * -1);
				.show-popup-arrow {
					right: var(--px-25);
				}
			}
		}

		.show-popup-arrow {
			height: var(--px-14);
			width: var(--px-14);
			position: absolute;
			background: var(--neutrals-00white);
			transform: rotate(225deg);
			margin: calc(var(--px-5) * -1);
			z-index: -1;
			box-shadow: var(--neutrals-300) var(--px-1) var(--px-1)
				var(--px-1);
		}

		h4 {
			margin: 0;
			padding: var(--px-10);
			background-color: var(--neutrals-100);
			border-bottom: var(--px-1) solid var(--neutrals-300);
			font-weight: bold;
			font-size: var(--px-15);
		}

		h3 {
			margin: 0;
			font-weight: bold;
		}
	}
`;
