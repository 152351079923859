import React from 'react';
import {NavLink} from 'react-router-dom';
import styled, {ThemeProvider} from 'styled-components';
// import { EYLogo } from '@ey/canvascoreservices';
import theme from '@ey/styled-theme';

const StyledNavbar = styled.section`
	height: var(--px-60);
	width: 100%;
	padding: 0 var(--px-40);
	max-width: 2048px;
	margin: 0 auto;
	border: none;
	background-color: var(--neutrals-900);
	@media only screen and (max-width: 1366px) {
		padding: 0 var(--px-20);
	}
	.EYHeader {
		width: 100%;
		display: flex;
		height: var(--px-60);
	}
	.EYLogo {
		display: inline-block;
		vertical-align: top;
		width: 50%;
		.LogoLink {
			display: inline-block;
			margin: var(--px-11) 0px var(--px-16);
			height: var(--px-33);
			figure {
				height: var(--px-33);
				width: var(--px-31);
				svg {
					height: var(--px-33);
					width: var(--px-31);
				}
			}
			.ey-logo-text {
				color: var(--neutrals-00white);
				font-size: var(--px-24);
				font-weight: 300;
				display: inline-block;
				line-height: var(--px-24);
				margin: var(--px-13) 0px 0px var(--px-8);
			}
		}
		.separator {
			color: var(--neutrals-00white);
			font-size: var(--px-20);
			display: inline-block;
			line-height: var(--px-30);
			margin: 0 var(--px-8);
			vertical-align: super;
			position: relative;
			top: var(--px-2);
		}
		.engagement-name {
			color: var(--neutrals-00white);
			font-size: var(--px-14);
			font-weight: 300;
			font-family: EYInterstate;
			display: inline-block;
			vertical-align: text-bottom;
			max-width: 50%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.CCPNav-Item {
		width: 50%;
		a {
			padding: var(--px-14) var(--px-16) var(--px-8_5) var(--px-16);
			margin: 0px var(--px-2);
			font-size: var(--px-15);
			font-weight: bold;
			display: inline-block;
			text-decoration: none;
			line-height: var(--px-33);
			@media only screen and (min-width: 1280px) and (max-width: 1366px) {
				padding: var(--px-14_5) var(--px-20) var(--px-8_5) var(--px-20);
			}
			&:hover {
				text-decoration: none;
				border-bottom: var(--px-4) solid var(--brand-500);
			}
			&.selected {
				color: var(--brand-500);
				border-bottom: var(--px-4) solid var(--brand-500);
				background-color: var(--neutrals-900);
			}
			svg {
				fill: var(--neutrals-00white);
				width: var(--px-24);
				height: var(--px-24);
				max-width: var(--px-24);
				max-height: var(--px-24);
				cursor: pointer;
				margin-top: var(--px-8);
			}
		}
		.user-dropdown {
			padding-right: var(--px-1);
			.motif-dropdown-menu {
				border: none;
				margin-top: calc(var(--px-5) * -1);
				margin-right: var(--px-6);
				z-index: 1000;
				@media only screen and (min-width: 1280px) and (max-width: 1366px) {
					margin-top: calc(var(--px-3) * -1);
					margin-right: var(--px-7);
				}
				.motif-dropdown-item {
					font-size: var(--px-12);
					font-weight: 400;
				}
			}
			.user-setting-separator {
				margin: var(--px-1) 0 !important;
				border-color: var(--neutrals-200) !important;
			}
		}
		.hamburger-menu {
			float: right;
			margin: var(--px-16) 0 var(--px-10) var(--px-16);
			svg {
				width: var(--px-24);
				height: var(--px-24);
				margin-top: var(--px-7);
				fill: var(--neutrals-00white);
			}
		}
	}
`;

export default function ServerNav() {
	return (
		<ThemeProvider theme={theme}>
			<section className="NavHeader">
				<StyledNavbar className="StyledNavbar">
					<section className="EYHeader">
						<section className="EYLogo">
							<NavLink to="/" className="LogoLink">
								{/* <EYLogo /> */}
								<h1 className="ey-logo-text">Canvas</h1>
							</NavLink>
						</section>
					</section>
				</StyledNavbar>
			</section>
		</ThemeProvider>
	);
}