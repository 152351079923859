import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { labels, standardStrings, urls } from '../../../util/uiconstants';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import ChooseNewExisting from '../EngagementWorkflowsNew/chooseneworexisting';
import CreateEditWorkspace from '../EngagementWorkflowsNew/createeditworkspace';
import CreateNewEngagement from './engagement/createengagement';
import Loader from '@ey/loader';
import SelectArchiveFileStep from '../RestoreWorkflow/selectarcfile';
import SelectExisting from '../EngagementWorkflowsNew/selectworkspace';
import WizardWrapper from '../Common/WizardWrapper';
import env from '../../../util/env';
import { getArchivedEngagementDetailsForRestore } from '../../../actions/restoreengagementaction';
import { getRollForwardEngagements } from '../../../actions/rollforwardengagementaction';

const rollForwardStepIDs = {
	chooseExistingStep: 1,
	selectExistingStep: 2,
	createEditWorkspaceStep: 3,
	createNewEngagementStep: 4,
	selectArcFileStep: 5
};

const RollForwardEngagementWizard = () => {
	const mode = { create: 'create', edit: 'edit', rollForward: 'rollForward' };
	const defaultStepIndex = 0;
	const dispatch = useDispatch();
	const history = useHistory();

	const rollForwardData = useSelector((state) => state.rollForwardEngagement);
	const arcEngagementInfo = useSelector((state) => state.arcEngagementInfo);

	const [step2NextChng, setStep2NextChng] = useState(1);
	const [step3FinishChng, setStep3FinishChng] = useState(1);
	const [step4FinishChng, setStep4FinishChng] = useState(1);

	const [canGoToNextStep, setCanGoToNextStep] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [domain, setDomain] = useState({
		domainChooseExisting: false,
		domainWorkspaceId: -1,
		domainClientId: -1,
		domainClientName: standardStrings.EMPTY,
		domainWorkspaceName: standardStrings.EMPTY,
		domainLocation: standardStrings.EMPTY,
		domainClientCode: -1,
		domainSelectedArcFileGuid: standardStrings.EMPTY,
		domainOriginLanguageId: -1,
		domainOriginLocation: -1
	});
	const wizLoaderName = labels.rollforwardengagementstepheader;

	const [isDataValid, setIsDataValid] = useState(true);
	const [activeStepIndex, setActiveStepIndex] = useState(defaultStepIndex);
	const [wizardStepLabels, setWizardStepLabels] = useState([
		{
			id: rollForwardStepIDs.chooseExistingStep,
			stepName: labels.step01,
			stepDescription: labels.chooserollfwdhdr,
			active: true
		},
		{
			id: rollForwardStepIDs.createEditWorkspaceStep,
			stepName: labels.step02,
			stepDescription: labels.rollfwdstephdrnew,
			active: false
		},
		{
			id: rollForwardStepIDs.createNewEngagementStep,
			stepName: labels.step03,
			stepDescription: labels.rollforwardengagementstepheader,
			active: false
		}
	]);
	const [wizardSteps, setWizardSteps] = useState([]);

	const buttons = [
		{
			label: activeStepIndex == 0 ? labels.cancel : labels.back, // Change label based on active step.
			onClick: () => {
				switch (activeStepIndex) {
					case 0:
						navigateBack();
						break;
					case 1:
						setActiveStepIndex((prev) => prev - 1);
						break;
					case 2:
						setActiveStepIndex((prev) => prev - 1);
						break;
					case 3:
						setActiveStepIndex((prev) => prev - 1);
						break;
					case wizardStepLabels.length - 1:
						setActiveStepIndex((prev) => prev - 1);
						break;
				}
			},
			isVisible: isDataValid,
			disabled: !isDataValid
		},
		{
			label:
				activeStepIndex === wizardStepLabels.length - 1
					? labels.finish
					: labels.continue,
			onClick: () => {
				switch (activeStepIndex) {
					case 0:
						setActiveStepIndex((prev) => prev + 1);
						resetDomainValues([
							'domainClientId',
							'domainClientName',
							'domainClientCode',
							'domainWorkspaceId',
							'domainWorkspaceName'
						]);
						break;
					case 1:
						if (domain.arcFilesCount > 1) {
							setActiveStepIndex((prev) => prev + 1);
						} else if (canGoToNextStep) {
							setActiveStepIndex((prev) => prev + 1);
						} else {
							setStep2NextChng((prev) => prev + 1);
						}
						break;
					case 2:
						if (domain.arcFilesCount > 1) {
							if (canGoToNextStep) {
								setActiveStepIndex((prev) => prev + 1);
							} else {
								setStep2NextChng((prev) => prev + 1);
							}
						} else {
							setStep3FinishChng((prev) => prev + 1);
						}
						break;
					case wizardStepLabels.length - 1:
						setStep4FinishChng((prev) => prev + 1);
						break;
				}
			},
			isVisible: isDataValid,
			disabled: !isDataValid
		},
		{
			label: labels.close,
			onClick: () => {
				navigateBack();
			},
			isVisible: !isDataValid,
			disabled: isDataValid
		}
	];

	useEffect(() => {
		const getRollForwardEngagementDataAsync = async () => {
			try {
				await dispatch(
					getRollForwardEngagements(
						getArchivedFileListURL(
							rollForwardData?.engagementData?.state?.engagementId
						)
					)
				);
				await getOriginalEngagementData(
					rollForwardData.engagementData.state.engagementId
				);
			} catch (e) {
				console.log(e);
				setIsDataValid(false);
			} finally {
				setIsLoading(false);
			}
		};

		setIsLoading(true);
		setWizardSteps(() => {
			const stepContentArray = [];

			stepContentArray.push(chooseExistingStep());
			stepContentArray.push(selectExistingOrCreateWorkspaceStep());
			stepContentArray.push(createNewEngagement());

			return stepContentArray;
		});
		updateWizardData('domainOriginEngagementId', rollForwardData?.engagementData?.state?.engagementId);

		if (rollForwardData?.engagementData?.state?.engagementId > 0) {
			getRollForwardEngagementDataAsync();
		}
		else {
			console.log('Cannot rollforward, missing engagement id for method getRollForwardEngagementDataAsync');
			setIsDataValid(false);
			setIsLoading(false);
		}

	}, []);

	useEffect(() => {
		if (Object.keys(arcEngagementInfo).length > 0 && isDataValid) {
			updateWizardData(
				'domainOriginLocation',
				arcEngagementInfo.data.countryid
			);
			updateWizardData(
				'domainOriginLanguageId',
				arcEngagementInfo.data.languageid
			);
			updateWizardData(
				'domainOriginEngVersion',
				arcEngagementInfo.data.engagementversion
			);
		}
	}, [arcEngagementInfo]);

	useEffect(() => {
		if (
			rollForwardData?.data &&
			Object.keys(rollForwardData?.data).length > 0
		) {
			updateWizardData(
				'arcFilesCount',
				rollForwardData.data.attachments.length
			);
		}
	}, [rollForwardData]);

	useEffect(() => {
		if (domain.arcFilesCount > 1) {
			setWizardStepLabels([
				{
					id: rollForwardStepIDs.selectArcFileStep,
					stepName: labels.step01,
					stepDescription: labels.selectarchiveheader,
					active: true
				},
				{
					id: rollForwardStepIDs.chooseExistingStep,
					stepName: labels.step02,
					stepDescription: labels.chooserollfwdhdr,
					active: true
				},
				{
					id: rollForwardStepIDs.createEditWorkspaceStep,
					stepName: labels.step03,
					stepDescription: labels.rollfwdstephdrnew,
					active: false
				},
				{
					id: rollForwardStepIDs.createNewEngagementStep,
					stepName: labels.step04,
					stepDescription: labels.rollforwardengagementstepheader,
					active: false
				}
			]);
			setWizardSteps(getWizardSteps(domain.arcFilesCount));
		}
		updateArcGuidIfOnlyOne();
	}, [domain.arcFilesCount]);

	useEffect(() => {
		setWizardStepLabels(getWizardStepLabels(domain.arcFilesCount));
		setWizardSteps(getWizardSteps(domain.arcFilesCount));
	}, [
		domain.domainChooseExisting,
		domain.domainOriginLocation,
		domain.domainClientName,
		domain.domainWorkspaceName,
		step2NextChng,
		step3FinishChng,
		step4FinishChng
	]);

	const resetDomainValues = (valuesArray) => {
		valuesArray.forEach((valueName) => {
			updateWizardData(valueName, undefined);
		});
	};

	// Step valid functions are triggered from inside components, thats how we manage canGoToNextStep local state.
	const isStep1Valid = (isComponentDataValid) => {
		if (isComponentDataValid == undefined || isComponentDataValid == null) {
			setCanGoToNextStep(false);
		} else {
			setCanGoToNextStep(isComponentDataValid);
		}
	};

	const isStep2Valid = (isComponentDataValid) => {
		if (isComponentDataValid == undefined || isComponentDataValid == null) {
			setCanGoToNextStep(false);
		} else {
			setCanGoToNextStep(isComponentDataValid);
		}
	};
	const isStep3Valid = (isComponentDataValid) => {
		if (isComponentDataValid == undefined || isComponentDataValid == null) {
			setCanGoToNextStep(false);
		} else {
			setCanGoToNextStep(isComponentDataValid);
		}
	};

	const isStep4Valid = (isComponentDataValid) => {
		if (isComponentDataValid == undefined || isComponentDataValid == null) {
			setCanGoToNextStep(false);
		} else {
			setCanGoToNextStep(isComponentDataValid);
		}
	};

	const getOriginalEngagementData = async (engagementId) => {
		try {
			await dispatch(
				getArchivedEngagementDetailsForRestore(
					getArchivedEngagementDetailsURL(engagementId)
				)
			);
		} catch {
			throw err;
		}
	};

	const updateWizardData = (fieldName, fieldValue) => {
		setDomain((prev) => {
			return {
				...prev,
				[fieldName]: fieldValue
			};
		});
	};

	const getWizardData = (domainFieldName) => {
		return domain[domainFieldName];
	};

	const updateArcGuidIfOnlyOne = () => {
		if (domain.arcFilesCount == 1) {
			updateWizardData(
				'domainSelectedArcFileGuid',
				rollForwardData.data.attachments[0].FileGUID
			);
		}
	};

	const getWizardSteps = (arcFilesCount) => {
		const wizardSteps = [];

		if (arcFilesCount > 1) {
			wizardSteps.push(selectArchiveFile());
		}

		wizardSteps.push(chooseExistingStep());
		wizardSteps.push(selectExistingOrCreateWorkspaceStep());
		wizardSteps.push(createNewEngagement());
		return wizardSteps;
	};

	const getWizardStepLabels = (arcFilesCount) => {
		const wizardStepLabels = [];

		if (arcFilesCount > 1) {
			wizardStepLabels.push({
				id: rollForwardStepIDs.selectArcFileStep,
				stepName: labels.step01,
				stepDescription: labels.selectarchiveheader,
				active: true
			});
		}
		if (domain.domainChooseExisting) {
			wizardStepLabels.push(
				{
					id: rollForwardStepIDs.chooseExistingStep,
					stepName: arcFilesCount > 1 ? labels.step02 : labels.step01,
					stepDescription: labels.chooserollfwdhdr,
					active: true
				},
				{
					id: rollForwardStepIDs.selectExistingStep,
					stepName: arcFilesCount > 1 ? labels.step03 : labels.step02,
					stepDescription: labels.rollfwdstephdrext,
					active: false
				},
				{
					id: rollForwardStepIDs.createNewEngagementStep,
					stepName: arcFilesCount > 1 ? labels.step04 : labels.step03,
					stepDescription: labels.rollforwardengagementstepheader,
					active: false
				}
			);
		} else {
			wizardStepLabels.push(
				{
					id: rollForwardStepIDs.chooseExistingStep,
					stepName: arcFilesCount > 1 ? labels.step02 : labels.step01,
					stepDescription: labels.chooserollfwdhdr,
					active: true
				},
				{
					id: rollForwardStepIDs.createEditWorkspaceStep,
					stepName: arcFilesCount > 1 ? labels.step03 : labels.step02,
					stepDescription: labels.rollfwdstephdrnew,
					active: false
				},
				{
					id: rollForwardStepIDs.createNewEngagementStep,
					stepName: arcFilesCount > 1 ? labels.step04 : labels.step03,
					stepDescription: labels.rollforwardengagementstepheader,
					active: false
				}
			);
		}

		return wizardStepLabels;
	};

	const navigateBack = () => {
		if (history.location.pathname !== '') {
			history.goBack();
		} else {
			history.push('');
		}
	};

	const getArchivedFileListURL = (engagementId) => {
		// this gives the list of available restored copies of eng.
		return (
			env.getURL('serviceUrl') +
			'/' +
			`${urls.ENGAGEMENTS_URL}` +
			'/getarcfiles?id=' +
			engagementId
		);
	};

	const getArchivedEngagementDetailsURL = (engagementId) => {
		// this gives the list of available restored copies of eng.
		return env.getURL('serviceUrl') + '/' + 'engagements.json/' + engagementId;
	};

	const chooseExistingStep = () => {
		return (
			<ChooseNewExisting
				updateWizardData={updateWizardData}
				getDependencyData={getWizardData}
				headerQuestion={labels.rollfwdhdrqstn}
			/>
		);
	};

	const selectExistingOrCreateWorkspaceStep = () => {
		return (
			<>
				{domain.domainChooseExisting ? (
					<SelectExisting
						isComponentValid={isStep2Valid}
						updateWizardData={updateWizardData}
						nextClicked={step2NextChng}
						getDependencyData={getWizardData}
						mode={mode.rollForward}
					/>
				) : (
					<CreateEditWorkspace
						isComponentValid={isStep2Valid}
						updateWizardData={updateWizardData}
						nextClicked={step2NextChng}
						getDependencyData={getWizardData}
						mode={mode.rollForward}
					/>
				)}
			</>
		);
	};

	const createNewEngagement = () => {
		return (
			<CreateNewEngagement
				isComponentValid={
					domain.arcFilesCount > 1 ? isStep4Valid : isStep3Valid
				}
				updateWizardData={updateWizardData}
				onFinishClick={
					domain.arcFilesCount > 1 ? step4FinishChng : step3FinishChng
				}
				getDependencyData={getWizardData}
				mode={mode.rollForward}
			/>
		);
	};

	const selectArchiveFile = () => {
		return (
			<SelectArchiveFileStep
				isComponentValid={isStep1Valid}
				updateWizardData={updateWizardData}
				getDependencyData={getWizardData}
				mode={mode.rollForward}
				arcFileList={rollForwardData.data.attachments}
			/>
		);
	};

	return (
		<StyledWizardWrapper>
			{isLoading ? (
				<Loader view="fullscreen" />
			) : (
				<WizardWrapper
					wizLoaderName={wizLoaderName}
					currentStepIndex={activeStepIndex}
					wizardSteps={wizardStepLabels}
					setWizardSteps={setWizardStepLabels}
					buttons={buttons}
					theme={
						document
							.getElementsByTagName('body')[0]
							.getAttribute('motif-theme') === 'light'
							? null
							: 'dark'
					}
					stepContent={wizardSteps}
				/>
			)}
		</StyledWizardWrapper>
	);
};

export default RollForwardEngagementWizard;

const StyledWizardWrapper = styled.section`
	width: 100%;
	padding: 0 var(--px-40);
	header h5 {
		font-size: var(--px-20);
		font-weight: bold;
		width: 100%;
		padding: var(--px-15) 0;
	}
	.componentBodyWrapper {
		height: calc(100vh - var(--px-280));
	}
`;
