import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import Loader from '@ey/loader';
import {FormRadio, FormComboBox} from '@ey/form';
import {Radio} from 'react-bootstrap';
import env from '../../../../util/env';
import {getFilteredClientAndWorkspaceDetails} from '../../../../actions/workspacesactions';
import {labels, standardStrings} from '../../../../util/uiconstants';
import {SVGICONS} from '@ey/icons';
import { useDidMountEffect } from '../../../../util/customHooks';

const SelectWorkspace = (props) => {
	const {
		getDependencyData,
		onComponentDisplayed,
		nextClicked,
		updateWizardData,
		isComponentValid,
	} = props;

	const dispatch = useDispatch();
	const clientData = useSelector((store) => store.clientWorkspaces);

	const [selectedClientId, setSelectedClientId] = useState(-1);
	const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(-1);
	const [clientWorkspaces, setClientWorkspaces] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [disableClient, setDisableClient] = useState(false);
	const [clientName, setClientName] = useState('');
	const [workspaceName, setWorkspaceName] = useState('');
	const [clientCode, setClientCode] = useState('');
	const [showValidation, setShowValidation] = useState(false);

	let engVersion = getDependencyData('domainOriginEngVersion');
	let locationProps = getDependencyData('domainOriginLocation'); //not using from state on purpose
	let versionFilteredURL =
		env.getURL('serviceUrl') +
		"/home.json?phase=GetClientAndWorkspaceDetails&filters=sourceengagementversion eq '" +
		engVersion +
		"',operationtype eq RestoreToExistingWorkspace,countryid eq " +
		locationProps;

	const getFilteredClients = async () => {
		try {
			await dispatch(getFilteredClientAndWorkspaceDetails(versionFilteredURL));
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		setIsLoading(true);
		getFilteredClients();
	}, []);

	useEffect(() => {
		setComponentValid();
	}, [onComponentDisplayed]);

	useDidMountEffect(() => {
		if (selectedClientId < 1 && selectedWorkspaceId < 1) {
			setShowValidation(true);
		}
	}, [nextClicked]);

	useEffect(() => {
		updateWizardFromComponent();
	}, [
		selectedClientId,
		clientCode,
		clientName,
		selectedWorkspaceId,
		workspaceName
	]);

	const setComponentValid = () => {
		// Validate data
		if (selectedClientId > -1 && selectedWorkspaceId > 0) {
			isComponentValid(true);
		} else {
			isComponentValid(false);
		}
	};

	const updateWizardFromComponent = () => {
		// Pass on data to next step
		updateWizardData('domainClientId', selectedClientId);
		updateWizardData('domainClientCode', clientCode);
		updateWizardData('domainClientName', clientName);
		updateWizardData('domainWorkspaceId', selectedWorkspaceId);
		updateWizardData('domainWorkspaceName', workspaceName);

		setComponentValid();
	};

	const dropdownChanged = (key, value) => {
		// Clearout workspaces if no client is selected
		if (value === null) {
			setSelectedClientId(-1);
			setSelectedWorkspaceId(-1);
			setClientName('');
			setWorkspaceName('');
			setClientCode('');
			setShowValidation(false);
			return;
		}

		// Setting selectedClientId to state
		setSelectedClientId(value);

		// Getting selected client object out of array
		let selectedClient =
			clientData &&
			clientData.collections &&
			clientData.collections.clients &&
			clientData.collections.clients.filter(function (item) {
				if (item.id === value) {
					return item;
				}
			});
		// Getting client workspaces out of array & setting in state
		selectedClient &&
			selectedClient.map(({data, collections}) => {
				setClientName(data.clientname);
				//clientCode: data.clientcode, client code to come from workpace
				setClientWorkspaces(collections.workspaces);
			});

		// Disabling client ddl if no workspaces are present
		if (!clientWorkspaces) {
			setDisableClient(true);
		}
	};

	const radioButtonClicked = (key, value, clientcode) => {
		setSelectedWorkspaceId(key);
		setWorkspaceName(value);
		setClientCode(clientcode);
		setShowValidation(false);
	};

	// Pass value to enable/disable client ddl
	let clientDropdownAttributes = {};
	if (disableClient === true) {
		clientDropdownAttributes.disabled = true;
	}

	// Array to load client workspaces radio list
	let result = [];
	if (clientWorkspaces) {
		clientWorkspaces.forEach(({id, data}) => {
			result.push(
				<Radio
					value={id}
					className={data.iseligiblefornewengagement === false ? 'disabled' : ''}							
					key={id}
					name="radio"
					onClick={() =>
						radioButtonClicked(id, data.workspacedesc, data.clientcode)
					}
				>
					{data.workspacedesc}
					{data.iseligiblefornewengagement === false ? (<span className='newengagement-eligible'>{'(' + labels.workspacenotelibilefornewengagement + ')'}</span>) : null}
				</Radio>
			);
		});
	}

	// Rendering radio list
	let Workspaces = (
		<section>
			<FormRadio name="radioStack" onChange={() => {}}>
				{result}
			</FormRadio>
		</section>
	);

	return (
		<>
			{isLoading ? ( // Show loading indicator in case data pull in progress
				<Loader />
			) : (
				<Selectexistingworkspace>
					<header>
						<h5>{labels.selectEngagementHeader}</h5>
					</header>
					<form>
						<section>
							<section className="existingworkspacelist">
								<FormComboBox
									id="clients"
									name="clients"
									value={selectedClientId}
									is_required
									errormessage={labels.isRequired.replace('{0}', labels.client)}
									help
									searchable
									placeholder={labels.selectworkspaceclient}
									options={
										clientData &&
										clientData.collections &&
										clientData.collections.clients.map(({id, data}) => {
											return {
												value: id,
												label: data.clientname
											};
										})
									}
									onChange={dropdownChanged}
									{...clientDropdownAttributes}
								/>
							</section>

							<section className="import-error">
								{showValidation === true ? (
									<section>
										<SVGICONS styleName="small" id="icon" name="error" />
										<span>{labels.selectexistingworkspacemsg}</span>
									</section>
								) : (
									standardStrings.EMPTY
								)}
							</section>

							<section className="selectexistingworkspace">
								{selectedClientId && selectedClientId < 0
									? labels.selectclient
									: clientWorkspaces.length > 0
									? Workspaces
									: labels.selectworkspacenoclientworkspaceexists}
							</section>
						</section>
					</form>
				</Selectexistingworkspace>
			)}
		</>
	);
};

export default SelectWorkspace;

const Selectexistingworkspace = styled.section`
	& .selectexistingworkspace {
		padding-top: var(--px-10);
		font-size: var(--px-14);

		& div.form-group .radio {
			margin: 0 0 var(--px-15) 0;
		}

		& .form-group label.control-label {
			display: inline;
		}
	}
	.radio.disabled label,
	input[type=radio][disabled] {
		cursor:default;
	}
	.radio.disabled {
		pointer-events: none;
		cursor: default;
	}

	& .import-error {
		font-size: var(--px-12);
		line-height: normal;
		height: var(--px-20);
		padding-top: var(--px-10);
		padding-bottom: var(--px-24);

		& svg {
			fill: var(--red-600);
			vertical-align: text-top;
			margin-right: 0.5em;
		}
	}

	& .form-group .radio {
		vertical-align: middle;
		margin: 0;

		& label input[type='radio'] {
			margin: 0 0 0 calc(var(--px-30) * -1);
		}
		& label {
			padding-left: var(--px-30);
			width: auto;
			word-wrap: break-word;
			font-weight: normal;
		}
		& .newengagement-eligible {
			display:inline-block;
			padding-left: var(--px-5);
			width: auto;
			font-weight: 300;
			font-size:var(--px-14);
			color: var(--neutrals-700);

		}
	}

	& .existingworkspacelist {
		display: inline-block;
		width: 95%;
		@media screen and (max-width: 1366px) {
			.Select {
				&.is-open .Select-menu-outer {
					max-height: 200px !important;
					& * {
						max-height: 200px !important;
					}
				}
			}
		}
		& div.form-group {
			display: inline-flex;
			width: 100%;
			font-size: var(--px-14);
			margin: 0;
			padding: 0 0 var(--px-20) 0;
			border-bottom: var(--px-1) solid var(--neutrals-200);

			& > section {
				width: var(--px-400);
				float: left;

				& .select-label {
					line-height: normal;
					color: var(--neutrals-900);
					font-size: var(--px-14);
				}

				& .Select-control {
					margin: 0 var(--px-20) 0 0;
					vertical-align: middle;
					border: var(--px-1) solid var(--neutrals-300);
					line-height: var(--px-46);
				}
			}
		}
	}
`;
