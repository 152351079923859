import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {currentResource} from '../../../../util/uiconstants';
import EngagementAlertsList from './engagementAlertsList';

const EngagementAlerts = (props) => {
	let issuesList = [];
	if (props.engagementAlerts) {
		issuesList = IssuesListCreation(props.engagementAlerts);
	}

	return (
		<>
			{issuesList.length > 0 && (
				<EngagementAlertsBlock className="engagement-alertsblock">
					<EngagementAlertsList issuesList={issuesList} />
				</EngagementAlertsBlock>
			)}
		</>
	);
};

// -------------- PROP TYPES --------------
// ----------------------------------------
EngagementAlerts.propTypes = {
	metrics: PropTypes.shape({
		hasPartnerInvited: PropTypes.bool.isRequired,
		hasEqrInvited: PropTypes.bool.isRequired,
		hasValidEngagementCode: PropTypes.bool.isRequired,
		overdueArchiveDeadline: PropTypes.bool.isRequired,
		upcomingArchiveDeadline: PropTypes.bool.isRequired,
		hasContentDeliveryInProgress: PropTypes.bool.isRequired,
		overdueMilestones: PropTypes.number.isRequired,
		overduePartnerTasks: PropTypes.number.isRequired,
		overdueEqrTasks: PropTypes.number.isRequired
	})
};

// This is the function that receives the metrics properties and creates the Issues list for each property that matches one of dataFunction's properties.
const IssuesListCreation = (metrics) => {
	let entries = Object.entries(metrics),
		issuesList = [];

	entries.forEach((property) => {
		let dataFunction = dataFunctions[property[0]];
		if (dataFunction && dataFunction.validate(metrics)) {
			issuesList.push(dataFunction.createIssue(metrics));
		}
	});

	return issuesList;
};

// This is the function that will create the Issue objects based on the issueType and label. You may pass a custom function inside the optional object,
// if needed. You can pass in other data (like the counter) inside this object as well. If more issueTypes are added, please modify this function accordingly.
const createAlertIssue = (issueType, label, optional) => {
	const exclamationMarkIssueTypes = [1, 2];
	const counterIssueTypes = [3];
	let createdIssue = {};

	if (optional && optional.customFunction) {
		createdIssue = optional.customFunction(issuetype, label, optional);
	} else if (exclamationMarkIssueTypes.includes(issueType)) {
		createdIssue = createExclamationMarkIssue(issueType, label);
	} else if (
		counterIssueTypes.includes(issueType) &&
		typeof optional != 'undefined' &&
		typeof optional.count != 'undefined'
	) {
		createdIssue = createCounterIssue(issueType, label, optional.count);
	}

	return createdIssue;
};

// This is the function that creates the Issue objects for Exclamation Mark type Issues.
const createExclamationMarkIssue = (issueType, label) => {
	return {
		issueType: issueType,
		name: label
	};
};

// This is the function that creates the Issue objects for Counter type Issues.
const createCounterIssue = (issueType, label, count) => {
	return {
		issueType: issueType,
		name: label,
		counter: count
	};
};

// In the future, when new alerts need to be added, you only need to add a new object inside dataFunctions,
// containing a validate function and a createIssue function. You can create custom functions for creating Issues if you need to.
const dataFunctions = {
	hasPartnerInvited: {
		validate: function (data) {
			return !data.hasPartnerInvited;
		},
		createIssue: function () {
			return createAlertIssue(
				1,
				currentResource.overdueIssues.partnerInvitationPending
			);
		}
	},
	hasEqrInvited: {
		validate: function (data) {
			return !data.hasEqrInvited;
		},
		createIssue: function () {
			return createAlertIssue(
				1,
				currentResource.overdueIssues.eqrInvitationPending
			);
		}
	},
	hasValidEngagementCode: {
		validate: function (data) {
			return !data.hasValidEngagementCode;
		},
		createIssue: function () {
			return createAlertIssue(
				1,
				currentResource.overdueIssues.invalidEngagementCode
			);
		}
	},
	overdueArchiveDeadline: {
		validate: function (data) {
			return data.overdueArchiveDeadline;
		},
		createIssue: function (data) {
			console.log('currentResource', currentResource);
			return createAlertIssue(
				1,
				currentResource.overdueIssues.overdueArchiveDeadline
			);
		}
	},
	upcomingArchiveDeadline: {
		validate: function (data) {
			return data.upcomingArchiveDeadline;
		},
		createIssue: function () {
			return createAlertIssue(
				2,
				currentResource.overdueIssues.upcomingArchiveDeadline
			);
		}
	},
	hasContentDeliveryInProgress: {
		validate: function (data) {
			return data.hasContentDeliveryInProgress;
		},
		createIssue: function () {
			return createAlertIssue(
				2,
				currentResource.overdueIssues.contentDeliveryInProgress
			);
		}
	},
	overdueMilestones: {
		validate: function (data) {
			return data.overdueMilestones > 0;
		},
		createIssue: function (data) {
			const optional = {
				count: data.overdueMilestones
			};
			return createAlertIssue(
				3,
				currentResource.overdueIssues.overdueMilestones,
				optional
			);
		}
	},
	overduePartnerTasks: {
		validate: function (data) {
			return data.overduePartnerTasks > 0;
		},
		createIssue: function (data) {
			const optional = {
				count: data.overduePartnerTasks
			};
			return createAlertIssue(
				3,
				currentResource.overdueIssues.overduePartnerTasks,
				optional
			);
		}
	},
	overdueEqrTasks: {
		validate: function (data) {
			return data.overdueEqrTasks > 0;
		},
		createIssue: function (data) {
			const optional = {
				count: data.overdueEqrTasks
			};
			return createAlertIssue(
				3,
				currentResource.overdueIssues.overdueEqrTasks,
				optional
			);
		}
	}
};

// -------------- STYLING CLASSES --------------
// ---------------------------------------------
const EngagementAlertsBlock = styled.div`
	padding: var(--px-0) 0 var(--px-4_5) 0;
	width: 100%;
	z-index: 99;
	padding-left: var(--px-28);	
`;

export default EngagementAlerts;
