import React from 'react';
import styled from 'styled-components';
import {SVGICONS} from '@ey/icons';
import {labels} from '../../util/uiconstants';
/*
	readyForFavClick : this props is used for avoiding quick subsequent clicks on favorites button ie. double click etc. 
	On click of favorite - onIconClick even will be gone for 300ms so no click can be performed. 
*/
const FavoriteButton = (props) => {
	const {isFav, onFavClick, readyForFavClick} = props;

	const favIconName = isFav ? 'star' : 'star-outline';
	const hoverTxt = isFav ? labels.unmarkfromfavorites : labels.markasfavorite;

	const onFavClickEvent = (e) => {
		onFavClick();
	};

	return (
		<Toplevelcss>
			<span title={hoverTxt}>
				<SVGICONS
					hoverText={hoverTxt}
					styleName="small"
					name={favIconName}
					onIconClick={readyForFavClick ? onFavClickEvent : undefined}
				/>
			</span>
		</Toplevelcss>
	);
};

export default FavoriteButton;

const Toplevelcss = styled.section`
	cursor: pointer;
	width: var(--px-45);
    height: var(--px-45);
    position: relative;
	clip-path: polygon(100% 0, 0% 100%, 100% 100%);
	background-color: var(--neutrals-50);
	border-radius: var(--border-radius-medium);
	svg {
		fill: ${(props) => (props.isFav === true ? '#2e2e38' : '#17706f')};
		position: absolute;
		right: var(--px-4);
		top: var(--px-20);
	}
`;
