import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import env from '../../../util/env';
import { pagingOptions } from '../../../util/uiconstants';
import Pagination from '@ey/pager';
import { useSelector, useDispatch } from 'react-redux';
import Table from '@ey/motif-react-wrapper/Table';
import EllipsesControl from '@ey/ellipses-control';
import Loader from '../../Common/Loaders/loadingindicator';
import { labels } from '../../../util/uiconstants';
import AccessDeniedMessage from '../../Common/AccessDeniedMessage/AccessDeniedMessage';
import Wrapper from './Styles/sharepointWorkspacesbasestyle';
import { clearErrors } from '../../../actions/erroractions';
import { setIsModalOpen } from '../../../actions/modalstateactions';
import { getSharepointWorkspaces } from '../../../actions/admin/sharepointworkspacesactions';
import WorkspacesWrapper from './WorkspaceDrawer/workspaceswrapper';
import FormField from '@ey/motif-react-wrapper/FormField';
import Search from '@ey/motif-react-wrapper/Search';

function DataCenterDetails() {
	const columnDefs = [
		{
			minWidth: 450,
			width: 450,
			headerName: labels.dataCenterNameHeader,
			field: 'dataCenterName',
			cellRenderer: (params) => dataCenterNameNameCellRenderer(params),
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			minWidth: 450,
			width: 450,
			headerName: labels.numberOfWorkSpacesHeader,
			field: 'sharePointWorkspaceCount',
			cellRenderer: (params) => numberofWorkspacesDecriptionCellRenderer(params),
			lockPosition: true,
			suppressSizeToFit: true
		}
	];
	const dataCenterNameNameCellRenderer = (e) => {
		return (
			<>
				<section className="roleType"  >
					<section>
						<a onClick={() => { showDrwaer(e.data) }} className="groupname">
							{ellipsesControlRender(e.data.dataCenterName)}							
						</a>
					</section>
				</section>
			</>
		);
	};

	const numberofWorkspacesDecriptionCellRenderer = (e) => {
		return (
			<>
				<section className="roleType">
					<section>
						{ellipsesControlRender(JSON.stringify(e.data?.sharePointWorkspaceCount))}	
					</section>
				</section>
			</>
		);
	};

	const ellipsesControlRender = (text) => {
		return (
			<section className="roleType">
				<EllipsesControl
					content={text}
					tooltip={text}
					displayId={''}
					noOfLines={5}
					ellipsisClass="columnName"
				/>
			</section>
		);
	};
	const showDrwaer = (data) => {
		setshowDrawer(true);
		setCurrentDatacenter(data)		
	}
	const closeDrawer = () => {
		setshowDrawer(false);
	}

	const sharepointWorkSpaces = useSelector((store) => store.sharepointWorkspaces);
	const userPermissions = useSelector((store) => store.userPermissions);
	const [optionPerPage, setOptionPerPage] = useState(pagingOptions.options[0]);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState();
	const [loading, setLoading] = useState(false);
	const [showDrawer, setshowDrawer] = useState(false);
	const [currentDatacenter, setCurrentDatacenter] = useState({});
	const [searchInputValue, setSearchInputValue] = useState('');
	const dispatch = useDispatch();

	useEffect(() => {
		loadDatacenterDetails(searchInputValue);
	}, [page, optionPerPage]);	

	const loadDatacenterDetails = (searchText) => {
		setLoading(true);
		let sharepointWorkSpacesUrl =
			env.getURL('serviceUrlV2') +
			`/admin/datacenters?page=${page}&pageSize=${optionPerPage}`;

		if (searchText) {
			sharepointWorkSpacesUrl += `&searchString=${searchText}`;
		}

		dispatch(getSharepointWorkspaces(sharepointWorkSpacesUrl))
			.then(() => {
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};
 const setCurrentDatacenterCallBack = (curremtDataCenter)=>{
	setCurrentDatacenter(curremtDataCenter);
 }

	const clearError = (isModelOpen) => {
		dispatch(clearErrors()).then(() => {
			dispatch(setIsModalOpen(isModelOpen));
		});
	};

	useEffect(() => {
		clearError(true);
	}, [])

	useEffect(() => {
		if (sharepointWorkSpaces) {
			setTotalPages(sharepointWorkSpaces?.pages);
		}
	}, [sharepointWorkSpaces]);

	const paginationHandler = (value) => {
		if (page !== value && page > 0) {
			setPage(value);
		}
	};

	const handlerOptionPage = (value) => {
		if (value !== optionPerPage) {
			setOptionPerPage(value);
			setPage(1);
		}
	};

	const onSearchChange = () => {
		if (searchInputValue != undefined) {
			setPage(1);
			loadDatacenterDetails(searchInputValue);
		}
	};

	const onClearClick = () => {
		setSearchInputValue('');
		loadDatacenterDetails('');
	};

	function sharepointWorkPermissions() {
		let sharepointWorkSpacesPermission = userPermissions?.permissions;
		return(
			sharepointWorkSpacesPermission &&
			(sharepointWorkSpacesPermission.canReadSharePointWorkspaces ||
				sharepointWorkSpacesPermission.canCreateUpdateSharePointWorkspaces ||
				sharepointWorkSpacesPermission.canDeleteSharePointWorkspaces)
		);
	}

	return (
		<Wrapper>
			{loading? (
				<Loader show view="blockView" styleName="loadingstyle" />
			) : (
				<section className="mainwrapper">
					{sharepointWorkPermissions() ? (
						sharepointWorkSpaces ? (
							<section>								
								<section className="sharepointWorkspace mainResponsiveWrapper">
									<section className="add-on">
									<section className="search">
										<FormField>
											<Search
												placeholder={labels.searchUserText}
												value={searchInputValue}
												onChange={(e) => setSearchInputValue(e.target.value)}
												onSearchButtonClick={onSearchChange}
												onClear={onClearClick}
												onEnter={onSearchChange}
												clearHoverText={labels.clear}
											/>
										</FormField>
									</section>

								</section>
								<section className="sharepointWorkspaceTable">
										<Table
											defaultColDef={{
												flex: 1,
												sortable: false,
												filter: false,
												resizable: false,
												minWidth: 180,
												lockPosition: true
											}}
											onGridReady={(params) => {
												params.api.sizeColumnsToFit();
											}}
											zebra={true}
											rowHeight={32}
											enableCellTextSelection
											suppressRowClickSelection
											suppressCellSelection
											columnDefs={columnDefs}
											rowSelection="multiple"
											rowData={sharepointWorkSpaces?.data}
											rowBuffer={200}
										/>
									</section>
								</section>
							</section>
						) : (
							<section className="norecord">{labels.noResultsFound}</section>
						)
					) : (
						<section className="message">
							<AccessDeniedMessage
								permissionMesage={labels.insufficientPermission}
							/>
						</section>
					)}
				</section>
			)}

			<section className="pagerholder">
				<Pagination
					styleName="sharepointWorkspace-paging mainResponsiveWrapper"
					options_per_page={pagingOptions.options}
					default_dropdown_value={optionPerPage}
					currentPage={page}
					TotalPages={totalPages}
					dropup
					onInputChange={(e) => {
						let inputValue = Number.parseInt(e.target.value);
						paginationHandler(inputValue);
					}}
					onDropdownChange={(e) => {
						handlerOptionPage(e.value);
					}}
					onPrevPageClick={() => {
						paginationHandler(page - 1);
					}}
					onNextPageClick={() => {
						paginationHandler(page + 1);
					}}					
					dropdown_position="left"
				/>
			</section>

			<section>
				<WorkspacesWrapper
					show={showDrawer}
					onClose={closeDrawer}
					currentDatacenter={currentDatacenter}
					setCurrentDatacenter={setCurrentDatacenterCallBack}
					dataCenterListForNavigation={sharepointWorkSpaces?.data}

				/>
			</section>
		</Wrapper>
	);
}

export default DataCenterDetails;
