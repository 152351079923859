import React from 'react';
import Input from '@ey/motif-react-wrapper/Input';
import FormField from '@ey/motif-react-wrapper/FormField';
import ErrorMessage from '@ey/motif-react-wrapper/ErrorMessage';
import Label from '@ey/motif-react-wrapper/Label';
import {labels} from '../../../util/uiconstants';
import styled from 'styled-components';

// All
const TicketFieldWrapper = styled.section`

`;

function TicketField(props) {
    return (
        <TicketFieldWrapper className='ticketdetail'>
            <h5>{labels.confirmationMessage}</h5>
            <FormField>
                <Label>{labels.ticketNumber}</Label>
                <Input
                    value={props.ticketNumber}
                    maxLength={100}
                    clearButtonTitle={labels.clear}
                    onChange={props.onTicketChanged}
                />
                {props.ticketNumber?.trim().length == 0 && props?.showValidation && (
                    <ErrorMessage>
                        {labels.validationMessage}
                    </ErrorMessage>
                )}
            </FormField>
        </TicketFieldWrapper>
    );
}

export default TicketField;
