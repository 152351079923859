import React from 'react';

const engroot = {
	height: 'var(--px-100)',
	width: 'var(--px-430)',
	paddingLeft: 'var(--px-30)',
	display: 'block',
	paddingTop: 'var(--px-30)'
};
const txt = {
	marginLeft: 'var(--px-10)'
};
const dr = {
	marginLeft: 'var(--px-10)',
	display: 'inline-block',
	width: 'var(--px-357)',
	paddingLeft: 'var(--px-10)'
};

export default class Engagement extends React.Component {
	constructor(props) {
		super(props);

		if (this.props.x)
			this.state = {
				x: this.props.x,
				y: this.props.y,
				showRest: true
			};
		else {
			this.state = {
				x: '',
				y: '0',
				showRest: false
			};
		}
	}

	render() {
		//return <section style={notifIn}>{this.props.title}</section>;
		return (
			<section style={engroot}>
				{this.props.showDrop ? (
					<section style={dr}>
						<select
							onChange={(e) => {
								this.setState({showRest: true, x: e.target.value});
							}}
						>
							<option value="Cola">Cola</option>
							<option value="Shoes">Shoes</option>
							<option value="NIce">Nile</option>
							<option value="audi">audi</option>
						</select>
					</section>
				) : null}
				{this.state.showRest ? (
					<section>
						<input type="text" style={txt} value={this.state.x} />
						<input type="text" style={txt} value={this.state.y} />
					</section>
				) : null}
			</section>
		);
	}
}
