/**
 * Created by calhosh on 7/13/2017.
 */
import React from 'react';
import { Ellipse } from '@ey/canvascoreservices';
import styled from 'styled-components';
import Checkbox from '@ey/motif-react-wrapper/Checkbox';

export default function CheckBox(props) {
	const {
		label,
		name,
		title,
		value,
		checked,
		disabled,
		dataAutoID,
		ellipse,
		inline,
		noOfLines,
		styleName,
		className,
		customElement,
		statusLabel,
		hidetitletip,
		id,
		onChange,
		indeterminate,
		ariaLabel
	} = props;
	return (
		<Wrapper className={styleName ? styleName : ''}>
			<Checkbox
				id={id}
				name={name}
				inline={inline}
				value={value}
				title={title}
				onChange={onChange}
				checked={checked}
				disabled={disabled}
				className={className}
				data-autoid={dataAutoID}
				indeterminate={indeterminate}
				aria-label={ariaLabel}
			>
				{customElement && customElement}
				{ellipse ? (
					<Ellipse
						content={label}
						id={name}
						tooltip={label}
						displayId={props.displayId}
						noOfLines={noOfLines}
					/>
				) : (
					<span
						htmlFor={id}
						title={hidetitletip ? '' : label}
						className="checkboxLabel"
					>
						{label}
					</span>
				)}
				{statusLabel && <span className="statusLabel">{statusLabel}</span>}
			</Checkbox>
		</Wrapper>
	);
}

const Wrapper = styled.span`
	display: inline-block;
	position: relative;
	vertical-align: top;
	font-size: var(--px-14);
	.motif-checkbox {
		.motif-checkbox-input {
			width: var(--px-16);
			height: var(--px-16);
			z-index: 999;
			left: 0;
			position: absolute;
			margin: 0;
		}
		.motif-checkbox-custom {
			width: var(--px-16);
			height: var(--px-16);
		}
		.motif-checkbox-label {
			width: 75%;
		}
	}
	.motif-checkbox-label-wrap {
		margin-bottom: 0;
		&.motif-checkbox-checked {
			&.partialChecked {
				.motif-checkbox-custom {
					.motif-checkbox-checked-icon {
						margin: var(--px-1);
						display: none;
					}
					.motif-checkbox-indeterminate-icon {
						display: block;
					}
				}
			}
		}
	}
`;
