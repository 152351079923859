import React from 'react';
import _ from 'lodash';
import {SVGICONS} from '@ey/icons';
import styled from 'styled-components';
import {
	rbacRoleKeys,
	UserERPTypeId,
	currentResource,
	labels
} from '../../../util/uiconstants';
import { Ellipse } from '@ey/canvascoreservices';
import CustomTooltip from './CustomTooltip';

const PermissionList = ({rbacRoles, picFlag, userType}) => {
	let isAdminRole = false,
		isDefaultRole = false,
		rolesExceptAdminandDefault = [],
		isReadOnly = false;
	let internalUserRoles = currentResource.internalUserRoles;

	if (Array.isArray(rbacRoles)) {
		_.map(rbacRoles, (rbacRole) => {
			if (rbacRole.roleKey === rbacRoleKeys.adminRole) {
				isAdminRole = true;
			} else if (rbacRole.roleKey === rbacRoleKeys.defaultRole) {
				isDefaultRole = true;
			} else if (rbacRole.roleKey === rbacRoleKeys.ThirdPartyUserReadOnly) {
				isReadOnly = true;
			} else {
				let matchedRole = _.find(internalUserRoles, {
					roleKey: rbacRole.roleKey
				});

				if (matchedRole) {
					rolesExceptAdminandDefault.push(matchedRole);
				}
			}
		});
	}

	let mergedLocalizedList = _.map(internalUserRoles, function (localizedRole) {
		return _.find(rolesExceptAdminandDefault, function (r) {
			return r.roleKey === localizedRole.roleKey;
		});
	});

	let newList = _.compact(mergedLocalizedList);

	if (
		newList.some(
			(x) => x.roleKey == rbacRoleKeys.StrictlyConfidentialDocumentsRole
		)
	) {
		newList = newList.filter(
			(r) =>
				r.roleKey != rbacRoleKeys.HighConfidentialDocumentsRole &&
				r.roleKey != rbacRoleKeys.ModerateConfidentialDocumentsRole &&
				r.roleKey != rbacRoleKeys.LowConfidentialDocumentsRole
		);
	} else if (
		newList.some((x) => x.roleKey == rbacRoleKeys.HighConfidentialDocumentsRole)
	) {
		newList = newList.filter(
			(r) =>
				r.roleKey != rbacRoleKeys.ModerateConfidentialDocumentsRole &&
				r.roleKey != rbacRoleKeys.LowConfidentialDocumentsRole
		);
	} else if (
		newList.some(
			(x) => x.roleKey == rbacRoleKeys.ModerateConfidentialDocumentsRole
		)
	) {
		newList = newList.filter(
			(r) => r.roleKey != rbacRoleKeys.LowConfidentialDocumentsRole
		);
	}

	//Sorting for group and role
	let sortedList = _.orderBy(
		newList,
		['groupDisplayOrder', 'roleDisplayOrder'],
		['asc', 'asc']
	);

	let allGroups = sortedList.map((r) => {
		return {groupName: r.groupName, sectionId: r.sectionId};
	});

	let uniqueGroups = _.uniqWith(allGroups, _.isEqual);

	const permissionLabel =
		sortedList.length === 1 ? labels.permissionLabel : labels.permission;

	return (
		<>
			{isAdminRole && (
				<div className="motif-badge motif-badge-alt">
					<span className="motif-badge-number"> {labels.adminRole}</span>{' '}
				</div>
			)}
			{picFlag && (
				<div className="pic">
					<span className="picflag"> {labels.picRole}</span>{' '}
				</div>
			)}
			{isReadOnly && (
				<div className="motif-badge read-only">
					<span className="motif-badge-number">
						{' '}
						{labels.readOnlyRoleForThirdPartyUser}
					</span>{' '}
				</div>
			)}
			{sortedList && sortedList.length > 0 ? (
				<CustomTooltip
					containerClass={'permission-list contextmenu'}
					// position="right bottom"
					label={
						<div className="motif-badge">
							<span className="motif-badge-number">{`${sortedList.length} ${permissionLabel}`}</span>
							<span>
								<SVGICONS styleName={'small right'} name={'chevron-more'} />
							</span>
						</div>
					}
				>
					<StyledDisplayRoles className="customScrollbar">
						{uniqueGroups.map((grp) => {
							return (
								<li className="checklist">
									<header className="headerchecklist">
										<h3>{grp.groupName}</h3>
									</header>
									<ul>
										{sortedList
											.filter((role) => role.sectionId == grp.sectionId)
											.map((role) => {
												return (
													<li key={role.roleKey}>
														<a>
															<section className="permissionlist">
																<Ellipse
																	content={role.displayName}
																	tooltip={role.displayName}
																	noOfLines={1}
																/>
															</section>
														</a>
													</li>
												);
											})}
									</ul>
								</li>
							);
						})}
					</StyledDisplayRoles>
				</CustomTooltip>
			) : !isDefaultRole && userType !== UserERPTypeId.ThirdPartyUser ? (
				<div className="motif-badge motif-badge-alt">
					<span className="motif-badge-number"> {labels.legacy}</span>{' '}
				</div>
			) : null}
		</>
	);
};

export default PermissionList;

const StyledDisplayRoles = styled.ul`
	width: var(--px-200);
	max-height: var(--px-210);
	overflow: auto;

	.checklist {
		border-bottom: var(--px-1) solid var(--neutrals-300);
		padding: var(--px-5) 0;
		margin-bottom: 0;
		
		a .ellipses div:hover {
			cursor: default;
			text-decoration: none;
		}

		&:last-child {
			border-bottom: 0;
		}

		ul li a {
			cursor: default;
			padding: var(--px-5) var(--px-10) !important;
		}

		h3 {
			background: none;
			border: none;
			font-size: var(--px-14);
			padding: var(--px-5) var(--px-10);
		}
	}

	.permissionlist {
		width: 90%;
	}
`;
