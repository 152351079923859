import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import EngagementHeader from './selectedengagementtableheader';
import Headerbody from './selectedheaderbodystyle';
import styled from 'styled-components';
import {
	labels,
	successMessageDisplayDuration,
	reportFormateType,
	reportType
} from '../../../../util/uiconstants';
import env from '../../../../util/env';
import moment from 'moment';
import Loader from '@ey/loader';
import SelectedEngagementTableRow from './selectedengagementtablerow';
import EllipsesControl from '@ey/ellipses-control';
import Toast from '../../../Common/Toast/Toast';
import {setEngagementUpdateSuccessMessage} from '../../../../actions/admin/successmessageactions';
import {downloadReport} from '../../../../actions/admin/reportaction';

function SelectedEngagementHeader(props) {
	const [errorMessage, setErrorMessage] = useState('');

	const dispatch = useDispatch();
	const engagementUpdateSuccessMessage = useSelector(
		(store) => store.successMessages?.updateEngagement
	);
	const [isLoading, setIsLoading] = useState(false);
	const duration = successMessageDisplayDuration;
	const engagementId = props.engagementDetails?.engagementId;

	useEffect(() => {
		const timer = setTimeout(() => {
			dispatch(setEngagementUpdateSuccessMessage(''));
		}, duration);
		return () => clearTimeout(timer);
	});

	const downloadEngagementValidationReport = () => {
		setIsLoading(true);
		const countryid = new URLSearchParams(location.search).get('countryid');
		const ssl = new URLSearchParams(location.search).get('ssl');
		var url = env.getURL('serviceUrlV2') + 'admin/subservicelines/'+ssl+'/countries/'+countryid+'/engagements/'+engagementId+'/engagementreports?ReportType='+reportType.Validation;
		var dateTime = moment(new Date()).format("MM-DD-YYYY-HH-mm-ss");
		dispatch(downloadReport(url,engagementId+'_'+dateTime+'.'+reportFormateType.xlsx)).finally(() => {
			setIsLoading(false);
		});
	};

	return (
		<Wrapper>
			<section
				className="mainResponsiveWrapper toastmessage"
				style={{
					display: engagementUpdateSuccessMessage.length > 0 ? 'block' : 'none'
				}}
			>
				<Toast
					variant="success"
					duration={duration}
					onClose={() => dispatch(setEngagementUpdateSuccessMessage(''))}
				>
					{engagementUpdateSuccessMessage}
				</Toast>
			</section>
			<section
				className="toastMessage"
				style={{display: errorMessage.length > 0 ? 'block' : 'none'}}
			>
				<Toast
					variant="error"
					onClose={() => {
						setErrorMessage(false);
					}}
				>
					{errorMessage}
				</Toast>
			</section>
			<section className="e-icon-wrapper mainResponsiveWrapper">
				<section className="pageheading">
					<h3 className="e-page-title">{labels.engagementdetails}</h3>
					{isLoading == true ?
					<Loader /> : 
					<a onClick={downloadEngagementValidationReport}>
							<span className="generatereport">{labels.generateValidationReport}</span>
					</a> }	
				</section>
			</section>
			<section className="desc-wrapper mainResponsiveWrapper">
				<section className="columns">
					<section className="column">
						<section className="title">
							<span>{labels.selectedengagements}</span>
							<span> : </span>
						</section>
						<section className="detail">
							<span className="engagementid">
								{props.engagementDetails?.engagementId}
							</span>
							<EllipsesControl
								content={props.engagementDetails?.engagementDescription}
								tooltip={props.engagementDetails?.engagementDescription}
								noOfLines={1}
							/>
						</section>
					</section>
					<section className="column">
						<section className="title">
							<span>{labels.workspace}</span>
							<span> : </span>
						</section>
						<section className="detail">
							<span className="engagementid">
								{props.engagementDetails?.workspaceId}
							</span>
							<EllipsesControl
								content={props.engagementDetails?.workspaceDescription}
								tooltip={props.engagementDetails?.workspaceDescription}
								noOfLines={1}
							/>
						</section>
					</section>
					<section className="column">
						<section className="title">
							<span>{labels.client}</span>
							<span> : </span>
						</section>
						<section className="detail">
							<span className="engagementid">
								{props.engagementDetails?.clientCode}
							</span>
							<EllipsesControl
								content={props.engagementDetails?.clientName}
								tooltip={props.engagementDetails?.clientName}
								noOfLines={1}
							/>
						</section>
					</section>
					<section className="column">
						<section className="title">
							<span>{labels.engagementcode}</span>
							<span> : </span>
						</section>
						<section className="detail">
							<span className="engagementid">
								{props.engagementDetails?.primaryEngagementCode}
							</span>
							<EllipsesControl
								content={props.engagementDetails?.primaryEngagementCodeName}
								tooltip={props.engagementDetails?.primaryEngagementCodeName}
								noOfLines={1}
							/>
						</section>
					</section>
				</section>
			</section>
			<Headerbody className="engagementpageview mainResponsiveWrapper">
				<section className="userlistwrapper">
					<EngagementHeader />
					<section id="tablebodycontainer" className=" userlist-body">
						<section className="userlistholder">
							<SelectedEngagementTableRow
								editPermission={props?.editPermission}
								retryArchivePermission={props?.retryArchivePermission}
								engagementStatusesDDOptions={props?.engagementStatusesDDOptions}
								engagementRowData={props?.engagementDetails}
								setErrorMessage={setErrorMessage}
							/>
						</section>
					</section>
				</section>
			</Headerbody>
		</Wrapper>
	);
}

const Wrapper = styled.section`
	margin-top: var(--px-50);
	overflow: visible;

	.userlistwrapper{
		.userlist-header {
			.userlistitem{
				white-space: nowrap ;
			}
		}
		.userlistitem{
			&.status{
				.react-select__menu-list{
					max-height: var(--px-160) ;
				}
			}
		}
	}

	.toastmessage {
		* {
			font-size: var(--px-14);
		}
	}

	//pagination

	.pagerholder {
		margin: 0 auto;
		width: 100%;
		position: absolute;
		bottom: var(--px-0);
	}

	.engagement-paging {
		justify-content: flex-end;
		padding: var(--px-10) 0;
		line-height: normal;
		min-width: auto !important;
		font-size: var(--px-14);
		background: var(--neutrals-00white);
		border-top: 1px solid var(--neutrals-200);
	}

	.e-MotifBreadcrumb {
		margin-top: var(--px-10);
		margin-bottom: var(--px-30);

		.e-breadcrumb a {
			font-size: var(--px-15);
		}
	}

	.e-icon-wrapper {
		.pageheading {
			display: flex;
			padding: var(--px-20) 0;
			svg {
				min-width: var(--px-25) !important;
				min-height: var(--px-25) !important;
				margin-right: var(--px-10);
			}

			a .generatereport{
				font-size: var(--px-14);
				font-weight: bold;
				cursor: pointer;
				color: var(--blue-600);
			}

			h3 {
				margin: 0;
				line-height: var(--px-25);
				margin-right: auto;
			}
		}
	}
	.desc-wrapper .column {
		float: left;
		width: 25%;
		display: inline-flex;
		font-size: var(--px-14);
		font-weight: normal;
		letter-spacing: 0;
		margin-bottom: var(--px-10);

		.title {
			margin-right: var(--px-10);
			display: inline-flex;
		}
		.detail {
			width: 65%;
			display: inline-flex;

			.engagementid {
				margin-right: var(--px-5);
				white-space: nowrap ;
			}
		}
	}
	/* Stops the float property from affecting content after the columns */
	.columns:after {
		content: '';
		display: table;
		clear: both;
	}

	.searches-wrapper {
		display: grid;
		grid-template-columns: repeat(5, 1fr) repeat(1, auto);
		grid-gap: 20px;
		margin-bottom: var(--px-30);

		> * {
			align-self: end;
		}

		.motif-typeahead-close-button {
			margin-top: var(--px-4);
		}

		.motif-input {
			height: var(--px-40);
		}

		.searchlink {
			display: flex;
		}

		.e-country-dd {
			border-bottom: solid var(--px-1);

			* {
				border: none !important;
			}

			.react-select__control {
				min-height: auto;

				.react-select__input {
					padding-top: 0 !important;
				}
			}

			.react-select__single-value {
				padding-top: var(--px-2);
			}
		}

		.e-search-btn {
			width: var(--px-100);
			height: var(--px-40);
			font-size: var(--px-14);
		}

		.e-clearsearch-btn {
			color: var(--blue-600);
			font-weight: bold;
			font-size: var(--px-12);
			cursor: pointer;
			align-self: end;
			line-height: var(--px-40);
			margin-left: var(--px-20);

			&:hover {
				text-decoration: none;
			}
		}
	}
`;

export default SelectedEngagementHeader;
