import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { SVGICONS } from '@ey/icons';
import Button from '@ey/motif-react-wrapper/Button';
import { Ellipse } from '@ey/canvascoreservices';
import { labels } from '../../../util/uiconstants';
import UserSearch from '../../Common/Search/UserSearch';
import Loader from '../../Common/Loaders/loadingindicator';
import Toast from '../../Common/Toast/Toast';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import TicketField from '../../Admin/common/ticketfield';


function AddUser(props) {
	const dispatch = useDispatch();

	const [refreshChildCompKey, setRefreshChildCompKey] = useState(0);
	const [showUserSeachValidations, setShowUserSearchValidations] = useState(0);
	const [isClearSearchText, setIsClearSearchText] = useState(true);
	const [isMultipleSearchUserAllowed, { }] = useState(true);
	const [isSearchDisable, setIsSearchDisable] = useState(false);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [addUserErrorMessage, setAddUserErrorMessage] = useState([]);
	const [currentRole, setCurrentRole] = useState();
	const [showRolesError, setShowRolesError] = useState(false);
	const [engagementRolesOptions, setEngagementRolesOptions] = useState([]);
	const showRolesDropDown = props?.showRolesDropDown ? true : false;
	const [ticketNumber, setTicketNumber] = useState('');
	const [isSubmitClicked, setIsSubmitClicked] = useState(false);

	useEffect(() => {
		if (props?.engagementRoles?.length > 0) {
			let options = [];
			props?.engagementRoles.forEach((role) => {
				options.push({
					value: role.roleId,
					label: role.roleName
				});
			});
			setEngagementRolesOptions(options);
		}
	}, []);

	const emailChecker = (email) => {
		if (email.includes("'")) {
			email = email.replaceAll("'", "''");
		}
		return email;
	};

	//Send to textbox change event
	const onUserSelectionChange = (user) => {
		var tempSentToItems = selectedUsers;
		let userItem = user && user.allProps ? user.allProps : null;
		if (userItem) {
			let email = emailChecker(userItem.email);
			let userSelectedFilter = tempSentToItems.filter(
				(ee) =>
					ee.emailAddress.trim().toLowerCase() == email.trim().toLowerCase()
			);
			if (userSelectedFilter.length === 0) {
				tempSentToItems.push({
					id: userItem.objectId,
					displayName: userItem.displayName,
					emailAddress: email,
					firstName: userItem.firstName,
					lastName: userItem.lastName
				});
				if (showRolesDropDown) {
					tempSentToItems[tempSentToItems.length - 1]['role'] = {};
					setShowRolesError(false);
				}
			}
			if (!isMultipleSearchUserAllowed) {
				setIsSearchDisable(true);
			}
			//clear user search textbox
			setIsClearSearchText(true);
		}
		setSelectedUsers(tempSentToItems);
		setRefreshChildCompKey((prevState) => prevState + 1);
		setIsSubmitClicked(false);
	};

	//Send to textbox clear event
	const onUserClear = () => {
		if (!isMultipleSearchUserAllowed) {
			if (!selectedUsers) setIsSearchDisable(true);
		}
	};

	const removeSelection = (user) => {
		var updatedSelection = selectedUsers.filter((usr) => usr.id !== user.id);
		setSelectedUsers(updatedSelection);
	};

	useEffect(() => {
		setAddUserErrorMessage(props?.addUserErrorMessage);
	}, [props?.addUserErrorMessage]);

	const closeToast = (index) => {
		setAddUserErrorMessage([
			...addUserErrorMessage.slice(0, index),
			...addUserErrorMessage.slice(index + 1, addUserErrorMessage.length)
		]);
	};

	useEffect(() => {
		setCurrentRole();
	}, [currentRole]);

	const onSelectRole = (role, index) => {
		let users = selectedUsers;
		users[index].role = role;
		setSelectedUsers(users);
		setCurrentRole(role);
	};

	const saveUsers = () => {
		if (ticketNumber.trim().length > 0) {
			if (showRolesDropDown) {
				for (let user of selectedUsers) {
					if (user.role.value === undefined) {
						setShowRolesError(true);
						return;
					}
				}
			}
			props.onSaveUsersClick(selectedUsers, ticketNumber.trim());
		}
		else {
			setIsSubmitClicked(true);
		}
	};

	function handlerOnTicketEntered(e) {
		setTicketNumber(e.target.value);
	}

	return (
		<AddUserWrapper
			className={'adduser ' + (showRolesDropDown ? 'role' : 'withoutrole')}
		>
			<section
				className="toastMessage"
				style={{
					display:
						addUserErrorMessage && addUserErrorMessage?.length > 0
							? 'block'
							: 'none'
				}}
			>
				{addUserErrorMessage?.map((errorMessage, index) => (
					<Toast
						variant="error"
						key={index}
						onClose={() => {
							closeToast(index);
						}}
					>
						{errorMessage}
					</Toast>
				))}
			</section>
			{props?.addUserLoading ? (
				<Loader show fullscreen view="blockView" styleName="loadingstyle" />
			) : (
				<section>
					<section className="userSearch customScrollbar">
						<section className="wrapper">
							<h5 className="headerText">{props.addUserDescription}</h5>
							<section className="adduserlist">
								<UserSearch
									key={refreshChildCompKey}
									showValidations={showUserSeachValidations}
									onUserClear={onUserClear}
									onUserSelectionChange={onUserSelectionChange}
									lookupType={props.userLookupType}
									enableClearText={isClearSearchText}
									enableUserSearch={isSearchDisable}
									isHideClearAllIconButton={true}
								/>
								{selectedUsers?.length > 0 ? (
									<>
										<section className="result">
											<h5>{`${labels.usersToBeAdded} (${selectedUsers.length})`}</h5>
											<section className="userlistrecord">
												{selectedUsers.map((user, index) => {
													return (
														<ul key={'selecteduser' + index} className="userlist">
															<li>
																<span className="nameemail">
																	<Ellipse
																		content={
																			user.displayName +
																			' ' +
																			'(' +
																			user.emailAddress +
																			')'
																		}
																		tooltip={
																			user.displayName +
																			' ' +
																			'(' +
																			user.emailAddress +
																			')'
																		}
																		displayId={''}
																		noOfLines={1}
																		ellipsisClass=""
																	/>
																</span>
																{showRolesDropDown && (
																	<span className="role">
																		<SelectDropdown
																			formLabel={labels.engagementRoles}
																			value={
																				user.role.value === undefined
																					? ''
																					: user.role
																			}
																			options={engagementRolesOptions}
																			onChange={(role) =>
																				onSelectRole(role, index)
																			}
																			errorMessage={''}
																			showErrorMessage={
																				showRolesError &&
																				user.role.value === undefined
																			}
																		></SelectDropdown>
																	</span>
																)}
																<span
																	className="remove"
																	onClick={() => {
																		removeSelection(user);
																	}}
																>
																	<SVGICONS
																		role="button"
																		styleName="medium"
																		name="close-outline"
																		hoverText="Remove user"
																	/>
																</span>
															</li>
														</ul>
													);
												})}
											</section>
										</section>
									</>
								) : null}
								<TicketField
									ticketNumber={ticketNumber}
									onTicketChanged={handlerOnTicketEntered}
									showValidation={isSubmitClicked}
								/>
							</section>
						</section>
					</section>

					<section className="modal-footer">
						<Button
							type="button"
							variant="primary-alt"
							disabled={(selectedUsers.length <= 0 || ticketNumber.trim().length <= 0) ? true : false}
							onClick={saveUsers}
						>
							{labels.add}
						</Button>
						<Button
							type="button"
							variant="secondary"
							onClick={() => {
								props.onNewUserModalClose(false);
							}}
						>
							{labels.cancel}
						</Button>
					</section>
				</section>
			)}
		</AddUserWrapper>
	);
}

const AddUserWrapper = styled.section`

	.loadingstyle {
		height: calc(100vh - var(--px-520)) !important;
	}

	&.withoutrole {
		.nameemail {
			width: 90% !important;
		}
		
		.userlistrecord{
			max-height: var(--px-220);
			overflow-y: auto;
			&::-webkit-scrollbar {
				width: var(--px-6);
			}
		
			&::-webkit-scrollbar-track {
				background: #E7E7EA;
				width: var(--px-6);
			}
		
			&::-webkit-scrollbar-thumb {
				background: #c4c4cd;
				width: var(--px-6);
				cursor: pointer;
			}
		
			&::-webkit-scrollbar-thumb:hover {
				background: #747480;
			}
		}
	}

	&.role{
		.nameemail{
			line-height: var(--px-50);
		}

		.remove{
			line-height: var(--px-45);
		}
	}

	.motif-loader {
		height: var(--px-485) !important;
	}

	.userSearch {
		display: flex;
		width: 100%;
		font-size: var(--px-14);
		padding-bottom: var(--px-20);
		min-height: var(--px-270);
		max-height: calc(100vh - var(--px-260));

		.adduserlist {
			width: 100%;
			display: block;
		}

		.react-select__menu-list {
			height: auto;
			max-height: var(--px-190);

			.react-select__option {
				section {
					section:first-child {
						font-size: var(--px-14) !important;
						line-height: var(--px-22);
					}
					section:last-child {
						font-size: var(--px-15) !important;
						line-height: var(--px-22);
					}
				}
			}
		}

		.wrapper {
			padding: var(--px-16) var(--px-20);
			display: inline-block;
			width: 100%;

			.headerText {
				font-size: var(--px-14);
				margin: 0;
				padding: 0 0 var(--px-10) 0;
			}

			//ticketstart
			.ticketdetail{
				margin: 0 0 var(--px-80) 0;
				h5{
					font-size: var(--px-14);
					font-weight: normal;
					color: var(--neutrals-900);
					border: 0!important;
				}

				.motif-form-field{
					width: var(--px-290);
				}

				.motif-form-field:not(.motif-form-field-has-value),
				.motif-form-field:not(.motif-form-field-focused){
					.motif-label{
						margin-top: var(--px-6);
					}
				}
		
				.motif-form-field-has-value,
				.motif-form-field-focused{
					.motif-label{
						margin-top: var(--px-2)!important;
					}
				}
				
				
				.motif-input {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			//ticketend

			.result {
				padding: 0 0 var(--px-10) 0;
				width: 100%;
				display: inline-block;

				h5 {
					font-size: var(--px-16);
					padding-bottom: var(--px-10);
					margin-bottom: var(--px-5);
					border-bottom: var(--px-1) solid var(--neutrals-200);
				}

				.userlist{ 
					width: 100%;     
					display: flex;
					border-bottom: var(--px-1) solid var(--neutrals-200);
				}

				li {
					width: 100%;
					display: block;
					margin-bottom: var(--px-2);
					list-style-type: none;

					.nameemail {
						width: 66%;
						display: inline-block;
						line-height: var(--px-40);

						.ellipses {
							width: 95%;
						}
					}

					.role {
						width: 26%;
						display: inline-block;
						vertical-align: top;
						position: relative;

						.react-select-container .react-select__control{
							min-height: auto;
						}

						.react-select__single-value{
							margin-left: var(--px-1);
						}

						.show {
							font-size: var(--px-12);
							color: var(--red-600);
							display: inline !important;
							position: absolute;
							left: calc(var(--px-20) * -1);
							top: var(--px-6);
							svg {
								width: var(--px-15);
								height: var(--px-15);
								margin-right: var(--px-2);
								vertical-align: text-bottom;
								fill: var(--red-600);
							}
						}

						.react-select-container .react-select__menu {
							height: var(--px-120);
							display: block;
							margin-bottom: var(--px-20);
							padding-bottom: var(--px-20);
							.react-select__menu-list {
								height: var(--px-120);
								margin-bottom: var(--px-20);
								padding-bottom: var(--px-20);
							}
						}
					}

					.remove {
						padding: 0 var(--px-15);
						text-align: right;
						display: inline-block;
						line-height: var(--px-40);
						width: 8%;
						svg {
							cursor: pointer;
							vertical-align: middle;
						}
					}
				}
			}
		}

		.user-selectbox .select-group {
			width: 100%;
		}
	}
	.modal-footer {
		display: inline-flex;
		width: 100%;

		button {
			font-size: var(--px-14);
			padding: var(--px-12) var(--px-15);
			margin-right: var(--px-10);
			line-height: normal;
		}
	}
`;

export default AddUser;
