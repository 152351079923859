/* eslint-disable prettier */
import {
	Addon
} from 'react-bootstrap/lib/InputGroup';

/**
 * Created by calhosh on 4/14/2017.
* KO resource file
 */
export const labels = {
    defaultDropdown: "드롭다운 메뉴",
    status: "상태",
    language: "언어",
    channel: "채널",
    header: "머리글",
    headerName: "머리글 이름",
    sectionCustomLabelLabel: "사용자지정 레이블",
    sectionNamePlaceHolder: "섹션 제목 입력",
    showRelatedObjectLabel: "연결된 Object 표시",
    errorBanner: "{0}개 오류",
    engagements: "Engagement",
    selectedengagements: "Engagement",
    addEngagement: "Engagement 추가",
    archives: "Archive",
    userInitialsTitle: "사용자 옵션",
    clickForOptions: "옵션을 더 보려면 클릭하십시오",
    comingDue: "만료 도래",
    whatsNew: "신규",
    auditMetrics: "감사 지표",
    id: "ID",
    archived: "Archive됨",
    myarchives: "내 Archive",
    allarchives: "모든 Archive",
    logout: "로그아웃",
    norecords: "기록을 찾을 수 없습니다",
    nomatches: "일치 항목을 찾을 수 없습니다",
    notemplates: "No templates are available for the selected Area/Region",
    pleasetryagain: "다시 시도하십시오",
    firstpage: "첫 페이지",
    prevpage: "이전 페이지",
    nextpage: "다음 페이지",
    lastpage: "마지막 페이지",
    allengagements: "모든 Engagement",
    searchPlaceholder: "검색",
    searchEngagementsPlaceholder: "Engagement 검색",
    clearSearch: "검색 지우기",
    searchTextPlaceHolder: "검색",
    show: "표시",
    manageaccess: "접근 관리",
    restore: "복원",
    rollforward: "Roll forward",
    viewaccesslist: "접근 목록 보기",
    teammembers: "팀 멤버",
    name: "이름",
    initials: "이니셜",
    role: "역할",
    usersettings: "사용자 설정",
    usersettingsnewengagements: "설정은 새로 생성된 engagement에 적용됩니다",
    usersettingsallengagements: "설정은 모든 workspace 및 engagement에 적용됩니다",
    enterfirstname: "표시 이름",
    enterlastname: "표시 성",
    firstname: "이름",
    lastname: "성",
    enter: "입력",
    select: "선택...",
    email: "이메일 주소",
    contact: "전화번호",
    accesslist: "접근 목록",
    engagementsettings: "Engagement 설정",
    displayname: "표시 이름",
    signoffinitials: "Sign-off 이니셜",
    primaryoffice: "Primary Office",
    roles: "역할",
    phonenumber: "전화번호",
    globalapplicationsettings: "Global Application 설정",
    displaylanguage: "표시 언어",
    preferreddateformat: "기본 날짜 형식",
    preferrednumberformat: "기본 숫자 형식",
    save: "저장",
    cancel: "취소",
    isRequired: "{0}은(는) 필수입니다",
    arcretrymessage: "Archive 프로세스에서 오류가 발생했습니다. 현재 기록된 archive일은 {0}입니다. EY Canvas에서 자동으로 archive를 재시도하므로, 헬프 데스크에 연락하기 전에 archive 제출 시간부터 15시간을 기다려 자동 복구가 완료되는지 확인하십시오. 헬프 데스크에서 engagement를 재활성화하지 않고 해결할 수 있다면, archive일은 계속 {0}로 남습니다. 헬프 데스크에서 engagement를 재활성화하여 팀이 수정 조치를 취하게 해야 한다면, archive일은 팀이 archive를 다시 제출한 날로 재설정됩니다. 이로 인해 archive가 지연된다면, 팀은 지역 archive 정책에 따라 헬프 데스크 티켓 번호, 문제, 해결하기 위해 취한 조치를 engagement에 문서화해야 합니다.",
    ArchiveRetryLink: "Archive 재시도",
    workspacelist: "Workspace 목록",
    engagementslist: "Engagement 목록",
    accessrequested: "접근 요청됨",
    noaccess: "접근 없음",
    accessrejected: "거절된 접근",
    requestaccess: "접근 요청",
    allarchiveinitialmsg: "시작하려면 고객 이름, Workspace 이름 또는 Engagement 이름을 입력하십시오",
    noengagementsforusermessage: "귀하에게 활성 engagement가 없습니다. 시작하려면 Engagement 생성을 클릭하십시오.",
    noengagementstoimportto: "불러올 engagement가 없습니다",
    favorites: "즐겨찾기",
    portfolioview: "모든 engagements v2",
    reviewengagement: "리뷰 engagement",
    noreviewengagementsmessage: "귀하에게 리뷰 engagement가 없습니다",
    noreviewengagementsmessagecontact: "귀하에게 리뷰 engagement가 없습니다. 접근권한을 요청하려면 engagement 팀 멤버에게 문의하십시오.",
    nofavoriteengagementsmsg1: "귀하에게 즐겨찾는 engagement가 없습니다",
    nofavoriteengagementsmsg2: "이 보기에 engagement를 추가하려면 모든 engagement로 가십시오",
    nofavoriteengagementsmsg3: "그리고 engagement 카드에서 즐겨찾기 아이콘을 클릭하십시오",
    markasfavorite: "즐겨찾기 표시",
    unmarkfromfavorites: "즐겨찾기에서 제거",
    selectworkspacenoclientworkspaceexists: "귀하에게 기존 workspace가 없습니다. 뒤로 가기를 클릭하여 단계 1에서 신규 workspace를 클릭하십시오.",
    importpkgselectworkspacenoclientworkspaceexists: "귀하에게 기존 workspace가 없습니다. 뒤로를 클릭하여 이전 단계에서 '신규 workspace 생성'을 선택하십시오.",
    expirydatelabel: "이 engagement 삭제까지 남은 날짜",
    expirydatetodaytomorrowlabel: "이 engagement 삭제일",
    /*Reporting Hub*/
    reportingHub: "보고",
    reportingHubTitle: "EY Canvas 보고",
    selectEngagement: "보고서를 보려면 engagement를 선택하십시오",
    connectVPN: "EY 네트워크 또는 EY Remote Connect에 연결됐을 때에만 보고서에 접근할 수 있습니다",
    reportsNotLoaded: "보고서를 로드할 수 없습니다. 페이지를 새로 고침하여 다시 시도하십시오. 문제가 지속되면 헬프 데스크에 문의하십시오.",
    searchByEngagementNameOrClientId: "Engagement를 선택하십시오",
    dataExportLabel: "데이터 내보내기",
    summaryScreensLabel: "요약 화면",
    projectManagementLabel: "프로젝트 관리",
    engagement: "Engagement 이름",
    reportingNotAvailable: "선택한 위치에서는 보고를 사용할 수 없습니다. 추가 세부정보는 지역 EY Canvas 챔피언에게 문의하십시오.",
    client: "고객",
    workspace: "Workspace",
    serviceline: "서비스라인",
    subserviceline: "서브서비스라인",
    engagementstartdate: "회계연도말일",
    engagementenddate: "보고기간말일",
    deleteafter: "Delete after",
    auditorsreportdate: "보고서일",
    createengagementquestionlabel: "Engagement 생성",
    choosenewexistingquestionheader: "신규 또는 기존 workspace에 engagement를 생성합니까?",
    createengagementheader: "Engagement 생성",
    restoreengagementheader: "Engagement 복원",
    createworkspacestepheader: "신규 workspace 생성",
    selectexistingworkspacestepheader: "기존 workspace 선택",
    workspacenotelibilefornewengagement: "신규 engagement에 적합하지 않은 workspace",
    choosenewexistingstepheader: "신규 또는 기존 workspace",
    editengagementwizheader: "Engagement 편집",
    removeengagementwizheader: "Engagement 제거",
    editworkspacewizheader: "Workspace 편집",
    editworkspacestepfooter: " '저장 & 닫기'를 클릭하여 workspace 변경사항을 저장하거나 '저장 & 계속'을 클릭하여 engagement를 편집하십시오",
    editwizheader: "편집",
    newworkspace: "신규 workspace",
    existingworkspace: "기존 workspace",
    back: "뒤로",
    continue: "계속",
    saveclose: "저장 & 닫기",
    savecontinue: "저장 & 계속",
    finish: "마침",
    step01: "단계 01",
    step02: "단계 02",
    step03: "단계 03",
    step04: "단계 04",
    step05: "단계 05",
    engagementname: "Engagement 이름",
    confirmdecline: "거부 확인. 이 작업은 실행 취소할 수 없습니다.",
    confirmdeclinevalidation: "거부를 확인하십시오",
    responderror: "이 초대에 이미 응답했습니다. 페이지를 새로 고침하여 다시 시도하십시오.",
    engagementcode: "Engagement 코드",
    engagementcodename: "Engagement 코드 이름",
    selectEngagementHeader: "기존 workspace 선택",

    selectworkspaceclient: "고객 선택",
    selectclient: "관련 workspace를 보려면 고객을 선택하십시오",
    createnewworkspace: "신규 workspace 생성",
    editworkspace: "Workspace 편집",
    clientcode: "고객 코드",
    clientname: "고객 이름",
    invalidclientcode: "유효하지 않은 고객 코드",
    workspacename: "Workspace 이름",
    location: "위치",
    workspacecodenameplaceholder: "고객 이름을 찾으려면 고객 코드를 입력하십시오",
    engagementcodenameplaceholder: "코드 이름을 찾으려면 engagement 코드를 입력하십시오",
    isprimary: "Primary",
    addengagementcode: "Engagement 코드 추가",
    engagementnamerequired: "Engagement 이름은 필수입니다",
    primaryofficerequired: "Primary office는 필수입니다",
    languagerequired: "언어는 필수입니다",
    servicelinerequired: "서비스라인은 필수입니다",
    subservicelinerequired: "서브서비스라인은 필수입니다",
    engagementcodevalidationmessage: "Engagement 코드가 고객 코드와 맞지 않습니다",
    engagementcoderequired: "Engagement 코드는 필수입니다",
    yearenddate: "회계연도말일",
    periodenddate: "보고기간말일",
    reportdate: "보고서일",
    complete: "완료",
    end: "끝",
    started: "시작",
    notstarted: "시작되지 않음",
    inprogress: "진행 중",
    inreview: "리뷰 중",
    taskstatus: "Task 상태",
    confirmlabel: "확인",
    submitlabel: "제출",
    editengagementquestionlabel: "Engagement 편집",
    removeengagementquestionlabel: "Engagement 제거",
    removeengaementconfirmationmessage1: "Engagement 목록에서 다음 engagement를 제거하시겠습니까? Engagement를 제거하면 더 이상 접근할 수 없습니다.",
    removeengagementerrormessage: "마지막 활동 사용자이므로 workspace에서 engagement를 제거할 수 없습니다. 다른 팀 멤버를 추가하거나 engagement를 삭제하십시오.",
    deleteengagement: "Engagement 삭제",
    deletreviewcopyoption: "삭제",
    deleteengagementconfirmationmessage: "다음 engagement 삭제 요청을 제출하시겠습니까?",
    deleteengagementconfirmationmessage1: "해지된 engagement이거나 보고서를 발행하지 않는 경우, engagement 팀은 archive가 필요한지 확인하기 위해 심리실에 자문을 구해야 합니다.",
    deleteengagementconfirmationmessageoneuser: "다음 engagement를 삭제하시겠습니까?",
    deleteengagementconfirmationmessageoneuser1: "DOC+ARC 3.1의 archive 요구사항을",
    deleteengagementconfirmationmessageoneuser2: 'DOC+ARC 3.1',
    deleteengagementconfirmationmessageoneuser3: "삭제 전에 고려해야 합니다. 예를 들어, 해지된 engagement이거나 보고서를 발행하지 않는 경우, archive가 필요한지 확인하기 위해 심리실에 자문을 구해야 합니다.",
    deleteengagementmarkeddelete: "Engagement가 이미 삭제 표시됐습니다",
    deleteengagementoption: "삭제",
    deleteengagementreasonlabel: "삭제 사유 입력",
    deleteengagementreasonquestion: "이 engagement를 삭제하는 사유는 무엇입니까?",
    deleteengagementreasonrequired: "근거는 필수입니다. 옵션을 선택하거나 위에 사유를 입력하십시오.",
    deleteengagementcommentreasonrequired: "근거는 필수입니다. 위에서 사유를 선택하십시오.",
    deleteengagementcommentrequiredlabel: "삭제 사유를 입력하십시오",
    deletereasontrainingengagementlabel: "교육용 engagement",
    deletereasonrestoredorcopiedlabel: "참조 전용으로 복원하거나 복사한 engagement",
    deletereasoncreatedbymistakelabel: "실수로 생성됨",
    deletereasonotherlabel: "기타",
    deletereasonterminedbyclientlabel: 'Terminated engagement where no work was performed. If work was performed in this engagement, then it should be archived.',
    markeddeleteconfirm: "삭제 확인",
    deleterequestbannertext1: "삭제 요청자",
    deleterequestbannertext2: "전",
    markeddeleteconfirmmessage: "다음 engagement를 삭제하시겠습니까?",
    markeddeleteconfirmmessage1: "삭제를 확인하면 이 작업은 실행 취소할 수 없습니다",
    markeddeletereasonlabel: "삭제 사유",
    engagementlistplaceholder: "Engagement를 선택하십시오",
    engagementrequired: "Engagement는 필수입니다",
    restoreconfirmationstepheader: "확인",
    restoreconfirmationheader: "Engagement를 복원하시겠습니까?",
    selectarchiveheader: "Archive 파일 선택",
    restoreengagementstepheader: "Engagement 복원",
    chooserollfwdhdr: "신규 또는 기존 workspace로 Roll forward",
    rollfwdstephdrext: "기존 Workspace로 Roll Forward",
    rollfwdstephdrnew: "신규 Workspace로 Roll Forward",
    rollfwdhdrqstn: "신규 또는 기존 workspace로 engagement를 Roll forward합니까?",
    rollforwardengagementstepheader: "Engagement Roll forward",
    genericerror1: "문제가 발생했습니다. 다시 시도하거나 지원팀에 문의하십시오.",
    restoreengagementmaxcopies: "Engagement 복원본 수가 최대치({0})를 초과했습니다. 기존 복원본에 접근을 요청하십시오.",
    restoreengagementengcopies: "사용 가능한 이 engagement 복원본은 {0}개입니다:",
    restoreengagementengcopy: "사용 가능한 이 engagement 복원본은 {0}개입니다:",
    restoreengagementengnocopies: "사용 가능한 이 engagement 복원본이 없습니다",
    globalexceptionrestoreengagementengcopies: "사용 가능한 이 engagement 복원본은 {0}개입니다. 글로벌 예외로 복원하려면 위 상자에 체크합니다.",
    globalexception: "글로벌 예외",
    globalexceptioncheckerror: "계속하려면 글로벌 예외 옵션에 체크하십시오",
    restoreengagementrestoredon: "복원됨",
    restoreengagementusers: "사용자",
    restoreengagementuser: "사용자",
    at: "at",
    arcfileuserunavailable: "불가능한 사용자",

    restorehdrqstn: "Engagement를 신규 또는 기존 workspace로 복원하시겠습니까?",
    chooserestorehdr: "신규 또는 기존 workspace로 복원",
    restorestephdrext: "기존 workspace로 복원",
    restorestephdrnew: "신규 workspace로 복원",

    close: "닫기",
    confirm: "확인",
    settings: "설정",
    expand: "확장",
    collapse: "축소",
    contextMenuEdit: "편집",
    contextMenuEditEngagement: "Engagement 편집",
    contextMenuRemoveEngagement: "Engagement 제거",

    contextMenuDeleteEngagement: "Engagement 삭제",
    contextMenuEditWorkspace: "Workspace 편집",
    contextMenuDelete: "삭제",
    contextMenuOverrideArchiveDate: "Archive 만료일 재지정",
    na: "N/A",
    archivesubmittedby: "제출한 사람",
    archivedateandtime: "날짜 및 시간",
    notifications: "알림",
    nonotificationmsg: "알림이 없습니다",
    notifcenter: "알림 센터",
    notifitemspendingact: "보류 중인 항목",
    accept: "수락",
    reject: "거절",
    acceptall: "모두 수락",
    rejectall: "모두 거절",
    notifEngInvite: "Engagement 초대",
    notifengaccesreq: "Engagement 접근 요청",
    keyevents: "주요 이벤트",
    milestones: "Milestone",
    importselecttemplateheader: "사용할 템플릿을 선택하십시오",
    importtemplateheader: "불러올 area/region을 선택하십시오",
    import: "불러오기",
    importregionstepheader: "Area/Region 선택",
    importtemplatestepheader: "템플릿 선택",
    importtemplatemsg: "계속할 Area/Region 선택",
    importselecttemplatemsg: "계속할 템플릿 선택",
    importengagementheader: "템플릿 불러오기",
    importhdrqstn: "신규 workspace 또는 기존 workspace로 불러오기",
    importstephdrext: "기존 workspace로 불러오기",
    importstephdrnew: "신규 workspace로 불러오기",
    chooseimporthdr: "신규 또는 기존 workspace로 불러오기",
    selectexistingworkspacemsg: "계속할 workpace 선택",
    selectaclienttocontinuemsg: "계속하려면 고객을 선택하십시오",
    selectengagementmsg: "데이터 패키지를 수락하려면 engagement를 선택하십시오",
    selectgaengagementmsg: "링크 요청을 수락하려면 engagement를 선택해야 합니다",
    arearegion: "Area/Region",
    importdatapackagequestionlabel: "무엇을 하고 싶습니까?",
    sentby: "발송자",
    on: "on",
    by: "by",
    view: "보기",
    sourceengagement: "원천 engagement",
    package: "패키지",
    decline: "거부",
    delegate: "위임",
    chooseaction: "작업 선택",
    selectworkspace: "Workspace를 하나 선택하십시오",
    declinepackage: "요청 거부",
    delegatepackage: "패키지 위임",
    selectengagement: "Engagement 선택",
    datapackagerequest: "데이터 패키지 요청",
    respond: "응답하려면 여기를 클릭하십시오",
    deletionrequestfor: "삭제 요청 수신됨",
    invalidengagementcode: "유효하지 않은 Engagement 코드",
    noNotifications: "사용 가능한 알림이 없습니다",
    bellIconTitle: "알림 센터",
    restoredengagement: "복원된 engagement",
    profiledeliveryinprogress: "프로필 전달 진행 중",
    completeprofileheader: "프로필 완료",
    completeindependenceheader: "독립성 완료",
    independencecompletedheader: "독립성 완료됨 ",
    retry: "재시도",
    errorDate: "오류",
    invitationDate: "초대일",
    engCreationFailedWarning: "Engagement 생성 실패. 페이지를 새로 고침하여 다시 시도하십시오. 문제가 지속되면 헬프 데스크에 문의하십시오.",
    deletedEngagementWarning: "Engagement가 사용 중이고 오류로 삭제된 경우, 즉시 헬프 데스크에 문의하십시오.",
    searchEngagementWarning: "추가 조치를 취하려면 이 engagement를 검색하십시오",
    archiveOverrideWarning: "선택한 archive 재지정 기간을 ARC에서 허용하지 않습니다. Archive 만료일이 지역 기본값으로 환원됐습니다.",
    archiveOverrideInProgress: "ARC 승인 보류 중인 archive 재지정",
    archiveOverrideAccepted: "Archive 재지정이 완료됐습니다",
    archiveOverrideRejected: "선택한 archive 재지정 기간을 ARC에서 허용하지 않습니다. Archive 만료일이 지역 기본값으로 환원됐습니다.",
    scopeName: "범위 이름",
    shortName: "이름",
    invited: "초대됨",
    invitedOn: "초대일",
    instructions: "지침",
    updates: "업데이트",
    viewinstructions: "지침 보기",
    viewinstructionsupdates: "업데이트 보기",
    more: "기타",
    addmembers: "멤버 추가",
    statusChangeDate: "상태 변경됨",
    deletedBy: "삭제한 사람",
    deletedDate: "삭제됨",
    deactivatedBy: "비활성화한 사람",
    deactivatedDate: "비활성화됨",
    requestUserName: "요청한 사람",
    requestDate: "요청됨",
    archiveDate: "Archive됨",
    receivedFrom: "발신자",
    received: "수신됨",
    clear: "처리",
    clearAll: "모두 처리",
    type: "유형",
    engagementhasbeendeleted: "선택한 engagement를 다른 사용자가 삭제했습니다. 페이지를 새로 고침하여 다시 시도하십시오.",
	independenceOnlyRejectInvite: {
        engagementIsNotAvailable: "Engagement를 사용할 수 없습니다. 페이지를 새로 고침하여 다시 시도하십시오. 문제가 지속되면, 헬프 데스크에 문의하십시오.",
        failedToRejectInvite: "초대를 거절하지 못했습니다. 페이지를 새로 고침하여 다시 시도하십시오. 문제가 지속되면, 헬프 데스크에 문의하십시오.",
        succesfulRejection: "초대가 거절됐습니다",
        loadingLabel: "초대 거절 중…",
	},
    manageTeam: "팀 관리",
    childinprogress: "Engagement 설정 진행 중",
    // SeeMore component
    showMore: "더 표시",
    showLess: "덜 표시",
    showMoreEllipsis: "더 표시…",
    showLessEllipsis: "덜 표시…",
    accepted: "수락됨",
    acceptedOn: "수락일:",
    rejected: "거절됨",
    deniedBy: "거부한 사람",
    engagementstatusrestored: "복원됨",
    engagementstatusmarkedfordeletion: "삭제 표시됨",
    delegaterequired: "위임 필요",
    username: "사용자 이름",
    noresults: "결과를 찾을 수 없습니다. 다시 시도하십시오.",
    importdatapackageconcurrencymessage: "복사 요청 상태가 변경됐습니다. 페이지를 새로 고침하여 다시 시도하십시오.",
    selectdelegateuser: "위임 선택",
    eyusersearchplaceholder: "검색할 이름 또는 이메일 입력",
    optional: "(선택 사항)",
    engagementAlerts: "Engagement 알림",
    engagementMetrics: "Engagement 지표",
    overdue: "지연",
    /*day labels*/
    mon: "월",
    tues: "화",
    wed: "수",
    thurs: "목",
    fri: "금",
    sat: "토",
    sun: "일",
    canvas: "EY Canvas",
    overrideArchiveDateModalHeader: "Archive 만료일 재지정",
    overrideArchiveDateModalMessage: "Archive 만료일을 재지정하시겠습니까?",
    overrideArchiveDateModalComboPlaceholder: "Archive 만료일 재지정",
    createdOn: "생성일",
    expiresOn: "만료일",
    archiveDeadlineDate: "Archive 만료일",
    defaultOverriden: "기본값 재지정됨",
    selectexistingworkspace: "기존 workspace 선택",
    importtonewworkspace: "신규 workspace로 불러오기",
    groupaudit: "그룹감사 링크 요청",
    gaacceptdeclinedelegatequestionlabel: "이 링크 요청을 어떻게 하시겠습니까?",
    gaacceptselectengagementquestionlabel: "링크할 engagement 선택",
    gadelegatequestionlabel: "그룹감사 링크 요청 위임",
    gadeclinequestionlabel: "그룹감사 링크 요청 거부",
    garegioncomponent: "지역/부문",
    gascope: "범위",
    gamxTransfer: "GAMx에서 이전",
    closeNotificationCenter: "알림 센터 닫기",
    contextMenucrp: "검토 복사본 생성",
    workingoffline: "오프라인 작업",
    trainingoffline: "오프라인 작업 (교육)",
    training: "교육",
    crpdescription: "외부 검토용 engagement 복사본 생성",
    crpeditdescritpion: "Engagement 이름이나 만료일을 편집합니다. 만료된 engagement는 자동으로 삭제됩니다.",
    expiry: "만기",
    enteremail: "이메일 주소 입력",
    create: "생성",
    metricsPlaceholderText: "선택한 기간에 사용 가능한 데이터가 없습니다",
    deleteReviewCopy: "검토 복사본 삭제",
    deleteReviewCopyMsg: "이 검토 복사본을 삭제하시겠습니까? 삭제 후에는 engagement를 더 이상 사용할 수 없고 복원할 수 없습니다.",
    crpNotAvailable: "지금 Canvas External Access Portal을 사용할 수 없으므로 이 engagement에 대한 검토 복사본을 생성할 수 없습니다. 세부 정보는 헬프 데스크에 문의하십시오.",
    externalmember: "외부 멤버",
    internalmember: "내부 멤버",
    add: "추가",
    crpEditMembers: "편집",
    invalidEmail: "유효한 이메일 주소 입력",
    emailNotFound: "이메일 주소를 찾을 수 없습니다",
    dupExternalUser: "외부 멤버가 이미 추가됐습니다",
    noExternalUser: "외부 멤버가 추가되지 않았습니다",
    expiresIn: "만기까지",
    days: "일",
    today: "오늘",
    tomorrow: "내일",
    reviewengagementname: "검토 engagement 이름",
    enternameoremail: "이름이나 이메일 주소 입력",
    options: "옵션",
    userNotFound: "사용자를 찾을 수 없습니다",
    remove: "제거",
    crpEditHeader: "검토 복사본 편집",
    globalexceptionrestore: "글로벌 예외 복원",
    expired: "만료됨",
    softwareUpdate: "소프트웨어 업데이트",
    updateNow: "지금 업데이트",
    updateMsg: "EY Canvas 소프트웨어 업데이트가 가능합니다. 업데이트를 다운로드하여 설치하려면, 지금 업데이트를 선택하십시오. 페이지가 새로 고쳐집니다",
    preferredlandingpageview: "기본 랜딩 페이지 보기",
    preferredlandingpagefilter: "기본 랜딩 페이지 필터",
    engagementlist: "Engagement 목록",
    workspacelistusersettings: "Workspace 목록",
    engagementsclientstoload: "애플리케이션에 접속할 때마다 로드할 engagement/고객 수",
    favoriteengagements: "즐겨찾는 engagement",
    preferredEngagementDashboard: "Engagement 대시보드 설정",
    preferredEngagementDashboardTeamMembers: "팀 멤버",
    preferredEngagementDashboardExecutives: "Executive",
    linkedHelixProjects: "EY Helix로 링크됨",
    loadingLabel: "로드 중…",
    loadingErrorText: "일정을 로드하는 동안 오류가 발생했습니다. 페이지를 새로 고침하여 다시 시도하십시오.",
    selectYear: "연도 선택 (필수)",
    clientNameOrId: "고객 이름 또는 ID",
    engagementNameOrId: "Engagement 이름 또는 ID",
    engagementCodeOrCodeName: "Engagement 코드 또는 코드 이름",
    searchArchives: "검색",
    clearSearchArchives: "검색 처리",
    searchArchivesMessage: "시작하려면 Engagement를 archive한 연도를 선택하십시오. 그 후 아래 표시된 옵션으로 검색하십시오:",
    titleUpdateProfile: "정보",
    msgUpdateProfileLn1: "Engagement 언어를 업데이트했습니다. 이 언어로 내용을 수신하려면, Engagement 내용 업데이트로 이동하여 글로벌 내용 업데이트를 선택하십시오.",
    sessionTimerMsg1: "활동이 없기 때문에 현재 작업 세션이 곧 만료됩니다.",
    sessionTimerMsg2: "보안을 위해, 30분 동안 활동이 없으면 EY Canvas 세션이 자동으로 종료됩니다.",
    sessionTimerMsg3: "현재 세션은 {0}분 후에 만료됩니다.",
    sessionTimerMsg4: "로그인 상태를 유지하려면, 다음을 클릭하십시오.",
    sessionTimerHeader: "보호를 위한 자동 로그아웃",
    theme: "테마",
    light: "라이트",
    dark: "다크",
    chooseDisplayTheme: "기본 표시 테마를 선택하십시오",
    searchByCountry: "국가 선택",
    primaryEngagementLabel: "Primary engagement",
    componentEngagementLabel: "부문 engagement",
    regionalEngagementLabel: "지역 engagement",
    linkedLabel: "링크됨",
    eyCanvasEconomicsTooltip: "EY Canvas Economics",
    eyOversightTooltip: "EY Canvas Oversight",
    eyPrivacyNoticeTooltip: "개인정보보호 고지",
    eyTermsOfServiceTooltip: "서비스 약관",
    eyGeneralHelp: "일반 도움말",

    preferrednegativenumberformat: "기본 음수 형식",

    protectedEngagementMessage: "문서 인쇄, 복사 또는 복제를 예방합니다",
    documentRrestriction: "문서 제한:",
    on: "On",
    off: "Off ",
    protectedEngagementMessageOn: "- 이 engagement에 있는 문서는 인쇄, 복사 또는 복제할 수 없습니다.",
    protectedEngagementMessageOff: "- 이 engagement에 있는 문서는 인쇄, 복사 또는 복제할 수 있습니다.",

    /* Dashboard labels*/
    homeLabel: "홈",
    manageAccessHeader: "접근 관리",
    showInactiveUserslabel: "비활동 사용자 표시",
    addUserButtonLabel: "사용자 추가",
    searchUserText: "검색",
    nameHeader: "이름",
    emailHeader: "이메일",
    actionHeader: "작업",
    manageRolesLabel: "역할 관리",
    deactivateUserlabel: "사용자 비활성화",
    manageRolesTableModule: "모듈",
    manageRolesTableRoles: "역할",
    manageRolesTableServiceLine: "서비스라인",
    manageRolesTableCountries: "국가",
    manageAccessAssignRole: "역할 배정",
    manageAccessNoRolesAdded: "추가된 역할 없음",
    manageAccessDeleteRoleModalHeader: "역할 삭제 ",
    manageAccessDeleteRoleButton: "삭제",
    noRoleResults: "결과 없음",
    confirmAssignRoleButton: "배정",
    permissionGroupName: "그룹 이름",
    permissionGroupDescription: "그룹 설명",
    activeMembers: "활동 멤버",
    permissionGroupMembersTitle: "제목",
    permissionGroupMembersPhone: "전화",
    permissionGroupMembersGui: "GUI",
    permissionGroupMembersUserId: "사용자 ID",
    permissionGroupMembersAdStatus: "AD 상태",
    permissionGroupAddMember: "멤버 추가",
    permissionGroupUserDeleteLabel: "삭제",
    permissionGroupUserDeleteHeader: "멤버 삭제",
    permissionGroupDetails: "권한 그룹 세부정보",
    cache: "캐시 키",
    cacheSelection: "캐시 위치 선택",
    engagementID: "Engagement ID",
    cacheKey: "캐시 키",
    validationMessage: "필수 필드",
    getCache: "캐시 가져오기",
    clearResult: "결과 지우기",
    removeCache: "캐시 제거",
    requiredField: "필수 필드",
    ticketNumber: "티켓 번호 (필수)",
    confirmationMessage: "이 작업을 위해 티켓 번호를 입력하십시오",
    confirmButtonLabel: "확인",
    cancelButtonLabel: "취소 ",
    modalHeaderLabel: "캐시 제거",
    adminModuleinvalidEngagementId: "유효하지 않은 engagement ID",
    adminModuleInvalidCacheKey: "캐시 키에 유효하지 않은 문자가 포함되어 있습니다",
    clearResults: "결과 처리",
    getDocuments: "문서 가져오기",
    documentsHeader: "문서 ",
    validTicketNumber: "유효한 티켓 번호를 입력하십시오 ",

    egagementsPageDescription: "하나 또는 여러 기준에 따라 engagement를 검색하여, engagement 데이터를 업데이트하고 신규 팀 멤버를 초대하며 기존 팀 멤버 상태와 권한을 업데이트합니다",
    engagementSearchCriteria: "검색을 수행하려면 국가 또는 Engagement ID가 필요합니다 ",
    engagementsHeader: "Engagement",
    engagementIdSearchPlaceholder: "Engagement ID",
    engagementNameSearchPlaceholder: "Engagement 이름 ",
    workSpaceSearchPlaceholder: "Workspace name or ID",
    clientSearchPlaceholder: "고객 이름 또는 코드",
    engagementCodeSearchPlaceholder: "Engagement 코드 또는 코드 이름",
    userSearchPlaceholder: "사용자 GUI, 이메일 또는 사용자 ID",
    countrySearchPlaceholder: "국가 ",
    engagementTableHeader: "Engagement",
    nameTableHeader: "Name",
    workspaceIdTableHeader: "Workspace",
    clientTableHeader: "Client",
    engagementCodeTableHeader: "Engagement code",
    yearEndTableHeader: "Year end",
    engagementdeleteDate: "Engagement 삭제일",
    archivalguid: "Archive GUID",
    archivalLogId: "Archive 로그 ID",
    archivalFileguid: "Archive 파일 GUID",
    profile: "프로필 완료",
    independence: "독립성 ",
    archiveRetry: "Archive 재시도",
    engagementdetails: "Engagement 세부정보",
    engagementsdetails: "Engagement 세부정보",
    statusTableHeader: "Status",
    attributesTableHeader: "Attributes",
    attributesProfileComplete: "Profile Complete",
    attributesIndpendenceApplicable: "Independence applicability",
    engagementPagePlaceholder: "Search for engagements above, results will display here",
    noEngagementFound: "Engagement를 찾을 수 없습니다",
    emptySearchFieldError: "하나 이상의 검색 필드에 값이 있어야 합니다",
    largeCountrySelectionError: "10개 이하의 국가를 선택하십시오",
    saveEngagement: "저장",
    undoEditEngagement: "재설정",
    engagementUpdateSuccess: "변경사항이 저장됐습니다",
    archiveRetrySuccessMessage: "이 engagement의 archive가 개시됐습니다. Archive가 성공했는지 검증하려면 나중에 상태를 확인하십시오. ",
    welcome: "Welcome to",
    welcomeadmin: "관리 도구",
    engagementCard: "Engagements",
    messagingCard: "Messaging",
    templatesCard: "Templates",
    permissionGroupCard: "Permission groups",
    manageAccessCard: "접근 관리",
    databaseLocatorCard: "데이터베이스 로케이터 동기화",
    importForReviewTooltip: "Engagement will be optimized for review and some features and functionalities will not be available.",
    importForReviewChecboxText: "Import for review purposes only",
    clientCode: "고객 코드",
    primaryEngagementCode: "Primary engagement 코드",
    invalidEngagementTitle: "유효하지 않은 고객 또는 engagement 코드",
    invalidEngagementMessage: "이 engagement에 접근하려면 고객 코드 및/또는 engagement 코드를 검증해야 합니다. 코드를 업데이트하고 검증하려면 계속을 클릭하십시오.",
    insufficientPermission: "이 모듈에 대한 권한 미비",
    noPermission: "권한 없음",
    noAdminRights: "이 도구에 대한 권한이 없습니다",
    //Engagement Users Lables
    engmtUserTableUserNameHeader: "사용자 이름",
    engmtUserTableEmailAddressHeader: "이메일 주소",
    engmtUserTableRoleHeader: "역할 ",
    engmtUserTableGUIeHeader: "GUI",
    engmtUserTableStatuseHeader: "상태 ",
    engmtUserTableIndependenceStatuseHeader: "독립성",
    engmtUserTableConfidentialityHeader: "기밀",
    engmtUserTableADStatusHeader: "AD 상태",
    engmtUserTablePermissionsHeader: "권한",
    engmtUserTableActionHeader: "작업",
    engagementUsersTitle: "Engagement 사용자",
    engagementInviteMember: "멤버 초대",
    engagementInviteUser: "사용자 초대",
    engagementRoles: "역할",
    engagementRolesRequired: "Engagement 역할 필요",
    engagementInviteMemberSuccessMessage: "멤버가 engagement에 초대됐습니다",
    engagementInviteMemberDescription: "위에서 성, 이름, 이메일 주소로 사용자를 검색하십시오. 결과가 여기에 표시됩니다.",
    engagementUsersTitleEdit: "편집",
    engagementUpdatePermissionToolTip: "Engagement를 업데이트할 권한이 없습니다",
    engagementRetryArchivePermissionToolTip: "Archive를 재시도할 권한이 없습니다",
    usersEditPermissionToolTip: "이 작업을 수행할 권한이 없습니다. 관리자에게 문의하여 권한을 요청하십시오.",
    usersPermissionToolTip: "Engagement 사용자를 업데이트할 권한이 없습니다",
    invalidArchivalGuidMessage: "유효하지 않은 archive GUID",
    invalidArchivalFileGuidMessage: "유효하지 않은 archive 파일 GUID",
    invalidArchivalLogId: "유효하지 않은 archive 로그 ID",
    datePickerLabel: "날짜 선택",
    updateEngagement: "Engagement 업데이트 ",

    invalidEngagementMessage: "이 engagement에 접근하려면 고객 코드 및/또는 engagement 코드를 검증해야 합니다. 코드를 업데이트하고 검증하려면 계속을 클릭하십시오.",
    invalidEngagementId: "Engagement ID가 유효하지 않습니다.",
    serverErrorMessage: "서버 오류. 나중에 다시 오십시오.",
    engagementDetailsNoPermission: "Engagement 세부정보를 볼 권한이 없습니다",
    engagementNotFound: "Engagement 세부정보를 찾을 수 없습니다",
    pageNotFound: "페이지를 찾을 수 없습니다",
    assignRoleModalMessage: "사용자를 위해 활성화할 모듈과 특정 기능을 선택하십시오",
    selectModule: "모듈 선택",
    selectRole: "역할 선택",
    serviceLine: "서비스라인",
    countries: "국가",
    confirmAssignRoleButton: "배정",
    addUser: "사용자 추가",
    addAdminUserHeader: "사용자의 이름, 성 또는 이메일 주소를 입력하고 엔터키를 눌러 검색하십시오",
    addMemberDescription: "위에서 성, 이름, 이메일 주소로 사용자를 검색하십시오. 결과가 여기에 표시됩니다.",
    seachInputRequired: "검색 입력 필요",
    searchUsers: "사용자 검색",
    noResultsFound: "결과를 찾을 수 없습니다",
    usersToBeAdded: "추가할 사용자",
    addUserSuccessMessage: "관리자가 추가됐습니다",
    addMemberSuccessMessage: "멤버가 그룹에 추가됐습니다",
    deactivateWarning: "다음 사용자를 비활성화하시겠습니까?",
    deactivateButton: "비활성화",
    deactivateModalTitle: "사용자 비활성화",
    generateReport: "보고서 생성",
    generateValidationReport: "검증 보고서 생성",

    templateManagement: "템플릿",
    templateName: 'Template name',
    templateAreaRegion: "Area/Region",
    templateSize: "크기",
    templateCreatedBy: "생성한 사람",
    templateCreatedDate: "생성일",
    templateCountOfEngagement: "Engagement",
    templateStatus: "상태",
    templatePublished: "발간됨",
    templateUnpublished: "발간되지 않음",
    templateSearchText: "검색어를 입력하고 Enter 키를 누르십시오",
    templateInsufficientPermissions: "이 모듈에 대한 권한 미비",
    templateMultipleArea: "복수",
    templateEdit: "템플릿 편집",
    templateEditSave: "저장",
    templateEditCancel: "취소",
    templateEditSuccessMessage: "템플릿이 업데이트됐습니다",
    templateDeleteConfirmationMessage: "템플릿을 삭제하시겠습니까?",
    templateDelete: "템플릿 삭제",
    templateEditDelete: "삭제",

    messagingMessageTitle: "메시지 제목 ",
    messagingDescriptionTitle: "메시지 설명 ",
    messagingStartsOn: "시작일 (UTC)",
    messagingEndsOn: "종료일 (UTC)",
    messagingAffectedDataCenters: "영향을 받는 데이터 센터",
    messagingStatus: "상태 ",
    messagingCreatedBy: "생성한 사람",
    messagingCreatedDate: "생성일",
    messagingDataCenterSystemWide: "시스템 전반",
    messagingDataCenterMultiple: "복수",
    messagingDataCenterSingle: "단일 ",
    messageName: "메시지 제목 ",
    messageStartsOn: "시작일",
    messageEndsOn: "종료일",
    messageDateTime: "날짜 및 시간 선택 ",
    messageAllTimeInUTC: "모든 시간은 UTC 기준입니다",
    messageTargetAudience: "대상 청중 ",
    messageDataCenters: "데이터 센터 ",
    messageSelectADate: "날짜 선택",
    messageAllDataCenters: "모든 데이터 센터 ",
    messageKendoDescription: "서식을 사용하면 최대 설명 길이가 4,000자에서 줄어들 수 있습니다",
    messageSelectDataCenter: "데이터 센터 선택",
    messageFieldRequired: "필수 필드",
    messageStartTimeError: "시작일 및 시간은 현재 시간보다 이전일 수 없습니다",
    messageEndTimeError1: "종료일 및 시간은 현재 날짜 및 시간보다 이전일 수 없습니다",
    messageEndTimeError2: "종료일 및 시간은 시작일 및 시간보다 이후여야 합니다. ",
    messageDataCenterError: "데이터 센터를 하나 이상 선택하십시오 ",
    messageSubmitValidationError: "필수 필드 누락. 스크롤하여 모든 필수 필드가 입력됐는지 확인하십시오.",
    messageChangesValidationError: "변경사항이 없습니다. 저장하려면 필드를 하나 이상 편집하십시오.",
    currentutc: "현재 UTC 날짜 및 시간: ",
    messageCurrentUTCTime: "{0} (24 시간 형식)",
    createMessage: "메시지 생성",
    createMessagePermissions: "이 모듈에 대한 권한 미비",
    createMessageSuccess: "메시지가 생성됐습니다",
    editMessage: "메시지 편집 ",
    editMessageSuccess: "메시지가 편집됐습니다",
    editHoverText: "편집",

    messagingModalDeleteTitle: "메시지 삭제 ",
    messagingModalDeleteMessage: "메시지를 삭제하시겠습니까?",
    messagingModalDeleteButton: "삭제 ",
    messagingModalDeleteConfirm: "확인 ",
    messagingModalDeleteCancel: "취소 ",
    messagingModalDeletedSuccessfully: "메시지가 삭제됐습니다",

    // databaselocator
    databaseLocatorSuccessMessage: "데이터베이스 로케이터 동기화가 대기 중입니다",

    // documents module
    docListHeaderDocId: "문서 ID ",
    docListHeaderDocVersion: "버전 ",
    docListHeaderDocName: "문서 이름 ",
    docListHeaderFileSize: "파일 크기 ",
    docListHeaderContentUri: "내용 URI ",
    docListHeaderFileExists: "파일이 있습니다 ",
    docListNoResults: "문서를 찾을 수 없습니다",
    docListTotalDocuments: "총 문서:",
    docListDocIdSearchLabel: "문서 ID ",
    docListExportBtn: "내보내기",

    // Internal user roles abbr
    picRole: "PIC",
    adminRole: "관리자",
    readOnlyRoleForThirdPartyUser: "읽기 전용",
    legacy: "무제한",
    permissionLabel: "권한",
    permission: "권한",
    active: "활동",
    inactive: "비활동",
    EngagementUserStatus: "상태 ",
    IndependenceStatus: "독립성",
    Confidentiality: "기밀",
    EditUserPermissionsMainTitle: "권한을 편집할 수 있습니다",
    EditUserPermissionsInstructions: "항상 귀하가 아닌 다른 사람 권한을 편집합니다",
    PermissionSaveButtonText: "저장",
    PermissionCancelButtonText: "취소",
    UnknownCountry: "알 수 없음",
    engagementNoPermissionMesage: "Engagement 모듈에 대한 권한이 없습니다",
    cacheRemoveSuccessMessage: "캐시가 제거됐습니다",
    cacheKeyNotFound: "캐시 키에 대한 결과가 없습니다",

    // Portfolio labels
    informationText: "Welcome back",
    filterEngagements: {
        all: "모든 engagement ",
        favorite: "즐겨찾는 engagement",
		review: "리뷰 engagement"
	},
    filterEngagementsLabel: "Engagement 필터",
	engv2noActiveEngagements: '귀하에게 활성 engagement가 없습니다. 시작하려면 "Engagement 추가" 버튼을 클릭하십시오.',
    engv2noFavoriteEngagements: "귀하에게 즐겨찾는 engagement가 없습니다. 이 보기에 engagement를 추가하려면 모든 engagement로 가서 engagement 카드 오른쪽 하단 코너에 있는 즐겨찾기 아이콘을 클릭하십시오 ",
    engv2noReviewEngagements: "귀하에게 리뷰 engagement가 없습니다. 접근권한을 요청하려면 engagement 팀 멤버에게 문의하십시오.",

    // Engagement Progress Labels
    engagementProgressStrategyPhaseLabel: "범위 및 전략 단계 ",
    engagementProgressExecutionPhaseLabel: "실행 단계",
    engagementProgressConclusionPhaseLabel: "결론 단계 ",
    engagementProgressCompletionPercentage: "완료 ",
    engagementProgressCompleted: "완료 ",
    engagementProgressNotAvailable: "로컬 데이터 센터가 업그레이드되면 engagement 진행상태가 제공됩니다 ",

    // Engagement archive countdown indicator
    EngagementArchiveCountdownIndicator: {
        reportreleasedate: "보고서 발행일은 <b>{0}</b>입니다",
        archivedeadlinedate: "Archive 기한은 <b>{0}</b>입니다",
        today: "오늘",
        tommorow: "내일",
        overdue: "지연",
        reportreleasedays: "보고서 발행일까지 <b>{0} 일</b>",
        archivedeadlinedays: "Archive 기한까지 <b>{0} 일</b>",
	},
    postArchive: "Post archive",

    adminTool: "관리툴",
    characters: "문자 길이 ",
    placeholder: "Placeholder",
    ofLabel: 'of',
    kendoTextLimitMessage: "최대 문자 한도를 초과했습니다",
    kendoRequiredMessage: "필수 ",
    engv2Errors: {
		noReviewEngagementsPermission: "귀하에게 engagement 리뷰 권한이 없습니다. 접근권한을 요청하려면 engagement 팀 멤버에게 문의하십시오."
	},
    timeTracking: 'Time Tracker',
    timeTrackingToolMessage: "이 선택적 도구는 engagement를 수행할 때 시간을 더욱 잘 관리할 수 있도록 특별히 생성되었습니다 ",
    timeTrackingToolMessage1: "시간 기록을 시작하면 최대 14일까지 집계된 데이터 요약을 확인할 있습니다. 14일 이후 데이터는 자동으로 제거됩니다. ",
    timeTrackingToolUpdatedMessage: "이 선택적 도구로 시간을 어떻게 썼는지 기록할 수 있습니다. 기록된 시간은 최대 2주 동안 보관되며 archive되지 않습니다. ",
    timeTrackerErrorFallBackMessage: "시간 요약을 로드할 수 없습니다. 페이지를 새로 고침하여 다시 시도하십시오. 문제가 지속되면 헬프 데스크에 문의하십시오.",
    serviceGateway: "서비스 게이트웨이",
    reportinghubDisableMessage: "Spotfire는 2024년 6월에 폐지될 예정입니다. 귀하는 EY Canvas engagement에서 신규 EY Canvas 보고서에 접근할 수 있습니다. 2024 release 1 이후, 기타 메뉴에서 접근할 수 있습니다.  이 보고서에 의존하는 사용자지정 보고서 또는 자동화를 2024년 6월 전에 신규 버전으로 업데이트하십시오.",
	mobileVersionLabels: {
        canvas: "Canvas",
        noEngagementFound: "You do not have any active engagements. Access Canvas on your computer to get started.",
		searchEngagementPlaceholder: '검색…',
        search: "검색",
        independenceIncomplete: "미완료 독립성",
        profileIncomplete: "미완료 프로필",
        allEngagements: "모든 engagement",
        noMatchesFound: "No matches found. Please try again.",
        inValidUserLogin: "사용자 설정을 완료하려면 귀하의 컴퓨터에 접속하십시오",
		pagination: {
            pageLabel: "페이지",
            ofLabel: "of"
		}
	},
    independenceOnlyStatus: "독립성 전용",
    // Adobe Analytics
    aaCookieConsentTitle: 'Welcome to',
    aaCookieContentPrompt: 'Do you want to allow cookies?',
    aaCookieConsentExplanation: '<p>In addition to cookies that are strictly necessary to operate this website, we use the following types of cookies to improve your experience and our services: <strong>Functional cookies</strong> to enhance your experience (e.g. remember settings), <strong>Performance cookies</strong> to measure the website\'s performance and improve your experience, <strong>Advertising/Targeting cookies</strong>, which are set by third parties with whom we execute advertising campaigns and allow us to provide you with advertisements relevant to you.</p><p>Review our <a target="_blank" href="https://www.ey.com/en_us/cookie-policy">cookie policy</a> for more information.</p>',
    aaCookieConsentExplanationWithDoNotTrack: '<p>In addition to cookies that are strictly necessary to operate this website, we use the following types of cookies to improve your experience and our services: <strong>Functional cookies</strong> to enhance your experience (e.g. remember settings), <strong>Performance cookies</strong> to measure the website\'s performance and improve your experience, <strong>Advertising/Targeting cookies</strong>, which are set by third parties with whom we execute advertising campaigns and allow us to provide you with advertisements relevant to you.</p><p>We have detected that you have enabled the Do Not Track setting in your browser; as a result, Advertising/Targeting cookies are automatically disabled.</p><p>Review our <a target="_blank" href="https://www.ey.com/en_us/cookie-policy">cookie policy</a> for more information.</p>',
    aaCookieConsentDeclineOptionalAction: 'I decline optional cookies',
    aaCookieConsentAcceptAllAction: 'I accept all cookies',
    aaCookieConsentCustomizeAction: 'Customize cookies',
    aaCookieConsentCustomizeURL: 'https://www.ey.com/en_us/cookie-settings',

    // Cookie Settings
    cookieSettings: {
        title: 'Cookie Settings',
        explanation: 'Please provide your consent for cookie usage on ey.com and the My EY platform. Select one or more of the cookie types listed below, and then save your selection(s). Refer to the listing below for details on the types of cookies and their purpose.',
        emptyCookieListNotice: 'Cookies from this category are not used in this app',
        nameTableHeader: 'Name of cookie',
        providerTableHeader: 'Cookie provider',
        purposeTableHeader: 'Purpose of cookie',
        typeTableHeader: 'Type of cookie',
        durationTableHeader: 'Duration of cookie',
        formSubmit: 'Save my selection',
        requiredCookieListTitle: 'Required Cookies',
        functionalCookieListTitle: 'Functional Cookies',
        functionalCookieAcceptance: 'I accept the functional cookies below',
        functionalCookieExplanation: 'Functionality cookies, which allow us to enhance your experience (for example by remembering any settings you may have selected).',
        performanceCookieListTitle: 'Performance Cookies',
        performanceCookieAcceptance: 'I accept the performance cookies below',
        performanceCookieExplanation: 'Performance cookies, which help us measure the website’s performance and improve your experience. In using performance cookies we do not store any personal data, and only use the information collected through these cookies in aggregated and anonymised form.',
        advertisingCookieListTitle: 'Targeting Cookies',
        advertisingCookieAcceptance: 'I accept the advertising/targeting cookies below',
        advertisingCookieExplanation: 'Advertising/targeting cookies, which we use to track user activity and sessions so that we can deliver a more personalized service, and (in the case of advertising cookies) which are set by the third parties with whom we execute advertising campaigns and allow us to provide advertisements relevant to you.',
        doNotTrackNotice: 'We have detected that you have enabled the Do Not Track setting in your browser; as a result, advertising/targeting cookies are automatically disabled.'
   },
};

export const errors = {
    // GenericErrors Codes
    '0005': "처리 보류 중",
    '0006': "처리 실패",
    '0008': "설명에는 다음 문자를 포함할 수 없습니다: \\",
    '0010': "Arc 호출 실패",

    0: "요청 실패",
    928: "승인받지 않은 접근 - 선택한 옵션에 접근할 권한이 없습니다. 승인받았다면, 헬프 데스크에 접근 지침을 문의하십시오.",

    1009: "데이터 서버를 찾을 수 없습니다",
    1010: "데이터 센터를 찾을 수 없습니다",
    1011: "이 국가에 대한 데이터 센터가 구성되지 않았습니다",
    1013: "국가 ID 또는 Engagement ID를 찾을 수 없습니다",
    1014: "국가 데이터 센터를 찾을 수 없습니다",
    1016: "데이터베이스 로케이터 매핑 실패",
    1017: "oDataQuery는 null이거나 비어 있을 수 없습니다",
    1018: "oDataQuery에 필드가 없습니다",
    1019: "Universal 데이터 센터를 찾을 수 없습니다",
    1020: "사용자가 시스템에 없습니다",
    1021: "선택한 위치에서는 보고를 사용할 수 없습니다. 추가 세부정보는 지역 EY Canvas 챔피언에게 문의하십시오.",

    // Scope & Strategy 1021 - 1200
    1026: "행을 찾을 수 없습니다",
    1030: "고객 이름을 찾을 수 없습니다",

    // Restore Engagement
    1101: "선택한 국가에 engagement를 복원할 수 없습니다. 페이지를 새로 고침하고 다시 시도하십시오.",

    // Execution   1201-1400
    1219: "방법을 찾을 수 없습니다",
    1234: "역할 ID는 null이거나 비어 있을 수 없습니다",
    1260: "작업 중인 항목이 다른 사용자에 의해 삭제되었습니다",
    1300: "유효하지 않은 요청 object",
    1301: "유효하지 않은 engagement 역할",
    1302: "유효하지 않은 사용자 언어",
    1303: "Engagement 사용자 세부정보를 찾을 수 없습니다",
    1304: "유효하지 않은 지역",
    1305: "Engagement 이름을 찾을 수 없습니다",
    1306: "Engagement 이름이 최대 길이(255자)를 초과합니다",
    1307: "보고기간말일을 찾을 수 없습니다",
    1308: "유효하지 않은 날짜",
    1309: "감사보고서일을 찾을 수 없습니다",
    1310: "Engagement archive 기일을 찾을 수 없습니다",
    1311: "Engagement archive 기일은 오늘보다 빠를 수 없습니다",
    1312: "Engagement에 중복된 engagement 코드가 있습니다",
    1313: "위치를 찾을 수 없습니다",
    1314: "Workspace 이름을 찾을 수 없습니다",
    1315: "Workspace 이름이 최대 길이(255자)를 초과합니다",
    1316: "고객 코드가 최대 길이(50자)를 초과합니다",
    1317: "고객 이름이 최대 길이(255자)를 초과합니다",
    1318: "교육용 workspace 여부를 나타내는 플래그 값이 유효하지 않습니다",
    1319: "교육용 workspace 여부를 나타내는 플래그를 찾을 수 없습니다",
    1320: "고객 검증 여부를 나타내는 플래그를 찾을 수 없습니다",
    1321: "고객 검증 여부를 나타내는 플래그 값이 유효하지 않습니다",
    1322: "유효하지 않은 고객 ID",
    1323: "Engagement 코드를 찾을 수 없습니다",
    1324: "Engagement ID를 찾을 수 없습니다",
    1325: "유효하지 않은 primary office ID",
    1052: "유효하지 않은 서비스라인 ID",
    1053: "유효하지 않은 서브서비스라인 ID",
    1326: "국가 ID를 찾을 수 없습니다",
    1327: "유효하지 않은 workspace ID",
    1328: "유효하지 않은 engagement 팀 멤버 상태",
    1329: "유효하지 않은 engagement 상태",
    1330: "Engageement가 이미 다른 팀 멤버에 의해 삭제됐습니다",
    1331: "회계연도말일을 찾을 수 없습니다",
    1339: "허용된 최대 Engagement 수를 초과했습니다",
    1340: "위치 세부정보를 찾을 수 없습니다",
    1341: "업데이트할 Engagement 데이터가 제대로 수신되지 않았습니다",
    1342: "현재 engagement 세부정보를 찾을 수 없습니다",
    1343: "현재 engagement 요약 데이터를 찾을 수 없습니다",
    1344: "선택한 ID에 engagement가 없습니다",
    1359: "활성 Engagement 중 하나의 데이터 센터 ID가 유효하지 않습니다",
    1363: "Universal에서 Engagement 생성에 실패했습니다",
    1364: "LDC에서 Engagement 생성에 실패했습니다",
    1373: "기존 workspace를 선택하거나 신규 workspace를 생성하십시오",
    1374: "Engagement 이름이 이미 있습니다. 템플릿을 불러오려면 신규 engagement 이름을 입력하십시오.",
    1379: "내용을 새로 고치고 있습니다. 나중에 다시 시도하십시오. 문제가 오래 지속되면 IT 헬프 데스크에 문의하십시오.",
    1380: "Archive 재시도를 완료할 수 없습니다. 페이지를 새로 고침하여 다시 시도하십시오. 문제가 지속되면 헬프 데스크에 문의하십시오.",
    1381: "Workspace와 engagement에 있는 국가 ID가 일치하지 않아 Engagement 생성 / RF / RS 실패",
    1382: "Workspace와 engagement에 있는 primary office가 일치하지 않아 Engagement 생성 / RF / RS 실패",
    1384: "이 engagement에서 복원 또는 Roll Forward가 이미 진행 중입니다. 나중에 다시 시도하십시오.",
	1385: "선택한 위치에 engagement를 생성할 수 업습니다. 페이지를 새로 고침하여 다시 시도하십시오.문제가 지속되면 헬프 데스크에 문의하십시오.",
	1386: '신규 engagement에 적합하지 않은 workspace.  페이지를 새로 고침하여 다시 시도하십시오. 문제가 지속되면, 헬프 데스크에 문의하십시오.',
    // Invite Team Members (1401 - 1500)
    1403: "Engagement 환영 메시지가 최대 길이(200자)를 초과합니다",
    1406: "Engagement 컬렉션을 찾을 수 없습니다",
    1424: "Engagement 또는 사용자 세부정보를 찾을 수 없습니다",
    1425: "Engagement 세부정보를 찾을 수 없습니다",

    // User details Error Codes (1426 - 1434)
    1426: "이름은 필수입니다",
    1427: "성은 필수입니다",
    1428: "이니셜은 필수입니다",
    1429: "Primary office는 필수입니다",
    1430: "기본 전화 번호는 필수입니다",
    1431: "언어는 필수입니다",
    1432: "기본 날짜 형식은 필수입니다",
    1433: "기본 숫자 형식은 필수입니다",
    1434: "사용자 세부정보는 null이거나 비어 있을 수 없습니다",
    1436: "충돌이 감지되었습니다. 문제가 지속되면 헬프 데스크에 문의하십시오.",
    1438: "사용자 컬렉션은 비어 있을 수 없습니다",
    1448: "선택한 국가는 EY Canvas 최신 버전으로 업데이트되지 않았습니다. EY Atlas에서 배포 일정을 참고하여 업데이트 이후 다시 시도하십시오. 문제가 지속되면 헬프 데스크에 문의하십시오.",

    1502: "지금은 작업을 완료할 수 없습니다. 페이지를 새로 고침하여 다시 시도하십시오. 문제가 지속되면 헬프 데스크에 문의하십시오.",
    1504: "지금은 작업을 완료할 수 없습니다. 페이지를 새로 고침하여 다시 시도하십시오. 문제가 지속되면 헬프 데스크에 문의하십시오.",
    1505: "유효하지 않은 입력",

    // Common control errors (1601 - 1700)
    1601: "유효하지 않은 퍼센트",
    18000: "ARC 처리 오류: ARC가 유지보수 중이어서 요청한 작업을 수행할 수 없습니다. 나중에 다시 시도하십시오.",
    18306: "ARC 처리 오류: Canvas Archive GUID 관련 ARC 양식을 찾을 수 없습니다",
    18307: "ARC 처리 오류: Engagement가 ARC에서 삭제됐습니다. 선택한 옵션을 진행할 수 없습니다. 추가 지원이 필요하면 ARC 담당자에게 문의하십시오.",
    18309: "ARC 처리 오류: Archive된 engagement에 대한 법적 제한으로 요청한 작업을 수행할 수 없습니다. 추가 지원이 필요하면 ARC 담당자에게 문의하십시오.",
    18320: "ARC 처리 오류: ARC 양식이 보류 상태여서 수정할 수 없습니다",
    18327: "ARC 처리 오류: ARC 양식이 삭제 보류 상태여서 수정할 수 없습니다",
    18328: "ARC 처리 오류: Engagement가 ARC에서 삭제하도록 표시되어 요청한 작업을 수행할 수 없습니다. 추가 지원이 필요하면 ARC 담당자에게 문의하십시오. ",
    18329: "ARC 처리 오류: Partner는 이 engagement에 접근할 수 없습니다. 추가 지원이 필요하면 ARC 담당자에게 문의하십시오. ",
    18330: "ARC 처리 오류: Engagement가 법적 제한으로 잠겨 있어 요청한 작업을 수행할 수 없습니다. 추가 지원이 필요하면 ARC 담당자에게 문의하십시오.",
    2027: "잘못된 요청",
    2046: "권한 없음",
    2047: "금지됨",
    2048: "기록을 찾을 수 없습니다",
    2049: "요청 엔터티가 너무 큽니다",
    2052: "허용되지 않음",
    2053: "데이터를 검색하는 동안 오류가 발생했습니다. 페이지를 새로 고침하여 다시 시도하십시오. 이슈가 지속되면 헬프 데스크에 문의하십시오.",
    2056: "일반 오류가 발생했습니다",
    2057: "서비스를 사용할 수 없습니다",

    // Roll Forward Error Codes (2301-2400)
    2301: "Roll forward 중 archive 파일을 찾지 못했습니다",
    2302: "Roll forward 중 archive 참조 가져오기 호출에 실패했습니다",
    2303: "Roll forward 중 반송된 archive 파일에 빈 고유 식별자가 있습니다",
    2304: "Roll forward 중 반송된 archive 파일에 빈 파일 저장소 URL이 있습니다",
    2305: "Roll forward 중 반송된 archive 파일에 빈 해시 값이 있습니다",
    2306: "사용자는 이 작업을 수행할 권한이 없습니다",
    2307: "허용된 복원본 수를 초과했습니다",

    // Link Error Codes
    2568: "귀하는 현재 연락처가 아니므로 링크를 더 이상 사용할 수 없습니다. 페이지를 새로 고침하십시오.",
    3005: "Primay team이 그룹 Engagement에서 부문을 이미 삭제했습니다",
    2571: "링크 요청이 업데이트됐습니다. 페이지를 새로 고침하십시오.",

    // Error for v1 engagement not supported features
    4001: "선택한 Engagement가 EY Canvas 구 버전으로 생성되어 지역 데이터센터가 EY Canvas 최신 버전으로 업그레이드될 때까지는 복원할 수 없습니다. 추가 정보는 지역 헬프 데스크에 문의하십시오.",
    4002: "선택한 Engagement가 EY Canvas 구 버전으로 생성되어 지역 데이터센터가 최신 버전으로 업그레이드될 때까지는 접근 요청을 할 수 없습니다. 추가 정보는 지역 헬프 데스크에 문의하십시오.",
    4214: "선택한 위치의 workspace에 템플릿을 불러올 수 없습니다. 다른 위치를 선택하고 다시 시도하십시오.",

    // Common framework error
    4205: "복사 요청 세부정보를 찾을 수 없습니다",
    401000: "네트워크 변경이 감지됐습니다. 계속하려면 페이지를 다시 로드하십시오.",

    // CopyHub concurrency scenarios
    4217: "데이터 패키지 요청 상태가 변경됐습니다. 페이지를 새로 고침하여 다시 시도하십시오.",
    4219: "이번 배포본에서는 복사 프로세스에 이루어진 개선사항으로 인해 다른 EY Canvas 버전 간 복사가 지원되지 않습니다. 세부 배포 일정은 EY Atlas를 참고하십시오. 귀하의 데이터 센터가 업그레이드된 후, 복사본을 만들 수 있습니다.",
    4220: "이 국가에서는 템플릿 버전이 지원되지 않습니다.",
    4221: "이 국가에서는 템플릿 버전이 지원되지 않습니다.",
    4222: "대상 위치가 최신 EY Canvas 버전으로 업그레이드될 때까지 불러오기를 완료할 수 없습니다. 계속하려면 다른 위치를 선택하십시오. 문제가 지속되면 헬프 데스크에 문의하십시오.",

    // Error for Mobility API
    4501: "Engagement에 사용 가능한 task가 없습니다",
    4502: "Engagement에 사용 가능한 To-do task가 없습니다",

    // Counrty API version error
    5001: "선택한 위치는 EY Canvas 구 버전을 가지고 있어 지역 데이터센터가 EY Canvas 최신 버전으로 업그레이트될 때까지는 신규 workspace로 roll forward할 수 없습니다. 추가 정보는 지역 헬프 데스크에 문의하십시오.",

    //CRP checks
    5101: "지금 Canvas External Access Portal을 사용할 수 없으므로 이 engagement에 대한 검토 복사본을 생성할 수 없습니다. 세부 정보는 헬프 데스크에 문의하십시오.",
    5102: "이 engagement의 CRP 복사본이 이미 진행 중입니다. 나중에 다시 시도하십시오.",
    5103: "지금 Canvas External Access Portal을 사용할 수 없으므로 이 engagement에 대한 검토 복사본을 편집할 수 없습니다. 세부 정보는 헬프 데스크에 문의하십시오.",
    5104: "이 engagement에서 문서 제한을 사용할 수 없습니다. 옵션 체크를 해제하고 다시 시도하십시오.",

    18100: "서비스라인을 찾을 수 없습니다",
    18101: "서브서비스라인을 찾을 수 없습니다",

    //RBAC realted error messages.
    935: "이 작업을 수행할 권한이 없습니다. 이 작업을 하려면 engagement 관리자에게 문의하여 권한을 요청하십시오.",
    8001: "지금은 작업을 완료할 수 없습니다. 페이지를 새로 고침하여 다시 시도하십시오. 문제가 지속되면 헬프 데스크에 문의하십시오.",
    336699: "시간 요약을 로드할 수 없습니다. 페이지를 새로 고침하여 다시 시도하십시오. 문제가 지속되면 헬프 데스크에 문의하십시오.",

    //Concurrency errors
	7821: "이 초대에 이미 응답했습니다. 페이지를 새로 고침하여 다시 시도하십시오."
};

export const adminErrors = {
    1332: "Archive 재시도 중 오류",
    1501: "사용자가 비활동 상태입니다. 페이지를 새로 고침하여 다시 시도하십시오.",
    156: "이 모듈에 대한 권한 미비",
    70740: "변경사항을 저장할 수 없습니다. Engagement에는 engagement를 관리할 권한을 가지고 독립성이 해결된 활동 멤버가 하나 이상 있어야 합니다. 문제가 지속되면 헬프 데스크에 문의하십시오.",
    70790: "도메인이 허용되지 않습니다. 다시 시도하고 문제가 지속되면 헬프 데스크에 문의하십시오.",
    70800: "제3자 사용자를 초대할 사용자가 유효하지 않습니다",
    70810: "제3자 사용자에 대해 유효하지 않은/비활동 RBAC 역할 선택됨 ",
    70820: "제3자 사용자에 대해 유효한 RBAC 역할을 선택하십시오 ",
	70830: "제3자 사용자에 대해 읽기 전용 역할을 선택하십시오"
};

export const actionTypes = {
    1: "시스템 메시지",
    2: "Engagement 초대",
    3: "거부된 접근",
    4: "Engagement 삭제 요청",
    5: "Engagement 생성 오류",
    6: "Engagement 접근 요청",
    7: "삭제된 Engagement",
    8: "Archive된 Engagement",
    9: "비활성화된 Engagement",
    10: "Archive 만료일 재지정",
    11: "그룹 링크 요청",
    12: "그룹 지침 수신됨",
    13: "복사 수신됨",
    14: "Engagement 진행 중"
};
export const timephaseType = {
    '-1': "보고기간말일",
    '-2': "보고서일",
    '-3': "Archive 만료일",
    '1': "범위",
    '2': "전략",
    '3': "Walkthrough",
    '4': "중간 통제 테스트",
    '5': "중간 실증 테스트",
    '6': "Walkthrough (기말 프로세스)",
    '7': "기말 통제 테스트",
    '8': "기말 실증 테스트",
    '9': "결론",
    '10': "N/A",
    '11': "실행",
    '12': "범위 및 전략 품질 활동",
    '13': "기타 범위 및 전략",
    '14': "CRA",
    '15': "위험 평가 및 중요성",
    '16': "범위 및 전략 마무리",
    '17': "중간감사 후 프로젝트 관리"
};
export const dataExport = [
	"Archive 양식",
	"Canvas 양식",
	"공동작업",
	"통제 양식",
	"문서",
	"Task",
	"팀 멤버",
	"시산표"
];
export const dataExportReportNames = {
	'Archive 양식': 'Archive Form',
	'Canvas 양식': 'Canvas Form Report',
	'공동작업': 'Collaboration',
	'통제 양식': 'Control Report',
	'문서': 'All Documents Report',
	'Task': 'All Tasks Report',
	'팀 멤버': 'Team Members',
	'시산표': 'Numbers Report'
};
export const projectManagement = [
	"공동작업 세부정보",
	"디지털 스코어카드",
	"Milestone",
	"리뷰노트",
	"유효성 검증"
];
export const projectManagementReportNames = {
	'공동작업 세부정보': 'Client Collaboration Dashboard',
	'Milestone': 'Milestone Report',
	'리뷰노트': 'Review Notes Report',
	'유효성 검증': 'Validation Checks Report'
};
export const summaryScreenDefaults = [
	"감사 계획 보고서",
	"감사 계획 및 연결된 증거",
	"통제 검토 도구 (CRT)",
	"CRA 요약",
	"발견사항",
	"IT App & SO 요약",
	"SCOT 요약",
	"SEM"
];
export const summaryScreenDefaultsReportNames = {
	'감사 계획 보고서': 'Audit Plan Report',
	'감사 계획 및 연결된 증거': 'Audit Plan with Related Evidence',
	'통제 검토 도구 (CRT)': 'Controls Review Tool (CRT)',
	'CRA 요약': 'CRA Summary',
	'발견사항': 'Findings',
	'IT App & SO 요약': 'ITSO CRT',
	'SCOT 요약': 'SCOT Summary',
	'SEM': 'SEM'
};
export const summaryScreenInterim = [
	"작업 계획 보고서",
	"작업 계획 및 연결된 증거"
];
export const summaryScreenInterimReportNames = {
	'작업 계획 보고서': 'Audit Plan Report',
	'작업 계획 및 연결된 증거': 'Audit Plan with Related Evidence'
};
export const summaryScreenGroupAudit = [
	"감사 계획 보고서",
	"감사 계획 및 연결된 증거",
	"통제 검토 도구 (CRT)",
	"CRA 요약",
	"발견사항",
	"그룹 지침 요약",
	"그룹 보고서",
	"그룹 구조 요약",
	"IT App & SO 요약",
	"SCOT 요약",
	"SEM"
];
export const summaryScreenGroupAuditReportNames = {
	'감사 계획 보고서': 'Audit Plan Report',
	'감사 계획 및 연결된 증거': 'Audit Plan with Related Evidence',
	'통제 검토 도구 (CRT)': 'Controls Review Tool (CRT)',
	'CRA 요약': 'CRA Summary',
	'발견사항': 'Findings',
	'그룹 지침 요약': 'Group Instruction Summary',
	'그룹 보고서': 'Group Report',
	'그룹 구조 요약': 'Group Structure Summary',
	'IT App & SO 요약': 'ITSO CRT',
	'SCOT 요약': 'SCOT Summary',
	'SEM': 'SEM'
};
export const summaryScreenGroupAuditInterim = [
	"그룹 지침 요약",
	"그룹 구조 요약",
	"작업 계획 보고서",
	"작업 계획 및 연결된 증거"
];
export const summaryScreenGroupAuditInterimReportNames = {
	'그룹 지침 요약': 'Group Instruction Summary',
	'그룹 구조 요약': 'Group Structure Summary',
	'작업 계획 보고서': 'Audit Plan Report',
	'작업 계획 및 연결된 증거': 'Audit Plan with Related Evidence'
};
export const rolelistitems = [{
        id: 8,
        label: "Staff"
    },
    {
        id: 7,
        label: "Senior"
    },
    {
        id: 6,
        label: "Manager"
    },
    {
        id: 5,
        label: "Senior Manager"
    },
    {
        id: 3,
        label: "Executive Director"
    },
    {
        id: 4,
        label: "Principal"
    },
    {
        id: 11,
        label: "Other Partner"
    },
    {
        id: 2,
        label: "Engagement Partner"
    },
    {
        id: 1,
        label: "Partner in Charge"
    },
    {
        id: 10,
        label: "Engagement Quality Reviewer"
    },
    {
        id: 17,
        label: "Audit Quality Reviewer"
    },
    {
        id: 16,
        label: "General Counsel"
    },
    {
        id: 12,
        label: "GDS - Staff"
    },
    {
        id: 30,
        label: "GDS - Senior"
    },
    {
        id: 31,
        label: "GDS - Manager"
    }
];
export const roleType = {
    '11': "Other Partner",
    '24': "Other (e.g. due diligence review)",
    '15': "Executive Support Services",
    '18': "ML Component Team",
    '17': "Audit Quality Reviewer",
    '1': "Partner in Charge",
    '2': "Engagement Partner",
    '9': "Intern",
    '13': "Advisory (FAIT, TAS, Human Capital or Other)",
    '14': "Tax",
    '27': "Industry",
    '10': "Engagement Quality Reviewer",
    '25': "Office",
    '8': "Staff",
    '20': "Client Staff",
    '19': "Client Supervisor",
    '5': "Senior Manager",
    '16': "General Counsel",
    '26': "Area",
    '23': "Regulator",
    '22': "Internal Audit Staff",
    '3': "Executive Director",
    '21': "Internal Audit Supervisor",
    '6': "Manager",
    '4': "Principal",
    '7': "Senior",
    '12': "Global Talent Hub",
    '28': "National",
    '29': "Global",
    '12': "GDS - Staff",
    '30': "GDS - Senior",
    '31': "GDS - Manager"
};

export const copyObjectGroupType = {
    1: "Engagement",
    2: "계정",
    3: "SCOT",
    4: "IT Application & 서비스 조직",
    5: "요청사항",
    6: "증거",
    7: "Numbers",
    8: "요청사항 및 외부 Task",
    9: "임시 파일",
    10: "전기 증거",
    11: "최근 삭제 파일",
    12: "기록 파일",
    13: "Engagement 템플릿",
    14: "GCO 복사본 복원",
    15: "외부 검토 복사본",
    16: "활성 Engagement 외부 검토",
    17: "Canvas 양식",
    20: "증거",
    21: "Engagement",
    22: "계정",
    23: "SCOTs",
    24: "Engagement roll-forward",
    27: "외부 증거",
    28: "가이드 업무흐름"
};

export const EngagementStatusText = [
	"미확정",
	"활성",
	"보류됨",
	"Archive됨",
	"삭제됨",
	"Archive 진행 중",
	"재활성화됨",
	"복원됨",
	"삭제 표시됨",
	"Archive 오류",
	"Roll Forward 오류",
	"재활성화 오류",
	"복원 오류",
	"Roll Forward 진행 중",
	"재활성화 진행 중",
	"복원 진행 중",
	"GAMx Migration 진행 중",
	"GAMx Migration 오류",
	"거래 생성 보류 중",
	"거래 생성 실패",
	"거래 업데이트 보류 중",
	"거래 업데이트 실패",
	"복사 진행 중",
	"복사 오류",
	"템플릿 복사 오류",
	"Engagement 외부 검토 진행 중",
	"Engagement 외부 검토 오류",
	"Engagement 외부 검토"
];
export const overdueIssues = {
    partnerInvitationPending: "Partner 초대 보류 중",
    eqrInvitationPending: "EQR 초대 보류 중",
    invalidEngagementCode: "유효하지 않은 Engagement 코드",
    overdueArchiveDeadline: "Archive 만료일 지연",
    upcomingArchiveDeadline: "Archive 만료 임박",
    contentDeliveryInProgress: "내용 전달 진행 중",
    overdueMilestones: "팀 milestone 지연",
    overduePartnerTasks: "Partner task 지연",
    overdueEqrTasks: "EQR task 지연"
};
export const engagementMetricNames = [{
        propName: 'preparer',
        value: "작성"
    },
    {
        propName: 'forMyReview',
        value: "리뷰"
    },
    {
        propName: 'upcomingReview',
        value: "임박한 리뷰"
    },
    {
        propName: 'reviewNotesAssigned',
        value: "내가 받은 노트"
    },
    {
        propName: 'reviewNotesAuthored',
        value: "내가 작성한 노트"
    },
    {
        propName: 'clientRequests',
        value: "요청사항"
    },
    {
        propName: 'timephases',
        value: "시기"
    },
    {
        propName: 'groupTasks',
        value: "그룹 task"
    },
    {
        propName: 'groupDeliverables',
        value: "그룹 산출물"
    },
    {
        propName: 'automationDocuments',
        value: "자동화"
    }
];
export const metricFilterComingDue = [{
        value: 1,
        label: "내일 만료 도래"
    },
    {
        value: 3,
        label: "다음 3일 만료 도래"
    },
    {
        value: 5,
        label: "다음 5일 만료 도래",
        isDefault: true
    },
    {
        value: 10,
        label: "다음 10일 만료 도래"
    },
    {
        value: 30,
        label: "다음 30일 만료 도래",
        isMax: true
    }
];
export const metricFilterWhatsNew = [{
        value: 1,
        label: "어제 신규 알림"
    },
    {
        value: 3,
        label: "최근 3일 신규 알림"
    },
    {
        value: 5,
        label: "최근 5일 신규 알림",
        isDefault: true
    },
    {
        value: 10,
        label: "최근 10일 신규 알림",
        isMax: true
    }
];

export const type1Alerts = [
    "hasPartnerInvited",
    "hasEqrInvited",
    "hasValidEngagementCode",
    "overdueArchiveDeadline"
];

export const engagementStatuses = {
    1: "활성",
    2: "보류됨",
    3: "Archive됨",
    4: "삭제됨",
    5: "Archive 진행 중",
    6: "재활성화됨",
    7: "복원됨",
    8: "삭제 표시됨",
    9: "Archive 오류",
    10: "Roll Forward 오류",
    11: "재활성화 오류",
    12: "복원 오류",
    13: "Roll Forward 진행 중",
    14: "재활성화 진행 중",
    15: "복원 진행 중",
    16: "GAMx Migration 진행 중",
    17: "GAMx Migration 오류",
    18: "거래 생성 보류 중",
    19: "거래 생성 실패",
    20: "거래 업데이트 보류 중",
    21: "거래 업데이트 실패",
    22: "복사 진행 중",
    23: "복사 오류",
    24: "템플릿 복사 오류",
    25: "Engagement 외부 검토 진행 중",
    26: "Engagement 외부 검토 오류",
    27: "Engagement 외부 검토",
    28: "격리 Universal로 로컬라이즈됨",
    29: "Archive된 격리 Universal로 로컬라이즈됨",
    30: "내용 전달 진행 중",
    31: "내용 전달 오류",
    32: "내용 전달 실패",
    33: "Canvas External Access Portal V2",
    34: "Canvas External Access Portal V2 진행 중",
    35: "Canvas External Access Portal V2 오류 ",
    100: "데이터센터 분할"
};

export const overrideArchiveDeadlineDateOptions = [{
        value: 0,
        label: "기본"
    },
    {
        value: 10,
        label: "10일"
    },
    {
        value: 14,
        label: "14일"
    },
    {
        value: 20,
        label: "20일"
    },
    {
        value: 21,
        label: "21일"
    },
    {
        value: 25,
        label: "25일"
    },
    {
        value: 30,
        label: "30일"
    },
    {
        value: 45,
        label: "45일"
    }
];
export const ExternalReviewCopyExpiryDays = [{
        value: 30,
        label: "30일"
    },
    {
        value: 60,
        label: "60일"
    },
    {
        value: 90,
        label: "90일"
    },
    {
        value: 180,
        label: "180일"
    }
];

export const serviceLines = [{
        servicelineid: 1,
        servicelinename: "Assurance"
    },
    {
        servicelineid: 3,
        servicelinename: "Strategy and Transactions"
    },
    {
        servicelineid: 2,
        servicelinename: "Consulting"
    },
    {
        servicelineid: 4,
        servicelinename: "Tax"
    },
    {
        servicelineid: 5,
        servicelinename: "GCO"
    }
];

export const subServiceLines = [{
        subservicelineid: 1,
        servicelineid: 1,
        subservicelinename: "Audit"
    },
    {
        subservicelineid: 2,
        servicelineid: 1,
        subservicelinename: "FIDS"
    },
    {
        subservicelineid: 3,
        servicelineid: 2,
        subservicelinename: "ITRA"
    },
    {
        subservicelineid: 9,
        servicelineid: 1,
        subservicelinename: "FAAS"
    },
    {
        subservicelineid: 10,
        servicelineid: 1,
        subservicelinename: "ACR"
    },
    {
        subservicelineid: 11,
        servicelineid: 1,
        subservicelinename: "CCaSS"
    },
    {
        subservicelineid: 4,
        servicelineid: 3,
        subservicelinename: "Capital Transformation"
    },
    {
        subservicelineid: 5,
        servicelineid: 3,
        subservicelinename: "Transaction Support"
    },
    {
        subservicelineid: 6,
        servicelineid: 2,
        subservicelinename: "Performance Improvement"
    },
    {
        subservicelineid: 7,
        servicelineid: 2,
        subservicelinename: "Risk"
    },
    {
        subservicelineid: 7,
        servicelineid: 23,
        subservicelinename: "Business Consulting"
    },
    {
        subservicelineid: 24,
        servicelineid: 6,
        subservicelinename: "Other"
    },
    {
        subservicelineid: 8,
        servicelineid: 2,
        subservicelinename: "Other"
    },
    {
        subservicelineid: 12,
        servicelineid: 4,
        subservicelinename: "BTS"
    },
    {
        subservicelineid: 13,
        servicelineid: 4,
        subservicelinename: "Human Capital"
    },
    {
        subservicelineid: 14,
        servicelineid: 4,
        subservicelinename: "Law"
    },
    {
        subservicelineid: 15,
        servicelineid: 4,
        subservicelinename: "Indirect Tax"
    },
    {
        subservicelineid: 16,
        servicelineid: 4,
        subservicelinename: "GCR"
    },
    {
        subservicelineid: 17,
        servicelineid: 4,
        subservicelinename: "Transaction Tax"
    },
    {
        subservicelineid: 18,
        servicelineid: 4,
        subservicelinename: "ITS"
    },
    {
        subservicelineid: 19,
        servicelineid: 5,
        subservicelinename: "GCO"
    },
    {
        subservicelineid: 20,
        servicelineid: 2,
        subservicelinename: "Business Consulting"
    },
    {
        subservicelineid: 21,
        servicelineid: 2,
        subservicelinename: "Technology Consulting"
    },
    {
        subservicelineid: 22,
        servicelineid: 3,
        subservicelinename: "Transactions and Corporate Finance"
    },
    {
        subservicelineid: 23,
        servicelineid: 3,
        subservicelinename: "EY Parthenon"
    },
    {
        subservicelineid: 25,
        servicelineid: 1,
		subservicelinename: 'Technology Risk'
    }
];

export const modules = [{
        id: 1,
        name: "접근 관리"
    },
    {
        id: 2,
        name: "Engagement"
    },
    {
        id: 3,
        name: "권한 그룹"
    },
    {
        id: 4,
        name: "템플릿"
    },
    {
        id: 5,
        name: "메시징"
    },
    {
        id: '6',
        name: "캐시"
    },
    {
        id: 7,
        name: "데이터베이스 로케이터"
    },
    {
        id: 8,
        name: "문서 "
    }
];

export const roles = [{
        id: 17,
        ModuleId: 1,
        name: "읽기 전용"
    },
    {
        id: 18,
        ModuleId: 1,
        name: "사용자 및 권한 추가/제거"
    },
    {
        id: 19,
        ModuleId: 2,
        name: "읽기 전용"
    },
    {
        id: 20,
        ModuleId: 2,
        name: "Engagement 업데이트"
    },
    {
        id: 21,
        ModuleId: 2,
        name: "팀 멤버 초대"
    },
    {
        id: 22,
        ModuleId: 2,
        name: "Engagement 사용자 업데이트"
    },
    {
        id: 23,
        ModuleId: 3,
        name: "읽기 전용"
    },
    {
        id: 24,
        ModuleId: 3,
        name: "멤버 추가/제거"
    },
    {
        id: 25,
        ModuleId: 3,
        name: "데이터베이스 로케이터 동기화"
    },
    {
        id: 26,
        ModuleId: 4,
        name: "읽기 전용"
    },
    {
        id: 27,
        ModuleId: 4,
        name: "템플릿 업데이트"
    },
    {
        id: 28,
        ModuleId: 4,
        name: "템플릿 삭제"
    },
    {
        id: 29,
        ModuleId: 5,
        name: "읽기 전용"
    },
    {
        id: 30,
        ModuleId: 5,
        name: "메시지 생성/업데이트"
    },
    {
        id: 31,
        ModuleId: 5,
        name: "메시지 삭제"
    },
    {
        id: 32,
        ModuleId: 6,
        name: "캐시 열람 및 삭제"
    },
    {
        id: 33,
        ModuleId: 8,
        name: "문서 접근"
    },
    {
        id: 34,
        ModuleId: 2,
        name: "Archive 재시도 "
    }
];

export const countries = {
    1: "Mayotte",
    2: "British Virgin Islands",
    3: "Spain",
    4: "Belize",
    5: "Peru",
    6: "Slovakia",
    7: "Venezuela",
    8: "Norway",
    9: "Falkland Islands (Malvinas)",
    10: "Mozambique",
    11: "Mainland China",
    12: "Sudan",
    13: "Israel",
    14: "Belgium",
    15: "Saudi Arabia",
    16: "Gibraltar",
    17: "Guam",
    18: "Norfolk Islands",
    19: "Zambia",
    20: "Reunion",
    21: "Azerbaijan",
    22: "Saint Helena",
    23: "Iran",
    24: "Monaco",
    25: "Saint Pierre and Miquelon",
    26: "New Zealand",
    27: "Cook Islands",
    28: "Saint Lucia",
    29: "Zimbabwe",
    30: "Iraq",
    31: "Tonga",
    32: "American Samoa",
    33: "Maldives",
    34: "Morocco",
    35: "국제감사기준 (ISA)",
    36: "Albania",
    37: "Afghanistan",
    38: "Gambia",
    39: "Burkina Faso",
    40: "Tokelau",
    41: "Libya",
    42: "Canada",
    43: "United Arab Emirates",
    44: "Korea Democratic Peoples Republic of",
    45: "Montserrat",
    46: "Greenland",
    47: "Rwanda",
    48: "Fiji",
    49: "Djibouti",
    50: "Botswana",
    51: "Kuwait",
    52: "Madagascar",
    53: "Isle of Man",
    54: "Hungary",
    55: "Namibia",
    56: "Malta",
    57: "Jersey",
    58: "Thailand",
    59: "Saint Kitts and Nevis",
    60: "Bhutan",
    61: "Panama",
    62: "Somalia",
    63: "Bahrain",
    64: "Bosnia and Herzegovina",
    65: "France",
    66: "Korea Republic of",
    67: "Iceland",
    68: "Portugal",
    69: "Tunisia",
    70: "Ghana",
    71: "Cameroon",
    72: "Greece",
    73: "French Southern Territories",
    74: "Heard and McDonald Islands",
    75: "Andorra",
    76: "Luxembourg",
    77: "Samoa",
    78: "Anguilla",
    79: "Netherlands",
    80: "Guinea-Bissau",
    81: "Nicaragua",
    82: "Paraguay",
    83: "Antigua and Barbuda",
    84: "국제회계기준 (IFRS)",
    85: "Niger",
    86: "Egypt",
    87: "Vatican City State",
    88: "Latvia",
    89: "Cyprus",
    90: "US Minor Outlying Islands",
    91: "Russia Other Legal Entities",
    92: "Saint Vincent and the Grenadines",
    93: "Guernsey",
    94: "Burundi",
    95: "Cuba",
    96: "Equatorial Guinea",
    97: "British Indian Ocean Territory",
    98: "Sweden",
    99: "Uganda",
    100: "North Macedonia",
    101: "Swaziland",
    102: "El Salvador",
    103: "Kyrgyzstan",
    104: "Ireland",
    105: "Kazakhstan",
    106: "Honduras",
    107: "Uruguay",
    108: "Georgia",
    109: "Trinidad and Tobago",
    110: "Palestinian Authority",
    111: "Martinique",
    112: "Aland Islands",
    113: "French Polynesia",
    114: "Ivory Coast",
    115: "Montenegro",
    116: "South Africa",
    117: "South Georgia and the South Sandwich Islands",
    118: "Yemen",
    119: "Hong Kong",
    120: "Kenya",
    121: "Chad",
    122: "Colombia",
    123: "Costa Rica",
    124: "Angola",
    125: "Lithuania",
    126: "Syria",
    127: "Malaysia",
    128: "Sierra Leone",
    129: "Serbia",
    130: "Poland",
    131: "Suriname",
    132: "Haiti",
    133: "Nauru",
    134: "Sao Tome and Principe",
    135: "Svalbard and Jan Mayen",
    136: "Singapore",
    137: "Moldova",
    138: "Taiwan",
    139: "Senegal",
    140: "Gabon",
    141: "Mexico",
    142: "Seychelles",
    143: "Micronesia Federated States of",
    144: "Algeria",
    145: "Italy",
    146: "San Marino",
    147: "Liberia",
    148: "Brazil",
    149: "Croatia",
    150: "Faroe Islands",
    151: "Palau",
    152: "Finland",
    153: "Philippines",
    154: "Jamaica",
    155: "French Guiana",
    156: "Cape Verde",
    157: "Myanmar",
    158: "Lesotho",
    159: "US Virgin Islands",
    160: "Cayman Islands",
    161: "Niue",
    162: "Togo",
    163: "Belarus",
    164: "Dominica",
    165: "Indonesia",
    166: "Uzbekistan",
    167: "Nigeria",
    168: "Wallis and Futuna",
    169: "Barbados",
    170: "Sri Lanka",
    171: "United Kingdom",
    172: "Ecuador",
    173: "Guadeloupe",
    174: "Laos",
    175: "Jordan",
    176: "Solomon Islands",
    177: "East Timor",
    178: "Lebanon",
    179: "Central African Republic",
    180: "India",
    181: "Christmas Island",
    182: "Vanuatu",
    183: "Brunei",
    184: "Bangladesh",
    185: "Antarctica",
    186: "Bolivia",
    187: "Turkey",
    188: "Bahamas",
    189: "Comoros",
    190: "Western Sahara",
    191: "Czech Republic",
    192: "Ukraine",
    193: "Estonia",
    194: "Bulgaria",
    195: "Mauritania",
    196: "Congo The Democratic Republic of the",
    197: "Liechtenstein",
    198: "Pitcairn",
    199: "Denmark",
    200: "Marshall Islands",
    201: "Japan",
    202: "Austria",
    203: "Oman",
    204: "Mongolia",
    205: "Tajikistan",
    206: "Switzerland",
    207: "Guatemala",
    208: "Eritrea",
    209: "Nepal",
    210: "Mali",
    211: "Slovenia",
    212: "Northern Mariana Islands",
    213: "(해당 없음)",
    214: "Aruba",
    215: "Congo",
    216: "Qatar",
    217: "Guinea",
    218: "United States",
    219: "Ethiopia",
    220: "Other",
    221: "Guyana",
    222: "Germany",
    223: "Bermuda",
    224: "Turks and Caicos Islands",
    225: "Australia",
    226: "Kiribati",
    227: "Puerto Rico",
    228: "Pakistan",
    229: "Mauritius",
    230: "Malawi",
    231: "Turkmenistan",
    232: "Cambodia",
    233: "Chile",
    234: "New Caledonia",
    235: "Papua New Guinea",
    236: "Bouvet Island",
    237: "Tuvalu",
    238: "Curacao",
    239: "Dominican Republic",
    240: "Vietnam",
    241: "Cocos (Keeling) Islands",
    242: "Grenada",
    243: "Tanzania",
    244: "Argentina",
    245: "Macao",
    246: "Benin",
    247: "Romania",
    248: "Armenia",
    249: "Global",
    250: "중소기업을 위한 IFRS",
    251: "US GAAP",
    252: "중소기업을 위한 AICPA 재무보고체계",
    253: "South Sudan",
    254: "Kosovo",
    255: "United States - Government",
    256: "Russia OOO EY & OOO EY Vneshaudit",
    257: "Armenia-RussiaDC",
    258: "Belarus-RussiaDC",
    259: "Uzbekistan-RussiaDC"
};

export const common = {
    selected: "선택됨",
    cancel: "취소",
    checkbox: {
        selectAllOn: "모두 선택",
        selectAllOff: "모두 선택 해제"
    },
    clear: "처리",
    close: "닫기",
    pagination: {
        show: "표시",
        iconHover: {
            first: "첫 페이지",
            previous: "이전 페이지",
            next: "다음 페이지",
            last: "마지막 페이지"
        }
    },
    engv2Pagination: {
        show: "표시",
        iconHover: {
            first: "첫 페이지",
            previous: "이전 페이지",
            next: "다음 페이지",
            last: "마지막 페이지"
        }
    },
    search: {
        clear: "검색 처리",
        buttonTitle: "검색",
        placeholder: "검색",
        noResultsPlaceholder: "결과를 찾을 수 없습니다"
    },
    table: {
        remove: "제거",
        noDataPlaceholder: "사용 가능한 데이터 없음",
        noResultsPlaceholder: "결과를 찾을 수 없습니다"
    },
    toggleSwitch: {
        no: "아니오",
        yes: "예"
    },
    wizard: {
        next: "계속",
        back: "뒤로",
        finish: "마침"
    }
};

export const ConfidentialityTypes = [{
        id: 0,
        Name: "기본"
    },
    {
        id: 10,
        Name: "Low"
    },
    {
        id: 12,
        Name: "Moderate"
    },
    {
        id: 11,
        Name: "High"
    }
];

export const internalUserRoles = [{
        roleKey: 3,
        sectionId: 2,
        groupDisplayOrder: 150,
        groupName: "권한",
        roleNameUI: "Engagement Archive",
        displayName: "Engagement Archive",
        roleDisplayOrder: 2,
        tooltipText: "Engagement를 archive하는 기능"
    },
    {
        roleKey: 4,
        sectionId: 2,
        groupDisplayOrder: 150,
        groupName: "권한",
        roleNameUI: "Engagement 관리",
        displayName: "Engagement 관리",
        roleDisplayOrder: 1,
        tooltipText: "내외부 멤버를 초대하고 내부 멤버에게 권한을 배정하는 기능"
    },
    {
        roleKey: 5,
        sectionId: 2,
        groupDisplayOrder: 150,
        groupName: "권한",
        roleNameUI: "내용 업데이트",
        displayName: "내용 업데이트",
        roleDisplayOrder: 6,
        tooltipText: "프로필을 편집하고 내용 업데이트를 수락하는 기능"
    },
    {
        roleKey: 7,
        sectionId: 2,
        groupDisplayOrder: 150,
        groupName: "권한",
        roleNameUI: "서비스 요청 생성",
        displayName: "서비스 요청사항 관리",
        roleDisplayOrder: 4,
        tooltipText: "서비스 요청사항을 생성하고 서비스 요청사항에 완료 표시하는 기능"
    },
    {
        roleKey: 8,
        sectionId: 2,
        groupDisplayOrder: 150,
        groupName: "권한",
        roleNameUI: "그룹 관리",
        displayName: "그룹 관리",
        roleDisplayOrder: 5,
        tooltipText: "Primary 또는 지역 팀에서 수신한 그룹 지침을 수락하거나 거절"
    },
    {
        roleKey: 9,
        sectionId: 2,
        groupDisplayOrder: 150,
        groupName: "권한",
        roleNameUI: "Engagement 복사, Roll forward, 복원",
        displayName: "Engagement 복사, Roll forward, 복원",
        roleDisplayOrder: 3,
		tooltipText: "Engagement를 복사하거나, archive본을 roll forward 또는 복원하는 기능"
    },
    {
        roleKey: 0,
        sectionId: 1,
        groupDisplayOrder: 200,
        groupName: "기밀",
        roleNameUI: "기본",
        displayName: "없음 (기본)",
        roleDisplayOrder: 1,
        tooltipText: "기본 기밀 문서 역할"
    },
    {
        roleKey: 10,
        sectionId: 1,
        groupDisplayOrder: 200,
        groupName: "기밀",
        roleNameUI: "Low",
        displayName: "Low",
        roleDisplayOrder: 8,
        tooltipText: "낮은 기밀 문서 역할"
    },
    {
        roleKey: 11,
        sectionId: 1,
        groupDisplayOrder: 200,
        groupName: "기밀",
        roleNameUI: "High",
        displayName: "High",
        roleDisplayOrder: 10,
        tooltipText: "높은 기밀 문서 역할"
    },
    {
        roleKey: 12,
        sectionId: 1,
        groupDisplayOrder: 200,
        groupName: "기밀",
        roleNameUI: "Moderate",
        displayName: "Moderate",
        roleDisplayOrder: 9,
        tooltipText: "중간 기밀 문서 역할"
    },
    // {
    // roleKey: 13,
    // sectionId:1,
    // groupDisplayOrder: 200,
    // groupName:"Confidentiality",
    // roleNameUI: "Confidential",
    // displayName: "Confidential",
    // roleDisplayOrder: 11,
    // tooltipText: 'Strictly Confidential Documents Role'
    // },
    {
        roleKey: 14,
        sectionId: 3,
        groupDisplayOrder: 100,
        groupName: "제한",
        roleNameUI: "읽기 전용",
        displayName: "읽기 전용",
        roleDisplayOrder: 12,
        tooltipText: "이 기능은 이 EY Canvas engagement에 제3자 검토자를 추가하는 데만 사용해야 합니다. 심리실에서 적합한 승인과 허가를 받아야 합니다."
    },
    {
        roleKey: 16,
        sectionId: 3,
        groupDisplayOrder: 100,
        groupName: "제한",
        roleNameUI: "검토 전용",
        displayName: "검토 전용",
        roleDisplayOrder: 13,
        tooltipText: "검토는 이 사용자에게 Canvas engagement에 있는 정보를 보고, sign-off하고 리뷰노트를 사용할 수 있는 기능만 부여합니다. 다른 EY 사용자가 이 engagement를 검토해야 하지만 (예, 그룹 감사 Primary Team) 편집하면 안될 때, 이 기능을 사용합니다.",
    },
    {
        roleKey: 37,
        sectionId: 3,
        groupDisplayOrder: 100,
        groupName: "제한",
        roleNameUI: "독립성 전용",
        displayName: "독립성 전용",
        roleDisplayOrder: 14,
        tooltipText: "독립성 전용은 이 사용자가 engagement에서 독립성 양식은 완료할 수 있지만 EY Canvas engagement에 완전하게 접근할 수는 없습니다. 사용자가 engagement를 위한 업무를 수행했지만 EY Canvas에 접근할 필요가 없는 경우 이를 사용해야 합니다.",
    },
    {
        roleKey: 6,
        sectionId: 2,
        groupDisplayOrder: 150,
        groupName: "권한",
        roleNameUI: "편집 실행 취소",
        displayName: "문서 roll-back",
        roleDisplayOrder: 7,
        tooltipText: "보고서 발행일 후 실수로 변경한 경우 문서를 roll-back하고 440 Canvas 양식에서 제거하는 기능"
    },
{
		roleKey: 35,
		sectionId: 2,
		groupDisplayOrder: 150,
		groupName: "권한",
		roleNameUI: "EY Canvas FIT enablement 공유 관리",
		displayName: "가이드 업무흐름 관리",
		roleDisplayOrder: 14,
		tooltipText: "EY Canvas engagement 간 가이드 업무흐름을 공유하는 기능"
	},
	{
		roleKey: 36,
		sectionId: 2,
		groupDisplayOrder: 150,
    groupName: "권한",
    roleNameUI: "EY Helix 데이터 불러오기",
    displayName: "EY Helix 데이터 불러오기",
		roleDisplayOrder: 15,
        tooltipText: "EY Helix에서 데이터를 불러오는 기능"
}
];

export const restoreengagementexpirydates = [{
        value: 3,
        label: "3 개월"
    },
    {
        value: 6,
        label: "6 개월"
    },
    {
        value: 9,
        label: "9 개월"
    },
    {
        value: 12,
        label: "12 개월"
    }
];

export const messageStatus = {
    0: "현재 ",
    1: "예정",
    2: "만료 "
};

export const kendoLabels = {
    alignLeft: "왼쪽 맞춤",
    alignRight: "오른쪽 맞춤",
    alignCenter: "가운데 맞춤",
    alignFull: "전체 맞춤",
    addComment: "Comment 추가",
    addColumnBefore: "왼쪽 열 추가",
    addColumnAfter: "오른쪽 열 추가",
    addRowAbove: "위쪽 행 추가",
    addRowBelow: "아래쪽 행 추가",
    bulletList: "비순서 리스트 삽입",
    bold: "굵게",
    backColor: "하이라이트",
    createLink: "하이퍼링크 삽입",
    createTable: "표 생성",
    cleanFormatting: "서식 정리",
    deleteRow: "행 삭제",
    deleteColumn: "열 삭제",
    deleteTable: "표 삭제",
    fontSizeInherit: "글꼴 크기",
    foreColor: "색상 ",
    format: "형식 ",
    fontSize: "글꼴 크기",
    hyperlink: "웹 링크 삽입",
    italic: "기울임꼴",
    indent: "들여쓰기",
    insertTableHint: "{0} x {1} 표 생성",
    "hyperlink-dialog-content-address": "웹 주소",
    "hyperlink-dialog-title": "하이퍼링크 삽입",
    "hyperlink-dialog-content-title": "제목",
    "hyperlink-dialog-content-newwindow": "새 창에서 링크 열기",
    "hyperlink-dialog-cancel": "취소 ",
    "hyperlink-dialog-insert": "삽입",
    outdent: "내어쓰기",
    orderedList: "순서 리스트 삽입",
    print: "인쇄",
    pdf: "pdf로 내보내기",
    redo: "재실행",
    strikethrough: "취소선",
    subscript: "위첨자",
    superscript: "아래첨자",
    underline: "밑줄",
    undo: "실행 취소"
};

export const kendoFormatOptions = [{
        text: "문단",
        value: "p"
    },
    {
        text: "제목 1",
        value: "h1"
    },
    {
        text: "제목 2",
        value: "h2"
    },
    {
        text: "제목 3",
        value: "h3"
    },
    {
        text: "제목 4",
        value: "h4"
    },
    {
        text: "제목 5",
        value: "h5"
    },
    {
        text: "제목 6",
        value: "h6"
    }
];

export const kendoFontSize = [{
        text: '8',
        value: "8px"
    },
    {
        text: '9',
        value: "9px"
    },
    {
        text: '10',
        value: "10px"
    },
    {
        text: '11',
        value: "11px"
    },
    {
        text: '12',
        value: "12px"
    },
    {
        text: '14',
        value: "14px"
    },
    {
        text: '16',
        value: "16px"
    },
    {
        text: '18',
        value: "18px"
    },
    {
        text: '20',
        value: "20px"
    },
    {
        text: '22',
        value: "22px"
    },
    {
        text: '24',
        value: "24px"
    },
    {
        text: '26',
        value: "26px"
    },
    {
        text: '28',
        value: "28px"
    },
    {
        text: '36',
        value: "36px"
    },
    {
        text: '48',
        value: "48px"
    },
    {
        text: '72',
        value: "72px"
    }
];

export const cacheOptionValue = [{
        value: 1,
        label: "LDC"
    },
    {
        value: 2,
        label: "Universal"
    }
]
