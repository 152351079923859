import styled from 'styled-components';

//engagement detail page style
const Headerbody = styled.section`
	// common for table UI start

	.userlistwrapper {
		display: inline-block;
		width: 100%;

		.manageaccessitemview {
			width: 100%;
			font-size: var(--px-14);
			display: flex;
			border-collapse: collapse;
			align-items: center;
			justify-content: center;
			border: var(--px-1) solid var(--neutrals-200);

			&.inactive mgt-person {
				color: var(--neutrals-300);
			}

			&:not(.inactive) .userName {
				color: var(--neutrals-900);
			}

			.grid {
				display: grid;
				grid-template-columns: auto 1fr;
				grid-gap: 0 10px;
			}
		}

		.userlist-header {
			width: 100%;
			display: inline-block;
			.userlistheader {
				width: 100%;
				font-size: var(--px-14);
				color: var(--neutrals-700);
				font-weight: bold;
				display: flex;
				border-collapse: collapse;
				background: var(--neutrals-100);
				border: var(--px-1) solid var(--neutrals-200);
			}
		}

		.userlist-body {
			overflow: auto;
			height: calc(100vh - var(--px-308));
			display: inline-block;
			width: 100%;
			border: var(--px-1) solid var(--neutrals-200);

			.userlistholder {
				border-bottom: var(--px-1) solid var(--neutrals-200);
			}
		}

		.userlist-header,
		.userlist-body {
			.userlistitem {
				padding: var(--px-10);
				flex: 1 1 auto;
				width: 10%;
				vertical-align: middle;
				display: inline-block;
				&.archiveretry{
					width: 8%;
					text-align: center;
					.motif-tooltip-wrapper {
						width: 30px;
					}
				}
				&.independence{
					width: 8%;
					text-align: center;
					.motif-tooltip-wrapper {
						width: 30px;
					}
				}
				&.isprofilecomplete{
					.motif-tooltip-wrapper {
						width: 30px;
					}
				}

				.e-country-dd .react-select__control {
					min-height: var(--px-42);

					.react-select__single-value {
						padding-top: var(--px-8);
					}
				}

				.motif-tooltip-wrapper {
					width: 100%;

					.motif-tooltip-trigger-wrap {
						display: block !important;

						.motif-form-field {
							margin: 0;

							.motif-typeahead {
								height: var(--px-40);
								display: grid;
								.motif-input {
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
								}
							}
						}
					}
				}

				.motif-form-field-date-input {
					margin-bottom: 0;

					.motif-date-picker-wrapper{
						width: auto;
					}

					.react-date-picker {
						height: var(--px-40);
						button[disabled] {
							background: none;
						}
					}
				}
			}
		}
	}

	// common for table UI end

	&.engagementpageview .userlistwrapper {
		.userlist-body {
			height: auto;
			overflow: visible;
			border: 0;

			.userlistholder .manageaccessitemview{
				padding: var(--px-20) 0 0 0;

				.react-date-picker__inputGroup{
					margin-top: var(--px-5)!important;
					& > *{
						font-size: var(--px-13)!important;
					}
				}

				.grid{
					height: var(--px-60);
					padding: 0 var(--px-10) !important;

					&.yearend{
						line-height: var(--px-40);
					}

					&.status{
						.react-select__control{
							height: var(--px-30);
								.react-select__single-value{
									padding-top: 0;
									line-height: normal;
								}
						}
					}

					&.isprofilecomplete,
					&.independence,
					&.archiveretry{
						line-height: var(--px-50);
					}

					&.isprofilecomplete,
					&.independence{
						.motif-toggle-switch-wrapper{
							display: block;
						}
					}
					
					&.independence{
						text-align: center;
					}
					
					&.archiveretry{
						text-align: center;
						svg{
							fill: var(--neutrals-900);
							width: var(--px-22);
							height: var(--px-22);
							cursor: pointer;
						}

						.disabled{
							pointer-events: none;						
						}

						.disabled svg{
							pointer-events: none;
							fill: var(--neutrals-300);
							cursor: default;							
						}
					}

				}
			}

			.userlistholder{
				.engagementrow.loadingstyle{
					width: 100%;
					margin: 0 auto;
					display: inline-flex;
					border: var(--px-1) solid var(--neutrals-200);
		
					& > section{
						margin: 1.3% auto;
					}
				}
			}

			.savereset{
				font-size: var(--px-12);
				margin: var(--px-20) 0;
				display: flex;	
				.motif-button{
					margin-right: var(--px-10);
					height: var(--px-40);
				}
			}
		}
`;

export default Headerbody;
