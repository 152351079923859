import React from 'react';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';

const EngagementMetric = (props) => {
	const {metricName, metricValues} = props;

	return (
		<StyledMetric>
			<section className="metric-counter-title">
				<span title={metricName}>{metricName}</span>
			</section>

			{metricValues &&
				metricValues.map((metric) => {
					const metricCount = metric === 0 ? '-' : metric;
					return (
						<section key={uuidv4()} className={`metric-badge-wrapper`}>
							{<span className="metric-badge">{metricCount}</span>}
						</section>
					);
				})}
		</StyledMetric>
	);
};

export default EngagementMetric;

const StyledMetric = styled.section`
	display: flex;
	width: 100%;
	margin: var(--px-10) 0px 0px;
	.metric-counter-title {
		font-weight: normal;
		width: 40%;
		padding-right: 10%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		line-height: var(--px-24);
	}
	.metric-badge-wrapper {
		width: 20%;
		display: inherit;
		.metric-badge {
			border: var(--px-1) solid var(--neutrals-900);
			border-radius: var(--px-4);
			min-width: var(--px-60);
			text-align: center;
			background: var(--neutrals-900);
			color: var(--neutrals-00white);
			line-height: var(--px-30);
			padding: 0 var(--px-10);
		}
	}
`;
