import React from 'react';
import styled from 'styled-components';
import EngagementMetricsDetails from './engagementMetricsDetails';
import { labels } from '../../../util/uiconstants';
import EngagementAlerts from './EngagementAlerts/engagementAlerts';
import Tooltip from '@ey/motif-react-wrapper/Tooltip';
import Button from '@ey/motif-react-wrapper/Button';
import { SVGICONS } from '@ey/icons';
import EngagementArchiveCountdownIndicator from '../../Common/EngagementArchiveCountdownIndicator';

const EngagementMetrics = (props) => {
	const {
		engagementId,
		timeline,
		expectedreportreleasedate,
		archivaldeadlinedate
	} = props;

	return (
		<EngagementMetricsContainer>
			<section className="engMetricBorder">
				<Tooltip
					allowHover={false}
					trigger={
						<Button variant="text" className="hide" >
							{labels.engagementMetrics}
							<SVGICONS
								hoverText={labels.engagementMetrics}
								styleName="small pull-left"
								name={'add_chart'}
							/>
						</Button>
					}
					contentClassName="auditmetrics-tooltip"
					placement="auto"
					variant="alt"
				>
					<section className="auditmetrics-container">
						<h3 className="auditmetrics-title">{labels.engagementMetrics}</h3>
						<EngagementMetricsDetails
							engagementId={engagementId}
							timeline={timeline}
						/>
					</section>
				</Tooltip>

				<EngagementArchiveCountdownIndicator
					// belwo dates can be used for testing purpose
					// archivaldeadlinedate={'2022-08-05'}
					// expectedreportreleasedate={'2022-06-19'}
					archivaldeadlinedate={archivaldeadlinedate}
					expectedreportreleasedate={expectedreportreleasedate}
				/>
			</section>

			{timeline && timeline.metrics && timeline.metrics.engagementAlerts && (
				<section className="engagementalerts-wrapper">
					<EngagementAlerts
						engagementAlerts={timeline.metrics.engagementAlerts}
					/>
				</section>
			)}
		</EngagementMetricsContainer>
	);
};

export default EngagementMetrics;

const EngagementMetricsContainer = styled.section`
	margin: 0 0 var(--px-10) 0;
	.engMetricBorder {
		padding-left: var(--px-30);
		padding-bottom: var(--px-10);
		@media screen and (max-width: 1366px) {
			padding-bottom: var(--px-14);
		}
		.motif-text-button {
			svg {
				margin-left: var(--px-3);
			}
		}
	}
`;
