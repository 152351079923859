import React from 'react';
import {Link} from 'react-router-dom';
import { actionIcHome24px } from '@ey/motif-react-wrapper/assets/icons/index';
import {labels} from '../../util/uiconstants';
import Breadcrumb from '@ey/motif-react-wrapper/Breadcrumb';
import BreadcrumbItem from '@ey/motif-react-wrapper/BreadcrumbItem';
import styled from 'styled-components';
import {useLocation} from 'react-router-dom';
import EllipsesControl from '@ey/ellipses-control';

function BreadCrumbNavigation(props) {
	console.log('inside breadcrumb');
	console.log(props);
	const location = useLocation();
	console.log(location);

	const getLinkAndLabel = (routeItem) => {
		var linkLabel = {link: routeItem.link, label: routeItem.label};
		if (routeItem.isDynamicLabel && location?.state) {
			var lbl = location?.state?.customLabel;
			if (lbl && lbl.length > 0) {
				linkLabel.label = lbl;
				linkLabel.link = location.pathname + location?.search;
			}
		}
		return linkLabel;
	};
	return (
		<Mainwrapper>
			<section className="breadcrumbwrapper">
				<Breadcrumb>
					{props.breadCrumbList &&
						props.breadCrumbList.map((item) => {
							var dynamicLinkInfo = null;
							if (item.isDynamicLabel && location?.state) {
								dynamicLinkInfo = getLinkAndLabel(item);
							}
							return (
								<BreadcrumbItem
									key={item.link}
									icon={
										item.label == labels.homeLabel ? actionIcHome24px : null
									}
								>
									{dynamicLinkInfo == null ? (
										<Link to={item.link}>{item.label} </Link>
									) : (
										<Link
											to={{
												pathname: dynamicLinkInfo.link,
												state: {customLabel: dynamicLinkInfo.label}
											}}
										>
											{' '}
											<EllipsesControl
												content={dynamicLinkInfo.label}
												tooltip={dynamicLinkInfo.label}
												displayId={''}
												noOfLines={1}
												ellipsisClass="dynamiclinkinfo"
											/>
										</Link>
									)}
								</BreadcrumbItem>
							);
						})}
				</Breadcrumb>
			</section>
		</Mainwrapper>
	);
}

const Mainwrapper = styled.section`
	margin-right: auto;
	width: 80%;
	.motif-breadcrumb {
		height: var(--px-48);

		.motif-breadcrumb-list {
			height: var(--px-48);
		}

		.motif-breadcrumb-list-item {
			&.motif-breadcrumb-list-item-active {
				width: 85%;

				a {
					width: 100%;
					display: block;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;

					&:focus{
						text-shadow: none;
					}

					.ellipses {
						vertical-align: middle;
						display: flex;
					}
				}
			}
			a {
				font-size: var(--px-12);
				color: var(--neutrals-900);
				&:hover{
					text-shadow: none;
				}
			}

			svg {
				min-width: var(--px-15) !important;
				min-height: var(--px-15) !important;
				fill: var(--neutrals-900);
				vertical-align: top;
			}

			.motif-icon {
				margin-bottom: var(--px-5);
				svg {
					vertical-align: top;
				}
			}
		}
	}
`;

export default BreadCrumbNavigation;
