/**
 * @module Workspaces
 * Container Component Workspaces
 */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import env from '../../util/env';
import { SVGICONS } from '@ey/icons';
import FavButton from './favoritesbutton';
import { currentResource, tabTypes, urls, labels } from '../../util/uiconstants';
import { EngagementSource, EngagementStatus } from '../../util/enumerations';
import { EngUserModelPatchFavorites } from '../../util/preparemodel';
import CogWheel from './cogwheel';
import EllipsesControl from '@ey/ellipses-control';
import EngagementRequestDeleteModal from './engagementRequestDeleteModal';
import EngagementInvalidModal from './engagementInvalidModal';
import Moment from 'react-moment';
import Timeline from '../Timeline/timeline';
import RestoredEngagementTimeline from '../Timeline/RestoredEngagementTimeline';
import { markUnmarkFavorite } from '../../actions/engagementuseractions';
import { getNotifications } from '../../actions/notificationsactions';
import Tooltip from '@ey/motif-react-wrapper/Tooltip';
import Button from '@ey/motif-react-wrapper/Button';
import Icon from '@ey/motif-react-wrapper/Icon';
import { contentIcLink24px } from '@ey/motif-react-wrapper/assets/icons/index';
import WorkspaceGroupAudit from './workspaceGroupAudit';
import ExpiryDateLabel from '../Common/ExpiryDateLabel';
import MotifCard from '@ey/motif-react-wrapper/Card';

const EngagementItem = (props) => {
	const dispatch = useDispatch();

	const { id, clientName, workspace, refreshDashboard, data } = props;
	const {
		isfavorite,
		incountryuri,
		hasengagementpassedalloweddays,
		isengagementcodevalid,
		isclientcodevalid,
		isclientengagementidvalidated,
		herculesappuri,
		engagementdesc,
		engagementstatus,
		incountryaudienceuri,
		incountryappuri,
		engagementisprofilecomplete,
		independencestatusid,
		isindependencetemplateavailable,
		expectedreportreleasedate,
		archivaldeadlinedate,
		garoletypeid
	} = data;

	const selectedTab = useSelector((store) => store.dashboard.activeTab);
	const timeline = useSelector((store) => store.timeline);
	const apponlinestate = useSelector((store) => store.apponlinestate);
	const notifications = useSelector((store) => store.notifications);
	const userPermissions = useSelector((store) => store.userPermissions);

	const [
		showRequestDeleteModalState,
		setShowRequestDeleteModalState
	] = useState(false);
	const [
		showInvalidEngagementModalState,
		setShowInvalidEngagementModalState
	] = useState(false);
	const [isFavInProgress, setIsFavInProgress] = useState(false);
	const [fav, setFav] = useState(isfavorite);
	const [readyForFavClick, setReadyForFavClick] = useState(true);
	const [notice, setNotice] = useState(null);
	const [helixProjects, setHelixProjects] = useState([]);
	const [hasCountdown, setHasCountdown] = useState(false);

	let hideComponent = false;
	let engagementAlertsStatus = 'eng-listholder arcstatus-wrapper';
	let classNameFavProgress = 'arrow-right';
	let engagementurl = incountryuri + '/dashboard?' + 'engagementid=' + id;

	let bindEngagementURL = false;
	let timelineAlertsStyle = 'engagementUpdates no-engagementalets';

	useEffect(() => {
		getNotice();
		if (selectedTab === tabTypes.FavoritesTab && fav === false) {
			hideComponent = true;
		}
	}, []);

	useEffect(() => {
		if (
			timeline?.timeline?.length > 0 &&
			timeline?.timeline[0].data.id !== undefined
		) {
			timelineAlertsStyle = 'engagementUpdates';
		}
	}, [timeline]);

	const getNotice = async () => {
		if (data && engagementstatus != EngagementStatus.MarkedForDeletion) {
			return;
		}
		let url = env.getURL('serviceUrl') + '/' + urls.LANDINGPAGENOTIFICATION_URL;
		await dispatch(getNotifications(url));

		if (notifications && notifications.notificationdetails) {
			for (const notice of notifications.notificationdetails) {
				if (notice.actionType == 4 && notice.engagementId == id) {
					setNotice(notice);
					break;
				}
			}
		}
	};

	const showValidEngagementLink = () => {
		if (!hasengagementpassedalloweddays) {
			bindEngagementURL = true;
		} else if (
			isengagementcodevalid &&
			isclientcodevalid &&
			!isclientengagementidvalidated &&
			hasengagementpassedalloweddays
		) {
			bindEngagementURL = true;
		} else if (
			isclientcodevalid &&
			isengagementcodevalid &&
			isclientengagementidvalidated &&
			hasengagementpassedalloweddays
		) {
			bindEngagementURL = true;
		} else if (!isclientcodevalid && hasengagementpassedalloweddays) {
			bindEngagementURL = false;
		} else if (!isengagementcodevalid && hasengagementpassedalloweddays) {
			bindEngagementURL = false;
		}

		// set the engagement link with href if valid otherwise with on click if invalid
		if (bindEngagementURL) {
			return (
				<a
					href={
						!apponlinestate && herculesappuri
							? herculesappuri + '?engagementid=' + id
							: engagementurl
					}
				>
					<EllipsesControl
						content={engagementdesc || <Skeleton width={250} height={20} />}
						tooltip={engagementdesc || <Skeleton width={250} height={20} />}
						isTooltipAvailable
					/>
				</a>
			);
		} else {
			return (
				<a onClick={showInvalidEngagementModal}>
					<EllipsesControl
						content={engagementdesc || <Skeleton width={250} height={20} />}
						tooltip={engagementdesc || <Skeleton width={250} height={20} />}
						isTooltipAvailable
					/>
				</a>
			);
		}
	};

	const showInvalidEngagementModal = () => {
		if (!hasengagementpassedalloweddays) {
			redirectToEngagement();
		} else if (
			isengagementcodevalid &&
			isclientcodevalid &&
			!isclientengagementidvalidated &&
			hasengagementpassedalloweddays
		) {
			redirectToEngagement();
		} else if (
			isclientcodevalid &&
			isengagementcodevalid &&
			isclientengagementidvalidated &&
			hasengagementpassedalloweddays
		) {
			redirectToEngagement();
		} else if (!isclientcodevalid && hasengagementpassedalloweddays) {
			setShowInvalidEngagementModalState(true);
		} else if (!isengagementcodevalid && hasengagementpassedalloweddays) {
			setShowInvalidEngagementModalState(true);
		}
	};

	const redirectToEngagement = () => {
		let url =
			!apponlinestate && herculesappuri
				? herculesappuri + '?engagementid=' + id
				: engagementurl;

		window.location.href = url;
	};

	const dblClick = () => { };

	const showTimeline = () => {
		return IsRestoredEngagement() || !apponlinestate ? false : true;
	};

	const getAlertMetricsStatus = () => {
		let status = false;

		timeline?.timeline?.filter((x) => {
			if (
				x &&
				x.data?.metrics?.engagementAlerts &&
				x.data?.id?.toString() === id
			) {
				let entries = Object.entries(x.data.metrics.engagementAlerts);
				entries.forEach((property) => {
					if (
						currentResource.type1Alerts.indexOf(property[0]) >= 0 &&
						((typeof property[1] == 'boolean' && property[1] == false) ||
							(typeof property[1] == 'number' && property[1] > 0))
					) {
						status = true;
						return;
					}
				});
			}
		});

		return status;
	};

	const IsRestoredEngagement = () => {
		if (
			data?.engagementsourceid === EngagementSource.Restore ||
			data?.engagementsourceid === EngagementSource.RestoreException
		) {
			return true;
		}
		return false;
	};

	const showRequestDeleteModal = () => {
		setShowRequestDeleteModalState(true);
	};

	const hideRequestDeleteModal = () => {
		setShowRequestDeleteModalState(false);
	};

	const onFavClick = async () => {
		setReadyForFavClick(false);
		setIsFavInProgress(true);
		let isFavTab = selectedTab === tabTypes.FavoritesTab;
		// Add favourite-inprogress class to arrow-right container when api call is in progress
		try {
			await dispatch(
				markUnmarkFavorite(
					env.getURL('serviceUrl') + '/' + 'EngagementUsers.json',
					EngUserModelPatchFavorites(id, !fav, 'markunmarkfavoriteengagement')
				)
			);
			setIsFavInProgress(false);
			setFav(!fav);
			setReadyForFavClick(true);
		} finally {
			if (isFavTab) {
				doRefreshDashboard();
			}
		}
	};

	const hideInvalidEngagementModal = () => {
		setShowInvalidEngagementModalState(false);
	};

	const doRefreshDashboard = () => {
		refreshDashboard();
	};

	const getLinkedHelixProjects = (linkedHelixProjects) => {
		setHelixProjects(linkedHelixProjects);
	};

	const renderLinkedHelixProjects = () => {
		let linkedProjectNames = [];
		helixProjects.forEach((project) => {
			linkedProjectNames.push(
				<li>
					<EllipsesControl
						content={project.projectName}
						tooltip={project.projectName}
						isTooltipAvailable
					></EllipsesControl>
				</li>
			);
		});

		return (
			<span className="helixlinked-tag">
				<Tooltip
					variant="alt"
					allowHover={false}
					trigger={
						<Button variant="text">
							<Icon src={contentIcLink24px} />
							{labels.linkedHelixProjects}
						</Button>
					}
					contentClassName="tooltip-helixlinked"
				>
					<ul>{linkedProjectNames}</ul>
				</Tooltip>
			</span>
		);
	};

	return (
		<>
			{!hideComponent && (
				<EngagementItemContainer
					className={
						notice ? 'eng-listwrapper deletion-reqsubmited' : 'eng-listwrapper'
					}
					onDoubleClick={dblClick}
				>					
					<EngagementItemDetails
						className={
							getAlertMetricsStatus()
								? 'engagementerror-indicator'
								: IsRestoredEngagement()
									? hasCountdown
										? 'restored-myengagement-with-countdown'
										: 'restored-myengagement'
									: engagementAlertsStatus
						}
					>
						<MotifCard orientation="horizontal">
							{IsRestoredEngagement() && data?.engagementdeletedate && (
								<ExpiryDateLabel
									expiryDate={data?.engagementdeletedate}
									setHasCountdown={setHasCountdown}
								/>
							)}

							{notice && (
								<MarkedDeleted className="deletion-status">
									<section className="deletionmsg">
										<SVGICONS hoverText="" styleName="large" name="delete" />
										<section className="deletionmsgholder">
											{labels.deleterequestbannertext1}
											{' ' + notice.displayuserName + ' '}
											<Moment local utc fromNow ago>
												{notice.displayDate}
											</Moment>
											{' ' + labels.deleterequestbannertext2}
											<a
												className="clickrespond"
												onClick={showRequestDeleteModal}
											>
												{labels.respond}
											</a>
										</section>
									</section>
								</MarkedDeleted>
							)}
							<section className="engagementInfo">
								<WorkspaceContent>
									<section className="clientname" title={clientName}>
										{(clientName === '!!!'
											? labels.invalidclientcode
											: clientName) || <Skeleton width={250} height={20} />}
									</section>
									<WorkspaceGroupAudit workspace={workspace} engagement={data} />
									{helixProjects.length > 0 ? renderLinkedHelixProjects() : null}
								</WorkspaceContent>

								{apponlinestate && (
									<Workspacemoreinfo className="rightmenu-container">
										<section className="pull-right">
											<section className="eng-rightmenu">
												<section className="engagementid">
													ID: {id || <Skeleton width={250} height={20} />}
												</section>
											</section>										
											<WSEdit>
												<CogWheel
													doRefreshDashboard={doRefreshDashboard}
													userPermissions={userPermissions}
													{...props}
												/>
											</WSEdit>
										</section>
									</Workspacemoreinfo>
								)}

								<EngagementDetails className="engagementname">
									<h4>
										<section className="anchor" data-autoid={id}>
											{showValidEngagementLink()}
										</section>
									</h4>
								</EngagementDetails>
							</section>
							{apponlinestate ? (IsRestoredEngagement() ? (
								<RestoredEngagementTimeline
									engagementId={id}
									AudienceUri={incountryaudienceuri}
									AppUri={incountryappuri}
									engagementisprofilecomplete={engagementisprofilecomplete}
									engagementstatus={engagementstatus}
									independencestatusid={independencestatusid}
									isindependencetemplateavailable={isindependencetemplateavailable}
									engagementuserstatusid={EngagementStatus.Active}
									getHelixProjects={getLinkedHelixProjects}
									expectedreportreleasedate={expectedreportreleasedate}
									garoletypeid={garoletypeid}
								/>
							) : (
								<section className="timelinecontrol">
									{
										<Timeline
											engagementId={id}
											AudienceUri={incountryaudienceuri}
											AppUri={incountryappuri}
											engagementisprofilecomplete={engagementisprofilecomplete}
											engagementstatus={engagementstatus}
											independencestatusid={independencestatusid}
											isindependencetemplateavailable={isindependencetemplateavailable}
											engagementuserstatusid={EngagementStatus.Active}
											getHelixProjects={getLinkedHelixProjects}
											expectedreportreleasedate={expectedreportreleasedate}
											archivaldeadlinedate={archivaldeadlinedate}
											garoletypeid={garoletypeid}
										/>
									}
								</section>
							)) : []}
							{apponlinestate && (
								<section className={timelineAlertsStyle}>
									<section className="eng-actions">
										<section className="restored-eng">
											{IsRestoredEngagement() ? labels.restoredengagement : ''}
										</section>
									</section>
									<FavButton
										isFav={fav}
										onFavClick={onFavClick}
										readyForFavClick={readyForFavClick}
									/>
								</section>
							)}
						</MotifCard>
					</EngagementItemDetails>					
					<EngagementRequestDeleteModal
						key={notice}
						notification={notice}
						show={showRequestDeleteModalState}
						hide={hideRequestDeleteModal}
						{...props}
					/>
					<EngagementInvalidModal
						notification={notice}
						show={showInvalidEngagementModalState}
						hide={hideInvalidEngagementModal}
						{...props}
					/>
				</EngagementItemContainer>
			)}
		</>
	);
};
export default EngagementItem;

const EngagementItemDetails = styled.section`
	.motif-card-border.motif-card-orientation-horizontal {
		width: 100%;
		height: auto;
		gap: 0;
		padding: 0;		
		border-left: var(--px-5) solid var(--blue-300);
		min-width: 0;
		min-height: 0;
	}
	&.engagementerror-indicator {
		.motif-card-border.motif-card-orientation-horizontal {
			border-left: var(--px-5) solid var(--red-600);
		}
	}
	&.restored-myengagement-with-countdown {		
		.restore-expiry {
			padding: var(--px-15) var(--px-20) var(--px-5) var(--px-20);
		}
		.rightmenu-container {
			margin-top: calc(var(--px-35) * -1);
		}
		.engagementname {
			margin-top: var(--px-5);
		}
	}
	& .modal-wrapper {
		height: 300px !important;
		width: 675px !important;
	}

	& .editList {
		& ul {
			background-color: var(--neutrals-00white);
			padding: 0;
			margin: 0;
			line-height: normal;

			& li {
				width: 100%;
				margin: 0;
				padding: 0;
				display: inline-block;
				font-size: var(--px-14);
				line-height: normal;

				& a,
				& span {
					text-decoration: none;
					color: var(--neutrals-900);
					width: 100%;
					display: inline-block;
					outline: none;
					font-weight: normal;
					padding: var(--px-8) var(--px-16);
				}

				&:hover {
					background-color: var(--neutrals-200);
					cursor: pointer;
				}
			}
		}
	}
	.deletion-status ~ .timelinecontrol .err-msg {
		margin-top: 0;
	}
	& .engagementInfo {
		width: 100%;
		padding: var(--px-10) 0 var(--px-10) 0;
		position: relative;
	}
	.motif-timeline-content
		.motif-milestone
		.motif-milestone-content
		.motif-progress-bar-circle {
		top: -28px;
	}

	.motif-timeline-content {
		width: 100%;
	}

	& .timelinecontrol {
		width: 100%;
		font-size: var(--px-14);
		color: var(--neutrals-700);

		.timelineloader {
			width: 20%;
			margin: 0 auto;
			padding-bottom: var(--px-30);

			.loadinglabel {
				margin-bottom: var(--px-5);
				color: var(--neutrals-700)!important;
			}
		}

		.timelineerrorlabel {
			padding: 0 var(--px-30) var(--px-30) var(--px-30);
		}

		.motif-timeline {
			@media screen and (max-width: 1366px) {
				margin-bottom: var(--px-65);
			}
		}
	}

	&.arcstatus-wrapper {
		& .arcstatus {
			background-color: var(--red-600);
			color: var(--neutrals-00white);
			padding: var(--px-2) var(--px-5);
			font-size: var(--px-14);

			& svg {
				fill: var(--neutrals-00white);
				vertical-align: top;
				margin: var(--px-2) var(--px-10) 0 0;
			}

			& .arcmsg {
				width: 75%;
				display: inline-block;
				line-height: var(--px-28);
			}

			& .arcaction {
				float: right;
				& a {
					text-decoration: none;
					cursor: pointer;
					margin-right: var(--px-40);
					font-weight: bold;
					margin-top: var(--px-5);
				}
				& a:hover {
					text-decoration: underline;
					color: #337ab7;
				}
			}
		}
	}

	& .engagementUpdates {
		display:flex;
		align-items: center;
		justify-content: space-between;
		border-top: var(--border-width-small) solid var(--neutrals-200);		
		& .faviconholder {
			float: right;
			position: relative;
			display: block;
		}
		& .eng-actions {
			display: inline-block;
			margin-left: var(--px-10);
			.restored-eng {
				color: var(--neutrals-700);
				font-size: var(--px-14);
				font-weight: bold;
				margin: 0px 0px 0px var(--px-20);
			}
		}
		& .arrow-right {
			width: 0;
			height: 0;
			border-top: var(--px-45) solid transparent;
			border-bottom: 0 solid transparent;
			border-right: var(--px-45) solid var(--neutrals-900);
			float: right;
			margin-right: calc(var(--px-1) * -1);
			margin-bottom: calc(var(--px-1) * -1);
			box-shadow: 3px 3px 5px 0px var(--neutrals-300);
			&.favourite-inprogress {
				border-top: var(--px-45) solid transparent;
				border-bottom: 0 solid transparent;
				border-right: var(--px-45) solid var(--neutrals-700);
				pointer-events: none;
				opacity: 0.5;
				+ section svg {
					cursor: default;
					pointer-events: none;
				}
			}
		}
	}
`;

const Timelinelegend = styled.section`
	display: inline-block;
	color: var(--neutrals-700);
	vertical-align: top;
	& .legendname {
		margin-right: 1em;
		display: inline-block;

		& svg {
			fill: var(--blue-300);
			margin-right: var(--px-5);

			&.milestone-icon {
				transform: rotate(-20deg);
			}
		}

		& label {
			vertical-align: top;
			line-height: normal;
			font-weight: normal;
			font-size: var(--px-14);
			-webkit-user-select: none;
			-moz-user-select: none;
			user-select: none;
		}
	}
`;

const EngagementDetails = styled.section`
	width: 100%;
	display: inline-block;
	& svg {
		vertical-align: middle;
		cursor: pointer;
		fill: var(--neutrals-700);
	}

	& h4 {
		display: inline-block;
		font-size: var(--px-16);
		margin: 0;
		font-weight: bold;
		color: var(--neutrals-900);
		padding-left: var(--px-30);
		width: 100%;

		& section.anchor {
			color: var(--neutrals-900);
			vertical-align: middle;
			width: 70%;
			display: inline-flex;
			line-height: var(--px-40);
			max-width: 70%;

			& a {
				display: inline-flex;
				color: var(--neutrals-900);
				max-width: 100%;
			}
		}

		& .eng-rightmenu {
			line-height: var(--px-40);
			width: 35%;
			& .restored-eng {
				color: var(--neutrals-300);
				font-size: var(--px-14);
				font-weight: bold;
			}

			& > section {
				display: inline-block;
			}
		}

		& .ellipses {
			width: auto;
			max-width: none;
			display: inline-flex;
			color: var(--neutrals-900);
			& div {
				max-width: none;
			}
		}
	}

	& .engagementid {
		display: inline-block;
		font-size: var(--px-14);
		line-height: var(--px-40);
		margin: 0 var(--px-50) 0 var(--px-20);
		color: var(--neutrals-700);
		vertical-align: text-top;
		font-weight: normal;
		float: right;
		width: var(--px-110);
	}
`;

const WorkspaceContent = styled.div`
	padding-left: var(--px-30);
	width: 67%;
	float: left;
	@media screen and (max-width: 1366px) {
		width: 61%;
	}
	@media screen and (min-width: 1367px) and (max-width: 1600px) {
		width: 61%;
	}
	.clientname,
	.workspace-name,
	.engagement-type,
	.engagement-typelinked {
		display: inline-block;
		font-size: var(--px-14);
		color: var(--neutrals-700);
		line-height: normal;
		max-width: 28%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		vertical-align: middle;
		padding-right: var(--px-3);
	}
	.workspace-name {
		max-width: 28%;
		padding-left: var(--px-3);
	}
	.engagement-type {
		max-width: 19%;
		padding-left: var(--px-3);
	}
	.engagement-typelinked {
		display: inline-block;
		max-width: 14%;
		padding-left: var(--px-3);
		.motif-icon svg {
			margin-top: calc(var(--px-3) * -1);
			vertical-align: middle;
			margin-right: var(--px-3);
		}
		.engagementtype-linkedtxt {
			font-size: var(--px-14);
			color: var(--neutrals-700);
			line-height: normal;
		}
	}
	.helixlinked-tag {
		display: inline-flex;
		border: 1px solid var(--teal-600);
		border-radius: var(--px-32);
		max-width: 18%;
		margin-left: var(--px-10);
		padding: var(--px-1) var(--px-10) 0 var(--px-10);
		vertical-align: middle;
		button {
			font-size: var(--px-12);
			font-weight: normal;
			color: var(--teal-600);
			border: none;
			box-shadow: none;
			svg {
				width: var(--px-24) !important;
				height: var(--px-16) !important;
				top: var(--px-1_5);
				color: var(--teal-600) !important;
			}
			&:hover {
				border: none;
				margin-bottom: 0;
				color: var(--teal-600);
			}
		}
		.motif-text-button:hover {
			margin-bottom: 0;
		}
		.motif-tooltip-wrapper {
			display: flex;
			align-items: center;
		}
	}
`;

const Workspacemoreinfo = styled.section`
	padding-right: var(--px-10);
	padding-top: var(--px-6);
	width: 32%;
	float: right;
	line-height: normal;
	@media screen and (max-width: 1366px) {
		width: 38%;
	}
	@media screen and (min-width: 1367px) and (max-width: 1600px) {
		width: 38%;
	}
	& .eng-rightmenu {
		display: inline-block;
		& .restored-eng {
			color: var(--neutrals-300);
			font-size: var(--px-14);
			font-weight: bold;
		}

		& > section {
			display: inline-block;
		}
	}
	& .engagementid {
		display: inline-block;
		font-size: var(--px-14);
		margin: 0 var(--px-50) 0 var(--px-10);
		color: var(--neutrals-700);
		font-weight: normal;
		float: right;
		width: var(--px-110);
		text-align: right;
	}
`;

const WSEdit = styled.div`
	flex-grow: 1;
	display: inline-block;
	float: right;
	margin-right: var(--px-10);
	svg {
		margin-top: calc(var(--px-4) * -1);
		transform: rotate(90deg);
	}
`;

const MarkedDeleted = styled.section`
	& {
		padding: var(--px-2) var(--px-5);
		font-size: var(--px-14);
		box-shadow: -1px 0px 6px 0px var(--neutrals-300);
	}

	& .deletionmsg {
		width: 95%;
		display: inline-block;
		line-height: var(--px-20);
		margin-top: var(--px-10);
		margin-bottom: var(--px-10);
		.deletionmsgholder {
			display: inline;
			vertical-align: text-top;
			font-weight: bold;
		}

		svg {
			fill: var(--red-600) !important;
			vertical-align: middle;
			margin: 0 var(--px-5) 0 0;
			width: var(--px-20);
			height: var(--px-20);
		}

		.clickrespond {
			font-weight: bold;
			color: var(--blue-500);
			margin-left: var(--px-4);
			cursor: pointer;
		}
	}
`;

const EngagementItemContainer = styled.section`
	margin: var(--px-15) 0;
	&.eng-listwrapper.deletion-reqsubmited > section:first-child {
		.deletion-status ~ .timelinecontrol .err-msg {
			margin-top: var(--px-15);
		}
		@media screen and (max-width: 1366px) {
			.deletion-status ~ .timelinecontrol .err-msg {
				margin-top: var(--px-21);
			}
		}
	}
`;
