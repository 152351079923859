import React, { useRef, useMemo, useState } from 'react';
import { useRect } from './useRect.util';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ProgressBar from '@ey/motif-react-wrapper/ProgressBar';
import Tooltip from '@ey/motif-react-wrapper/Tooltip';

import Milestone from './milestone/milestone';
import './_timeline.scss';

const getDatePercentage = (date, startDate, endDate) =>
	(((Date.parse(date) || date) - Date.parse(startDate)) /
		(Date.parse(endDate) - Date.parse(startDate))) *
	100;

const getReadableDate = (date, dateFormat, short) => {
	let month = `${date.getMonth() + 1}`;
	let day = String(date.getDate());
	const year = date.getFullYear();

	if (month.length < 2) {
		month = `0${month}`;
	}
	if (day.length < 2) {
		day = `0${day}`;
	}

	let dateString = '';
	let shortDateString = '';
	if (dateFormat === 'MM/DD/YYYY') {
		dateString = [month, day, year].join('/');
		shortDateString = dateString.substr(0, 5);
	} else if (dateFormat === 'DD/MM/YYYY') {
		dateString = [day, month, year].join('/');
		shortDateString = dateString.substr(0, 5);
	} else {
		dateString = [year, month, day].join('/');
		shortDateString = dateString.substr(5, 10);
	}

	return short ? shortDateString : dateString;
};

const getYear = (date) => date.getFullYear();

const getDefaultMarkers = (startDt, endDt) => {
	const start = Date.parse(startDt);
	const end = Date.parse(endDt);
	const splitTime = Math.floor((end - start) / 4);

	return [
		new Date(start),
		new Date(start + splitTime),
		new Date(start + splitTime * 2),
		new Date(start + splitTime * 3),
		new Date(end)
	];
};

const getCombinedElement = (currentElement, nextElement) => {
	const combinedElement = {
		progress: currentElement.progress || nextElement.progress,
		tooltipComponent: null
	};
	if (currentElement.tooltipComponent || nextElement.tooltipComponent) {
		combinedElement.tooltipComponent = (
			<div>
				{currentElement.tooltipComponent}
				{nextElement.tooltipComponent && (
					<React.Fragment>
						<br />
						{nextElement.tooltipComponent}
					</React.Fragment>
				)}
			</div>
		);
	}

	return combinedElement;
};

const moveDataPoint = (milestoneShift, keyEventShift, diff, type) => {
	let value = 0;
	if (type === 'milestone') {
		value = milestoneShift - diff;
	} else {
		value = keyEventShift - diff;
	}

	return value;
};

const getDataPoints = (
	startDate,
	endDate,
	milestoneConfig,
	keyEventConfig,
	width
) => {
	const data = [];
	if (milestoneConfig) {
		milestoneConfig.forEach((milestone) => {
			data.push({
				type: 'milestone',
				value:
					(getDatePercentage(milestone.date, startDate, endDate) / 100) * width,
				date: milestone.date,
				progress: milestone.progress,
				tooltipComponent: milestone.tooltipComponent,
				tooltipPlacement: milestone.tooltipPlacement
			});
		});
	}

	if (keyEventConfig) {
		keyEventConfig.forEach((keyEvent) => {
			data.push({
				type: 'keyEvent',
				value:
					(getDatePercentage(keyEvent.date, startDate, endDate) / 100) * width,
				date: keyEvent.date,
				tooltipComponent: keyEvent.tooltipComponent,
				tooltipPlacement: keyEvent.tooltipPlacement
			});
		});
	}

	data.sort((val1, val2) => val2.value - val1.value);

	for (let int = 0; int < data.length - 1; int++) {
		const currentElement = data[int];
		const nextElement = data[int + 1];
		const diff = currentElement.value - nextElement.value;
		const sameDate =
			Date.parse(currentElement.date) === Date.parse(nextElement.date);

		if (sameDate) {
			const combinedElement = getCombinedElement(currentElement, nextElement);
			nextElement.value = currentElement.value;
			nextElement.type = 'milestone';
			nextElement.progress = combinedElement.progress;
			nextElement.tooltipComponent = combinedElement.tooltipComponent;
		} else {
			if (currentElement.type === 'milestone' && diff < 25) {
				nextElement.value -= moveDataPoint(25, 19, diff, nextElement.type);
			}
			if (currentElement.type === 'keyEvent' && diff < 18) {
				nextElement.value -= moveDataPoint(20, 15, diff, nextElement.type);
			}
		}
	}

	return data.reverse();
};

const MotifTimeline = ({
	startDate,
	endDate,
	dateMarkers,
	currentDate,
	milestoneConfig,
	keyEventConfig,
	tooltipClassName,
	hideTooltips,
	dateFormat
}) => {
	const ref = useRef(null);
	const rect = useRect(ref);
	const { width } = rect;

	const [pastDate, setPastDate] = useState(false);

	const data = useMemo(
		() =>
			getDataPoints(startDate, endDate, milestoneConfig, keyEventConfig, width),
		[startDate, endDate, milestoneConfig, keyEventConfig, width]
	);

	let currDate;
	const parsedEndDate = Date.parse(endDate);
	if (currentDate) {
		currDate = Date.parse(currentDate);
	} else {
		currDate = new Date().toISOString();
	}

	if (Date.parse(currDate) >= parsedEndDate) {
		currDate = parsedEndDate;
	}

	let dateMarks;
	if (dateMarkers) {
		const newDateMarkers = [startDate];
		for (let int = 0; int < dateMarkers.length; int++) {
			newDateMarkers.push(dateMarkers[int]);
		}
		newDateMarkers.push(endDate);
		dateMarks = newDateMarkers;
	} else {
		dateMarks = getDefaultMarkers(startDate, endDate);
	}

	const yearMarks = [];
	for (let int = 0; int < dateMarks.length - 1; int++) {
		const currentMark = new Date(dateMarks[int]).getFullYear();
		const nextMark = new Date(dateMarks[int + 1]).getFullYear();
		if (currentMark < nextMark) {
			yearMarks.push(`01/01/${nextMark}`);
		}
	}

	let dateList = data.map(
		(dataPoint) => Date.parse(dataPoint.date) === currDate
	);

	let filteredDate = dateList.includes(true);

	return (
		<div className="motif-timeline hide">
			<div
				className="motif-timeline-content styled-motif-timeline-content"
				ref={ref}
			>
				<ProgressBar
					value={getDatePercentage(currDate, startDate, endDate)}
				/>

				<div
					className={`motif-current-date-marker  ${filteredDate ? 'alignDateIcon' : ''
						}`}
					style={{ left: `${getDatePercentage(currDate, startDate, endDate)}%` }}
				>
					<div className="motif-current-date-icon" />
					<p className="motif-current-date">
						{getReadableDate(new Date(currDate), dateFormat, true)}
					</p>
				</div>
				{data.map((dataPoint, index) => {
					const readableDate = getReadableDate(
						new Date(dataPoint.date),
						dateFormat
					);
					const tooltipId = `${readableDate}-${index}`;
					if (dataPoint.type === 'milestone') {
						const past = Date.parse(dataPoint.date) <= currDate;

						return (
							<div
								key={index}
								className="motif-milestone styled-motif-milestone"
								style={{ left: dataPoint.value >= 0 ? dataPoint.value : 0 }}
							>
								<Tooltip
									trigger={
										<button
											className={classNames('motif-milestone-content', {
												past: past && dataPoint.progress < 100
											})}
											aria-describedby={tooltipId}
											aria-label={readableDate}
										>
											<Milestone past={past} progress={dataPoint.progress} />
										</button>
									}
									id={tooltipId}
									placement={dataPoint.tooltipPlacement || 'top'}
									contentClassName={tooltipClassName}
									hide={hideTooltips}
								>
									{dataPoint.tooltipComponent || <div>{readableDate}</div>}
								</Tooltip>
							</div>
						);
					}

					return (
						<div
							key={dataPoint.date + dataPoint.type}
							className="motif-key-event"
							style={{ left: dataPoint.value >= 0 ? dataPoint.value : 0 }}
						>
							<Tooltip
								trigger={
									<button
										className="motif-key-event-shape"
										aria-describedby={tooltipId}
										aria-label={readableDate}
									/>
								}
								id={tooltipId}
								placement={dataPoint.tooltipPlacement || 'top'}
								contentClassName={tooltipClassName}
								hide={hideTooltips}
							>
								{dataPoint.tooltipComponent || <div>{readableDate}</div>}
							</Tooltip>
						</div>
					);
				})}
				{dateMarks.map((date) => (
					<div
						key={date}
						className="motif-date-marker"
						style={{ left: `${getDatePercentage(date, startDate, endDate)}%` }}
					>
						<p title={getReadableDate(new Date(date), dateFormat)}>
							{getReadableDate(new Date(date), dateFormat, true)}
						</p>
					</div>
				))}
				{yearMarks.map((date) => (
					<div
						key={date}
						className="motif-date-marker-year"
						style={{ left: `${getDatePercentage(date, startDate, endDate)}%` }}
					>
						<p>{getYear(new Date(date))}</p>
					</div>
				))}
			</div>
		</div>
	);
};

MotifTimeline.defaultProps = {
	startDate: '2019-02-02T00:00:00Z',
	endDate: '2019-10-02T00:00:00Z',
	currentDate: null,
	dateMarkers: null,
	milestoneConfig: null,
	keyEventConfig: null,
	tooltipClassName: '',
	dateFormat: 'MM/DD/YYYY',
	hideTooltips: false
};

MotifTimeline.propTypes = {
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	currentDate: PropTypes.string,
	dateMarkers: PropTypes.arrayOf(PropTypes.string),
	milestoneConfig: PropTypes.arrayOf(
		PropTypes.exact({
			date: PropTypes.string,
			progress: PropTypes.number,
			tooltipComponent: PropTypes.oneOfType([
				PropTypes.element,
				PropTypes.elementType
			]),
			tooltipPlacement: PropTypes.oneOf([
				'auto',
				'top',
				'bottom',
				'left',
				'right'
			]),
			tooltipClassName: PropTypes.string
		})
	),
	keyEventConfig: PropTypes.arrayOf(
		PropTypes.exact({
			date: PropTypes.string,
			tooltipComponent: PropTypes.oneOfType([
				PropTypes.element,
				PropTypes.elementType
			]),
			tooltipPlacement: PropTypes.oneOf([
				'auto',
				'top',
				'bottom',
				'left',
				'right'
			]),
			tooltipClassName: PropTypes.string
		})
	),
	tooltipClassName: PropTypes.string,
	dateFormat: PropTypes.oneOf(['MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY/MM/DD']),
	hideTooltips: PropTypes.bool
};

export { MotifTimeline };
