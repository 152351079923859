/**
 * Created by rastwe on 3/14/2017.
 */
/**
 * This file represents the initial state in the Redux store.
 * It's used to provide default values in the reducers, in cases
 * where the store doesn't yet have a value, and there are no
 * action matches in the reducer, so that the return value of
 * the reducer function will never be undefined.
 * Use this file to help give you an idea what the overall
 * picture of the Redux store looks like.
 */

import {displayGroupings, tabTypes} from '../util/uiconstants';

export default {
	adminEngagementDocumentsList: [],
	adminUserPermissions: {},
	adminUserRoles: {},
	adminUsers: {},
	sharepointWorkspaces: {},
	workSpaceDetails:{},
	myEngagements: {},
	myEngagementsV2: {},
	appVersion: false,
	arcFiles: {},
	archives: {},
	cache: {},
	clientWorkspaces: {},
	clientengagement: {},
	copyHub: {},
	countries: {},
	crp: {},
	dataCenters: {},
	employeeADStatuses: {
		statuses: []
	},
	engagementDetails: {},
	engagementError: {},
	engagementMetaData: {},
	engagementOverview: [],
	engagementRoles: {},
	engagementSearchParameters: {
		engagementId: '',
		engagementName: '',
		clientNameCode: '',
		codeOrCodeName: '',
		userGUIEmailUserId: '',
		countryId: null
	},
	engagementTemplates: {},
	engagementUserPermissions: {},
	engagementUserStatuses: [],
	engagementUsers: {},
	engagements: {},
	errors: {},
	eyusers: {},
	favoriteEngagements: {},
	favoriteWorkspaces: {},
	featureConfiguration: {},
	groupAuditLink: {},
	idleLogout: {
		timeoutID: null,
		startTime: null,
		showPopup: false,
		startLogout: false
	},
	independenceStatuses: [],
	individualWorkspaceData: {},
	isLoading: false,
	isModalOpen: false,
	knowledgeTemplate: {},
	languages: {},
	messageById: {},
	messaging: {},
	modules: {},
	myEngDashboard: {
		activeTab: tabTypes.AllEngagementsTab,
		groupingType: displayGroupings.LIST,
		refreshDashBoard: 0,
		refreshDashBoardPagnReset: 0
	},
	myEngagements: {},
	myWorkspaces: {},
	notifications: {},
	permissionGroup: {},
	permissionGroupUsers: {},
	primaryoffices: {},
	rbacRoles: null,
	regions: {},
	requestaccess: {},
	restoreEngagement: {},
	reviewEngagements: {},
	reviewWorkspaces: {},
	roles: {},
	rollforwardEngagement: {},
	saveEngagement: {},
	searchEngagement: {
		searchText: ''
	},
	selectTemplate: {},
	servicelines: {},
	singleEngagement: {},
	successfulToastMessage: {},
	spotfireDataCenter: '',
	status: {},
	subServiceLines: {},
	successMessages: {
		updateEngagement: ''
	},
	timeline: {},
	userContext: {},
	userSettings: {},
	userPermissions: {},
	viewAccessList: {},
};
