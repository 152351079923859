/**
 * @module Workspaces
 * Container Component Workspaces
 */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import env from '../../util/env';
import { SVGICONS } from '@ey/icons';
import Button from '@ey/motif-react-wrapper/Button';
import FavButton from './favoritesbutton';
import CogWheel from './cogwheel';
import { tabTypes, urls } from '../../util/uiconstants';
import { EngagementStatus } from '../../util/enumerations';
import { EngUserModelPatchFavorites } from '../../util/preparemodel';
import { labels, showLinks } from '../../util/uiconstants';
import EllipsesControl from '@ey/ellipses-control';
import EngagementRequestDeleteModal from './engagementRequestDeleteModal';
import Moment from 'react-moment';
import { markUnmarkFavorite } from '../../actions/engagementuseractions';
import { getNotifications } from '../../actions/notificationsactions';
import WorkspaceGroupAudit from './workspaceGroupAudit';
import {
	IndependenceStatus,
	EngagementAccessType
} from '../../util/enumerations';
import MotifCard from '@ey/motif-react-wrapper/Card';

const IncompleteEngagementItem = (props) => {
	const dispatch = useDispatch();
	const {
		data,
		id,
		clientId,
		clientName,
		workspace,
		workspaceID,
		refreshDashboard,
		incomplete,
		isReviewWorksapce
	} = props;
	const {
		isfavorite,
		incountryuri,
		workspacecountryid,
		engagementdesc,
		engagementversion,
		engagementstatus
	} = data;

	const selectedTab = useSelector((store) => store.dashboard.activeTab);
	const apponlinestate = useSelector((store) => store.apponlinestate);
	const notifications = useSelector((store) => store.notifications);

	const [isFavInProgress, setIsFavInProgress] = useState(false);
	const [showRequestDeleteModalState, setShowRequestDeleteModalState] =
		useState(false);
	const [fav, setFav] = useState(isfavorite);
	const [readyForFavClick, setReadyForFavClick] = useState(true);

	const engagementurl =
		incountryuri +
		'/v1redirect/index?' +
		'countryid=' +
		workspacecountryid +
		'&engagementid=' +
		id +
		'&engagementdesc=' +
		encodeURIComponent(engagementdesc.substring(0, 50)) +
		'&clientid=' +
		clientId +
		'&clientname=' +
		encodeURIComponent(clientName) +
		'&workspacedesc=' +
		encodeURIComponent(workspace.workspacedesc.trim()) +
		'&workspaceid=' +
		workspaceID +
		'&engagementversion=' +
		encodeURIComponent(engagementversion) +
		'&userPrefLang=en-us';
	const [notice, setNotice] = useState(null);

	let hideComponent = false;
	let classNameFavProgress = 'arrow-right';

	useEffect(() => {
		getNotice();
		if (selectedTab === tabTypes.FavoritesTab && fav === false) {
			hideComponent = true;
		}
	}, []);

	const getNotice = async () => {
		if (data && engagementstatus != EngagementStatus.MarkedForDeletion) {
			return;
		}

		let url = env.getURL('serviceUrl') + '/' + urls.LANDINGPAGENOTIFICATION_URL;
		try {
			await dispatch(getNotifications(url));
		} finally {
			if (notifications && notifications.notificationdetails) {
				const notice = notifications.notificationdetails.find(
					(notice) => notice.actionType == 4 && notice.engagementId == id
				);
				setNotice(notice);
			}
		}
	};

	const showRequestDeleteModal = () => {
		setShowRequestDeleteModalState(true);
	};

	const hideRequestDeleteModal = () => {
		setShowRequestDeleteModalState(false);
	};

	const doRefreshDashboard = () => {
		refreshDashboard();
	};

	const showTimeline = () => {
		return false;
	};

	const onFavClick = async () => {
		setReadyForFavClick(false);
		setIsFavInProgress(true);
		let isFavTab = selectedTab === tabTypes.FavoritesTab;
		// Add favourite-inprogress class to arrow-right container when api call is in progress
		try {
			await dispatch(
				markUnmarkFavorite(
					env.getURL('serviceUrl') + '/' + 'EngagementUsers.json',
					EngUserModelPatchFavorites(id, !fav, 'markunmarkfavoriteengagement')
				)
			);
			setIsFavInProgress(false);
			setFav(!fav);
			setReadyForFavClick(true);
		} finally {
			if (isFavTab) {
				doRefreshDashboard();
			}
		}
	};

	const dblClick = () => { };

	const getIncountryURL = (navigateToPage) => {
		return (
			incountryuri +
			navigateToPage +
			'countryid=' +
			workspacecountryid +
			'&engagementid=' +
			id +
			'&engagementdesc=' +
			encodeURIComponent(engagementdesc.substring(0, 50)) +
			'&userPrefLang=en-us&workspacedesc=' +
			encodeURIComponent(workspace.workspacedesc.trim()) +
			'&workspaceid=' +
			workspaceID +
			'&engagementversion=' +
			Math.floor(Number.parseInt(engagementversion)) +
			'&engagementversionfull=' +
			engagementversion
		);
	};

	const routeToCompleteProfile = () => {
		let url = '/v1redirect/engagementprofile?';
		window.location.href = getIncountryURL(url);
	};

	const routeToCompleteIndependence = () => {
		let url = '/v1redirect/index?';
		let independenceurlpage = getIncountryURL(url) + '&showindependence=true';
		window.location.href = independenceurlpage;
	};

	const incompeletetabs = () => {
		if (incomplete === showLinks.ProfileSubmit && apponlinestate) {
			return (
				<section>
					<Button variant="text-alt" onClick={routeToCompleteProfile}>
						{labels.completeprofileheader}
					</Button>
				</section>
			);
		} else if (
			incomplete === showLinks.CompleteIndependence &&
			apponlinestate
		) {
			return (
				<section>
					<Button variant="text-alt" onClick={routeToCompleteIndependence}>
						{labels.completeindependenceheader}
					</Button>
				</section>
			);
		} else return null;
	};

	const IsRestoredEngagement = () => {
		return engagementstatus === EngagementStatus.Restored ? true : false;
	};

	const IsContentDeliveryInprogressEngagement = () => {
		return engagementstatus === EngagementStatus.ContentDeliveryInProgress
			? true
			: false;
	};

	return (
		<>
			{!hideComponent && (
				<EngagementItemContainer
					className="eng-listwrapper"
					onDoubleClick={dblClick}
				>
					<EngagementItemDetails className="eng-listholder arcstatus-wrapper">
						<MotifCard orientation="horizontal">
							{notice && (
								<MarkedDeleted className="deletion-status">
									<section className="deletionmsg">
										<SVGICONS hoverText="" styleName="large" name="delete" />
										<section className="deletionmsgholder">
											{labels.deleterequestbannertext1}
											{' ' + notice.displayuserName + ' '}
											<Moment local utc fromNow ago>
												{notice.displayDate}
											</Moment>
											{' ' + labels.deleterequestbannertext2}
											<a
												className="clickrespond"
												onClick={showRequestDeleteModal}
											>
												{labels.respond}
											</a>
										</section>
									</section>
								</MarkedDeleted>
							)}
							<section className="engagementInfo">
								<WorkspaceContent>
									<section className="clientname" title={clientName}>
										{(clientName === '!!!'
											? labels.invalidclientcode
											: clientName) || <Skeleton width={250} height={20} />}
									</section>
									<WorkspaceGroupAudit workspace={workspace} engagement={data} />
								</WorkspaceContent>
								{apponlinestate && (
									<Workspacemoreinfo>
										<section className="pull-right">
											<section className="eng-rightmenu">
												<section className="engagementid">
													ID: {id || <Skeleton width={250} height={20} />}
												</section>
											</section>
											<WSEdit>
												<CogWheel
													doRefreshDashboard={doRefreshDashboard}
													{...props}
												/>
											</WSEdit>
										</section>
									</Workspacemoreinfo>
								)}
								<EngagementDetails className="engagementname">
									<h4>
										<section className="anchor" data-autoid={id}>
											<a href={engagementurl} className={'newengagement'}>
												<EllipsesControl
													content={
														engagementdesc || <Skeleton width={250} height={20} />
													}
													tooltip={
														engagementdesc || <Skeleton width={250} height={20} />
													}
													isTooltipAvailable
												/>
											</a>
										</section>
									</h4>
								</EngagementDetails>
							</section>
							{apponlinestate && (
								<section className="engagementUpdates">
									<section className="eng-actions">
										{isReviewWorksapce === true ? null : incompeletetabs()}
										<section className="restored-eng">
											{IsRestoredEngagement()
												? labels.restoredengagement
												: IsContentDeliveryInprogressEngagement()
													? labels.profiledeliveryinprogress
													: ''}
										</section>
									</section>
									<FavButton
										isFav={fav}
										onFavClick={onFavClick}
										readyForFavClick={readyForFavClick}
									/>
								</section>
							)}
						</MotifCard>
					</EngagementItemDetails>
					<EngagementRequestDeleteModal
						key={notice}
						notification={notice}
						show={showRequestDeleteModalState}
						hide={hideRequestDeleteModal}
						{...props}
					/>
				</EngagementItemContainer>
			)}
		</>
	);
};
export default IncompleteEngagementItem;

const EngagementItemDetails = styled.section`
	.motif-card-border.motif-card-orientation-horizontal {
		width: 100%;
		height: auto;
		gap: 0;
		padding: 0;		
		border-left: var(--px-5) solid var(--blue-300);
		min-width: 0;
		min-height: 0;
	}
	&.engagementerror-indicator {
		.motif-card-border.motif-card-orientation-horizontal {
			border-left: var(--px-5) solid var(--red-600);
		}
	}
	& .modal-wrapper {
		height: var(--px-300) !important;
		width: var(--px-675) !important;
	}

	& .editList {
		& ul {
			background-color: var(--neutrals-00white);
			padding: 0;
			margin: 0;
			line-height: normal;

			& li {
				width: 100%;
				margin: 0;
				padding: 0;
				display: inline-block;
				font-size: var(--px-14);
				line-height: normal;

				& a,
				& span {
					text-decoration: none;
					color: var(--neutrals-900);
					width: 100%;
					display: inline-block;
					outline: none;
					font-weight: normal;
					padding: var(--px-8) var(--px-16);
				}

				&:hover {
					background-color: var(--neutrals-200);
					cursor: pointer;
				}
			}
		}
	}

	& .engagementInfo {
		width: 100%;
		padding: var(--px-15) 0 var(--px-10) 0;
		position: relative;

		& .engagementname .anchor.newengagement {
			color: var(--neutrals-300);
			pointer-events: none;
			cursor: default;
		}
	}

	& .timelinecontrol {
		float: left;
		width: 100%;
		padding-bottom: var(--px-15);
	}

	& .engagementUpdates {
		display:flex;
		align-items: center;
		justify-content: space-between;
		border-top: var(--border-width-small) solid var(--neutrals-200);
		& .faviconholder {
			float: right;
			position: relative;
			display: block;
		}
		& .eng-actions {
			display: inline-block;
			margin-left: var(--px-10);
			.motif-text-alt-button {
				margin-left: var(--px-20);
			}
			.restored-eng {
				color: var(--neutrals-700);
				font-size: var(--px-14);
				font-weight: bold;
				margin: 0px 0px 0px var(--px-20);
			}
		}
		& .eng-actions > section {
			display: inline-block;
		}
		& .arrow-right {
			width: 0;
			height: 0;
			border-top: var(--px-45) solid transparent;
			border-bottom: 0 solid transparent;
			border-right: var(--px-45) solid var(--neutrals-900);
			float: right;
			margin-right: calc(var(--px-2) * -1);
			margin-bottom: calc(var(--px-2) * -1);
			box-shadow: 3px 3px 5px 0px var(--neutrals-300);
			&.favourite-inprogress {
				border-bottom: 0 solid transparent;
				border-right: var(--px-45) solid var(--neutrals-700);
				pointer-events: none;
				opacity: 0.5;
				+ section svg {
					cursor: default;
					pointer-events: none;
				}
			}
		}
	}
`;

const Timelinelegend = styled.section`
	display: inline-block;
	color: var(--neutrals-700);

	& .legendname {
		margin-right: 1em;
		display: inline-block;

		& svg {
			fill: var(--blue-300);
			margin-right: var(--px-5);

			&.milestone-icon {
				transform: rotate(-20deg);
			}
		}

		& label {
			vertical-align: top;
			line-height: normal;
			font-weight: normal;
			font-size: var(--px-14);
			-webkit-user-select: none;
			-moz-user-select: none;
			user-select: none;
		}
	}
`;

const EngagementDetails = styled.section`
	width: 100%;
	display: inline-block;
	& svg {
		vertical-align: middle;
		cursor: pointer;
		fill: var(--neutrals-700);
	}

	& h4 {
		display: inline-block;
		font-size: var(--px-16);
		margin: 0;
		margin-bottom: 0;
		font-weight: bold;
		color: var(--neutrals-900);
		padding-left: var(--px-30);
		padding-right: var(--px-10);
		width: 100%;

		& section.anchor {
			color: var(--neutrals-900);
			vertical-align: middle;
			width: 60%;
			display: inline-flex;
			line-height: var(--px-40);
			max-width: 70%;
			pointer-events: none;

			& a {
				display: inline-flex;
				color: var(--neutrals-300);
				max-width: 100%;
			}
		}

		& .eng-rightmenu {
			line-height: var(--px-40);
			margin-right: var(--px-20);
			.motif-text-alt-button {
				margin-left: var(--px-20);
			}
			& .restored-eng {
				color: var(--neutrals-300);
				font-size: var(--px-14);
				margin: 0 0 0 var(--px-20);
			}

			& > section {
				display: inline-block;
				float: right;
			}
		}

		& .ellipses {
			width: auto;
			max-width: none;
			display: inline-flex;
			color: var(--neutrals-900);
			& div {
				max-width: none;
			}
		}
	}

	& .engagementid {
		display: inline-block;
		font-size: var(--px-14);
		line-height: var(--px-40);
		margin: 0 var(--px-50) 0 var(--px-20);
		color: var(--neutrals-700);
		vertical-align: text-top;
		font-weight: normal;
		float: right;
		width: var(--px-110);
	}
`;

const WorkspaceContent = styled.div`
	padding-left: var(--px-30);
	width: 67%;
	float: left;
	@media screen and (max-width: 1366px) {
		width: 61%;
	}
	@media screen and (min-width: 1367px) and (max-width: 1600px) {
		width: 61%;
	}
	.clientname,
	.workspace-name,
	.engagement-type,
	.engagement-typelinked {
		display: inline-block;
		font-size: var(--px-14);
		color: var(--neutrals-700);
		line-height: normal;
		max-width: 28%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		vertical-align: middle;
		padding-right: var(--px-3);
	}
	.workspace-name {
		max-width: 28%;
		padding-left: var(--px-3);
	}
	.engagement-type {
		max-width: 19%;
		padding-left: var(--px-3);
	}
	.engagement-typelinked {
		display: inline-block;
		max-width: 14%;
		padding-left: var(--px-3);
		.motif-icon svg {
			margin-top: calc(var(--px-3) * -1);
			vertical-align: middle;
			margin-right: var(--px-3);
		}
		.engagementtype-linkedtxt {
			font-size: var(--px-14);
			color: var(--neutrals-700);
			line-height: normal;
		}
	}
`;

const Workspacemoreinfo = styled.section`
	padding-right: var(--px-10);
	padding-top: var(--px-6);
	width: 32%;
	float: right;
	line-height: normal;
	@media screen and (max-width: 1366px) {
		width: 38%;
	}
	@media screen and (min-width: 1367px) and (max-width: 1600px) {
		width: 38%;
	}
	& .eng-rightmenu {
		display: inline-block;
		& .restored-eng {
			color: var(--neutrals-300);
			font-size: var(--px-14);
		}

		& > section {
			display: inline-block;
		}
	}
	& .engagementid {
		display: inline-block;
		font-size: var(--px-14);
		margin: 0 var(--px-50) 0 var(--px-10);
		color: var(--neutrals-700);
		font-weight: normal;
		float: right;
		width: var(--px-110);
		text-align: right;
	}
`;

const WSEdit = styled.div`
	flex-grow: 1;
	display: inline-block;
	float: right;
	margin-right: var(--px-10);
	svg {
		margin-top: calc(var(--px-4) * -1);
		transform: rotate(90deg);
	}
`;

const EngagementItemContainer = styled.section`
	margin: var(--px-15) 0;
`;

const MarkedDeleted = styled.section`
	& {
		padding: var(--px-2) var(--px-5);
		font-size: var(--px-14);
	}

	& .deletionmsg {
		width: 95%;
		display: inline-block;
		line-height: var(--px-20);
		margin-top: var(--px-10);

		.deletionmsgholder {
			display: inline;
			vertical-align: text-top;
		}

		svg {
			fill: var(--red-600);
			vertical-align: middle;
			margin: 0 var(--px-5) 0 0;
			width: var(--px-20);
			height: var(--px-20);
		}

		.clickrespond {
			font-weight: bold;
			color: var(--neutrals-900);
			margin-left: var(--px-4);
			text-decoration: underline;
			cursor: pointer;
		}
	}
`;
