import React from 'react';
import { labels } from '../../../resources/en-us';
import Icon from '@ey/motif-react-wrapper/Icon';
import { actionIcLock24px } from '@ey/motif-react-wrapper/assets/icons/index';
import styled from 'styled-components';

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100vh - var(--px-60));

	p {
		margin-bottom: 0;
		color: var(--neutrals-900);

		&.title h5 {
			font-weight: bold;
		}

		&.description {
			font-size: var(--px-14);
		}
	}

	svg {
		width: var(--px-100) !important;
		height: var(--px-100) !important;
		margin-bottom: var(--px-10);
		fill: var(--neutrals-700)!important;
	}
`;
function AccessDeniedMessage(props) {
	return (
		<Wrapper>
			<p>
				<Icon src={actionIcLock24px} />
			</p>
			<p className="title">
				<h5>{labels.noPermission}</h5>
			</p>
			<p className="description">
				{props.permissionMesage ? props.permissionMesage : labels.noAdminRights}
			</p>
		</Wrapper>
	);
}

export default AccessDeniedMessage;
