import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';
import MotifIcon from '@ey/motif-react-wrapper/Icon';
import { IconoirBell } from '@ey-xd/motif-icon';
import MotifButton from '@ey/motif-react-wrapper/Button';
import MotifBadge from '@ey/motif-react-wrapper/Badge';
function CustomTooltip(props) {
	const {
		containerClass,
		styleName,
		label,
		children,
		title,
		dueDate,
		userInitials,
		iconName,
		iconClassName,
		position,
		popupArrowColor,
		defaultLoad,
		disabled,
		count
	} = props;

	const [popupVisible, setPopupVisible] = useState(false);
	const [popupTopPosition, setPopupTopPosition] = useState(true);
	const [popupLeftPosition, setPopupLeftPosition] = useState(false);
	const node = useRef();

	useEffect(() => {
		document.addEventListener('mousedown', handleOutsideClick);
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, [props.isAnyInviteAcceptedOrRejected]);

	useEffect(() => {
		document.addEventListener('mousedown', handleOutsideClick);
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, [props.isAnyArchiveInviteAcceptedOrRejected]);

	useEffect(() => {
		setPopupVisible(props.popupVisible);
	}, [props.popupVisible]);

	const handleClick = (e) => {
		if (!popupVisible) {
			setPopupVisible(true);
			if (props.onPopupShow) {
				props.onPopupShow();
			}
		} else {
			setPopupVisible(false);
			if (props.onPopupHide) {
				props.onPopupHide();
			}
			if (props.isAnyInviteAcceptedOrRejected) {
				props.refreshDashboard();
			}
			if (props.isAnyArchiveInviteAcceptedOrRejected) {
				props.refreshDashboard();
			}
		}

		let bound = e.currentTarget.getBoundingClientRect();
		let winHeight = window.innerHeight;
		let rBottom = winHeight - bound.bottom;
		let winWidth = window.innerWidth;
		let rLeft = winWidth - bound.left;

		if (rBottom > 300) {
			setPopupTopPosition(true);
		}

		if (rLeft > 300) {
			setPopupLeftPosition(true);
		}
	};

	const handleDivClick = (e) => {};

	const handleOutsideClick = (e) => {
		if (node.current.contains(e.target)) {
			// inside click
			return;
		}
		// outside click
		setPopupVisible(false);
		if (props.onPopupHide) {
			props.onPopupHide();
		}
		if (props.isAnyInviteAcceptedOrRejected) {
			props.refreshDashboard();
		}
		if (props.isAnyArchiveInviteAcceptedOrRejected) {
			props.refreshDashboard();
		}
	};
	//const customModelActive = popupVisible ? popupVisible : count > 0;

	return (
		<StyledPopOver className={containerClass}>
			<span ref={node}>
				<MotifButton
					aria-label="notification"
					type="button"
					variant="ghost"
					size="medium"
					title={title}
					label={label}
					disabled={disabled}
					onClick={handleClick}
					className={`${popupVisible ? 'active motif-button-icon-only' : 'motif-button-icon-only'} ${styleName}`}
				>
					<MotifIcon src={IconoirBell} size="24" title={title} className={iconClassName} />
					<span className="btnLabel">{label}</span>
					{dueDate && <>{dueDate}</>}
					{userInitials && <>{userInitials}</>}
					{count > 0 && <MotifBadge value={count} overlap={true} />}
				</MotifButton>
				{!defaultLoad && popupVisible && (
					<section
						className={`
							${'show-pop-content doNotClosePopup'}
							${popupTopPosition ? 'bottom' : 'top'}
							${popupLeftPosition ? 'right' : 'right'}
							${position ? position : ''}
						`}
					>
						<section className={`${popupArrowColor} show-popup-arrow`} />
						<section className="show-pop-content-body" onClick={handleDivClick}>
							{children}
						</section>
					</section>
				)}
				{defaultLoad && (
					<section
						className={`
							${'show-pop-content'}
							${popupTopPosition ? 'bottom' : 'top'}
							${popupLeftPosition ? 'right' : 'right'}
							${popupVisible ? 'showPopUp' : 'hidePopUp'}
							${position ? position : ''}
						`}
					>
						<section className={`${popupArrowColor} show-popup-arrow`} />
						<section
							className="show-pop-content-body"
							id="customToolTip"
							onClick={handleDivClick}
						>
							{children}
						</section>
					</section>
				)}
			</span>
		</StyledPopOver>
	);
}

export default CustomTooltip;

const StyledPopOver = styled.section`
	position: relative;
	.new-notificationicon {
		.btnLabel {
			display: none;
		}
	}
	&.NotifationContainerClass {
		.btnCount {
			background-color: var(--neutrals-00white);
			border-radius: var(--px-10);
			padding: var(--px-1) var(--px-5);
			position: absolute;
			left: var(--px-15);
			font-size: var(--px-10);
			line-height: initial;
			top: 0;
			font-weight: bold;
			border: var(--px-1) solid var(--neutrals-900);
			color: var(--neutrals-900);
		}
	}
	button {
		padding: 0;
	}
	.ey-primary-button {
		padding: var(--px-9) var(--px-12);
		border: var(--px-1) solid var(--neutrals-900);
		font-size: var(--px-14);
		svg {
			&.right {
				float: right;
			}
			&.left {
				margin-right: var(--px-5);
			}
			&.deg90 {
				transform: rotate(90deg);
			}
		}
		&:hover {
			background: var(--neutrals-900);
			color: var(--neutrals-00white);
			svg {
				fill: var(--neutrals-00white);
			}
		}
		.btnLabel {
			line-height: var(--px-20);
		}
	}

	.ey-secondary-button {
		padding: var(--px-9) var(--px-15);
		border: var(--px-1) solid var(--neutrals-900);
		color: var(--neutrals-900);
		font-size: var(--px-14);
		svg {
			&.right {
				float: right;
			}
			&.left,
			&.center {
				margin-right: var(--px-5);
			}
			&.deg90 {
				transform: rotate(90deg);
			}
		}
		&:hover {
			background: var(--neutrals-900);
			color: var(--neutrals-00white);
			svg {
				fill: var(--neutrals-00white);
			}
		}
		.btnLabel {
			line-height: var(--px-20);
			margin-right: var(--px-5);
		}
	}

	.show-pop-content {
		background: var(--neutrals-00white);
		border: var(--px-1) solid var(--neutrals-300);
		width: auto;
		padding: 0;
		position: absolute;
		z-index: 1;
		box-shadow: 0 var(--px-6) var(--px-10) rgba(0, 0, 0, 0.4);
		&.showPopUp {
			display: block;
		}
		&.hidePopUp {
			display: none;
		}

		&.bottom {
			&.left {
				left: calc(var(--px-5) * -1);
				.show-popup-arrow {
					top: 0%;
					left: var(--px-10);
				}
			}

			&.center {
				left: -50%;
				.show-popup-arrow {
					left: 50%;
				}
			}
			&.right {
				right: calc(var(--px-20) * -1);
				.show-popup-arrow {
					right: var(--px-30);
				}
			}
		}

		&.top {
			bottom: var(--px-30);
			.show-popup-arrow {
				bottom: 0;
				transform: rotate(45deg);
			}

			&.left {
				.show-popup-arrow {
					left: 10%;
					bottom: 0;
				}
			}

			&.center {
				left: -50%;
				.show-popup-arrow {
					left: 50%;
				}
			}

			&.right {
				right: calc(var(--px-20) * -1);
				.show-popup-arrow {
					right: var(--px-25);
				}
			}
		}

		.show-popup-arrow {
			height: var(--px-14);
			width: var(--px-14);
			position: absolute;
			background: var(--neutrals-00white);
			transform: rotate(225deg);
			margin: calc(var(--px-6) * -1);
			z-index: -1;
			box-shadow: var(--neutrals-300) var(--px-1) var(--px-1)
				var(--px-1);
		}

		h4 {
			margin: 0;
			padding: var(--px-10);
			background-color: var(--neutrals-100);
			border-bottom: var(--px-1) solid var(--neutrals-300);
			font-weight: bold;
			font-size: var(--px-15);
		}
	}
`;
