/**
 * @module Workspaces
 * Container Component Workspaces
 */

import React, {useEffect, useState} from 'react';
import {
	dateFormats,
	dateFormatsListItems,
	labels
} from '../../util/uiconstants';
import {useDispatch, useSelector} from 'react-redux';

import EllipsesControl from '@ey/ellipses-control';
import {EngUserModelPatchFavorites} from '../../util/preparemodel';
import FavButton from './favoritesbutton';
import {PostEngagement} from '../../actions/engagementsaction';
import {SVGICONS} from '@ey/icons';
import Skeleton from 'react-loading-skeleton';
import WorkspaceGroupAudit from './workspaceGroupAudit';
import _ from 'lodash';
import env from '../../util/env';
import {markUnmarkFavorite} from '../../actions/engagementuseractions';
import moment from 'moment';
import styled from 'styled-components';
import {tabTypes} from '../../util/uiconstants';
import MotifCard from '@ey/motif-react-wrapper/Card';

const EngagementErrorItem = (props) => {
	const dispatch = useDispatch();

	const {
		data,
		archiveretryshortcutkey,
		id,
		refreshDashboard,
		clientName,
		workspace
	} = props;
	const {isfavorite, archivalrequestdate, engagementdesc} = data;

	const selectedTab = useSelector((store) => store.dashboard.activeTab);

	const [isFavInProgress, setIsFavInProgress] = useState(false);
	const [fav, setFav] = useState(isfavorite);
	const [readyForFavClick, setReadyForFavClick] = useState(true);
	const [arcRetry, setArcRetry] = useState(false);
	const [keys, setKeys] = useState([]);
	const [arcmsgCls, setArcmsgCls] = useState('arcmsgmaxwidth');

	let hideComponent = false;
	let classNameFavProgress = 'arrow-right';
	const baseKeyCombo = [
		{
			name: 16,
			value: 0
		},
		{
			name: 17,
			value: 0
		},
		{
			name: 18,
			value: 0
		}
	];

	useEffect(() => {
		if (selectedTab === tabTypes.FavoritesTab && fav === false) {
			hideComponent = true;
		}

		if (keys.length == 0) {
			let keyCombo = archiveretryshortcutkey.split(','); // Get the key from props and split.
			/*
				Set the Keys State, with the keys needed to the Key Combo Command
					and the previous State key items.
			*/
			setKeys((prevState) => [
				...prevState,
				...keyCombo.map((key) => {
					return {name: key.charCodeAt(0), value: 0};
				}),
				...baseKeyCombo
			]);
			// Current key combo received from props: (a + t + l). Added shift(16) + ctrl(17) + alt(18) to unify work
		}
	}, []);

	useEffect(() => {
		if (keys.length > 0 && keys.every((key) => key.value)) {
			//
			setArcRetry(true);
			setArcmsgCls('arcmsgminwidth');
		}
	}, [keys]);

	const keyEvent = (e) => {
		e.persist();
		setKeys((prevState) => {
			const newState = [...prevState];
			prevState.forEach((key) => {
				if (key.name === e.keyCode) {
					/* 
					When matched keyCode, find the item in the original array
					and update the value with (keydown : 1, keyup : 0).
					*/
					newState[newState.findIndex((x) => x.name === key.name)].value =
						e.type === 'keydown' ? 1 : 0;
				}
			});
			return newState;
		});
	};

	const onArchiveRetryLink = () => {
		dispatch(
			PostEngagement(env.getURL('serviceUrl') + '/' + 'archives.json', {
				data: {
					requesttypeId: 7, //request type for retry archive
					engagementid: id
				}
			})
		).finally(() => {
			window.location.reload();
		})
	};

	const onFavClick = async () => {
		setReadyForFavClick(false);
		setIsFavInProgress(true);
		let isFavTab = selectedTab === tabTypes.FavoritesTab;
		// Add favourite-inprogress class to arrow-right container when api call is in progress
		try {
			await dispatch(
				markUnmarkFavorite(
					env.getURL('serviceUrl') + '/' + 'EngagementUsers.json',
					EngUserModelPatchFavorites(id, !fav, 'markunmarkfavoriteengagement')
				)
			);
			setIsFavInProgress(false);
			setFav(!fav);
			setReadyForFavClick(true);
		} finally {
			if (isFavTab) {
				refreshDashboard();
			}
		}
	};

	const dblClick = () => {};

	return (
		<EngagementItemContainer
			className="eng-listwrapper"
			onDoubleClick={dblClick}
			onKeyDown={(e) => {
				keyEvent(e);
			}}
			onKeyUp={(e) => {
				keyEvent(e);
			}}
			tabIndex="0"
		>
			<EngagementItemDetails className="eng-listholder arcstatus-wrapper engagementerror engagementerror-indicator">
				<MotifCard orientation="horizontal">
					<section className="arcstatus">
						<SVGICONS hoverText={'info_outline'} styleName="large" name="error" />
						<span className={arcmsgCls}>
							{archivalrequestdate != null
								? labels.arcretrymessage.replaceAll(
										'{0}',
										moment
											.utc(archivalrequestdate)
											.format(dateFormatsListItems[dateFormats].label)
								  )
								: labels.arcretrymessage}
						</span>
						<span className="arcaction">
							{arcRetry ? (
								<a onClick={onArchiveRetryLink}>{labels.ArchiveRetryLink}</a>
							) : (
								<a />
							)}
						</span>
					</section>
					<section className="engagementInfo">
						<WorkspaceContent>
							<section className="clientname" title={clientName}>
								{(clientName === '!!!'
									? labels.invalidclientcode
									: clientName) || <Skeleton width={250} height={20} />}
							</section>
							<WorkspaceGroupAudit workspace={workspace} engagement={data} />
						</WorkspaceContent>
						<EngagementDetails className="engagementname">
							<h4>
								<section className="anchor" data-autoid={id}>
									<a>
										<EllipsesControl
											content={
												engagementdesc || <Skeleton width={250} height={20} />
											}
											tooltip={
												engagementdesc || <Skeleton width={250} height={20} />
											}
											isTooltipAvailable
										/>
									</a>
								</section>
								<section className="pull-right eng-rightmenu">
									<section className="engagementid">
										ID: {id || <Skeleton width={250} height={20} />}
									</section>
								</section>
							</h4>
						</EngagementDetails>
					</section>
					<section className="engagementUpdates">
						<FavButton
							isFav={fav}
							onFavClick={onFavClick}
							readyForFavClick={readyForFavClick}
						/>
					</section>
				</MotifCard>
			</EngagementItemDetails>
		</EngagementItemContainer>
	);
};
export default EngagementErrorItem;

const EngagementItemDetails = styled.section`
	.motif-card-border.motif-card-orientation-horizontal {
		width: 100%;
		height: auto;
		gap: 0;
		padding: 0;		
		border-left: var(--px-5) solid var(--blue-300);
		min-width: 0;
		min-height: 0;
	}

	&.engagementerror-indicator {
		.motif-card-border.motif-card-orientation-horizontal {
			border-left: var(--px-5) solid var(--red-600);
		}
	}
	& .engagementInfo {
		width: 100%;
		padding: var(--px-10) 0 var(--px-10) 0;
		position: relative;
	}
	&.arcstatus-wrapper {
		&.engagementerror .engagementInfo {
			& .engagementname .anchor {
				color: var(--neutrals-300);
				pointer-events: none;
				cursor: default;
			}
		}

		& .arcstatus {
			padding: var(--px-2) var(--px-5);
			font-size: var(--px-14);

			& svg {
				fill: var(--red-600);
				vertical-align: top;
				margin: var(--px-9) var(--px-5) 0 0;
				width: var(--px-20);
				height: var(--px-20);
			}

			& .arcmsgmaxwidth {
				width: 95%;
				display: inline-block;
				line-height: var(--px-20);
				margin-top: var(--px-10);
			}

			& .arcmsgminwidth {
				width: 76%;
				display: inline-block;
				line-height: var(--px-20);
				margin-top: var(--px-10);
			}

			& .arcaction {
				float: right;
				& a {
					text-decoration: none;
					cursor: pointer;
					margin-right: var(--px-40);
					font-weight: bold;
					margin-top: var(--px-5);
				}
				& a:hover {
					text-decoration: underline;
					color: #337ab7;
				}
			}
		}
	}

	& .engagementUpdates {
		display:flex;
		align-items: center;
		justify-content: end;
		border-top: var(--border-width-small) solid var(--neutrals-200);
		& .faviconholder {
			float: right;
			position: relative;
			display: block;
		}

		& .arrow-right {
			width: 0;
			height: 0;
			border-top: var(--px-45) solid transparent;
			border-bottom: 0 solid transparent;
			border-right: var(--px-45) solid var(--neutrals-900);
			float: right;
			margin-right: calc(var(--px-2) * -1);
			margin-bottom: calc(var(--px-2) * -1);
			box-shadow: 3px 3px 5px 0px var(--neutrals-300);
			&.favourite-inprogress {
				border-bottom: 0 solid transparent;
				border-right: var(--px-45) solid var(--neutrals-700);
				pointer-events: none;
				opacity: 0.5;
				+ section svg {
					cursor: default;
					pointer-events: none;
				}
			}
		}
	}
`;

const EngagementDetails = styled.section`
	width: 100%;
	display: inline-block;
	& svg {
		vertical-align: middle;
		cursor: pointer;
		fill: var(--neutrals-700);
	}

	& h4 {
		display: inline-block;
		font-size: var(--px-16);
		margin: 0;
		font-weight: bold;
		color: var(--neutrals-900);
		padding-left: var(--px-30);
		width: 100%;

		& section.anchor {
			color: var(--neutrals-900);
			vertical-align: middle;
			width: 70%;
			display: inline-flex;
			line-height: var(--px-40);
			max-width: 70%;

			& a {
				display: inline-flex;
				color: var(--neutrals-300);
				max-width: 100%;
			}
		}

		& .eng-rightmenu {
			line-height: var(--px-40);
			margin-top: calc(var(--px-20) * -1);
			& .restored-eng {
				color: var(--neutrals-300);
				font-size: var(--px-14);
			}

			& > section {
				display: inline-block;
			}
		}

		& .ellipses {
			width: auto;
			max-width: none;
			display: inline-flex;
			color: var(--neutrals-900);
			& div {
				max-width: none;
			}
		}
	}

	& .engagementid {
		display: inline-block;
		font-size: var(--px-14);
		line-height: var(--px-40);
		margin: 0 var(--px-50) 0 var(--px-20);
		color: var(--neutrals-700);
		vertical-align: text-top;
		font-weight: normal;
		float: right;
		width: var(--px-110);
	}
`;

const WorkspaceContent = styled.div`
	padding-left: var(--px-30);
	width: 69%;
	float: left;
	padding-bottom: var(--px-10);
	.clientname,
	.workspace-name,
	.engagement-type,
	.engagement-typelinked {
		display: inline-block;
		font-size: var(--px-14);
		color: var(--neutrals-700);
		line-height: normal;
		max-width: 28%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		vertical-align: middle;
		padding-right: var(--px-3);
	}
	.workspace-name {
		max-width: 28%;
		padding-left: var(--px-3);
	}
	.engagement-type {
		max-width: 19%;
		padding-left: var(--px-3);
	}
	.engagement-typelinked {
		display: inline-block;
		max-width: 14%;
		padding-left: var(--px-3);
		.motif-icon svg {
			margin-top: calc(var(--px-3) * -1);
			vertical-align: middle;
			margin-right: var(--px-3);
		}
		.engagementtype-linkedtxt {
			font-size: var(--px-14);
			color: var(--neutrals-700);
			line-height: normal;
		}
	}
`;

const EngagementItemContainer = styled.section`
	margin: var(--px-15) 0;
`;
