import styled from 'styled-components';

const Wrapper = styled.section`

	.loadingstyle {
		height: calc(100vh - var(--px-250)) !important;
	}

	//pagination

	.pagerholder {
		margin: 0 auto;
		width: 100%;
		position: absolute;
		bottom: var(--px-0);
	}

	.permissiongroup-paging {
		justify-content: flex-end;
		line-height: normal;
		min-width: auto !important;
		font-size: var(--px-14);
		background: var(--neutrals-00white);
		border-top: 1px solid var(--neutrals-200);
	}


	.mainwrapper{
		overflow: hidden;
		padding-top: var(--px-90);
	
	.message{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: calc(100vh - var(--px-180));

		&h5{
			color: var(--neutrals-900);
		}
	}

	//heading
	.permissiongroup {
		.pageheading {
			display: flex;
			padding: var(--px-20) 0;
			svg {
				min-width: var(--px-25) !important;
				min-height: var(--px-25) !important;
				margin-right: var(--px-10);
			}

			h3 {
				margin: 0;
				line-height: var(--px-25);
			}
		}
	}

	//addon

	.add-on {
		display: flex;
		margin-right: auto;
		padding: 0 0 var(--px-20) 0;

		.adduser {
			.motif-button {
				height: var(--px-40);
				width: var(--px-100);
			}
		}

		.search {
			width: 16%;
			line-height: var(--px-40);
			margin-right: auto;
            
            .motif-icon-button{
                top: var(--px-5);
            }

			.motif-typeahead-close-button {
				top: var(--px-4);
			}

			.motif-input {
				height: var(--px-40);
			}
		}
	}

    .norecord{
        height: calc(100vh - var(--px-300));
        display: inline-block;
        width: 100%;
        display: flex;
        justify-content: center;
        flex: 0 auto;
        align-items: center;
        font-size: var(--px-16);
        color: var(--neutrals-700);
    }

    .managepermissionTable {
        /* height: 100%; */
        overflow-y: auto;
        user-select: none;
        .motif-table {
            height: calc(100vh - var(--px-190));
    
            &.ag-theme-material .ag-checkbox-input-wrapper:focus-within {
                box-shadow: none;
            }
    
            .ag-header-cell {
                color: var(--neutrals-700);
                &:hover,
                    &.ag-header-cell-moving{
                        background: transparent!important;
                    }
            }
    
            .ag-header-cell .ag-header-select-all{
                visibility: hidden;
            }
    
            .ag-body-viewport{
                height: calc(100vh - var(--px-300));
                border: var(--px-1) solid ${(props) =>
									props.theme.color['grey200']} !important;
    
                &:after{
                    content: none;
                }
            }
    
            .ag-header-viewport {
                background: var(--neutrals-100);
                font-size: var(--px-14);
            }
            .ag-root.ag-layout-normal {
                overflow-y: auto;
                width: 100%;
            }
    
            .ag-root.ag-layout-auto-height {
                display: block;
            }
    
            .ag-center-cols-viewport {
                overflow-x: hidden;
            }
    
            .ag-row-hover{
                border-width: 0 0 var(--px-1) 0 !important;		
                border-style: solid !important;
                border-color: ${(props) =>
									props.theme.color[`grey250`]} !important;
            }
    
            .ag-row{
                border-width: 0 0 var(--px-1) 0 !important;		
                border-style: solid !important;
                border-color: ${(props) =>
									props.theme.color[`grey250`]} !important;
                color: var(--neutrals-700);
    
                .ag-cell-value{
                    font-weight: 100;

					a.groupname{
						width: 100%;
						display: inline-block;
						color: var(--blue-600);

						.ellipses{
							color: var(--blue-600)!important;
						}
					}
                }
            }
    
            .ag-row,
            .ag-row-hover {
                background: var(--neutrals-00white) !important;
                font-weight: normal;

				.ag-cell-wrapper{
					width: 100%;
					display: grid;
				}
				
                .ag-column-hover {
                    background: ${(props) =>
											props.theme.color[`white`]} !important;
                }
            }
    
            .ag-header-row .ag-header-cell{
                &:hover{
                    background: var(--neutrals-100)
                }
            }

            .ag-cell {
                line-height: normal;
				background: var(--neutrals-00white) !important;
			}
    
            .ag-cell,
            .ag-header-cell {
                font-size: var(--px-14);
                padding: 0 var(--px-20);
                display: block;
    
                
                .ag-react-container {
                    [role='button'] {
                        cursor: default;
                    }
                }
    
                .ag-header-cell-text{
                    font-size: var(--px-14);
                }
            }
    
            .ag-header-cell:last-child,
            .ag-cell:last-child {
                border-right: none;
            }
    
            .roleType.disabled,
            .roleType.disabled *{
                color: var(--neutrals-300)!important;
                fill: var(--neutrals-300);
            }
            .noptr{
                pointer-events: none;
            }
            .disabled{
                color: var(--neutrals-300)!important;
                fill: var(--neutrals-300);
            }
    
            .roleType {
                display: inline-block;
                width: 100%;
                align-items: center;
				cursor: default;
    
                &.enable.icon{
                    fill: var(--neutrals-1000)!important;
                }
                .columnName{
                    font-size: var(--px-14);
                    color: var(--neutrals-900);
                    }
                }
            }
    
            .errorRow {
                color: var(--red-600) !important;
            }
        }
    
        .ag-body-horizontal-scroll {
			overflow: auto;
        }
    
        .ag-layout-normal {
            &::-webkit-scrollbar {
                width: var(--px-6);
            }
            &::-webkit-scrollbar-track {
                background: var(--neutrals-200);
                width: var(--px-6);
            }
            &::-webkit-scrollbar-thumb {
                background: var(--neutrals-300);
                width: var(--px-6);
                cursor: pointer;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: var(--neutrals-700);
            }
        }
    
    }
}

`;

export default Wrapper;
