import styled from 'styled-components';

import EllipsesControl from '@ey/ellipses-control';
import PropTypes from 'prop-types';
import React from 'react';
import {SVGICONS} from '@ey/icons';

const EngagementAlertsItem = (props) => {
	let alertType = getAlertType(props.issue.issueType);

	return (
		<StyledAlert>
			{alertType !== 'Count' ? (
				<span className={`${alertType}`}>
					<SVGICONS hoverText="" styleName="medium" name="error" />
				</span>
			) : (
				<div className="countWrapper">
					<span className={`${alertType}`}>{props.issue.counter}</span>
				</div>
			)}
			{/* Ellipses makes the issues go to different lines, that's why it's commented */}
			<section className="eng_name">
				<EllipsesControl
					content={props.issue.name}
					tooltip={props.issue.name}
					noOfLines={1}
					isTooltipAvailable
				/>
			</section>
		</StyledAlert>
	);
};

// -------------- PROP TYPES --------------
// ----------------------------------------
EngagementAlertsItem.propTypes = {
	issue: PropTypes.shape({
		issueType: PropTypes.number.isRequired,
		name: PropTypes.string.isRequired,
		counter: PropTypes.number
	})
};

// This is the function that sets the alert type according to the issueType received.
// If it's an alert, the second word will determine the css subclass to use, which in turn will determine the color of the exclamation mark.
const getAlertType = (issueType) => {
	switch (issueType) {
		case 1:
			return 'alert red';
		case 2:
			return 'alert grey';
		case 3:
			return 'Count';
		default:
			return '';
	}
};

// -------------- STYLING CLASSES --------------
// ---------------------------------------------
const StyledAlert = styled.section`
	display: inline-flex;
	width: 13.3vw;
	padding-right: var(--px-10);
	vertical-align: middle;
	.EllipsesControl {
		display: inline;
	}
	.alert {
		display: inline-flex;
		padding: 0;
		margin-bottom: 0;
		align-items: center;
		&.red {
			svg {
				fill: var(--red-600);
			}
		}
		&.grey {
			svg {
				fill: var(--neutrals-900);
			}
		}
	}
	.countWrapper {
		display: inline-flex;
		align-items: center;
	}
	.Count {
		display: inline-flex;
		border-radius: var(--px-6)x;
		color: var(--neutrals-00white);
		background-color: var(--neutrals-900);
		align-items: center;
		justify-content: center;
		font-size: var(--px-12);
		padding: 0 var(--px-5);
		height: var(--px-18);
		min-width: var(--px-18);
	}
	.eng_name {
		font-size: var(--px-13);
		display: inline-flex;
		align-items: center;
		margin: var(--px-5) 0 0 var(--px-5);
		line-height: normal;
		width: calc(100% - var(--px-30));
	}
`;

export default EngagementAlertsItem;
