import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from "styled-components";
import { deleteAdminUserRole } from '../../../../../actions/admin/manageaccessactions';
import TicketField from '../../../common/ticketfield';
import env from '../../../../../util/env';
import Loader from '../../../../Common/Loaders/loadingindicator';
import { labels } from '../../../../../util/uiconstants';
import Button from '@ey/motif-react-wrapper/Button';
import { getLocalizedAPIError } from '../../../common/util';
import Toast from '../../../../Common/Toast/Toast';

function DeleteRole(props) {
    const [errorMessage, setErrorMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ticketNumber, setTicketNumber] = useState('');
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    const dispatch = useDispatch();

    const onTicketChanged = (e) => {
        setTicketNumber(e.target.value ?? '');
    }

    const onConfirmDeleteClick = () => {
        setIsSubmitClicked(true);
        if (ticketNumber.trim().length <= 0) {
            return;
        }

        const roleToDelete = props.roleToDelete;
        setIsLoading(true);
        let userId = location.pathname.split('/').at(-1);
        let deleteRoleUrl = '';
        let uriTicketNumber = encodeURIComponent(ticketNumber.trim());
        if (roleToDelete.countryIds && roleToDelete.countryIds.length && roleToDelete.subServiceLineId) {
            deleteRoleUrl =
                env.getURL('serviceUrlV2') +
                'admin/Users/' +
                userId +
                '/roles/' +
                roleToDelete.rbacRoleId +
                '/subservicelines/' +
                roleToDelete.subServiceLineId +
                '?ticketnumber=' +
                uriTicketNumber;
        } else {
            deleteRoleUrl =
                env.getURL('serviceUrlV2') +
                'admin/Users/' +
                userId +
                '/roles/' +
                roleToDelete.rbacRoleId +
                '?ticketnumber=' +
                uriTicketNumber;
        }

        dispatch(deleteAdminUserRole(deleteRoleUrl))
            .then(() => {
                props.onModalClose(true);
                setIsSubmitClicked(false);
            })
            .catch((e) => {
                setErrorMessage(getLocalizedAPIError(e));
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <DeleteRoleWrapper>
            <section
                className="toastMessage"
                style={{
                    display:
                        errorMessage && errorMessage.length > 0
                            ? 'block'
                            : 'none'
                }}
            >
                <Toast
                    variant="error"
                    onClose={() => setErrorMessage(false)}
                >
                    {errorMessage}
                </Toast>
            </section>
            {isLoading ? (<section className="delete-role-loader">
                <Loader show view="blockView" styleName="loadingstyle" /></section>
            ) : (
                <section>
                    <section>
                        <section className="title" aria-label="">
                            <h5>{props.roleToDelete.moduleName + ' ' + props.roleToDelete.roleName}</h5>
                        </section>
                    </section>
                    <TicketField
                        ticketNumber={ticketNumber}
                        onTicketChanged={onTicketChanged}
                        showValidation={isSubmitClicked}
                    />
                    <section className="modal-footer">
                        <Button
                            type="button"
                            variant="primary-alt"
                            disabled={ticketNumber.trim().length <= 0}
                            onClick={onConfirmDeleteClick}
                        >
                            {labels.manageAccessDeleteRoleButton}
                        </Button>
                        <Button
                            type="button"
                            variant="secondary"
                            onClick={() => {
                                props.onModalClose(false);
                            }}
                        >
                            {labels.cancel}
                        </Button>
                    </section>
                </section>
            )}
        </DeleteRoleWrapper>
    );
}

const DeleteRoleWrapper = styled.section`
.title h5{
    font-size: var(--px-14);
    font-weight: normal;
    color: var(--neutrals-900);
    margin: var(--px-20) 0 var(--px-20) var(--px-20);
}
.delete-role-loader{
    padding: var(--px-20) !important;
    .loadingstyle {
        height: calc(100vh - var(--px-720)) !important;
    }
}
//ticketstart
		.ticketdetail{
			margin: 0 var(--px-20) var(--px-20) var(--px-20);
			padding-bottom: var(--px-20);
			h5{
				font-size: var(--px-14);
				font-weight: normal;
				color: var(--neutrals-900);
				border: 0!important;
			}

			.motif-form-field{
				width: var(--px-290);
			}

			.motif-form-field:not(.motif-form-field-has-value),
			.motif-form-field:not(.motif-form-field-focused){
				.motif-label{
					margin-top: var(--px-6);
				}
			}

			.motif-form-field-has-value,
			.motif-form-field-focused{
				.motif-label{
					margin-top: var(--px-2)!important;
				}
			}
			
			
			.motif-input {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
		//ticketend
	.modal-footer {
		padding: var(--px-20);

		.motif-toast-close-button,
		.motif-toast-close-button:focus {
			outline: 0 !important;

			* {
				outline: 0 !important;
			}

			svg {
				outline: 0 !important;
			}

			*:focus {
				outline: 0 !important;
			}

			svg:focus {
				outline: 0 !important;
			}
		}
	}

	.motif-loader {
		height: var(--px-180) !important;
	}

    .modal-footer {
		display: inline-flex;
		width: 100%;

		button {
			font-size: var(--px-14);
			padding: var(--px-12) var(--px-15);
			margin-right: var(--px-10);
			line-height: normal;
		}
	}
`;


export default DeleteRole;