import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { SVGICONS } from '@ey/icons';
import { Ellipse } from '@ey/canvascoreservices';
import Tooltip from '@ey/motif-react-wrapper/Tooltip';
import PermissionList from '../../../Common/Permission/PermissionList';
import { currentResource, labels } from '../../../../util/uiconstants';
import { useSelector, useDispatch } from 'react-redux';
import { getEmployeeADStatus } from '../../../../actions/admin/engagement/engagementActions';
import { setIsModalOpen } from '../../../../actions/modalstateactions';
import { clearErrors } from '../../../../actions/erroractions';
import {
	userStatusEnum,
	employmentStatuses,
	employmentStatusTypes,
	engagementAccessTypeEnum
} from '../../../../util/uiconstants';
import { EngagementStatus } from '../../../../util/enumerations';

function EngagementUserItem(props) {
	const dispatch = useDispatch();
	const [selectedUserStatus, setSelectedUserStatus] = useState('');
	const [userName, setUserName] = useState('');
	const [selectedIndependenceStatus, setSelectedIndependenceStatus] = useState('');
	const [engagementRoleLabel, setEngagementRoleLabel] = useState('');
	const [isPostArchiveColVisible, setIsPostArchiveColVisible] = useState(false);

	let defaultStatus = {
		key: employmentStatusTypes.DefaultStatus,
		text: '...'
	};

	let onErrorStatus = {
		key: employmentStatusTypes.InActive,
		text: labels.inactive
	};
	const [employeeADStatus, setEmployeeADStatus] = useState(defaultStatus);
	const employeeADStatuses = useSelector(
		(store) => store.employeeADStatuses.statuses
	);

	useEffect(() => {
		clearError(true);
		return () => {
			clearError(false);
		};
	}, []);

	const clearError = (isModelOpen) => {
		dispatch(clearErrors()).then(() => {
			dispatch(setIsModalOpen(isModelOpen));
		});
	};

	//Load AD staus on UI
	useEffect(() => {

		if (props?.engagementStatusId == EngagementStatus.Archived) {
			setIsPostArchiveColVisible(true);
		}

		var filteredStatus = employeeADStatuses.filter(
			(ee) => ee.email == props?.data?.emailAddress
		)[0];
		if (
			filteredStatus &&
			filteredStatus?.employeeADStatus &&
			employmentStatuses.includes(filteredStatus?.employeeADStatus)
		) {
			if (employmentStatusTypes.Active !== employeeADStatus.key) {
				let empStatus = {
					key: employmentStatusTypes.Active,
					text: labels.active
				};
				setEmployeeADStatus(empStatus);
			}
		}
	}, [employeeADStatuses]);

	//Get AD stauses and find user roles
	useEffect(() => {
		setUserName(props?.data?.firstName + ' ' + props?.data?.lastName);
		dispatch(getEmployeeADStatus(props?.data?.emailAddress))
			.catch((error) => {
				setEmployeeADStatus(onErrorStatus);
				clearError(true);
			})
			.finally(() => {
				clearError(true);
			});
		clearError(true);

		let roleList = currentResource?.rolelistitems;
		if (roleList) {
			var roleName = roleList.filter(
				(ee) => ee.id == props?.data?.engagementRoleId
			);
			if (roleName && roleName[0] && roleName[0]?.label) {
				setEngagementRoleLabel(roleName[0]?.label);
			}
		}
	}, []);

	//Set user status and independence status label for engagement user table
	useEffect(() => {
		if (props.userStatusesOptions) {
			let statusLabel = '';

			if (props.data?.engagementUserStatusId === userStatusEnum.active &&
				props.data?.engagementAccessTypeID === engagementAccessTypeEnum.independenceOnly)
			{
				statusLabel = labels.independenceOnlyStatus;
			}
			else {
				statusLabel = props.userStatusesOptions?.find(
							  option => option.value === props.data?.engagementUserStatusId)?.label ?? '';
			}

			setSelectedUserStatus(statusLabel);
		}

		if (props.independenceStatuseOptions) {
			let independenceStatusLabel = props.independenceStatuseOptions?.find(
									      option => option.value == props.data?.independenceStatusId)?.label ?? '';

			setSelectedIndependenceStatus(independenceStatusLabel);
		}

	}, [props]);



	const onEditItemhandler = () => {
		props?.setShowEditUserPermissions(true);
		props?.setItemToEditUserPermissions(props?.data);
	};

	return (
		<StyledEngagementUserItem>
			<section className="manageaccessitemview">
				<section className="userlistitem">
					<Ellipse
						content={userName}
						tooltip={userName}
						noOfLines={1}
					/>
				</section>
				<section className="userlistitem">
					<Ellipse
						content={props.data.emailAddress}
						tooltip={props.data.emailAddress}
						noOfLines={1}
					/>
				</section>
				<section className="userlistitem">
					<Ellipse
						content={engagementRoleLabel}
						tooltip={engagementRoleLabel}
						noOfLines={1}
					/>
				</section>
				<section className="userlistitem gui">
					<Ellipse
						content={props.data.gui}
						tooltip={props.data.gui}
						noOfLines={1}
					/>
				</section>
				<section className={`userlistitem status ${props?.data?.isPostArchiveAddition ? 'postarchiveactive':'nopostarchiveactive'}`}>
					<Ellipse
						content={selectedUserStatus}
						tooltip={selectedUserStatus}
						noOfLines={1}
					/>
					{isPostArchiveColVisible && (
						<section className="postarchive">
							<span>
								{props?.data?.isPostArchiveAddition && (
									<div className="motif-badge">
										<span className="motif-badge-number">{labels.postArchive}</span>{' '}
									</div>
								)}
								{'		'}
							</span>
						</section>
					)}
				</section>

				<section className="userlistitem indstatus">
					<Ellipse
						content={selectedIndependenceStatus}
						tooltip={selectedIndependenceStatus}
						noOfLines={1}
					/>
				</section>
				<section className="userlistitem adstatus">{employeeADStatus?.text}</section>
				<section className="userlistitem permission">
					<PermissionList
						rbacRoles={props?.data?.userRoles}
						picFlag={props?.data?.hasOverallResponsibility? true : false}
						userType={null}
					></PermissionList>
				</section>
				<section className="editicon-button">
					<Tooltip
						flip
						id={'editbButtonTooltipId'}
						placement={'top'}
						hide={props.permissions}
						trigger={
							<section>
								<button
									role="button"
									aria-label="edit-icon"
									disabled={!props.permissions}
									onClick={() => onEditItemhandler()}
									className="edit-user-edit-btn"
								>
									<SVGICONS
										styleName="medium editname-icon"
										name="edit"
										hoverText={labels.engagementUsersTitleEdit}
									/>
								</button>
							</section>
						}
					>
						<section>{labels.usersEditPermissionToolTip}</section>
					</Tooltip>
				</section>
			</section>
		</StyledEngagementUserItem>
	);
}

export default EngagementUserItem;

const StyledEngagementUserItem = styled.section`
	.edit-user-edit-btn {
		background: transparent;
		border: none;
		outline: none;
		transition: 0.2s ease-in-out opacity;
		svg {
			fill: var(--neutrals-1000);
		}

		&[disabled] {
			opacity: 0.2;
			pointer-events: none;
		}
	}

	.editname-icon {
		margin: calc(var(--px-2) * -1) 0 0 0;
		cursor: pointer;
	}
`;
