import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {FormRadio} from '@ey/form';
import {Radio} from 'react-bootstrap';
import {labels} from '../../../util/uiconstants';
import _ from 'lodash';

const TopLevelStyle = styled.section`
	& .choiceworkspace {
		& > .form-group {
			margin: 0 0 var(--px-15) var(--px-15);
		}

		& .form-group label.control-label {
			display: inline;
		}
	}
	& .choiceworkspace div.form-group .radio {
		vertical-align: middle;
		margin: 0 0 var(--px-15) 0;

		&.disabled {
			pointer-events: none;
			cursor: default;
		}

		& label input[type='radio'] {
			margin: 0 0 0 calc(var(--px-30) * -1);
		}
		& label {
			padding-left: var(--px-30);
			font-weight: normal;
		}
	}
`;

const ChooseNewExisting = (props) => {
	const {
		updateWizardData,
		loadTemplatEng,
		enableCopyToNewWrkspc,
		headerQuestion,
		mode,
		getDependencyData
	} = props;

	const [isExistingWorkspace, setIsExistingWorkspace] = useState(
		getDependencyData('domainChooseExisting')
	);
	
	const importdp = 'importdp';
	const lblHeader = headerQuestion
		? headerQuestion
		: mode == importdp
		? labels.importhdrqstn
		: labels.choosenewexistingquestionheader;
	const enableNewWorkspace = () => {
		return _.isEmpty(enableCopyToNewWrkspc) ? true : enableCopyToNewWrkspc;
	};

	useEffect(() => {
		if (loadTemplatEng) {
			loadTemplatEng();
		}
		updateWizard();
	}, []);

	useEffect(() => {
		updateWizard();
	}, [isExistingWorkspace]);
	
	const updateWizard = () => {
		updateWizardData('domainChooseExisting', isExistingWorkspace);
	};

	const radioChanged = (key, value) => {
		if(_.isEmpty(value)){
			setIsExistingWorkspace(getDependencyData('domainChooseExisting'));
		}else{
			setIsExistingWorkspace(value == 'existing');
		}
	};

	return (
		<form>
			<TopLevelStyle>
				<section>
					<h5>{lblHeader} </h5>
				</section>
				<section className="choiceworkspace">
					<FormRadio
						label=""
						name="radioStack"
						help
						is_required
						onChange={radioChanged}
					>
						<Radio
							value={'new'}
							key={1}
							defaultChecked={!getDependencyData('domainChooseExisting')}
							className={!enableNewWorkspace() ? 'disabled' : ''}
						>
							{labels.newworkspace}
						</Radio>

						<Radio
							value={'existing'}
							key={2}
							defaultChecked={getDependencyData('domainChooseExisting')}
						>
							{labels.existingworkspace}
						</Radio>
					</FormRadio>
				</section>
			</TopLevelStyle>
		</form>
	);
}
export default ChooseNewExisting;
