import * as types from '../actions/actiontypes'; //just externalizes action name constants
import initialState from './initialstate';

export default function IndividualWorkspaceDataReducer(
	state = initialState.individualWorkspaceData,
	action
) {
	switch (action.type) {
		case types.GET_WORKSPACE_DATA:
			return action.response;
		case types.RESET_WORKSPACE_DATA:
			return initialState.individualWorkspaceData;
		default:
			return state;
	}
}
