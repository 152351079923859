import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getLocalizedAPIError } from '../../common/util';
import Toast from '../../../Common/Toast/Toast';
import env from '../../../../util/env';
import { labels } from '../../../../util/uiconstants';
import * as manageaccessactions from '../../../../actions/admin/manageaccessactions';
import Loader from '../../../Common/Loaders/loadingindicator';
import Button from '@ey/motif-react-wrapper/Button';
import EllipsesControl from '@ey/ellipses-control';
import TicketField from '../../common/ticketfield';

function DeactivateUser(props) {
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(false);
	const [deactivateUserErrorMessage, setDeactivateUserErrorMessage] = useState(
		''
	);
	const [ticketNumber, setTicketNumber] = useState('');
	const [isSubmitClicked, setIsSubmitClicked] = useState(false);

	const userToDeactivate = props.userToDeactivate;

	const onTicketChanged = (e) => {
		setTicketNumber(e.target.value);
	}

	const deactivateUser = () => {
		setIsSubmitClicked(true);
		if (ticketNumber.trim().length <= 0) {
			return;
		}

		setIsLoading(true);
		let data = {
			isActive: false
		};

		let uriTicketNumber = encodeURIComponent(ticketNumber.trim());

		let deactivateUrl =
			env.getURL('serviceUrlV2') +
			`admin/Users/${userToDeactivate.userId}/isactive?ticketNumber=${uriTicketNumber}`;

		dispatch(manageaccessactions.deactivateAdminUser(deactivateUrl, data))
			.then(() => {
				setIsLoading(false);
				props.onDeactivateModalClose(true);
			})
			.catch((result) => {
				setDeactivateUserErrorMessage(getLocalizedAPIError(result));
			})
			.finally(() => {
				setIsLoading(false);
				setIsSubmitClicked(false);
			});
	};

	return (
		<DeactivateUserWrapper className="adduser">
			<section
				className="toastMessage"
				style={{
					display:
						deactivateUserErrorMessage && deactivateUserErrorMessage.length > 0
							? 'block'
							: 'none'
				}}
			>
				<Toast
					variant="error"
					onClose={() => setDeactivateUserErrorMessage(false)}
				>
					{deactivateUserErrorMessage}
				</Toast>
			</section>
			{isLoading ? (<section className="delete-role-loader">
				<Loader show view="blockView" styleName="loadingstyle" /></section>
			) : (
				<section>
					<section className="userSearch customScrollbar">
						<section className="result">
							<h5>{labels.deactivateWarning}</h5>
							<ul className="userlist">
								<li>
									<span className="nameemail">
										<EllipsesControl
											content={
												userToDeactivate.displayName +
												' ' +
												'(' +
												userToDeactivate.email +
												')'
											}
											tooltip={
												userToDeactivate.displayName +
												' ' +
												'(' +
												userToDeactivate.email +
												')'
											}
											displayId={''}
											noOfLines={1}
											ellipsisClass=""
										/>
									</span>
								</li>
							</ul>
						</section>
					</section>
					<TicketField
						ticketNumber={ticketNumber}
						onTicketChanged={onTicketChanged}
						showValidation={isSubmitClicked}
					/>
					<section className="modal-footer">
						<Button
							type="button"
							variant="primary-alt"
							disabled={ticketNumber.trim().length <= 0}
							onClick={() => {
								deactivateUser();
							}}
						>
							{labels.deactivateButton}
						</Button>
						<Button
							type="button"
							variant="secondary"
							onClick={() => {
								props.onDeactivateModalClose(false);
							}}
						>
							{labels.cancel}
						</Button>
					</section>
				</section>
			)}
		</DeactivateUserWrapper>
	);
}

const DeactivateUserWrapper = styled.section`
//ticketstart
		.ticketdetail{
			margin: 0 var(--px-20) var(--px-20) var(--px-20);
			padding-bottom: var(--px-20);
			h5{
				font-size: var(--px-14);
				font-weight: normal;
				color: var(--neutrals-900);
				border: 0!important;
			}

			.motif-form-field{
				width: var(--px-290);
			}

			.motif-form-field:not(.motif-form-field-has-value),
			.motif-form-field:not(.motif-form-field-focused){
				.motif-label{
					margin-top: var(--px-6);
				}
			}

			.motif-form-field-has-value,
			.motif-form-field-focused{
				.motif-label{
					margin-top: var(--px-2)!important;
				}
			}
			
			
			.motif-input {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
		//ticketend
	.modal-footer {
		padding: var(--px-20);

		.motif-toast-close-button,
		.motif-toast-close-button:focus {
			outline: 0 !important;

			* {
				outline: 0 !important;
			}

			svg {
				outline: 0 !important;
			}

			*:focus {
				outline: 0 !important;
			}

			svg:focus {
				outline: 0 !important;
			}
		}
	}
	.delete-role-loader{
		padding: var(--px-20) !important;
		.loadingstyle {
			height: calc(100vh - var(--px-660)) !important;
		}
	}
	// .motif-loader {
	// 	height: var(--px-180) !important;
	// }

	.userSearch {
		display: flex;
		width: 100%;
		font-size: var(--px-14);

		.react-select__menu-list {
			height: var(--px-320);
			min-height: var(--px-320);
		}

		.result {
			padding: var(--px-16) var(--px-20);
			display: inline-block;
			width: 100%;

			h5 {
				font-size: var(--px-14);
				margin: 0;
				padding: 0 0 var(--px-20) 0;
			}

			li {
				margin: 0;
				list-style-type: none;
				.nameemail {
					width: 100%;
					display: inline-block;
					.ellipses {
						width: 100%;
						display: inline-flex;
					}
				}
			}

			.userlist {
				width: 100%;
				display: inline-block;
			}
		}

		.user-selectbox .select-group {
			width: 100%;
		}
	}
	.modal-footer {
		display: inline-flex;
		width: 100%;

		button {
			font-size: var(--px-14);
			padding: var(--px-12) var(--px-15);
			margin-right: var(--px-10);
			line-height: normal;
		}
	}
`;

export default DeactivateUser;
