import styled from 'styled-components';

const Wrapper = styled.section`
	.loadingstyle {
		height: calc(100vh - var(--px-250)) !important;
		
		& > section {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 100%;
			margin: 0 auto;
			display: flex;
		}
	}

	//pagination

	.pagerholder {
		margin: 0 auto;
		width: 100%;
		position: absolute;
		bottom: var(--px-0);

		.pagin-btn[disabled] {
			svg {
				fill: var(--neutrals-700);
			}
		}
	}

	.permissiongroup-paging {
		justify-content: flex-end;
		line-height: normal;
		min-width: auto !important;
		font-size: var(--px-14);
		background: var(--neutrals-00white);
		border-top: 1px solid var(--neutrals-200);
	}

	.mainwrapper {
		overflow: hidden;
		padding-top: var(--px-50);

		.toastMessage {
			.motif-toast-text-container {
			}

			&.block {
				display: block;
			}

			&.none {
				display: none;
			}
		}

		.message {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: calc(100vh - var(--px-180));

			&h5 {
				color: var(--neutrals-900);
			}
		}

		//heading

		.headerdetail {
			display: flex;
			padding: var(--px-12) 0;

			.title {
				margin-right: auto;
				.activediretory {
					width: 100%;
					display: inline-block;

					&.title {
						font-weight: bold;
					}
				}
			}

			.addmember .motif-button {
				width: var(--px-100);
				height: var(--px-40);
				margin-top: var(--px-5);

				&[disabled] {
					pointer-events: none;
				}
			}

			.title,
			.addmember.button {
				display: block;
				font-size: var(--px-14);
			}
		}

		.permissiongroup {
			.pageheading {
				display: flex;
				padding: var(--px-20) 0;
				svg {
					min-width: var(--px-25) !important;
					min-height: var(--px-25) !important;
					margin-right: var(--px-10);
				}

				h3 {
					margin: 0;
					line-height: var(--px-25);
				}
			}
		}

		//addon

		.add-on {
			display: flex;
			margin-right: auto;
			padding: 0 0 var(--px-20) 0;

			.adduser {
				.motif-button {
					height: var(--px-40);
					width: var(--px-100);
				}
			}

			.search {
				width: 16%;
				line-height: var(--px-40);
				margin-right: auto;

				.motif-icon-button{
					top: var(--px-5);
				}

				.motif-typeahead-close-button {
					top: var(--px-4);
				}

				.motif-input {
					height: var(--px-40);
				}
			}
		}

		.norecord {
			height: calc(100vh - var(--px-300));
			display: inline-block;
			width: 100%;
			display: flex;
			justify-content: center;
			flex: 0 auto;
			align-items: center;
			font-size: var(--px-16);
			color: var(--neutrals-700);
		}

		.managepermissionTable {
			height: 100%;
			overflow-y: auto;
			user-select: none;
			.errorRow {
				color: var(--red-600) !important;
			}
		}
	}
`;
export default Wrapper;
