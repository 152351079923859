import styled from 'styled-components';

const Importscot = styled.section`
	& .importscot {
		padding: 0 0 var(--px-10) 0;
		border-bottom: var(--px-1) solid var(--neutrals-200);
		width: 95%;
		display: inline-block;

		& .packagesourcedetail {
			float: left;
			//line-height: normal;
			width: 100%;
			& > div.form-group p {
				margin-right: 1em;
				width: var(--px-345);
				display: inline-flex;
			}

			& > .packagedetailscolumn {
				width: 50%;
				float: left;
				//line-height: normal;
				& > div.form-group p {
					margin-right: 1em;
					width: var(--px-345);
					display: inline-flex;
				}
			}
		}

		& div.form-group {
			//line-height: normal;
			height: auto;
			display: inline-block;
			width: 100%;
			margin: 0x;
		}

		& div.form-group p,
		& div.form-group label {
			padding: 0;
			margin: 0;
			min-height: auto;
			//line-height: normal;
			margin-right: var(--px-5);
			float: left;
			font-size: var(--px-14);
		}

		& div.form-group p {
			line-height: initial;
		}
	}
`;

export default Importscot;
