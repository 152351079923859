import React, {useEffect, useState} from 'react';
import FormField from '@ey/motif-react-wrapper/FormField';
import Label from '@ey/motif-react-wrapper/Label';
import Select from '@ey/motif-react-wrapper/Select';
import Option from '@ey/motif-react-wrapper/Option';
import {labels, engagementFilterTypes} from '../../../util/uiconstants';
import styled from 'styled-components';

const FilterEngagements = (props) => {
	const {setSearchType, searchType, isReviewEngagementAvailable} = props;
	const [selectedFilter, setSelectedFilter] = useState(
		searchType ? searchType : engagementFilterTypes.ALL
	);

	const getFilters = () => {
		let filters = [
			{
				value: engagementFilterTypes.ALL,
				label: labels.filterEngagements.all
			},
			{
				value: engagementFilterTypes.MY_FAVORITES,
				label: labels.filterEngagements.favorite
			}
		];

		if (isReviewEngagementAvailable) {
			filters.push({
				value: engagementFilterTypes.REVIEW_ENGAGEMENTS,
				label: labels.filterEngagements.review
			});
		}

		return filters;
	};

	const filterOptions = getFilters();

	useEffect(() => {
		setSelectedFilter(searchType);
	},[searchType])

	const handleFilterChange = (newFilter) => {
        if(newFilter){
            setSelectedFilter(newFilter);
            setSearchType(newFilter);
        }
    }
    
    return (
		<>
			<StyledFilterEngagements>
				<FormField>
					<Label>{labels.filterEngagementsLabel}</Label>
					<Select
						value={selectedFilter}
						onChange={(newFilterSelected) =>
							handleFilterChange(newFilterSelected)
						}
						key={selectedFilter}
					>
						{filterOptions.map((filterItem) => (
							<Option value={filterItem.value}>
								{filterItem.label}
							</Option>
						))}
					</Select>
				</FormField>
			</StyledFilterEngagements>
		</>
	);
};

export default FilterEngagements;

const StyledFilterEngagements = styled.section`
	width: 16%;
	display: inline-block;
	.motif-select-input {
		svg {
			width: var(--px-20);
			height: var(--px-20);
		}
	}
`;
