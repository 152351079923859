import styled from 'styled-components';

//enagement userlist page style
const Headerbody = styled.section`
	
	// common for table UI start
	.editPermissions-modal {
		width: 30%;
		max-width: 30%;
		height: auto;
	}

	.pageheading {
		display: flex;
		padding: var(--px-20) 0 var(--px-22) 0;

		.motif-tooltip-wrapper {
			.motif-button {

				&[disabled] {
					pointer-events: none;
				}
			}
		}
	}

	.e-page-title {
		margin: 0;
		margin-right: auto;
		line-height: var(--px-40);
	}

	.pagerholder {
		margin: 0 auto;
		width: calc(100vw - var(--px-5));
		position: absolute;
		bottom: var(--px-0);
		background: var(--neutrals-00white);

		.engagement-paging {
			font-size: var(--px-14);
			justify-content: flex-end;
			background: var(--neutrals-00white);
			border-top: 1px solid var(--neutrals-200);

			.Dropdown-arrow {
				margin-top: var(--px-3);
			}
		}
	}

	.userlistwrapper {
		width: 100%;
		margin-bottom: var(--px-40);
		display: inline-block;

		.manageaccessitemview {
			width: 100%;
			font-size: var(--px-14);
			display: flex;
			border-collapse: collapse;
			align-items: center;
			justify-content: center;
			border-bottom: var(--px-1) solid var(--neutrals-200);

			&.inactive mgt-person {
				color: var(--neutrals-300);
			}

			&:not(.inactive) .userName {
				color: var(--neutrals-900);
			}

			.grid {
				display: grid;
				grid-template-columns: auto 1fr;
				grid-gap: 0 10px;
			}

			&:last-child {
				border-bottom: none;
				// padding-bottom: var(--px-10);
			}
		}

		.userlist-header {
			width: 100%;
			display: inline-block;
			.editicon {
				width: 4%;
				padding: var(--px-10);
				vertical-align: middle;
			}
			.userlistheader {
				width: 100%;
				font-size: var(--px-14);
				color: var(--neutrals-700);
				font-weight: bold;
				display: flex;
				border-collapse: collapse;
				background: var(--neutrals-100);
				border: var(--px-1) solid var(--neutrals-200);
			}
		}

		.engagement-userlist-body {
			width: 100%;
			// overflow: auto;
			// height: calc(100vh - var(--px-550));
			display: inline-block;
			border: var(--px-1) solid var(--neutrals-200);

			.manageaccessitemview {
				border-bottom: var(--px-1) solid var(--neutrals-200);
			}

			.editicon-button {
				width: 4%;
				.motif-tooltip-wrapper {
					width: auto !important;
				}
			}

			.userlistholder {
				border-bottom: var(--px-1) solid var(--neutrals-200);
				.motif-tooltip-wrapper {
					width: 100%;
					.motif-tooltip-trigger-wrap {
						width: 100%;
						display: inline-block;

						.react-select__single-value {
							padding: 0;
						}
					}
				}
			}
		}

		.userlist-header,
		.engagement-userlist-body {
			.userlistitem {
				padding: var(--px-10);
				flex: 1 1 auto;
				width: 10%;
				vertical-align: middle;
				&.date,
				&.status {
					width: 6%;
				}

				&.status{

					&.postarchiveactive {
						.ellipses{
							width: auto;
							max-width: 48%;
						}
					}

					&.nopostarchiveactive{
						.postarchive{
							display: none;
						}
					}
					
					.ellipses{
						display: inline-flex;
						width: 100%;				
						margin-right: var(--px-10);						
					}
					
					.postarchive{
						display: inline-flex;						
					}
				}

				&.adstatus,
				&.gui{
					width: 8%;
				}

				&.status,
				&.indstatus {
					width: 12%;
				}

				&.permission{
					width: 16%;

					.pic{
						margin: 0 var(--px-11) 0 var(--px-6);
						padding: var(--px-10) 0 0 0;
						line-height: normal;
						display: inline-flex;
						.picflag{
							font-weight: bold;
							font-size: var(--px-12);
							color: var(--green-400);
						}
					}		
				}

				.e-country-dd .react-select__control {
					min-height: var(--px-40);
				}

				.permission-list {
					button {
						border: 0;
						background: none;

						.motif-badge {
							svg {
								vertical-align: text-top;
							}
						}
					}
					.show-pop-content {
						&.left{
							left: 0;
						}
						&.right{
							right: 0;
						}
						.checklist {
							ul li {
								margin-bottom: 0;
								a {
									width: 100%;
									color: var(--neutrals-900);
									display: inline-block;
									&:hover {
										text-decoration: none;
									}
								}
							}
						}
					}
				}
				.motif-badge {
					margin-right: var(--px-5);
				}
			}
		}
	}

	// common for table UI end
`;

export default Headerbody;
