/**
 * Modal.js
 */
import React from 'react';
import PropTypes from 'prop-types';
import MotifModal from '@ey/canvascoreservices/Modal';

export default function Modal(props) {
	const {
		show,
		onHide,
		onOkClick,
		onSaveCreateClick,
		title,
		children,
		isOkButtonDisabled,
		isCancelButtonDisabled,
		isSaveCreateButtonDisabled,
		showButtons,
		hideCloseButton,
		hideConfirmButton,
		hideCancelButton,
		confirmBtnTitle,
		confirmBtnToolTip,
		closeBtnTitle,
		saveCreateBtnTitle,
		saveCreateBtnToolTip,
		closeTitle,
		modalsize,
		modalbodystyle,
		modalContainerClass,
		modalonmodal,
		disableClose,
		okTitle,
		footerInfo,
		bodyminheight,
		role,
		tooltipLabel = null,
		tootilPosition,
		footerError,
		focusTrapOptions,
		variant,
		confirmBtnVariant,
		className,
		takeover,
		size,
		showFooter,
		customFooter,
		labels,
		focusTrapActive
	} = props;

	const isSizeDefined = size || false;

	return (
		<MotifModal
			show={show}
			onClose={onHide}
			className={`${className}  ${modalsize} ${modalContainerClass} ${
				isSizeDefined ? 'motif-size' : ''
			}`}
			takeover={takeover}
			size={size}
			onHide={onHide}
			onOkClick={onOkClick}
			onSaveCreateClick={onSaveCreateClick}
			title={title}
			isOkButtonDisabled={isOkButtonDisabled}
			isCancelButtonDisabled={isCancelButtonDisabled}
			isSaveCreateButtonDisabled={isSaveCreateButtonDisabled}
			showButtons={showButtons}
			hideCloseButton={hideCloseButton}
			hideConfirmButton={hideConfirmButton}
			hideCancelButton={hideCancelButton}
			hideMiddleButton={!saveCreateBtnTitle}
			onMiddleButtonClick={onSaveCreateClick}
			confirmBtnTitle={confirmBtnTitle}
			confirmBtnToolTip={confirmBtnToolTip}
			closeBtnTitle={closeBtnTitle}
			middleButtonTitle={saveCreateBtnTitle}
			saveCreateBtnToolTip={saveCreateBtnToolTip}
			closeTitle={closeTitle}
			modalbodystyle={modalbodystyle}
			modalContainerClass={modalContainerClass}
			modalonmodal={modalonmodal}
			disableClose={disableClose}
			footerInfo={footerInfo}
			bodyminheight={bodyminheight}
			role={role}
			tooltipLabel={tooltipLabel}
			tootilPosition={tootilPosition}
			footerError={footerError}
			focusTrapOptions={focusTrapOptions}
			variant={variant}
			confirmBtnVariant={confirmBtnVariant}
			showFooter={showFooter}
			customFooter={customFooter}
			labels={labels}
			focusTrapActive={focusTrapActive}
		>
			{children}
		</MotifModal>
	);
}

Modal.propTypes = {
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	modalsize: PropTypes.string,
	confirmBtnTitle: PropTypes.string,
	confirmBtnToolTip: PropTypes.string,
	closeBtnTitle: PropTypes.string,
	saveCreateBtnTitle: PropTypes.string,
	saveCreateBtnToolTip: PropTypes.string,
	modalbodystyle: PropTypes.string,
	modalContainerClass: PropTypes.string,
	showButtons: PropTypes.bool,
	hideCloseButton: PropTypes.bool,
	hideConfirmButton: PropTypes.bool,
	hideCancelButton: PropTypes.bool,
	isOkButtonDisabled: PropTypes.bool,
	isCancelButtonDisabled: PropTypes.bool,
	isSaveCreateButtonDisabled: PropTypes.bool,
	disabled: PropTypes.bool,
	show: PropTypes.bool,
	bodyminheight: PropTypes.string,
	role: PropTypes.string,
	tooltipLabel: PropTypes.string,
	tootilPosition: PropTypes.string
};

Modal.defaultProps = {
	className: 'defaultModal',
	bodyminheight: 'unset',
	confirmBtnVariant: 'primary',
	focusTrapActive: false
};