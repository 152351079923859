import styled from 'styled-components';
import {lightColor} from '@ey/styled-theme';

const EditUserPermissionsWrapper = styled.section`

.Userloadingstyle{
    height: calc(100vh - var(--px-191)) !important;
}

.inputDisabled {
    opacity: 0.8;
}

.motif-modal-footer{

    &.footermsg{
        flex-direction: column;

        .requiredtext{
            margin-left: var(--px-10);
        }

        .warning-wrap{         
            display: flex;
            line-height: var(--px-20);
            padding-bottom: var(--px-20);
            color: var(--neutrals-900);

            .motif-icon {
                margin-right: var(--px-5);

                svg{
                    fill: var(--red-600);
                    width: var(--px-24);
                    height: var(--px-24);
                    max-width: var(--px-24);
                    max-height: var(--px-24);
                }
            }

            .warningmessage{
                line-height: var(--px-24);
            }
        }

        .footer-btn{
            display: flex;
            width: 100%;
        }
    }

}

& > *{
    font-size:var(--px-14);
    color: var(--neutrals-700);
}

.thirdpartydescription{
    padding: var(--px-10) 0 var(--px-10) 0;
    line-height: var(--px-20);
    color:var(--neutrals-900);
}

.addmemberwrapper{
    overflow:auto;
    height:calc(100vh - var(--px-255));
}

.addmemberholder {
    padding: var(--px-10) var(--px-20);
    font-size: var(--px-14);
    
    //ticketstart
			.ticketdetail{
				margin: var(--px-20) 0 var(--px-80) 0;
				h5{
					font-size: var(--px-14);
					font-weight: normal;
                    margin-bottom: var(--px-10);
					color: var(--neutrals-900);
					border: 0!important;
				}

				.motif-form-field{
					width: var(--px-290);
				}

				.motif-form-field:not(.motif-form-field-has-value),
				.motif-form-field:not(.motif-form-field-focused){
					.motif-label{
						margin-top: var(--px-6);
					}
				}
		
				.motif-form-field-has-value,
				.motif-form-field-focused{
					.motif-label{
						margin-top: var(--px-2)!important;
					}
				}
				
				
				.motif-input {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			//ticketend

    .react-select-container{
     .react-select__value-container{
        height: var(--px-40)!important;
        line-height: normal!important;
     }   
    }

    h5{
        font-size: var(--px-14);
        color:var(--neutrals-1000);
        font-weight: bold;
        margin-bottom: var(--px-5);
    }

    .description{
        font-size: var(--px-14);
        color:var(--neutrals-1000);
    }

    & > .toastMessage{        
        width: 100%!important;
        position: relative;
        display:block;

        .motif-toast{
			position:absolute;
			z-index: 9999;
            margin: 0;
            
            .motif-toast-content{
                width:100%;
            }
		}
    }

    .motif-typeahead-close-button{
       display:none;
    }

    .userfield-row{
        margin: var(--px-10) 0;
        width: 100%;

        .css-b8ldur-Input{
            react-select__input{
                padding-top: 0!important;
            }
        }

        &.nameandemail {
    
        label{     
            margin-left: auto;
            left: var(--px-10);
        }

        .react-select-container.react-select--is-disabled .react-select__control{
            height: var(--px-47);
            min-height: auto;

            .react-select__value-container{
                height: auto;
                min-height: auto;
            }
        }

        .react-select__single-value{
            margin-left: auto;
            left: var(--px-10);   
            top: var(--px-24);
        }

        .react-select__indicators {
            display:none;
        }

        .react-select__single-value > section > section:last-child{
            font-size: var(--px-13)!important;
            margin-top: 0;
        }
        
        .react-select__menu{
            z-index: 999;
        }
    }
        &.email > .useremailid,
        &.nameandlastname > .userfirstname,
        &.nameandlastname > .userlastname{
            width: 45%;
            float:left;
            margin-right: 4%;
        }

        &.initialandrole{
            & > .userinitial{
                width: 48%;
                float:left;
                margin-right: 4%;
            }

            & .engagementrole{
                width: 48%;
                margin-right: 0%;
                float: left;

                .react-select__control{
                    height: var(--px-47);
                }
            }

            & .confidentialityType {
                width: 100%;
            }


            &.external > .userinitial {
                width: 15%;
                float:left;
                margin-right: 4%;
            }
        }
    }
    & > * {
            display:inline-block;
        }
    }

.user-confid-permission{
    width: 100%;

    .checklist{
        margin: 0 0 var(--px-10) 0;
    }

    h4 {			
        color:var(--neutrals-900);
        font-size: var(--px-15);
        font-weight: bold;
    }

    .checkoption-list{
        display: flex;
        flex-wrap: wrap;
        width:100%;

        & li{
            width: 45%;
            list-style-type:none;
            margin: var(--px-8) 4% var(--px-8) 0;
            position: relative;

            & .checkoption-item:hover ~ .infotooltip{
                display: block;
            }
        }

        .motif-checkbox-label{
            width: 100%;
            font-weight:normal;
        }

        .infotooltip {
            position: absolute;
            z-index: 99999;
            background: var(--neutrals-00white);
            left: 0;
            max-width: var(--px-400);
            bottom: var(--px-42);
            padding: var(--px-10);
            line-height: var(--px-20);
            z-index: 1;
            box-shadow: 0 0 var(--px-8) var(--px-2) ${(props) =>
							props.theme.color[`grey225`]};
            // word-break: break-all;
            display: none;

            & .tooltip-downarrow {              
                position: absolute;
                width: var(--px-13);
                height: var(--px-6);
                display: block;
                bottom: 0;
                z-index: -1;
                left: var(--px-40);
            

                /* Stroke */
                &:before {
                    content: '';
                    display: block;
                    position: absolute; 
                    left: 50%;
                    top: auto;
                    right: auto;
                    bottom: calc(var(--px-7) * -1);
                    width: 0;
                    height: 0;    
                    transform: translateX(-50%) rotate(-45deg) skew(-9deg, -9deg);
                    box-shadow: calc(var(--px-4) * -1) var(--px-4) var(--px-5) calc(var(--px-2) * -1) ${(props) =>
											props.theme.color[`grey225`]};            
                    border: var(--px-11) solid ${(props) =>
											props.theme.color[`white`]} !important;                   
                }
            }
        }
    }
}

header.access-group{
    h4{
        color:var(--neutrals-900);
        font-size: var(--px-15);
        font-weight:bold;
    }
     svg {
         margin-left: var(--px-4);
     }
}

header.headerchecklist{
    padding: var(--px-10) 0 var(--px-5) 0;
    
    svg {
        vertical-align:middle;
    }
}

header.access-group{
    padding: var(--px-10) 0 var(--px-10) 0;
    
    svg {
        vertical-align:middle;
    }
}

header.addmemberdescription{
    padding: var(--px-10) 0 var(--px-5) 0;
    display:inline-block;
    width: 100%;

    &.userinfo{
        padding-bottom:0;
    }

    h4{
        margin: 0;
        color: var(--neutrals-900);
        font-weight: bold;
        font-size: var(--px-15);
    }

}

header.addmemberdescription,
header.headerchecklist,
header.access-group{	
    h4{
        padding: 0;
        margin: 0;
        display:inline;
    }
}

    .motif-form-field.motif-form-field-has-value .motif-label-for-text{
        top: var(--px-5);
    }
    
    
    &.motif-form-field{
        margin-bottom: 0;

        .motif-form-field{
            margin: 0;
        }
    }

    &.hidePlaceHolder:not(floating-label) label{
        color: var(--neutrals-700);	
    }

    &:error{
        border: var(--px-1) solid var(--neutrals-300);
    }

    &.floating-label.hidePlaceHolder label{
        color: var(--neutrals-700);	
    }

    &.hidePlaceHolder label{
        color: var(--neutrals-700);	 	
    }

    &:not(.floating-label) label{
        font-size: var(--px-14);
        
    }

    &.userfirstname,
    &.userlastname{
        width:100%;
        margin-right: 3em;
    }

    &.userinitial{
        width: 100%;
        margin-right: 3em;
    }


.userfield-row.nameandemail {
    svg.note-error-icon{
        fill:var(--red-600);
    }
}


.accessgroupwrapper{

    .access-group svg{
        fill: var(--neutrals-1000);
    }

    &.userfield-row{
        width: 100%;
        margin-top: 0;

        svg.note-error-icon{
            fill:var(--red-600);
        }

        .accessgroup{

            .react-select__input{
                color: var(--neutrals-1000);
            }
            
            .react-select-container .react-select__control .react-select__value-container.react-select__value-container--is-multi{
                padding-top: var(--px-20);
            }
        }
    }

    .react-select__menu{
        z-index: 9999;
    }

  
}
`;

export default EditUserPermissionsWrapper;
