import styled from 'styled-components';

const Headerbody = styled.section`
	// common for table UI start

	.userlistwrapper {
		display: inline-block;
		width: 100%;

		.manageaccessitemview {
			width: 100%;
			font-size: var(--px-14);
			display: flex;
			border-collapse: collapse;
			align-items: center;
			justify-content: center;
			border-bottom: var(--px-1) solid var(--neutrals-200);

			&.inactive mgt-person {
				color: var(--neutrals-300);
			}

			&:not(.inactive) .userName {
				color: var(--neutrals-900);
			}

			&:last-child {
				border-bottom: none;
				padding-bottom: var(--px-10);
			}
		}

		.userlist-header {
			width: 100%;
			display: inline-block;
			.userlistheader {
				width: 100%;
				font-size: var(--px-14);
				color: var(--neutrals-700);
				font-weight: bold;
				display: flex;
				border-collapse: collapse;
				background: var(--neutrals-100);
				border: var(--px-1) solid var(--neutrals-200);
			}
		}

		.userlist-body {
			overflow: auto;
			height: calc(100vh - var(--px-280));
			display: inline-block;
			width: 100%;
			border: var(--px-1) solid var(--neutrals-200);

			.userlistholder {
				border-bottom: var(--px-1) solid var(--neutrals-200);
			}
		}

		.userlist-header,
		.userlist-body {
			.userlistitem {
				padding: var(--px-10);
				flex: 1 1 auto;
				width: 10%;
				vertical-align: middle;
				// display:flex;
			}
		}
	}

	// common for table UI end

	// manage access page table UI start

	&.manageaccesspageview .userlistwrapper {
		.userdetail {
			width: 47.5%;

			.userdata {
				width: auto;
				float: left;
			}

			.initials {
				width: 100%;
				float: left;

				a {
					display: block;
				}
			}

			& > section:first-child {
				display: inline;
			}

			mgt-person {
				--avatar-size: var(--px-40);
			}
		}

		.useremail {
			width: 47.5%;
		}

		.userlistitem.contextmenu {
			width: 5%;
			text-align: center;

			.context .show-pop-content-body li {
				margin-bottom: 0;
			}

			.show-popup-arrow {
				margin: calc(var(--px-6) * -1);
			}
		}
	}

	// manage access page table UI end
`;

export default Headerbody;
