import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ConfirmationStep from '../RestoreWorkflow/confirmation';
import SelectArchvieFileStep from '../RestoreWorkflow/selectarcfile';
import ChooseNewExisting from '../EngagementWorkflowsNew/chooseneworexisting';
import RestoreToExistingWorkspace from './workspace/restoretoexistingworkspace';
import RestoreToNewWorkspace from '../EngagementWorkflowsNew/createeditworkspace';
import RestoreEngagement from './engagement/restoreengagement';
import {
	labels,
	urls,
	standardStrings,
	getDateformat,
	dateFormats,
	dateFormatsListItems,
	permissionGroups
} from '../../../util/uiconstants';
import moment from 'moment';
import styled from 'styled-components';
import Loader from '@ey/loader';
import {
	getRestoreEngagements,
	getArchivedEngagementDetailsForRestore
} from '../../../actions/restoreengagementaction';
import env from '../../../util/env';
import WizardWrapper from '../Common/WizardWrapper';

const RestoreEngagementWizard = (props) => {
	const {history} = props;
	const dispatch = useDispatch();

	const restoreData = useSelector((reduxStore) => reduxStore.restoreEngagement);
	const userPermissions = useSelector((state) => state.userPermissions);
	const arcEngagementInfo = useSelector(
		(reduxStore) => reduxStore.arcEngagementInfo
	);

	let mode = {
		restore: 'restore'
	};
	let oldPath = '/archives';
	let engagementId = standardStrings.EMPTY;
	let workspaceId = null;
	let restoreType = 1;
	const wizLoaderName = labels.restoreengagementheader;

	const [domain, setDomain] = useState({});
	const [arcFileAttachments, setarcFileAttachments] = useState([]);
	const [canGoToNextStep, setCanGoToNextStep] = useState(false);
	const [activeStepIndex, setActiveStepIndex] = useState(0);
	const [step3OpenChng, setStep3OpenChng] = useState(1);
	const [step1NextChng, setstep1NextChng] = useState(1);
	const [step3NextChng, setstep3NextChng] = useState(1);
	const [step4FinishChng, setstep4FinishChng] = useState(1);
	const [arcFilesCount, setArcFilesCount] = useState(0);
	const [wizardStepsContent, setWizardStepsContent] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [engagementNameState, setengagementNameState] = useState('');
	const [isDataValid, setIsDataValid] = useState(true);
	const [restoreEngagementCount, setRestoreEngagementCount] = useState(0);
	const [maxRestoreCopiesAllowed, setMaxRestoreCopiesAllowed] = useState(0);
	const [
		maxglobalexceptionrestoreperengmt,
		setMaxglobalexceptionrestoreperengmt
	] = useState(0);

	const set4WizardSteps = [
		{
			stepName: labels.step01,
			stepDescription: labels.restoreconfirmationstepheader,
			active: true
		},
		{
			stepName: labels.step02,
			stepDescription: labels.chooserestorehdr,
			active: false
		},
		{
			stepName: labels.step03,
			stepDescription: domain.domainChooseExisting
				? labels.restorestephdrext
				: labels.restorestephdrnew,
			active: false
		},
		{
			stepName: labels.step04,
			stepDescription: labels.restoreengagementstepheader,
			active: false
		}
	];
	const [wizardSteps, setWizardSteps] = useState(set4WizardSteps);

	const isGlobalRestoreApplicable = () => {
		let perm = userPermissions && userPermissions.userpermissions;

		if (perm) {
			var item = perm.find(
				(x) => x.groupname === permissionGroups.Global_RestoreException
			);
			return item != null ? true : false;
		}
	};

	const showClose = () => {
		if (!isDataValid) {
			return true;
		}

		if (
			isGlobalRestoreApplicable() &&
			restoreEngagementCount < maxglobalexceptionrestoreperengmt
		) {
			return false;
		}

		if (restoreEngagementCount < maxRestoreCopiesAllowed) {
			return false;
		}

		return true;
	};

	const buttons = [
		{
			label: activeStepIndex == 0 ? labels.cancel : labels.back, // Change label based on active step.
			onClick: () => {
				switch (activeStepIndex) {
					case 0:
						navigateBack();
						break;
					case 1:
						setActiveStepIndex((prev) => prev - 1);
						break;
					case 2:
						setActiveStepIndex((prev) => prev - 1);
						break;
					case 3:
						setActiveStepIndex((prev) => prev - 1);
						break;
					case wizardSteps.length - 1:
						setActiveStepIndex((prev) => prev - 1);
						break;
					default:
						setActiveStepIndex((prev) => prev - 1);
						break;
				}
			},
			isVisible: !showClose(),
			disabled: showClose()
		},
		{
			label:
				activeStepIndex == wizardSteps.length - 1
					? labels.finish
					: labels.continue,
			onClick: () => {
				switch (activeStepIndex) {
					case 0:
						canGoToNextStep
							? setActiveStepIndex((prev) => prev + 1)
							: setstep1NextChng((prev) => prev + 1);
						// else => ignore next step click - there are some errors on the UI.
						// ask user to select checkbox.
						break;
					case 1:
						if (arcFilesCount > 1) {
							setActiveStepIndex((prev) => prev + 1);
						} else {
							setActiveStepIndex((prev) => prev + 1);
							setStep3OpenChng((prev) => prev + 1);
							resetDomainValues([
								'domainClientId',
								'domainClientName',
								'domainClientCode',
								'domainWorkspaceId',
								'domainWorkspaceName'
							]);
						}
						break;
					case 2:
						if (arcFilesCount > 1) {
							setActiveStepIndex((prev) => prev + 1);
						} else {
							canGoToNextStep
								? setActiveStepIndex((prev) => prev + 1)
								: setstep3NextChng((prev) => prev + 1);
						}
						break;
					case 3:
						if (arcFilesCount > 1) {
							canGoToNextStep
								? setActiveStepIndex((prev) => prev + 1)
								: setstep3NextChng((prev) => prev + 1);
						} else {
							setstep4FinishChng((prev) => prev + 1);
						}
						break;
					case wizardSteps.length - 1:
						setstep4FinishChng((prev) => prev + 1);
						break;
				}
			},
			isVisible: !showClose(),
			disabled: showClose()
		},
		{
			label: labels.close,
			onClick: () => {
				navigateBack();
			},
			isVisible: showClose(),
			disabled: !showClose()
		}
	];

	useEffect(() => {
		const dispatchNeededActions = async () => {
			try {
				await dispatch(getRestoreEngagements(getRestoreEngListURL()));
				await getPreviousEngagementData();
			} catch {
				setIsDataValid(false);
			} finally {
				setIsLoading(false);
			}
		};
		try {
			setIsLoading(true);
			setDomain({
				domainChooseExisting: false,
				domainClientId: '',
				domainClientCode: standardStrings.EMPTY,
				domainWorkspaceId: '',
				domainClientCode: '',
				domainEngagementName: '',
				domainSelectedArcFileGuid: standardStrings.EMPTY,
				domainClientName: standardStrings.EMPTY,
				domainWorkspaceName: standardStrings.EMPTY,

				domainRestoreType: restoreType,
				domainOriginEngId: standardStrings.EMPTY,
				domainOriginWorkspaceId: workspaceId,
				domainOriginLocation: -1,
				domainOriginCountryName: '',

				domainOriginLanguageId: -1,
				domainOriginLanguageName: '',
				domainOriginPrimoffId: -1,
				domainOriginPrimoffName: '',
				domainOriginServiceLnId: -1,
				domainOriginSubServiceLnId: -1,

				domainOriginEngCodeArr: [],
				domainOriginEngYearEndDate: null,
				domainOriginEngEndDate: null,
				domainOriginAudReportDate: null
			});
			setengagementNameState(
				restoreData?.restoreEngagementData?.state?.engagementName
			);

			engagementId = restoreData?.restoreEngagementData?.state?.engagementId;
			workspaceId = restoreData?.restoreEngagementData?.state?.workspaceId;
			restoreType = restoreData?.restoreEngagementData?.state?.restoreType;
			updateWizardData('domainOriginEngId', engagementId);
		} finally {
			// check if we have valid engagement id and workspace id
			if (engagementId > 0 && workspaceId > 0) {
				dispatchNeededActions();
			} else {
				console.log(
					'Cannot restore, missing engagement id or workspace id for method dispatchNeededActions'
				);
				setIsDataValid(false);
				setIsLoading(false);
			}
		}
	}, []);

	useEffect(() => {
		if (arcEngagementInfo.hasOwnProperty('data')) {
			updateWizardData(
				'domainOriginLocation',
				arcEngagementInfo.data.countryid
			);
			updateWizardData(
				'domainOriginEngVersion',
				arcEngagementInfo.data.engagementversion
			);
			updateWizardData(
				'domainOriginPrimoffId',
				arcEngagementInfo.data.primaryoffice
			);
			updateWizardData(
				'domainOriginServiceLnId',
				arcEngagementInfo.data.servicelineid
			);
			updateWizardData(
				'domainOriginSubServiceLnId',
				arcEngagementInfo.data.subservicelineid
			);
			updateWizardData(
				'domainOriginLanguageId',
				arcEngagementInfo.data.languageid
			);
			updateWizardData(
				'domainOriginEngCodeArr',
				arcEngagementInfo.collections.engagementcodes
			);
			updateWizardData(
				'domainOriginEngYearEndDate',
				formatDateTime(arcEngagementInfo.data.yearenddate)
			);
			updateWizardData(
				'domainOriginEngEndDate',
				formatDateTime(arcEngagementInfo.data.enddateperiod)
			);
			updateWizardData(
				'domainOriginAudReportDate',
				formatDateTime(arcEngagementInfo.data.reportreleasedate)
			);
		}
	}, [arcEngagementInfo]);

	const formatDateTime = (date) => {
		return date
			? getDateformat(
					dateFormats,
					moment.utc(date).format(dateFormatsListItems[dateFormats].label)
			  )
			: standardStrings.EMPTY;
	};

	useEffect(() => {
		if (restoreData.hasOwnProperty('data')) {
			setRestoreEngagementCount(restoreData.data.restoreengagementcount);
			setMaxRestoreCopiesAllowed(restoreData.data.maxrestorecopiesallowed);
			setMaxglobalexceptionrestoreperengmt(
				restoreData.data.maxglobalexceptionrestoreperengmt
			);
			setArcFilesCount(restoreData.data.arcfilescount);
			setarcFileAttachments(restoreData.data.arcfileattachments);
		}
	}, [restoreData]);

	useEffect(() => {
		if (arcFilesCount > 1) {
			setWizardSteps([
				{
					stepName: labels.step01,
					stepDescription: labels.restoreconfirmationstepheader,
					active: true
				},
				{
					stepName: labels.step02,
					stepDescription: labels.selectarchiveheader,
					active: false
				},
				{
					stepName: labels.step03,
					stepDescription: labels.chooserestorehdr,
					active: false
				},
				{
					stepName: labels.step04,
					stepDescription: domain.domainChooseExisting
						? labels.restorestephdrext
						: labels.restorestephdrnew,
					active: false
				},
				{
					stepName: labels.step05,
					stepDescription: labels.restoreengagementstepheader,
					active: false
				}
			]);
		}
		updateArcGuidIfOnlyOne();
	}, [arcFilesCount]);

	useEffect(() => {
		// If the "selecting new or create workspace" state is changed, update the step labels as needed.
		setWizardSteps((prev) => {
			return prev.map((step) => {
				return step.stepDescription === labels.restorestephdrext ||
					step.stepDescription === labels.restorestephdrnew
					? {
							...step,
							stepDescription: domain.domainChooseExisting
								? labels.restorestephdrext
								: labels.restorestephdrnew
					  }
					: step;
			});
		});
	}, [domain.domainChooseExisting]);

	const resetDomainValues = (valuesArray) => {
		valuesArray.forEach((valueName) => {
			updateWizardData(valueName, undefined);
		});
	};

	const getRestoreEngListURL = () => {
		// this gives the list of available restored copies of eng.
		return (
			env.getURL('serviceUrl') +
			'/' +
			`${urls.ENGAGEMENTS_URL}` +
			'/restoreeng?filters=restoretypeid eq ' +
			restoreType +
			'&id=' +
			engagementId
		);
	};

	const getArchivedEngagementDetailsURL = () => {
		// this gives the list of available restored copies of eng.
		return (
			env.getURL('serviceUrl') +
			'/' +
			'engagements.json/' +
			engagementId +
			'?phase=RestoreOperation&filters=workspaceid eq ' +
			workspaceId
		);
	};

	const getPreviousEngagementData = async () => {
		await dispatch(
			getArchivedEngagementDetailsForRestore(getArchivedEngagementDetailsURL())
		);
	};

	const updateArcGuidIfOnlyOne = () => {
		if (arcFilesCount == 1) {
			updateWizardData(
				'domainSelectedArcFileGuid',
				restoreData.data.arcfileattachments[0].FileGUID
			);
		}
	};

	const navigateBack = () => {
		history.push(oldPath);
	};

	// Step valid functions are triggered from inside components, thats how we manage canGoToNextStep local state.

	const isStep1Valid = (isComponentDataValid) => {
		if (activeStepIndex == 0) {
			if (isComponentDataValid == undefined || isComponentDataValid == null)
				setCanGoToNextStep(false);
			else setCanGoToNextStep(isComponentDataValid);
		}
	};
	const isStep2Valid = (isComponentDataValid) => {
		if (activeStepIndex == 1) {
			if (isComponentDataValid == undefined || isComponentDataValid == null)
				setCanGoToNextStep(false);
			else setCanGoToNextStep(isComponentDataValid);
		}
	};
	const isStep3Valid = (isComponentDataValid) => {
		if (activeStepIndex == 2) {
			if (isComponentDataValid == undefined || isComponentDataValid == null)
				setCanGoToNextStep(false);
			else setCanGoToNextStep(isComponentDataValid);
		}
	};
	const isStep4Valid = (isComponentDataValid) => {
		if (activeStepIndex == 3) {
			if (isComponentDataValid == undefined || isComponentDataValid == null)
				setCanGoToNextStep(false);
			else setCanGoToNextStep(isComponentDataValid);
		}
	};
	const isStep5Valid = (isComponentDataValid) => {
		if (activeStepIndex == 4) {
			if (isComponentDataValid == undefined || isComponentDataValid == null)
				setCanGoToNextStep(false);
			else setCanGoToNextStep(isComponentDataValid);
		}
	};

	const updateWizardData = (fieldName, fieldValue) => {
		//Adds or update a prop of the state object domain.
		setDomain((prev) => {
			return {
				...prev,
				[fieldName]: fieldValue
			};
		});
	};

	const getWizardData = (domainFieldName) => {
		return domain[domainFieldName];
	};

	const Step1 = () => {
		return (
			<ConfirmationStep
				isComponentValid={isStep1Valid}
				updateWizardData={updateWizardData}
				getDependencyData={getWizardData}
				engagementName={engagementNameState}
				isDataValid={isDataValid}
				domainRestoreType={restoreType}
				nextClicked={step1NextChng}
			/>
		);
	};

	const Step2 = () => {
		return arcFilesCount > 1 ? (
			<SelectArchvieFileStep
				isComponentValid={isStep2Valid}
				updateWizardData={updateWizardData}
				getDependencyData={getWizardData}
				mode={mode.restoreMode}
				arcFileList={arcFileAttachments}
			/>
		) : (
			<ChooseNewExisting
				isComponentValid={arcFilesCount > 1 ? isStep3Valid : isStep2Valid}
				updateWizardData={updateWizardData}
				getDependencyData={getWizardData}
				headerQuestion={labels.restorehdrqstn}
			/>
		);
	};

	const Step3 = () => {
		return arcFilesCount > 1 ? (
			<ChooseNewExisting
				isComponentValid={arcFilesCount > 1 ? isStep3Valid : isStep2Valid}
				updateWizardData={updateWizardData}
				getDependencyData={getWizardData}
				headerQuestion={labels.restorehdrqstn}
			/>
		) : domain.domainChooseExisting ? (
			<RestoreToExistingWorkspace
				isComponentValid={arcFilesCount > 1 ? isStep4Valid : isStep3Valid}
				updateWizardData={updateWizardData}
				nextClicked={step3NextChng}
				onComponentDisplayed={step3OpenChng}
				getDependencyData={getWizardData}
			/>
		) : (
			<RestoreToNewWorkspace
				isComponentValid={arcFilesCount > 1 ? isStep4Valid : isStep3Valid}
				updateWizardData={updateWizardData}
				nextClicked={step3NextChng}
				onComponentDisplayed={step3OpenChng}
				getDependencyData={getWizardData}
				mode={mode.restore}
			/>
		);
	};

	const Step4 = () => {
		return arcFilesCount > 1 ? (
			domain.domainChooseExisting ? (
				<RestoreToExistingWorkspace
					isComponentValid={arcFilesCount > 1 ? isStep4Valid : isStep3Valid}
					updateWizardData={updateWizardData}
					nextClicked={step3NextChng}
					onComponentDisplayed={step3OpenChng}
					getDependencyData={getWizardData}
				/>
			) : (
				<RestoreToNewWorkspace
					isComponentValid={arcFilesCount > 1 ? isStep4Valid : isStep3Valid}
					updateWizardData={updateWizardData}
					nextClicked={step3NextChng}
					onComponentDisplayed={step3OpenChng}
					getDependencyData={getWizardData}
					mode={mode.restore}
				/>
			)
		) : (
			<RestoreEngagement
				isComponentValid={arcFilesCount > 1 ? isStep5Valid : isStep4Valid}
				updateWizardData={updateWizardData}
				onFinishClick={step4FinishChng}
				getDependencyData={getWizardData}
				wizLoaderName={wizLoaderName}
				closeWizard={navigateBack}
			/>
		);
	};

	const Step5 = () => {
		return (
			<RestoreEngagement
				isComponentValid={arcFilesCount > 1 ? isStep5Valid : isStep4Valid}
				updateWizardData={updateWizardData}
				onFinishClick={step4FinishChng}
				getDependencyData={getWizardData}
				wizLoaderName={wizLoaderName}
				closeWizard={navigateBack}
			/>
		);
	};

	return (
		<>
			{isLoading ? (
				<Loader view="fullscreen" />
			) : (
				<StyledWizardWrapper>
					<WizardWrapper
						wizLoaderName={wizLoaderName}
						currentStepIndex={activeStepIndex}
						wizardSteps={wizardSteps}
						setWizardSteps={setWizardSteps}
						buttons={buttons}
						theme={
							document
								.getElementsByTagName('body')[0]
								.getAttribute('motif-theme') === 'light'
								? null
								: 'dark'
						}
						stepContent={[Step1(), Step2(), Step3(), Step4(), Step5()]}
					/>
				</StyledWizardWrapper>
			)}
		</>
	);
};

export default RestoreEngagementWizard;

const StyledWizardWrapper = styled.section`
	width: 100%;
	padding: 0 var(--px-40);
	header h5 {
		font-size: var(--px-20);
		font-weight: bold;
		width: 100%;
		padding: var(--px-15) 0;
	}
	.componentBodyWrapper {
		height: calc(100vh - var(--px-280));
	}
`;
