import styled from 'styled-components';
import {color} from '@ey/styled-theme';

const Createeditstyle = styled.section`
       width: 100%;
       .createeditengagement .clientworkspaceDetails {
              padding: 0 0 var(--px-10) 0;
              border-bottom: var(--px-1) solid ${(props) =>	props.theme.color[`grey235`]};
              width: 100%;

              & > div.form-group {
                      line-height: normal;
                      height: auto;
                      display: inline-block;
                      width: 100%;
                      margin:0;
              }

              & > div.form-group p{
                     width:80%;
              }

              & > div.form-group p,
              & > div.form-group label {
                     padding: 0;
                     margin: 0;
                     min-height: auto;
                     line-height: normal;
                     margin-right: var(--px-5);
                     float: left;
              }
       }

       .createeditengagement .addengagement {
              width: 95%;
              border-bottom: var(--px-1) solid ${(props) =>	props.theme.color[`grey235`]};
              margin-bottom: var(--px-20);
              & .btn {
                      margin: 0 0 var(--px-20) 0;
                      font-weight:bold;
                      &:active{
                             box-shadow:none;
                      }
              }
       }
       .createeditengagement {
              display: inline-block;
              width: 100%;
			  .createengagement {                    
                      padding-top:var(--px-20);
                      div.form-group:nth-child(1) {
                             span.input-group {
                                    width: var(--px-600);
                             }
                      }
                      .addnewengagement {
                             display: flex;
                             input[type='radio'] {
                                    vertical-align: middle;
                                    margin: var(--px-5);
                             }
                      }
					  .addnewengagement div.form-group:nth-child(1) {
                             float: left;
                             & span.input-group {
                                    width: var(--px-400);
                             }
                      }
                      .addnewengagement .clientname div.form-group:nth-child(1) {
                             float: left;
                             span.input-group {
                                    width: var(--px-600);
                                    margin-left: var(--px-20);
                             }
                      }

              & .text-input{
                      display: flex;
                      width:var(--px-400);
                      margin:0 var(--px-35) var(--px-20) 0;
                      .form-group {
                             width: 100%;
                      }
              }

              & .motif-form-field {
                      display:inline-block;
                      width:var(--px-400);
                      margin-right:var(--px-35);
                      margin-bottom:var(--px-20);
              }
              
              }

              

              

              & .form-group {
                      margin: 0;
                      padding: 0;
                      display: inline;
              }

              & .form-group label.control-label {
                      vertical-align: bottom;
                      display: inline;
              }

              & > .form-group * {
                      font-size: var(--px-16);
                      line-height: normal;
                      padding: 0;
                      margin: 0;
              }

              & .form-control-static {
                      float: right;
                      line-height: var(--px-48);
                      font-size: var(--px-14);
              }

              & div.form-group {
                      display: inline-flex;
                      font-size: var(--px-14);
                      height: var(--px-75);
                      margin: 0 1em 1em 0;

                      & .input-label {
                             line-height: normal;
                      }

                      & span.input-group {
                             float: left;
							 display:inline-block;
                             & input.form-control {
                                    margin: 0 var(--px-20) 0 0;
                                    vertical-align: middle;
                                    border: var(--px-1) solid var(--neutrals-300);
                                    font-size: var(--px-14);

                                    &[disabled]:not([type='radio']):not([type='checkbox']){
                                           opacity:1;
                                           color: var(--neutrals-200);
                                           border:var(--px-1) solid ${color(`grey235`)};
                                    }
                             }
                      }

                      & > section {
                             width: var(--px-600);
                             float: left;
                             margin-right: var(--px-20);

                             & .select-label {
                                    line-height: normal;
                                    color: var(--neutrals-900);
                             }

                             & .Select-control {
                                    margin: 0 var(--px-20) 0 0;
                                    vertical-align: middle;
                                    border: var(--px-1) solid var(--neutrals-300);
                                    line-height: var(--px-46);
                             }
                      }

                      & > section {
                             width: var(--px-400);
                             float: left;

                             & .Select-control {
                                    border: var(--px-1) solid var(--neutrals-300);
                             }
                      }
              }

              .zeus-datepicker {
				    display: flex;                	
              }
       }
`;

export default Createeditstyle;
