/**
 * Created by dwarika on 3/24/2022.
 */
import MotifToast from '@ey/motif-react-wrapper/Toast';
import React from 'react';

export default function Toast(props) {
	const {
		variant,
		actionName,
		actionOnClick,
		onClose,
		position,
		duration,
		children
	} = props;

	return (
		<MotifToast
			variant={variant}
			actionName={actionName}
			actionOnClick={actionOnClick}
			onClose={onClose}
			position={position}
			duration={duration}
		>
			{children}
		</MotifToast>
	);
}
