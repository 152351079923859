import * as QueryString from 'query-string';
import TabNavigation from '@ey/motif-react-wrapper/TabNavigation';
import TabControl from '@ey/motif-react-wrapper/TabControl';
import Dropdown from '@ey/motif-react-wrapper/Dropdown';
import DropdownItem from '@ey/motif-react-wrapper/DropdownItem';
import Button from '@ey/motif-react-wrapper/Button';
import Icon from '@ey/motif-react-wrapper/Icon';
import React, { useEffect, useState } from 'react';
import {
	displayGroupings,
	engagementV2DefaultSkip,
	engagementV2DefaultTop,
	engagementV2PageSizeOptions,
	labels,
	pagerOptions,
	permissionGroups,
	searchTypes,
	settingsPreferences,
	standardStrings,
	tabTypes,
	urls
} from '../../util/uiconstants';
import {
	getMyWorkspaces,
	getReviewEngagements
} from '../../actions/workspacesactions';
import {
	getUserPermissions,
	updateGroupPrefUserSettings,
	updatePagePrefUserSettings
} from '../../actions/usersettingsaction';
import {
	searchEngagementState,
	setCurrentActiveTabInMyEngSection,
	setCurrentGroupingTypeInMyEngSection
} from '../../actions/dashboardactions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import ClientList from '../Clients/clientlist';
import DashboardStyles from './dashboardStyleComp';
import EngagementGrouping from './engagemengrouping';
import EngagementList from './engagementlist';
import LoadingIndicator from '../Common/LoadingIndicator/LoadingIndicator';
import Pagination from '@ey/pager';
import PortfolioView from './PortfolioView/portfolioview';
import Search from '@ey/search';
import Toast from '../Common/Toast/Toast';
import _ from 'lodash';
import { async } from 'regenerator-runtime';
import { clearToastError } from '../../actions/engagementerroraction';
import { contentIcAddCircle24px } from '@ey-xd/motif-react/assets/icons';
import env from '../../util/env';
import { getFavoriteEngagements } from '../../actions/favoriteengagementsaction';
import { getFavoriteWorkspaces } from '../../actions/favoriteworkspacesaction';
import { getMyEngagements } from '../../actions/engagementsaction';
import { getReviewWorkspaces } from '../../actions/reviewworkspaceaction';
import { lightColor } from '@ey/styled-theme';
import styled from 'styled-components';
import { successMessageDisplayDuration } from '../../util/uiconstants';
import UAParser from 'ua-parser-js';
import {deviceIcDevices24px} from '@ey/motif-react-wrapper/assets/icons/index';

/* 
 refreshDashboard  :This is a prop passed to child component if we want to refresh parent.
*/
const Dashboard = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const search = useLocation().search.toLowerCase();

	const { location, viewDisplayName } = props;

	const myWorkspaces = useSelector((store) => store.myWorkspaces);
	const myEngagements = useSelector((store) => store.myEngagements);
	const favoriteEngagements = useSelector((store) => store.favoriteEngagements);
	const favoriteWorkspaces = useSelector((store) => store.favoriteWorkspaces);
	const currentTab = useSelector((store) => store.dashboard.activeTab);
	const apponlinestate = useSelector((store) => store.apponlinestate);
	const userPermissions = useSelector((store) => store.userPermissions);
	const preferredlandingpageview = useSelector(
		(store) => store.userSettings?.data?.preferredlandingpageview
	);
	const preferrednumberofengagements = useSelector(
		(store) => store.userSettings?.data?.preferrednumberofengagements
	);
	const userSettings = useSelector((store) => store.userSettings);
	const reviewEngagements = useSelector((store) => store.reviewEngagements);
	const reviewWorkspaces = useSelector((store) => store.reviewWorkspaces);
	const searchText = useSelector((store) => store.searchEngagementState);
	const engagementError = useSelector((store) => store.engagementError);
	const successfulToastMessage = useSelector((store) => store.successfulToastMessage);

	const params = QueryString.parse(location.search);
	const hasgroupby = params.groupby;
	let defaultGroupingUserSetting =
		preferredlandingpageview === settingsPreferences.WorkspaceList
			? displayGroupings.MODULE
			: displayGroupings.LIST;

	const svgHoverText = {
		first: labels.firstpage,
		previous: labels.prevpage,
		next: labels.nextpage,
		last: labels.lastpage
	};
	let archiveretryshortcutkey = env.getArchiveRetryShortcutKey();
	let defaultTab = tabTypes.AllEngagementsTab;
	let perm = userPermissions.userpermissions;

	const topEngagementv2 = engagementV2PageSizeOptions.includes(
		parseInt(new URLSearchParams(search).get('pagesize'))
	)
		? parseInt(new URLSearchParams(search).get('pagesize'))
		: engagementV2DefaultTop;
	const skipEngagementv2 =
		!new URLSearchParams(search).has('pagenumber') ||
			parseInt(new URLSearchParams(search).get('pagenumber')) < 1
			? engagementV2DefaultSkip
			: parseInt(new URLSearchParams(search).get('pagenumber'));

	const pathAndTabs = [
		{
			path: '/engagements',
			tab: tabTypes.AllEngagementsTab
		},
		{
			path: '/favorites',
			tab: tabTypes.FavoritesTab
		},
		{
			path: '/reviewengagements',
			tab: tabTypes.ReviewengagementsTab
		},
		// {
		// 	path: '/engagementsv2',
		// 	tab: tabTypes.portfolioViewTab
		// },
		// {
		// 	path: '/engagementsv2/allengagements',
		// 	tab: tabTypes.engagementsv2Filter
		// },
		// {
		// 	path: '/engagementsv2/favorites',
		// 	tab: tabTypes.favoritesv2Filter
		// },
		// {
		// 	path: '/engagementsv2/reviewengagements',
		// 	tab: tabTypes.reviewengagementsv2Filter
		// },
		{
			path: '/',
			tab: tabTypes.engagementsv1Tab
		}
	];
	const uniqkey = 0;

	const [totalPages, setTotalPages] = useState(0);
	const [showDropdownState, setShowDropdownState] = useState(false);
	const [isFirsRenderFinish, setIsFirsRenderFinish] = useState(false);
	const [skip, setSkip] = useState(
		(myEngagements &&
			myEngagements.pagermetadata &&
			myEngagements.pagermetadata.currentpagenumber) ||
		1
	);
	const [top, setTop] = useState(
		(myEngagements &&
			myEngagements.pagermetadata &&
			preferrednumberofengagements) ||
		preferrednumberofengagements ||
		10
	);
	const [clients, setClients] = useState([]);
	const [groupBy, setGroupBy] = useState(
		_.isEmpty(location.search) ? defaultGroupingUserSetting : 'module'
	);
	const [isLoading, setIsLoading] = useState(true);
	const [currentTabState, setCurrentTabState] = useState(defaultTab);

	const isTabEngV2 = [
		tabTypes.portfolioViewTab,
		tabTypes.engagementsv2Filter,
		tabTypes.favoritesv2Filter,
		tabTypes.reviewengagementsv2Filter
	].includes(currentTabState);

	const [userInfo, setUserInfo] = useState({
		isThirdPartyUser: false,
		isThirdPartyUserWithNoRecord: false
	});
	const [engagementlist, setEngagementlist] = useState([]);
	const [totalRecordCount, setTotalRecordCount] = useState(0);
	const [isReviewEngagementAvailable, setIsReviewEngagementAvailable] =
		useState(false);
	const [successMessage, setSuccessMessage] = useState('');
	const successMessageDuration = successMessageDisplayDuration;

	const isEyUser = () => {
		if (
			userSettings?.data?.iseyuser !== null &&
			userSettings?.data?.iseyuser !== undefined
		) {
			return userSettings.data.iseyuser;
		} else {
			if (
				userSettings.isError &&
				JSON.parse(userSettings?.request?.response).ErrorMessage !== '938'
			) {
				return undefined;
			} else {
				return false;
			}
		}
	};

	const isGlobalPortalUser = () => {
		if (userPermissions?.userpermissions) {
			// Global\ReviewPortal - id=14
			return userPermissions.userpermissions.find(
				(x) => x.groupname === permissionGroups.Global_ReviewPortal
			) != null
				? true
				: false;
		}
	};

	const loadPagedDataAsync = async (isSearchTextEmpty, resetPage) => {
		await loadPagedData(isSearchTextEmpty, resetPage);
	};

	const checkIsReviewEngagementAvailable = () => {
		setIsReviewEngagementAvailable(
			(apponlinestate && isGlobalPortalUser() && isEyUser()) ||
			(apponlinestate &&
				userInfo.isThirdPartyUser !== undefined &&
				userInfo.isThirdPartyUserWithNoRecord !== undefined &&
				(userInfo.isThirdPartyUser || userInfo.isThirdPartyUserWithNoRecord))
		);
	};

	useEffect(() => {
		checkIsUserGlobalReviewPortalMember();
		dispatch(
			setCurrentGroupingTypeInMyEngSection(
				_.isEmpty(location.search) ? defaultGroupingUserSetting : 'module'
			)
		);
		onTabChanged(getTab(location.pathname));
		setIsFirsRenderFinish(true);
	}, []);

	const checkRejectInvitationSuccessMessage = () => {
		if(successfulToastMessage?.message === labels.independenceOnlyRejectInvite.succesfulRejection){
			setSuccessMessage(successfulToastMessage?.message);
		}
	};


	useEffect(() => {
		if (successMessage) {
			var timer = setTimeout(() => {
				setSuccessMessage('');				
			}, successMessageDuration);

			return () => clearTimeout(timer);
		}
	}, [successMessage]);

	useEffect(() => {
		if (
			userSettings?.data?.iseyuser !== null &&
			userSettings?.data?.iseyuser !== undefined
		) {
			setUserInfo({
				isThirdPartyUser: !userSettings.data.iseyuser,
				isThirdPartyUserWithNoRecord: false
			});
		} else {
			if (
				userSettings.isError &&
				JSON.parse(userSettings?.request?.response).ErrorMessage !== '938'
			) {
				setUserInfo({
					isThirdPartyUser: undefined,
					isThirdPartyUserWithNoRecord: undefined
				});
			} else {
				setUserInfo({
					isThirdPartyUser: true,
					isThirdPartyUserWithNoRecord: true
				});
			}
		}
	}, [userSettings]);

	useEffect(() => {
		const fetchData = async () => {
			if (!location.pathname.includes('/engagementsv2') && isFirsRenderFinish) {
				setGroupPreferences();
				await loadPagedDataAsync();
			}
		};
		fetchData();
	}, [skip, top, preferredlandingpageview, isFirsRenderFinish, currentTab]);

	useEffect(() => {
		checkIsReviewEngagementAvailable();
	}, [userPermissions]);

	const setGroupPreferences = async () => {
		if (
			((location.pathname === '/' ||
				location.pathname === '/reviewengagements') &&
				defaultGroupingUserSetting === displayGroupings.MODULE) ||
			hasgroupby
		) {
			defaultGroupingUserSetting = displayGroupings.MODULE;
			await dispatch(
				updateGroupPrefUserSettings(settingsPreferences.WorkspaceList)
			);
		} else {
			defaultGroupingUserSetting = displayGroupings.LIST;
			await dispatch(
				updateGroupPrefUserSettings(settingsPreferences.EngagementList)
			);
		}
	};

	useEffect(() => {
		let engagementsToDisplay = {};
		let activeWorkSpaces = {};
		let preferredPageView = preferredlandingpageview ?? 'engagements';
		if (
			(preferredPageView === 'engagements' ? 'list' : 'module') ===
			displayGroupings.LIST
		) {
			if (currentTab === tabTypes.FavoritesTab) {
				setSkip(favoriteEngagements?.pagermetadata?.currentpagenumber || 1);
				setEngagementlist(favoriteEngagements);
				engagementsToDisplay = favoriteEngagements;
			} else if (currentTab === tabTypes.ReviewengagementsTab) {
				setSkip(reviewEngagements?.pagermetadata?.currentpagenumber || 1);
				setEngagementlist(reviewEngagements);
				engagementsToDisplay = reviewEngagements;
			} else {
				setSkip(myEngagements?.pagermetadata?.currentpagenumber || 1);
				setEngagementlist(myEngagements);
				engagementsToDisplay = myEngagements;
			}
			setTotalPages(
				(engagementsToDisplay &&
					engagementsToDisplay.pagermetadata &&
					engagementsToDisplay.pagermetadata.totalpages) ||
				0
			);
			setTotalRecordCount(
				(engagementsToDisplay &&
					engagementsToDisplay.pagermetadata &&
					engagementsToDisplay.pagermetadata.totalnumberofrecords) ||
				0
			);
		} else {
			if (currentTab === tabTypes.FavoritesTab) {
				activeWorkSpaces = favoriteWorkspaces;
			} else if (currentTab === tabTypes.ReviewengagementsTab) {
				activeWorkSpaces = reviewWorkspaces;
			} else {
				activeWorkSpaces = myWorkspaces;
			}
			setClients(
				activeWorkSpaces &&
				activeWorkSpaces.collections &&
				activeWorkSpaces.collections.clients
			);
			setTotalPages(
				(activeWorkSpaces &&
					activeWorkSpaces.pagermetadata &&
					activeWorkSpaces.pagermetadata.totalpages) ||
				0
			);
			setTotalRecordCount(
				(activeWorkSpaces &&
					activeWorkSpaces.pagermetadata &&
					activeWorkSpaces.pagermetadata.totalnumberofrecords) ||
				0
			);
		}
		setPaginationState(
			preferredPageView === 'engagements' ? 'list' : 'module',
			currentTab
		);
		setIsLoading(false);
	}, [
		myEngagements,
		favoriteEngagements,
		reviewEngagements,
		myWorkspaces,
		favoriteWorkspaces,
		reviewWorkspaces
	]);

	useEffect(() => {
		if (params.reportreleasedate) {
			history.push({
				pathname: '/editengagement',
				search:
					'?workspaceid=' +
					params.workspaceid +
					'&engagementid=' +
					params.engagementid
			});
		}
	}, [params]);

	const getTab = (pathName) => {
		return pathAndTabs.find((x) => x.path === pathName).tab;
	};

	const checkIsUserGlobalReviewPortalMember = () => {
		if (perm) {
			return;
		}
		let userPermissionsUrl =
			env.getURL('serviceUrl') + '/' + urls.USERS_PERMISSION_URL;
		dispatch(getUserPermissions(userPermissionsUrl));
		setIsLoading(false);
	};

	const onTabChanged = (tabKey) => {
		const param = groupBy == displayGroupings.MODULE ? '?groupby=clients' : '';
		let redirectTab = '';
		switch (tabKey) {
			case 'myengtabs':
				history.push('/engagements' + param);
				break;
			case 'myFavEng':
				history.push('/favorites' + param);
				break;
			case tabTypes.ReviewengagementsTab:
				history.push('/reviewengagements' + param);
				break;
			// case tabTypes.portfolioViewTab:
			// 	if (
			// 		userSettings &&
			// 		userSettings.data.preferredlandingpagefilter ===
			// 		settingsPreferences.AllEngagements
			// 	) {
			// 		history.push(
			// 			`/engagementsv2/allengagements?pagenumber=${skipEngagementv2}&pagesize=${topEngagementv2}`
			// 		);
			// 		redirectTab = tabTypes.engagementsv2Filter;
			// 	} else {
			// 		history.push(
			// 			`/engagementsv2/favorites?pagenumber=${skipEngagementv2}&pagesize=${topEngagementv2}`
			// 		);
			// 		redirectTab = tabTypes.favoritesv2Filter;
			// 	}
			// 	break;
			// case tabTypes.favoritesv2Filter:
			// 	history.push(
			// 		`/engagementsv2/favorites?pagenumber=${skipEngagementv2}&pagesize=${topEngagementv2}`
			// 	);
			// 	break;
			// case tabTypes.engagementsv2Filter:
			// 	history.push(
			// 		`/engagementsv2/allengagements?pagenumber=${skipEngagementv2}&pagesize=${topEngagementv2}`
			// 	);
			// 	break;
			// case tabTypes.reviewengagementsv2Filter:
			// 	history.push(
			// 		`/engagementsv2/reviewengagements?pagenumber=${skipEngagementv2}&pagesize=${topEngagementv2}`
			// 	);
			// 	break;
			default:
				if (
					userSettings &&
					userSettings.data &&
					userSettings.data.preferredlandingpagefilter ===
					settingsPreferences.FavoriteEngagements
				) {
					history.push('/favorites' + param);
					redirectTab = tabTypes.FavoritesTab;
				} else {
					history.push('/engagements' + param);
					redirectTab = tabTypes.AllEngagementsTab;
				}
		}
		setCurrentTabState(redirectTab.length > 0 ? redirectTab : tabKey);
		setSkip(1);
		dispatch(
			setCurrentActiveTabInMyEngSection(
				redirectTab.length > 0 ? redirectTab : tabKey
			)
		);
	};

	const loadPagedData = async (isSearchTextEmpty, resetPage) => {
		let searchKey = isSearchTextEmpty ? '' : searchText.searchText;
		const page = resetPage ? 1 : skip;
		var preferredPageView = preferredlandingpageview ?? 'engagements';
		let displaytypeid =
			(preferredPageView === 'engagements' ? 'list' : 'module') ===
				displayGroupings.LIST
				? 1
				: 2;

		searchKey = encodeURIComponent(searchKey);
		let mainUrl =
			currentTab === tabTypes.ReviewengagementsTab
				? env.getURL('serviceUrl') +
				  '/home.json?phase=MyCSRWorkspaces&Top=' +
				  top +
				  '&Skip=' +
				  page
				: env.getURL('serviceUrl') +
				  '/home.json?phase=MyNavigation&Top=' +
				  top +
				  '&Skip=' +
				  page;
		let searchURLPart =
			'&filters=searchtypeid eq ' +
			(currentTab === tabTypes.FavoritesTab
				? searchTypes.MY_FAVORITES
				: currentTab === tabTypes.ReviewengagementsTab
					? searchTypes.REVIEW_ENGAGEMENTS
					: (preferredPageView === 'engagements' ? 'list' : 'module') ===
						displayGroupings.LIST
						? searchTypes.EXCLUDE_WORKSPACE
						: searchTypes.ALL) +
			',displaytypeid eq ' +
			displaytypeid +
			'&SearchExpression=' +
			searchKey;

		setIsLoading(true);
		if (
			(preferredPageView === 'engagements' ? 'list' : 'module') ===
			displayGroupings.LIST
		) {
			if (currentTab === tabTypes.FavoritesTab) {
				try {
					await dispatch(
						getFavoriteEngagements(
							mainUrl + searchURLPart,
							mainUrl.split('/api/v1')[0]
						)
					);
				} finally {
				}
			} else if (currentTab === tabTypes.ReviewengagementsTab) {
				await dispatch(
					getReviewEngagements(
						mainUrl + searchURLPart,
						mainUrl.split('/api/v1')[0]
					)
				);
			} else {
				await dispatch(
					getMyEngagements(mainUrl + searchURLPart, mainUrl.split('/api/v1')[0])
				);
			}
		} else {
			if (currentTab === tabTypes.FavoritesTab) {
				await dispatch(
					getFavoriteWorkspaces(
						mainUrl + searchURLPart,
						mainUrl.split('/api/v1')[0]
					)
				);
			} else if (currentTab === tabTypes.ReviewengagementsTab) {
				await dispatch(
					getReviewWorkspaces(
						mainUrl + searchURLPart,
						mainUrl.split('/api/v1')[0]
					)
				);
			} else if (currentTab === tabTypes.AllEngagementsTab) {
				await dispatch(
					getMyWorkspaces(mainUrl + searchURLPart, mainUrl.split('/api/v1')[0])
				);
			}
		}
		checkRejectInvitationSuccessMessage();
	};

	const setPaginationState = (selectedGroupBy, selectedCurrentTab) => {
		let currentEngagements;
		let currentWorkspaces;

		switch (selectedCurrentTab) {
			case tabTypes.FavoritesTab:
				currentEngagements = favoriteEngagements;
				currentWorkspaces = favoriteWorkspaces;
				break;
			case tabTypes.ReviewengagementsTab:
				currentEngagements = reviewEngagements;
				currentWorkspaces = reviewWorkspaces;
				break;
			default:
				currentEngagements = myEngagements;
				currentWorkspaces = myWorkspaces;
		}

		setTop(
			selectedGroupBy === displayGroupings.LIST
				? (currentEngagements &&
					currentEngagements.pagermetadata &&
					preferrednumberofengagements) ||
				preferrednumberofengagements ||
				10
				: (currentWorkspaces &&
					currentWorkspaces.pagermetadata &&
					preferrednumberofengagements) ||
				preferrednumberofengagements ||
				5
		);
		setGroupBy(selectedGroupBy);
	};

	const getMenuItems = () => {
		return [getCreateEngagementDetails(), getImportengagementDetails()];
	};

	const getCreateEngagementDetails = () => {
		return {
			linkTo: {
				pathname: '/createengagement'
			},
			label: labels.createengagementheader
		};
	};

	const getImportengagementDetails = () => {
		return {
			linkTo: {
				pathname: '/import'
			},
			label: labels.importengagementheader
		};
	};

	const activeClassHandler = (index) => {
		// if (
		// 	index === tabTypes.portfolioViewTab &&
		// 	location.pathname.includes('/engagementsv2')
		// ) {
		// 	return 'motif-active';
		// }
		return getTab(location.pathname) === index ? 'motif-active' : '';
	};

	const handleEngagementOptionsChange = (value) => {
		setShowDropdown(false);
		history.push(value.linkTo.pathname);
	};

	const onPagerDropdownChange = (e) => {
		setTop(parseInt(e.value));
		setSkip(1);
		dispatch(updatePagePrefUserSettings(parseInt(e.value)));
	};

	const onPagerInputChange = (e) => {
		e = parseInt(e.target.value);
		if (!(e > 0)) {
			setSkip(1);
		} else {
			if (e > totalPages) {
				e = totalPages;
			}
			setSkip(e);
		}
	};

	const onFirstPageClicked = () => {
		setSkip(1);
	};

	const onPrevPageClicked = () => {
		setSkip((prev) => prev - 1);
	};

	const onNextPageClicked = () => {
		setSkip((prev) => prev + 1);
	};

	const onLastPageClicked = () => {
		setSkip(totalPages);
	};

	const onKeyDownInSearchBox = (val) => {
		if (val.keyCode === 13) {
			setSkip(1);
			loadPagedDataAsync(false, true);
		}
	};

	const onClearSearchText = () => {
		setSkip(1);
		dispatch(searchEngagementState(standardStrings.EMPTY));
		loadPagedDataAsync(true, true);
	};

	const isPortfolioActive = () => {
		if (!env.getPortfolioCfg()) {
			history.push('/engagements');
			return false;
		} else {
			return true;
		}
	};

	const setClickOutside = () => {
		setShowDropdown(false);
	};

	const handleToggle = () => {
		setShowDropdown(true);
	};

	const setShowDropdown = (value) => {
		setShowDropdownState(value);
	};

	const [isMobile, setIsMobile] = useState(false);
	useEffect(() => {
		let parser = new UAParser();
		let result = parser.getResult();
		setIsMobile(["mobile", "tablet"].includes(result.device.type));
	}, []);

	return (
		<DashboardStyles className="customScrollbar view-wrapper">
			{successMessage && (
				<Toast
					variant="success"
					duration={successMessageDuration}
					onClose={() => setSuccessMessage(undefined)}
				>
					{successMessage}
				</Toast>
			)}
			{engagementError?.message && (
				<StyledNotificationToast>
					<Toast variant="error" onClose={() => dispatch(clearToastError())}>
						{engagementError.message}
					</Toast>
				</StyledNotificationToast>
			)}
			<section className="menu-nav">
				<StyledTabBar>
					<TabNavigation variant="alt" className="view-switcher">
						{apponlinestate &&
							userInfo.isThirdPartyUser !== undefined &&
							userInfo.isThirdPartyUserWithNoRecord !== undefined &&
							!userInfo.isThirdPartyUser &&
							!userInfo.isThirdPartyUserWithNoRecord &&
							env.getPortfolioCfg() ? (
							<TabControl
								className={activeClassHandler(tabTypes.portfolioViewTab)}
								onClick={() => onTabChanged(tabTypes.portfolioViewTab)}
							>
								{labels.portfolioview}
							</TabControl>
						) : (
							[]
						)}
						{userInfo.isThirdPartyUser !== undefined &&
							userInfo.isThirdPartyUserWithNoRecord !== undefined &&
							!userInfo.isThirdPartyUser &&
							!userInfo.isThirdPartyUserWithNoRecord ? (
							<TabControl
								className={activeClassHandler(tabTypes.AllEngagementsTab)}
								onClick={() => onTabChanged(tabTypes.AllEngagementsTab)}
							>
								{labels.allengagements}
							</TabControl>
						) : (
							[]
						)}
						{apponlinestate &&
							userInfo.isThirdPartyUser !== undefined &&
							userInfo.isThirdPartyUserWithNoRecord !== undefined &&
							!userInfo.isThirdPartyUser &&
							!userInfo.isThirdPartyUserWithNoRecord ? (
							<TabControl
								className={activeClassHandler(tabTypes.FavoritesTab)}
								onClick={() => onTabChanged(tabTypes.FavoritesTab)}
							>
								{labels.favorites}
							</TabControl>
						) : (
							[]
						)}
						{isReviewEngagementAvailable ? (
							<TabControl
								className={activeClassHandler(tabTypes.ReviewengagementsTab)}
								onClick={() => onTabChanged(tabTypes.ReviewengagementsTab)}
							>
								{labels.reviewengagement}
							</TabControl>
						) : (
							[]
						)}
					</TabNavigation>
				</StyledTabBar>
				{apponlinestate ? (
					<section className="filterrow">
						{userInfo.isThirdPartyUser !== undefined &&
							userInfo.isThirdPartyUserWithNoRecord !== undefined &&
							!userInfo.isThirdPartyUserWithNoRecord && (
								// !location.pathname.includes('/engagementsv2') &&
								<EngagementGrouping
									location={location}
									history={history}
									resetPager={() => setSkip(1)}
								/>
							)}

						{userInfo.isThirdPartyUser !== undefined &&
							userInfo.isThirdPartyUserWithNoRecord !== undefined &&
							!userInfo.isThirdPartyUser &&
							!userInfo.isThirdPartyUserWithNoRecord ? (
							// &&
							// !location.pathname.includes('/engagementsv2')
							<Dropdown
								open={showDropdownState}
								placement="bottom-right"
								handleClickOutside={setClickOutside}
								trigger={
									<Button
										type="button"
										variant="secondary"
										onClick={handleToggle}
									>
										<Icon src={contentIcAddCircle24px} />
										{labels.addEngagement}
									</Button>
								}
							>
								{getMenuItems().map((option, index) => {
									return (
										<DropdownItem
											onClick={() => handleEngagementOptionsChange(option)}
											title={option.label}
											key={index}
										>
											{option.label}
										</DropdownItem>
									);
								})}
							</Dropdown>
						) : (
							[]
						)}
					</section>
				) : (
					[]
				)}
			</section>
			{isTabEngV2 && isPortfolioActive() ? (
				isLoading ? (
					<section className="portfolio-loader">
						<LoadingIndicator show fullscreen />;
					</section>
				) : (
					<PortfolioView
						fullname={
							userSettings.data.userfname + ' ' + userSettings.data.userlname
						}
						isReviewEngagementAvailable={isReviewEngagementAvailable}
						activeFilter={currentTabState}
						defaultTop={topEngagementv2}
						defaultSkip={skipEngagementv2}
					/>
				)
			) : apponlinestate ? (
				userInfo.isThirdPartyUser !== undefined &&
				userInfo.isThirdPartyUserWithNoRecord !== undefined && (
					<>
						<section className="page-filter">
							{!userInfo.isThirdPartyUserWithNoRecord && (
								<section className="search-filter">
									<Search
										name="name"
										clearHoverText={labels.clearSearch}
										value={searchText.searchText}
										placeholder={labels.searchTextPlaceHolder}
										onChange={(val) => {
											dispatch(searchEngagementState(val));
										}}
										onKeyDown={(val) => {
											onKeyDownInSearchBox(val);
										}}
										onClearClick={() => {
											onClearSearchText();
										}}
										onSearchClick={(val) => {
											setSkip(1);
											loadPagedDataAsync(false, true);
										}}
										isDisabled={false}
										key={uniqkey}
									/>
								</section>
							)}
							<section className="pagination">
								<Pagination
									styleName="pagination"
									dropdown_position="left"
									options_per_page={pagerOptions}
									default_dropdown_value={top}
									currentPage={skip}
									TotalPages={totalPages}
									showText={labels.show}
									iconHover={svgHoverText}
									onInputChange={onPagerInputChange}
									onDropdownChange={onPagerDropdownChange}
									onFirstPageClick={onFirstPageClicked}
									onPrevPageClick={onPrevPageClicked}
									onNextPageClick={onNextPageClicked}
									onLastPageClick={onLastPageClicked}
								/>
							</section>
						</section>

						<ul>
							{!isLoading ? (
								groupBy === displayGroupings.LIST ? (
									engagementlist && engagementlist.allengagements ? (
										engagementlist.allengagements.map((engagement) => (
											<EngagementList
												workspaceID={engagement.workspaceID}
												workspace={engagement.workspace}
												engagements={engagement.engagements}
												viewDisplayName={viewDisplayName}
												client={engagement.client}
												clientId={engagement.client.id}
												clientName={engagement.clientName}
												clientCode={engagement.clientCode}
												refreshDashboard={loadPagedDataAsync}
												archiveretryshortcutkey={archiveretryshortcutkey}
											/>
										))
									) : null
								) : clients ? (
									<ClientList
										clients={clients}
										refreshDashboard={loadPagedDataAsync}
										archiveretryshortcutkey={archiveretryshortcutkey}
										isReviewWorkspaces={
											currentTabState === tabTypes.ReviewengagementsTab
										}
									/>
								) : null
							) : (
								<LoadingIndicator show />
							)}
						</ul>

						{!totalRecordCount && searchText?.searchText?.length > 0 && !isLoading && (
							<section className="empty-message">
								<section className="norecords">
									<p>{labels.nomatches}</p>
									<p className="try-message">{labels.pleasetryagain}</p>
								</section>
							</section>
						)}
						{!totalRecordCount && searchText?.searchText?.length === 0 && !isLoading &&  (
							<section className="empty-message">
								<section className="norecords">
									{currentTabState === tabTypes.FavoritesTab ? (
										<p>
											<p>{labels.nofavoriteengagementsmsg1}</p>
											<p>{labels.nofavoriteengagementsmsg2}</p>
											<p>{labels.nofavoriteengagementsmsg3}</p>
										</p>
									) : currentTabState === tabTypes.ReviewengagementsTab ? (
										isEyUser() !== undefined ? (
											<p>{labels.noreviewengagementsmessage}</p>
										) : (
											<p>{labels.noreviewengagementsmessagecontact}</p>
										)
									) : (
										((myEngagements &&
											myEngagements.allengagements &&
											myEngagements.allengagements.length == 0) ||
											!myEngagements.allengagements) && (
											<p>{labels.noengagementsforusermessage}</p>
										)
									)}
								</section>
							</section>
						)}
					</>
				)
			) : engagementlist && engagementlist.allengagements ? (
				engagementlist.allengagements.map((engagement) => (
					<EngagementList
						workspaceID={engagement.workspaceID}
						workspace={engagement.workspace}
						engagements={engagement.engagements}
						viewDisplayName={viewDisplayName}
						client={engagement.client}
						clientId={engagement.client.id}
						clientName={engagement.clientName}
						clientCode={engagement.clientCode}
						refreshDashboard={loadPagedDataAsync}
						archiveretryshortcutkey={archiveretryshortcutkey}
					/>
				))
			) : (
				[]
			)}
			{isMobile && <StyledAddNoteButton>
				<section className="device-toggle-icon">
					<Icon src={deviceIcDevices24px} />
				</section>
				<section className="device-toggle-main">
					<a className="device-toggle-label" href="/mobile/engagements">
						{labels.toggleView}
					</a>
				</section>
			</StyledAddNoteButton>}
		</DashboardStyles>
	);
};
export default Dashboard;

const StyledAddNoteButton = styled.section`
	position: fixed;
	right: var(--px-24);
	bottom: var(--px-32);
	border-radius: var(--px-25);
	padding-left: var(--px-27);
	height: 30px;
	width: fit-content;
	// used rgb instead bec theme color not available
	background-color: rgb(19, 110, 246); 
	z-index: 9999;
	display: flex;
	.device-toggle-icon {
		width: 15px;
		align-content: center;
		line-height: var(--px-2);
	}
	.device-toggle-main {
		padding-right: var(--px-27);
		width: fit-content;
		line-height: var(--px-30);
		.device-toggle-label {
			padding: 5px;
		}
	}
`;

const StyledTabBar = styled.section`
	display: inline-flex;
	width: 65%;
	.view-switcher {
		padding-left: var(--px-40);
		border: none;
		.motif-tab-button {
			height: var(--px-45);
			margin-right: var(--px-15);
		}
	}
`;

const StyledNotificationToast = styled.section`
	width: calc(100vw - var(--px-85)) !important;
	top: var(--px-15) !important;
	left: var(--px-40) !important;
	margin: 0 auto !important;
	padding: 0 !important;
	z-index: 1000 !important;
	max-width: 2048px;
	position: fixed;
	@media only screen and (min-width: 2048px) {
		width: calc(100vw - var(--px-170)) !important;
		left: var(--px-82) !important;
	}
	.motif-toast {
		&.motif-toast-error {
			.motif-toast-icon-container svg {
				fill: var(--red-600)!important;
				width: var(--px-18);
				height: var(--px-18);
				margin-top: -var(--px-3);
			}
		}
	}
`;
