import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorMessage from '@ey/motif-react-wrapper/ErrorMessage';
import FormField from '@ey/motif-react-wrapper/FormField';
import Search from '@ey/motif-react-wrapper/Search';
import Button from '@ey/motif-react-wrapper/Button';
import { pagingOptions, reportFormateType } from '../../../util/uiconstants';
import { labels } from '../../../util/uiconstants';
import AccessDeniedMessage from '../../Common/AccessDeniedMessage/AccessDeniedMessage';
import DocumentsTable from './documentstable/documentstable';
import { getAdminEngagementDocuments, resetAdminEngagementDocuments } from '../../../actions/admin/documentsactions';
import Pagination from '@ey/pager';
import { getMeCall } from '../../../actions/admin/mepermissionsactions';
import styled from 'styled-components';
import env from '../../../util/env';
import Loader from '../../Common/Loaders/loadingindicator';
import { downloadReport } from '../../../actions/admin/reportaction';
import moment from 'moment';
import { SVGICONS } from '@ey/icons';
import Toast from '../../Common/Toast/Toast';
import { getLocalizedAPIError } from '../common/util';
import { clearErrors } from '../../../actions/erroractions';
import { setIsModalOpen } from '../../../actions/modalstateactions';

const Wrapper = styled.section`
margin-top: var(--px-50);

.toastMessage {
	width: 100% !important;
	position: relative;
	.motif-toast {
		position: absolute;
		z-index: 9999;
	}
}

.loadingstyle{
	height: calc(100vh - var(--px-220)) !important;
}

.documentaddon{
	width: 100%;
	margin: var(--px-30) 0 var(--px-10) 0;
	display: flex;
	
	.search{
		display: inline-block;
		margin-right: var(--px-10);
		.canvas-core-search {
			width: var(--px-250);
		}
	}	
}

.totaldocument{
	font-size: var(--px-14);
	margin-right: auto;
	display: inline-block;
	label{
		margin-right: var(--px-5);
	}
}

.export{
	display: inline-block;
	a{
		font-size: var(--px-14);
		font-weight: bold;
		vertical-align: text-bottom;
		color: var(--blue-600);
		&:hover{
			text-decoration: underline;
			cursor: pointer;
		}
	}
	svg{
		width: auto;
		height: var(--px-20);	
		margin-right: var(--px-2);
		fill: var(--neutrals-1000);
	}
}

.fieldholder{
	width: 100%;
	margin-top: var(--px-10);
    display: flex;
	.engid{		
		width: 100%;	
		margin-top: var(--px-20);
		display: flex;

		.wrapper{
			width: var(--px-350);
			display: inline-block;
			margin-right: var(--px-20);

			.optional{
				font-size: var(--px-12);
				font-weight: normal;
			}
		}

		.motif-form-field{
			margin-bottom: 0;
		}

		.motif-search-input-wrapper {
			.motif-input{
				height: var(--px-45);
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			.motif-search-input-icon{
				display: none;
			}

			.motif-typeahead-close-button{
				margin-top: var(--px-6);
			}
		}

		.motif-error-message {
			margin-top: var(--px-5);
			svg{
				width: var(--px-18);
				height: var(--px-18);
			}
		}

		h5{
			width: 100%;
			font-size: var(--px-14);
			font-weight: bold;
		}
	}
	.getdocuments {
		padding-top: var(--px-36);

		.motif-button{
			display: inline-block;
			height: var(--px-45);
			margin-right: var(--px-20);
		}
	}
}

.pagerholder {
	margin: 0 auto;
	width: calc(100vw - var(--px-5));
	position: absolute;
	bottom: var(--px-0);
	background: var(--neutrals-00white);

	.document-paging {
		font-size: var(--px-14);
		justify-content: flex-end;
		background: var(--neutrals-00white);
		border-top: 1px solid var(--neutrals-200);

		.Dropdown-arrow {
			margin-top: var(--px-3);
		}
	}
}
`;

export default function DocumentsBase() {
	const userPermissions = useSelector((store) => store.userPermissions);
	const documents = useSelector((store) => store.adminEngagementDocumentsList);
	const [hasClickedSearch, setHasClickedSearch] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isMeCallSent, setIsMeCallSent] = useState(false);
	const [showRequiredValidationMessage, setShowRequiredEngIdValidationMessage] = useState(false);
	const [showInvalidEngIdValidationMessage, setShowInvalidEngIdValidationMessage] = useState(false);
	const [totalPages, setTotalPages] = useState();
	const [pageAndPageSize, setPageAndPageSize] = useState({
		page: 1,
		pageSize: pagingOptions.options[0]
	});
	const [engIdSearchText, setEngIdSearchText] = useState('');
	const [docIdSearchText, setDocIdSearchText] = useState('');
	const [engIdToSearch, setEngIdToSearch] = useState('');
	const [docIdToSearch, setDocIdToSearch] = useState('');
	const [errorMessage, setErrorMessage] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		if (engIdSearchText.trim().length <= 0) {
			setDocIdSearchText('');
		}
	}, [engIdSearchText])

	useEffect(() => {
		dispatch(resetAdminEngagementDocuments()).then(() => dispatch(getMeCall()).then(() => setIsMeCallSent(true)));
	}, []);

	useEffect(() => {
		if (hasClickedSearch) {
			setShowRequiredEngIdValidationMessage(true);
		}
	}, [hasClickedSearch]);

	useEffect(() => {
		if (hasClickedSearch) {
			getDocumentsList();
		}
	}, [pageAndPageSize, engIdToSearch, docIdToSearch, isMeCallSent]);

	useEffect(() => {
		if (documents) {
			setTotalPages(documents.pages)
		}
	}, [documents]);

	const downloadDocuments = () => {
		let engId = engIdSearchText?.trim();
		if (!isValidEngId(engId)) {
			return;
		}

		setIsLoading(true);
		let documentReportUrl = env.getURL('serviceUrlV2') +
			`admin/engagements/${engId}/documents?all=true`;

		var dateTime = moment(new Date()).format("MM-DD-YYYY-HH-mm-ss");
		dispatch(downloadReport(documentReportUrl, `DocumentDetails_${engId}_${dateTime}.${reportFormateType.xlsx}`)).finally(() => { setIsLoading(false) });
	}

	const clearError = (isModelOpen) => {
		dispatch(clearErrors()).then(() => {
			dispatch(setIsModalOpen(isModelOpen));
		});
	};

	useEffect(() => {
		clearError(true);
	}, [])
	const getDocumentsList = () => {
		let docIdQuerySearch = '';
		let docId = docIdSearchText?.trim();
		if (docId?.length > 0) {
			docIdQuerySearch = `&documentId=${docId}`
		}

		let engId = engIdSearchText?.trim();
		if (!isValidEngId(engId)) {
			return;
		}

		setIsLoading(true);
		let engDocumentsListUrl = env.getURL('serviceUrlV2') +
			`admin/engagements/${engId}/documents?page=${pageAndPageSize.page}&pageSize=${pageAndPageSize.pageSize}${docIdQuerySearch}`;

		dispatch(getAdminEngagementDocuments(engDocumentsListUrl))
			.catch((e) => {
				setErrorMessage(getLocalizedAPIError(e));
				setHasClickedSearch(false);
				clearError(true);
			})
			.finally(() => {
				clearError(true);
				setIsLoading(false);
			});
	};

	const isValidEngId = (engId) => {
		if (!engId || engId.length <= 0) {
			setHasClickedSearch(false);
			setShowRequiredEngIdValidationMessage(true);
			return false;
		}

		if (!Number(engId)) {
			setHasClickedSearch(false);
			setShowInvalidEngIdValidationMessage(true);
			return false;
		}

		return true;
	}

	useEffect(() => {
	}, [engIdSearchText]);

	function onGetResultButton() {
		setPageAndPageSize({
			page: 1,
			pageSize: pagingOptions.options[0]
		});
		setHasClickedSearch(true);
		setEngIdToSearch(engIdSearchText);
	}

	const resetAllData = () => {
		setHasClickedSearch(false);
		setShowInvalidEngIdValidationMessage(false);
		setShowRequiredEngIdValidationMessage(false);
		setEngIdSearchText('');
		setDocIdSearchText('');
		setEngIdToSearch('');
		setDocIdToSearch('');
		setPageAndPageSize({
			page: 1,
			pageSize: pagingOptions.options[0]
		});
		dispatch(resetAdminEngagementDocuments());
	}

	function hasAdminDocumentsPermission() {
		let documentsPermission = userPermissions?.permissions;
		return documentsPermission && documentsPermission?.canAccessDocuments;
	}

	const onDocIdSearchClick = () => {
		setPageAndPageSize({
			page: 1,
			pageSize: pagingOptions.options[0]
		});
		setHasClickedSearch(true);
		setDocIdToSearch(docIdSearchText);
	}

	const handlerOptionPage = (value) => {
		if (value !== pageAndPageSize.pageSize) {
			setPageAndPageSize({
				page: 1,
				pageSize: value
			});
		}
	};

	const paginationHandler = (value) => {
		if (pageAndPageSize.page !== value) {
			setPageAndPageSize((prevValue) => ({
				page: value,
				pageSize: prevValue.pageSize
			}));
		}
	};

	return (
		<Wrapper className="wrapper">
			<section className="mainResponsiveWrapper">
				<section
					className="toastMessage"
					style={{
						display:
							errorMessage && errorMessage.length > 0
								? 'block'
								: 'none'
					}}
				>
					<Toast
						variant="error"
						onClose={() => setErrorMessage(false)}
					>
						{errorMessage}
					</Toast>
				</section>
			</section>
			{hasAdminDocumentsPermission() ? (
				<section>
					<section className="mainResponsiveWrapper">
						<section className="fieldholder">
							<section className="engid">
								<section className="wrapper">
									<h5>{labels.engagementID}</h5>
									<FormField>
										<Search
											value={engIdSearchText}
											title={engIdSearchText}
											clearButtonTitle={labels.clear}
											onChange={(event) => {
												setEngIdSearchText(event.target.value);
												setShowInvalidEngIdValidationMessage(false);
											}}
											onEnter={onGetResultButton}
											placeholder={labels.engagementID}
										/>
										{engIdSearchText.trim().length == 0 && showRequiredValidationMessage ? (
											<ErrorMessage>{labels.requiredField}</ErrorMessage>
										) : <></>}
										{showInvalidEngIdValidationMessage ? (
											<ErrorMessage>{labels.adminModuleinvalidEngagementId}</ErrorMessage>
										) : <></>}
									</FormField>
								</section>
								<section className="wrapper">
									<h5>{labels.docListDocIdSearchLabel}<span className="optional"> {labels.optional}</span></h5>
									<FormField>
										<Search
											placeholder={labels.docListDocIdSearchLabel}
											value={docIdSearchText}
											onChange={(e) => setDocIdSearchText(e.target.value)}
											onSearchButtonClick={onDocIdSearchClick}
											onClear={() => setDocIdSearchText('')}
											onEnter={onDocIdSearchClick}
											clearHoverText={labels.clear}
											disabled={engIdSearchText?.trim().length <= 0}
											clearButtonTitle={labels.clear}
										/>
									</FormField>
								</section>
								<section className="getdocuments button">
									<Button
										aria-label="getdocuments"
										role="button"
										type="button"
										variant="primary-alt"
										onClick={onGetResultButton}
									>
										{labels.getDocuments}
									</Button>
									<Button
										aria-label="cleardocuments"
										role="button"
										type="button"
										variant="secondary"
										disabled={!(documents.totalEntityCount)}
										onClick={resetAllData}
									>
										{labels.clearResults}
									</Button>
								</section>
							</section>
						</section>
						{isLoading ? <Loader show view="blockView" styleName="loadingstyle" />
							: <section>
								{documents?.data ?
									<section className="documentaddon">
										<section className="totaldocument">
											<section><label>{labels.docListTotalDocuments} </label><span>{documents.totalEntityCount}</span></section>
										</section>
										<section className="export">
											<SVGICONS
												role="button"
												styleName="medium"
												name="import-export-icon"
												hoverText={labels.docListExportBtn}
												onIconClick={downloadDocuments}
											/>
											<a onClick={downloadDocuments}>{labels.docListExportBtn}</a>
										</section>
									</section>
									: <></>}
								<section className="tableholder">
									{documents?.data ? (
										documents.totalEntityCount > 0 ? (
											<DocumentsTable
												data={documents.data}
											/>
										) : (<section className="norecord">{labels.noResultsFound}</section>)
									) : (<></>
									)}
								</section>
							</section>}
					</section>
					<section className="pagerholder">
						<Pagination
							styleName="document-paging mainResponsiveWrapper"
							options_per_page={pagingOptions.options}
							default_dropdown_value={pageAndPageSize.pageSize}
							// showText={currentResource.common.pagination.show}
							currentPage={pageAndPageSize.page < 1 ? 1 : pageAndPageSize.page}
							TotalPages={totalPages < 1 ? 1 : totalPages}
							dropup
							onInputChange={(e) => {
								let inputValue = Number.parseInt(e.target.value);
								if (inputValue != pageAndPageSize.page && inputValue > 0) {
									paginationHandler(inputValue);
								}
							}}
							onDropdownChange={(e) => {
								handlerOptionPage(e.value);
							}}
							onFirstPageClick={() => {
								paginationHandler(1);
							}}
							onPrevPageClick={() => {
								paginationHandler(pageAndPageSize.page - 1);
							}}
							onNextPageClick={() => {
								paginationHandler(pageAndPageSize.page + 1);
							}}
							onLastPageClick={() => {
								paginationHandler(totalPages);
							}}
							dropdown_position="left"
						// iconHover={currentResource.common.pagination.iconHover}
						/>
					</section>
				</section>
			) : (
				<section className="message mainResponsiveWrapper">
					<AccessDeniedMessage
						permissionMesage={labels.insufficientPermission}
					/>
				</section>
			)}
		</Wrapper>
	);
}
