import React from 'react';
import Headerbody from '../styles/headerbodystyle';
import EngagementHeader from './EngagementHeader';
import EngagementList from './EngagementList';
import {labels} from '../../../../util/uiconstants';
import styled from 'styled-components';
import Loader from '@ey/loader';

function EngagementTable(props) {
	const {isLoading} = props;

	return (
		<Headerbody className="engagementpageview mainResponsiveWrapper">
			{isLoading ? (
				<Loader show view="blockView" styleName="loadingstyle" />
			) : props.data?.totalRowCount != undefined ? (
				<section className="userlistwrapper">
					<EngagementHeader />
					{props.data?.engagementSearchModel?.length > 0 ? (
						<EngagementList list={props.data.engagementSearchModel} />
					) : (
						<PlaceholderWrapper>
							<div className="searchplaceholder">
								{labels.noEngagementFound}
							</div>
						</PlaceholderWrapper>
					)}
				</section>
			) : (
				<PlaceholderWrapper>
					<div className="searchplaceholder">
						{labels.engagementPagePlaceholder}
					</div>
				</PlaceholderWrapper>
			)}
		</Headerbody>
	);
}

const PlaceholderWrapper = styled.section`
	position: relative;
	overflow: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: var(--px-14);
	height: calc(100vh - var(--px-300));
	color: var(--neutrals-700);
	border: var(--px-1) solid var(--neutrals-200);
`;

export default EngagementTable;
