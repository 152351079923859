import React, { useState } from 'react';
import FormField from '@ey/motif-react-wrapper/FormField';
import Label from '@ey/motif-react-wrapper/Label';
import DatePicker from '@ey/motif-react-wrapper/DatePicker';
import TimeInput from '@ey/motif-react-wrapper/TimeInput';
import styled from 'styled-components';

const Wrapper = styled.section`

`;

export default function DateTimePicker(props) {
    const {
        label,
        value,
        onChange,
        dateFormat,
        maxDate,
        minDate,
        onCalendarOpen,
        todayMark
    } = props;

    const setDateInput = () => {
        if (value == '') {
            return '';
        }
        else {
            var date = new Date(value);
            return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
        }
    }

    const setTimeInput = () => {
        if (value == '') {
            return new Date();
        }
        else {
            var time = new Date(value);
            return new Date(time.getFullYear(), time.getMonth(), time.getDate(), time.getHours(), time.getMinutes());
        }
    }

    const [date, setDate] = useState(setDateInput());
    const [time, setTime] = useState(setTimeInput());

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    const getISOString = (date, time) => {
        const yyyy = date.getFullYear();
        const mm = padTo2Digits((date.getMonth()+1));
        const dd = padTo2Digits(date.getDate());
        const hh = padTo2Digits(time.getUTCHours());
        const mn = padTo2Digits(time.getUTCMinutes());

        var dateTimeString = `${yyyy}-${mm}-${dd}T${hh}:${mn}:00.000Z`;
        return dateTimeString;
    };

    const onDateChange = (date) => {
        setDate(date);
        onChange(getISOString(date, time));
    };

    const onTimeChange = (time) => {
        setTime(time);
        onChange(getISOString(date, time));
    };

    return (
        <Wrapper className="datepicker">
            <FormField>
                <Label>
                    {label}
                </Label>
                <DatePicker
                    value={date}
                    onChange={(date) => {
                        onDateChange(date);
                    }}
                    dateFormat={dateFormat}
                    yearPlaceholder="YYYY"
                    dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    maxDate={maxDate}
                    minDate={minDate}
                    onCalendarOpen={onCalendarOpen}
                    todayMark={todayMark}
                />
            </FormField>
            <FormField>
                <TimeInput
                    value={time}
                    onChange={(time) => {
                        onTimeChange(time);
                    }}
                />
            </FormField>
        </Wrapper>
    );
}