import React from 'react';
import {useSelector} from 'react-redux';
import {labels, getTheme} from '../../util/uiconstants';
import styled from 'styled-components';
import RemoteComponent from './RemoteComponent';
import env from '../../util/env';
import * as queryString from 'query-string';
import TimeTrackingLoader from '../Common/Loaders/TimeTrackingLoader';
import TimeTrackerErrorPlaceholder from './TimeTrackerErrorPlaceholder';

const TimeTracking = () => {
	const userSettings = useSelector((state) => state.userSettings);

	const getQueryStringParam = (paramName) => {
		const qs = queryString.parse(location.search.toLowerCase());
		return qs[paramName];
	};

	return (
		<StyledTimeTracking className="customScrollbar timesheet-view">
			<h1 className="timetracking-header">{labels.timeTracking}</h1>
			<h5 className="timetracking-info">{labels.timeTrackingToolUpdatedMessage}</h5>
			
			<RemoteComponent
				id="aion"
				adConfig={env.getAdObjectConfiguration()}
				engagementId={getQueryStringParam('engagementid')}
				displayLanguage={userSettings?.data?.uidisplaylanguage_resourcename}
				darkMode={getTheme() === 'dark'}
				numberFormat={userSettings?.data?.usernumberformat}
				userId={userSettings?.data?.gui}
				loadingFallback={<TimeTrackingLoader />}
				errorFallback={<TimeTrackerErrorPlaceholder />}
			/>
		</StyledTimeTracking>
	);
};
export default TimeTracking;

const StyledTimeTracking = styled.section`
	padding: var(--px-14) 0;
	height: calc(100vh - var(--px-65));
	display: flex;
	flex: 1 1 0%;
	flex-direction: column;
	overflow: auto;
	max-width: 2048px;
	margin: 0px auto;
	.timetracking-header {
		display: block;
		font-size: var(--px-40);
		font-weight: 400;
		line-height: normal;
		margin-top: 0;
		padding-left:var(--px-40);
	}
	.timetracking-info {
		display: block;
		font-size: var(--px-14);
		font-weight: 300;
		padding-left:var(--px-40);
		margin:0;
		color: var(--neutrals-700);
	}
	.timetracking-info1 {
		margin-top: 0;
		margin-bottom: 0;
		line-height: normal;
		padding-left:var(--px-40);
	}
	.MainContainerWrapper.customScrollbar {
		.timetracker-wrap {
			.filter-timesheet-wrap
				.filter-settings-wrap
				.filter-dropdowns
				> section:first-child {
				margin-left: 0;
			}
		}
	}
`;
