import { EngagementSource, EngagementStatus } from '../../../util/enumerations';
import Button from '@ey/motif-react-wrapper/Button';
import Card from '@ey/motif-react-wrapper/Card';
import Icon from '@ey/motif-react-wrapper/Icon';
import IconButton from '@ey/motif-react-wrapper/IconButton';
import TextLink from '@ey/motif-react-wrapper/TextLink';
import Tooltip from '@ey/motif-react-wrapper/Tooltip';
import React, { useEffect, useState } from 'react';
import {
	contentIcLink24px,
	imageIcTimer24px,
	toggleIcStar24px,
	toggleIcStarBorder24px
} from '@ey/motif-react-wrapper/assets/icons/index';
import {
	engagementFilterTypes,
	gaRoleTypeConstants,
	invalidClientCode,
	labels,
	urls
} from '../../../util/uiconstants';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import EllipsesControl from '@ey/ellipses-control';
import { EngUserModelPatchFavorites } from '../../../util/preparemodel';
import EngagementAlerts from './EngagementAlerts';
import EngagementInvalidModal from '../engagementInvalidModal';
import EngagementMetricsDetails from './EngagementMetrics';
import EngagementOptions from './EngagementOptions';
import EngagementProgressStatus from './EngagementProgressStatus';
import EngagementRequestDeleteModal from '../engagementRequestDeleteModal';
import Env from '../../../util/env';
import { SVGICONS } from '@ey/icons';
import _ from 'lodash';
import { getEngagementOverview } from '../../../actions/engagementOverviewActions';
import { getNotifications } from '../../../actions/notificationsactions';
import { markUnmarkFavorite } from '../../../actions/engagementuseractions';

const EngagementItem = (props) => {
	const {
		engagementId,
		clientName,
		workspace,
		workspaceID,
		refreshDashboard,
		incomplete,
		searchType,
		data
	} = props;
	const {
		engagementDesc,
		engagementIsProfileComplete,
		engagementSourceId,
		engagementStatus,
		engagementVersion,
		gaRoleTypeId,
		hasEngagementPassedAllowedDays,
		herculesAppUri,
		inCountryAppUri,
		inCountryAudienceUri,
		herculesWebUri,
		isClientCodeValid,
		isclientengagementidvalidated,
		isEngagementAccessible,
		isengagementcodevalid,
		isFavorite,
		isLdcUpgraded,
		workspacecountryid
	} = data;

	const apponlinestate = useSelector((store) => store.apponlinestate);
	const notifications = useSelector((store) => store.notifications);
	const userPermissions = useSelector((store) => store.userPermissions);
	const engagementOverview = useSelector(
		(store) =>
			store.engagementOverview.find(
				(x) =>
					x.engagementId === parseInt(engagementId) ||
					x.id === parseInt(engagementId)
			) || {},
		shallowEqual
	);

	const [helixProjects, setHelixProjects] = useState([]);
	const [notice, setNotice] = useState(null);
	const [showInvalidEngagementModalState, setShowInvalidEngagementModalState] =
		useState(false);
	const [showRequestDeleteModalState, setShowRequestDeleteModalState] =
		useState(false);
	const [fav, setFav] = useState(isFavorite);
	const [isFavInProgress, setIsFavInProgress] = useState(false);
	const [readyForFavClick, setReadyForFavClick] = useState(true);
	const engagementurl = `${herculesWebUri}/dashboard?engagementid=${engagementId}`;
	const dispatch = useDispatch();

	useEffect(() => {
		getNotice();
		if (isEngagementAccessible) {
			if (Object.keys(engagementOverview).length === 0) {
				dispatch(
					getEngagementOverview(
						herculesAppUri,
						inCountryAudienceUri,
						inCountryAppUri,
						inCountryAudienceUri,
						isLdcUpgraded,
						engagementId,
						true,
						true,
						true,
						true
					)
				);
			}
		}
	}, []);

	useEffect(() => {
		if (Object.keys(engagementOverview).length > 0) {
			setHelixProjects(engagementOverview?.helixProjects);
		}
	}, [engagementOverview]);

	//TODO: Check the scenario where we should show the Invalid Engagement Modal on Engagements V2.
	const showInvalidEngagementModal = () => {
		if (!hasEngagementPassedAllowedDays) {
			redirectToEngagement();
		} else if (
			isengagementcodevalid &&
			isClientCodeValid &&
			!isclientengagementidvalidated &&
			hasEngagementPassedAllowedDays
		) {
			redirectToEngagement();
		} else if (
			isClientCodeValid &&
			isengagementcodevalid &&
			isclientengagementidvalidated &&
			hasEngagementPassedAllowedDays
		) {
			redirectToEngagement();
		} else if (!isClientCodeValid && hasEngagementPassedAllowedDays) {
			setShowInvalidEngagementModalState(true);
		} else if (!isengagementcodevalid && hasEngagementPassedAllowedDays) {
			setShowInvalidEngagementModalState(true);
		}
	};

	const getNotice = async () => {
		if (data && engagementStatus !== EngagementStatus.MarkedForDeletion) {
			return;
		}
		let url = Env.getURL('serviceUrl') + '/' + urls.LANDINGPAGENOTIFICATION_URL;
		await dispatch(getNotifications(url));

		if (notifications && notifications.notificationdetails) {
			for (const notice of notifications.notificationdetails) {
				if (notice.actionType == 4 && notice.engagementId == engagementId) {
					setNotice(notice);
					break;
				}
			}
		}
	};

	const renderLinkedHelixProjects = () => {
		let linkedProjectNames = [];
		helixProjects.forEach((project) => {
			linkedProjectNames.push(
				<li>
					<EllipsesControl
						content={project.projectName}
						tooltip={project.projectName}
						isTooltipAvailable
					></EllipsesControl>
				</li>
			);
		});

		return (
			<section className="helixlinked-tag">
				<Tooltip
					allowHover={false}
					variant="alt"
					trigger={
						<Button variant="text">
							<Icon src={contentIcLink24px} />
							{labels.linkedHelixProjects}
						</Button>
					}
					contentClassName="tooltip-helixlinked"
				>
					<ul>{linkedProjectNames}</ul>
				</Tooltip>
			</section>
		);
	};

	const onFavClick = async () => {
		setReadyForFavClick(false);
		setIsFavInProgress(true);
		let isFavTab = searchType === engagementFilterTypes.MY_FAVORITES;
		// Add favourite-inprogress class to arrow-right container when api call is in progress
		try {
			await dispatch(
				markUnmarkFavorite(
					Env.getURL('serviceUrl') + '/' + 'EngagementUsers.json',
					EngUserModelPatchFavorites(
						engagementId,
						!fav,
						'markunmarkfavoriteengagement'
					)
				)
			);
			setIsFavInProgress(false);
			setFav(!fav);
			setReadyForFavClick(true);
		} finally {
			if (isFavTab) {
				doRefreshDashboard();
			}
		}
	};

	const IsRestoredEngagement = () => {
		return (
			engagementSourceId === EngagementSource.Restore ||
			engagementSourceId === EngagementSource.RestoreException
		);
	};


	const doRefreshDashboard = () => {
		refreshDashboard();
	};

	const redirectToEngagement = () => {
		const url =
			!apponlinestate && herculesAppUri
				? `${herculesAppUri}?engagementid=${engagementId}`
				: engagementurl;

		return url;
	};

	const hasAccessToEngagement = () => {
		const deniedEngagementStatusList = [
			EngagementStatus.ArchiveError,
			EngagementStatus.ContentDeliveryInProgress
		];
		let allowAccess = true;

		if (!deniedEngagementStatusList.includes(engagementStatus)) {
			if (!incomplete) {
				allowAccess = false;
			}
		}

		return allowAccess;
	};

	const hideInvalidEngagementModal = () => {
		setShowInvalidEngagementModalState(false);
	};

	const hideRequestDeleteModal = () => {
		setShowRequestDeleteModalState(false);
	};

	const getInCountryURL = (navigateToPage) => {
		return (
			herculesWebUri +
			navigateToPage +
			'countryid=' +
			workspacecountryid +
			'&engagementid=' +
			engagementId +
			'&engagementdesc=' +
			encodeURIComponent(engagementDesc.substring(0, 50)) +
			'&userPrefLang=en-us&workspacedesc=' +
			encodeURIComponent(workspace.trim()) +
			'&workspaceid=' +
			workspaceID +
			'&engagementversion=' +
			Math.floor(Number.parseInt(engagementVersion)) +
			'&engagementversionfull=' +
			engagementVersion
		);
	};

	//TODO: This will be used as part of the Complete Profile US.
	const routeToCompleteProfile = () => {
		let url = '/v1redirect/engagementprofile?';
		window.location.href = getInCountryURL(url);
	};

	//TODO: This will be used as part of the Complete Independence US.
	const routeToCompleteIndependence = () => {
		let url = '/v1redirect/index?';
		let independenceURLPage = getInCountryURL(url) + '&showindependence=true';
		window.location.href = independenceURLPage;
	};

	const renderEngagementMetrics = () => {
		const engagementMetrics = isLdcUpgraded
			? engagementOverview?.metrics
			: engagementOverview?.metrics?.engagementMetrics;

		return (
			<>
				{engagementMetrics?.length > 0 ? (
					<section className="engmetrics-container">
						<Tooltip
							allowHover={false}
							trigger={
								<IconButton type="button">
									<SVGICONS
										hoverText={labels.engagementMetrics}
										styleName="small pull-left"
										name={'add_chart'}
									/>
								</IconButton>
							}
							contentClassName="auditmetrics-tooltip"
							placement="auto"
						>
							{engagementMetrics ? (
								<section className="auditmetrics-container">
									<h3 className="auditmetrics-title">
										{labels.engagementMetrics}
									</h3>
									<EngagementMetricsDetails
										engagementId={engagementId}
										metrics={engagementMetrics}
										key={engagementId}
									/>
								</section>
							) : (
								''
							)}
						</Tooltip>
					</section>
				) : null}
			</>
		);
	};

	const getEngagementAlerts = () => {
		return isLdcUpgraded
			? engagementOverview?.alerts
			: engagementOverview?.metrics?.engagementAlerts;
	};

	return (
		<>
			<Card>
				<section>
					<section className="engagementinfo-container">
						<section className="clientname-wrapper">
							<span
								className="client-name engagement-ellipsis"
								title={
									clientName === invalidClientCode
										? labels.invalidclientcode
										: clientName
								}
							>
								{clientName === invalidClientCode
									? labels.invalidclientcode
									: clientName}
							</span>
						</section>
						<TextLink
							className="engagement-description engagement-ellipsis"
							title={engagementDesc}
							href={redirectToEngagement()}
							disabled={hasAccessToEngagement()}
						>
							{engagementDesc}
						</TextLink>
						<section className="workspace-name-wrapper">
							<span
								className="workspace-name engagement-ellipsis"
								title={workspace}
							>
								{workspace}
							</span>
							{gaRoleTypeId === gaRoleTypeConstants.Primary && (
								<span
									className="engagement-type engagement-ellipsis"
									title={labels.primaryEngagementLabel}
								>
									| {labels.primaryEngagementLabel}
								</span>
							)}
							{gaRoleTypeId === gaRoleTypeConstants.Component && (
								<span
									className="engagement-type engagement-ellipsis"
									title={labels.componentEngagementLabel}
								>
									| {labels.componentEngagementLabel}
								</span>
							)}
							{gaRoleTypeId === gaRoleTypeConstants.Region && (
								<span
									className="engagement-type engagement-ellipsis"
									title={labels.regionalEngagementLabel}
								>
									| {labels.regionalEngagementLabel}
								</span>
							)}
							<span className="engagementid">| ID: {engagementId}</span>
						</section>
					</section>
					<section className="engagementactions-container">
						<section className="engagement-typelinked">
							{helixProjects.length > 0 ? renderLinkedHelixProjects() : null}
						</section>
						<EngagementAlerts engagementAlerts={getEngagementAlerts()} />
						{renderEngagementMetrics()}
						<EngagementOptions
							key={engagementId}
							engagementID={engagementId}
							engagementStatus={engagementStatus}
							userPermissions={userPermissions?.userpermissions}
							isEngagementProfileComplete={engagementIsProfileComplete}
							isEngagementAccessible={isEngagementAccessible}
							incomplete={incomplete}
							doRefreshDashboard={doRefreshDashboard}
							{...props}
						/>
					</section>
				</section>
				<section className="engagementstats-container">
					<section className="engagementstatus-container">
						<EngagementProgressStatus
							engagementId={engagementId}
							engagementOverview={engagementOverview?.tasksProgress}
						/>
					</section>
					<section className="engagementdates-container">
						<section className="expiry-label">
							<Icon className="deadlinedate-icon" src={imageIcTimer24px} />
							<span className="deadlinedays-count">55</span>
							<span className="deadline-message">Days to report date</span>
						</section>
					</section>
				</section>
				<section className="engagementfavourite-container">
					<section
						className={
							isFavInProgress
								? 'engagementfavourite-wrapper favourite-inprogress'
								: 'engagementfavourite-wrapper'
						}
					>
						{fav ? (
							<span
								title={labels.unmarkfromfavorites}
								className="marked-favourite"
							>
								<Icon
									src={toggleIcStar24px}
									onClick={readyForFavClick ? onFavClick : undefined}
								/>
							</span>
						) : (
							<span title={labels.markasfavorite} className="mark-favourite">
								<Icon
									src={toggleIcStarBorder24px}
									onClick={readyForFavClick ? onFavClick : undefined}
								/>
							</span>
						)}
					</section>
				</section>
				<EngagementRequestDeleteModal
					key={notice}
					notification={notice}
					show={showRequestDeleteModalState}
					hide={hideRequestDeleteModal}
					{...props}
				/>
				<EngagementInvalidModal
					notification={notice}
					show={showInvalidEngagementModalState}
					hide={hideInvalidEngagementModal}
					{...props}
				/>
			</Card>
		</>
	);
};

EngagementItem.propTypes = {};

export default EngagementItem;
