/**
 * @module AddNewEngagementCode
 */

import * as EngagementCodeNameActions from '../../../../actions/engagementcodenameaction';

import {EYForm, FormRadio, FormTextInput} from '@ey/form';
import {labels} from '../../../../util/uiconstants';
import styled from 'styled-components';

import Button from '@ey/button';
import {Radio} from 'react-bootstrap';
import React from 'react';
import _ from 'lodash';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

const AddEngagementCode = styled.section`
	 & .addnewengagement {
		 & .form-group .radio label {
			 padding: 0;
			 font-weight: normal;
		 }
		 & .clientname,
		 & .primaryradio {
			 display: inline;
		 }
 
		 & .form-group .radio + .engagement-label {
			 margin: var(--px-14) 0 0 var(--px-35);
 
			 &[disabled] {
				 color: var(--neutrals-700);
			 }
		 }
 
		 & .clientname .form-group input.input-group {
			 width: var(--px-600);
			 height: var(--px-48);
			 border: var(--px-1) solid var(--neutrals-300);
			 display: inline;
			 padding: var(--px-6) 0 0 var(--px-10);
			 color: var(--neutrals-700);
		 }
 
		 &
			 .clientname.input-group.disabled
			 .form-group
			 input.form-control[disabled]:not([type='radio']):not([type='checkbox']) {
			 border: var(--px-1) solid var(--neutrals-200);
			 color: var(--neutrals-200);
		 }
 
		 &
			 .clientname.input-group.error.disabled
			 .form-group
			 input.form-control[disabled]:not([type='radio']):not([type='checkbox']) {
			 color: var(--red-300);
			 border: var(--px-1) solid var(--neutrals-200);
		 }
 
		 & .removeengagement {
			 float: left;
			 & .btn {
				 color: var(--neutrals-900);
				 font-weight: bold;
				 text-decoration: none;
				 opacitiy: 1;
				 margin: var(--px-5) 0 0 var(--px-10);
				 padding: 0;
				 border: 0;
				 background: none;
 
				 &:active {
					 box-shadow: none;
				 }
			 }
		 }
	 }
 `;
 
 export class AddNewEngagementCode extends React.Component {
	 static propTypes = {
		 // defaultengagementcode:React.PropTypes.string,
		 // defaultengagementcodename:React.PropTypes.string
		 /* myProp: PropTypes.number */
	 };
 
	 constructor(props) {
		 super(props);
		 console.log('@@ nice - eng code :' + this.props.engCode);
		 this.state = {
			 engagementcode: this.props.engCode,
			 engagementcodename: this.props.engCodeName,
			 isprimary: this.props.isPrimary
		 };
 
		 // this.onBlurEngagementCode = this.onBlurEngagementCode.bind(this);
		 // this.fetchEngagementCodeNameFromDB = this.fetchEngagementCodeNameFromDB.bind(
		 // 	this
		 // );
		 // this.radioIsPrimaryChanged = this.radioIsPrimaryChanged.bind(this);
	 }
 
	 radioIsPrimaryChanged = (key, value) => {};
 
	 onBlurEngagementCode(key, value) {
		 if (value != '') {
			 var index = key.split('_')[1];
			 this.fetchEngagementCodeNameFromDB(value, index);
		 }
	 }
 
	 componentWillReceiveProps(nextProps) {
		 // this.setState({
		 // 	engagementcode: nextProps.engagementcodeobject.code,
		 // 	engagementcodename: nextProps.engagementcodeobject.name
		 // });
	 }
 
	 render() {
		 let engagementCodeAttributes = {};
		 if (this.props.isEngagementCodeDisabled) {
			 engagementCodeAttributes.disabled = true;
		 }
		 let allClasses = 'clientname input-group';
		 let error =
			 this.state.engagementcodename &&
			 this.state.engagementcodename.includes(
				 labels.engagementcodevalidationmessage
			 )
				 ? true
				 : false;
		 let invalid =
			 this.state.engagementcodename &&
			 this.state.engagementcodename.includes(labels.invalidclientcode)
				 ? true
				 : false;
		 if (error) {
			 allClasses += ' error disabled';
		 }
		 if (invalid) {
			 allClasses += ' disabled';
		 }
 
		 let EngagementCodeName = (props) => {
			 /* workaround for broken data binding */
			 return (
				 <section className={allClasses}>
					 <FormTextInput
						 key={'engagementcodename_' + this.props.engagementcodeindex}
						 placeholder={labels.engagementcodename}
						 name={'engagementcodename_' + this.props.engagementcodeindex}
						 value={props.engagementCodeName}
						 rules={[]}
						 is_required
						 help
						 disabled
						 maxLength="50"
						 onChange={() => {}}
					 />
				 </section>
			 );
		 };
 
		 return (
			 <AddEngagementCode>
				 <div className="addnewengagement" key={this.props.engagementcodeindex}>
					 <EYForm
						 id={'addEngagementCode_' + this.props.engagementcodeindex}
						 onChange={() => {}}
						 onSubmit={() => {}}
						 action="\"
						 className="addnewengagement"
					 >
						 <FormTextInput
							 key={'engagementcode_' + this.props.engagementcodeindex}
							 placeholder={labels.engagementcode}
							 name={'engagementcode_' + this.props.engagementcodeindex}
							 value={this.state.engagementcode}
							 rules={[]}
							 help
							 disabled={this.props.isEngagementCodeDisabled}
							 maxLength="50"
							 onBlur={(e) => {
								 this.onBlurEngagementCode(
									 e.currentTarget.name,
									 e.currentTarget.value
								 );
							 }}
						 />
 
						 {/* <div className="form-group">
							 <label class="control-label" />
							 <span className={allClasses}>
								 {this.state.engagementcodename}
							 </span>
						 </div> */}
 
						 <EngagementCodeName
							 engagementCodeName={this.state.engagementcodename}
						 />
 
						 <section className="primaryradio">
							 <FormRadio
								 label=""
								 name={'isprimaryform'}
								 help
								 {...engagementCodeAttributes}
								 is_required
								 onChange={this.radioIsPrimaryChanged}
							 >
								 <Radio
									 value={'isprimary_' + this.props.engagementcodeindex}
									 key={'isprimary_' + this.props.engagementcodeindex}
									 defaultChecked={this.state.isprimary}
									 disabled={this.props.isEngagementCodeDisabled}
								 />
								 <span className="engagement-label">{labels.isprimary} </span>
							 </FormRadio>
						 </section>
						 <section className="removeengagement">
							 {this.props.isDeletedDisabled ? (
								 <div />
							 ) : (
								 <Button
									 name="deleteengagementcode"
									 value="deleteengagementcode"
									 buttonType="link"
									 disabled={this.props.isDeletedDisabled}
									 label="X"
									 onClick={(e) => {
										 this.props.deleteEngagementCodeClick(
											 e,
											 this.props.engagementcodeindex
										 );
									 }}
								 />
							 )}
						 </section>
					 </EYForm>
				 </div>
			 </AddEngagementCode>
		 );
	 }
 }
 
 /**
  * This part of the Redux lifecycle comes right after the Reducers have
  * been called, passing the new "state" values into this function, where
  * we tell Redux what portion of our Redux state to expose to this
  * React component.
  * You can think of this function as injecting data from Redux into the
  * React component.
  * The name "state.forms" refers to the "forms" name we assigned
  * in the root reducer, located in reducers/index.js
  * @param {Object} storeState - The current Redux store state
  * //@param {Object} ownProps - reference to the component's own props, which
  *      can be useful for dealing with prop values like those coming from
  *      react-router.
  * @return {{forms: (Array|*|HTMLCollection)}} The object describing the
  *      piece of the state being exposed.
  */
 const mapStateToProps = (storeState) => {
	 /*
	  * Link your props to the store values here.
	 let {myStoreObj} = storeState,
	  * You can also mutate your objects from the reducer here before
	  * you pass them on to your props.
	  * This pattern is called a "Redux Selector" and is useful for
	  * libraries which require different structures to function properly.
	  * Your mutating functions should be written inside the
	  * reducer files.
		 formattedObj = objectFormatted(myStoreObj);
	  */
	 return {
		 clientengagement: storeState.clientengagement
		 /* obj: formattedObj */
	 };
 };
 
 /**
  * The mapDispatchToProps just chooses which actions you want to expose
  * directly as props, instead of having to refer to them using the
  * .dispatch(types.SOME_ACTION_NAME) syntax.
  * The signature for bindActionCreators is (Object, Function), so if
  * you need to bind multiple actions object, use Object.assign to create
  * a new Object containing all your actions.
  * @param {Function} dispatch - dispatch Redux function
  * @return {{actions: Object}} An object containing all the actions
  *  passed as parameters, plus the dispatch function.
  */
 const mapDispatchToProps = (dispatch) => {
	 return {
		 actions: bindActionCreators(
			 Object.assign(
				 {},
				 EngagementCodeNameActions
				 /* myActions */
			 ),
			 dispatch
		 )
	 };
 };
 
 /*
  * Not declaring matchDispatchToProps will automatically give you the
  * props.dispatch() function.
  */
 export default connect(
	 mapStateToProps,
	 mapDispatchToProps
 )(AddNewEngagementCode);
