import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import Loader from '@ey/loader';
import {FormRadio, FormComboBox} from '@ey/form';
import {Radio} from 'react-bootstrap';
import env from '../../../util/env';
import {
	getClientAndWorkspaceDetails,
	getFilteredClientAndWorkspaceDetails
} from '../../../actions/workspacesactions';
import {labels} from '../../../util/uiconstants';
import {SVGICONS} from '@ey/icons';
import {useDidMountEffect} from '../../../util/customHooks';

const SelectWorkspace = (props) => {
	const dispatch = useDispatch();
	const {
		nextClicked,
		onComponentDisplayed,
		isComponentValid,
		updateWizardData,
		getDependencyData
	} = props;

	const clientData = useSelector((store) => store.clientWorkspaces);

	const [selectedClientId, setSelectedClientId] = useState(
		getDependencyData('domainClientId') || -1
	);
	const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(
		getDependencyData('domainWorkspaceId') || -1
	);
	const [clientName, setClientName] = useState(
		getDependencyData('domainClientName') || ''
	);
	const [workspaceName, setWorkspaceName] = useState(
		getDependencyData('domainWorkspaceName') || ''
	);
	const [clientCode, setClientCode] = useState(
		getDependencyData('domainClientCode') || ''
	);
	const [location, setLocation] = useState(
		getDependencyData('domainLocation') || 0
	);
	const [clientWorkspaces, setClientWorkspaces] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [disableClient, setDisableClient] = useState(false);
	const [showValidation, setShowValidation] = useState(false);
	const [shouldUpdateWizard, setShouldUpdateWizard] = useState(0);

	let stepHeaderText = props.stepHeaderText || labels.selectEngagementHeader;
	let errorMessage = labels.selectexistingworkspacemsg;

	const clientDropdownAttributes = () => {
		// Pass value to enable/disable client ddl
		return {
			disabled: disableClient ? true : false
		};
	};

	const Workspaces = () => {
		let result = [];
		clientWorkspaces
			? clientWorkspaces.map(({id, data}) => {
					result.push(
						(<Radio
							value={id}
							className={data.iseligiblefornewengagement === false ? 'disabled' : ''}							
							key={id}
							name="radio"
							checked={id == selectedWorkspaceId}
							onClick={() =>
								radioButtonClicked(id, data.workspacedesc, data.countryid)
							}
						>
							{data.workspacedesc}
							{data.iseligiblefornewengagement === false ? (<span className='newengagement-eligible'>{'(' + labels.workspacenotelibilefornewengagement + ')'}</span>) : null}
						</Radio>
						)
					);
			  })
			: null;

		return (
			<section>
				<FormRadio name="radioStack" onChange={() => {}}>
					{result}
				</FormRadio>
			</section>
		);
	};

	useEffect(() => {
		setIsLoading(true);
		getClientAndWorkspaceDetailsUrls();
	}, []);

	const getClientAndWorkspaceDetailsUrls = () => {
		const modes = {
			rollForward: 'rollForward'
		};
		if (props.mode === modes.rollForward) {
			const engVersion = props.getDependencyData('domainOriginEngVersion');
			const location = props.getDependencyData('domainOriginLocation');
			const versionFilteredURL =
				env.getURL('serviceUrl') +
				"/home.json?phase=GetClientAndWorkspaceDetails&filters=sourceengagementversion eq '" +
				engVersion +
				"',operationtype eq RollForwardToExistingWorkspace,countryid eq " +
				location;

			dispatch(getFilteredClientAndWorkspaceDetails(versionFilteredURL))
				.then(() => {
					setIsLoading(false);
				})
				.catch(() => {
					setIsLoading(false);
				});
		} else {
			const workspacesurl =
				env.getURL('serviceUrl') + '/home.json?phase=GetClientWorkspaces';
			dispatch(getClientAndWorkspaceDetails(workspacesurl))
				.then(() => {
					setIsLoading(false);
				})
				.catch(() => {
					setIsLoading(false);
				});
		}
	};

	useDidMountEffect(() => {
		if (selectedClientId < 1 || selectedWorkspaceId < 1) {
			setShowValidation(true);
		}
	}, [nextClicked]);

	useDidMountEffect(() => {
		setComponentValid();
	}, [onComponentDisplayed]);

	useEffect(() => {
		if (clientWorkspaces.length == 0 && selectedClientId > 0) {
			setShowValidation(false);
		}
		if (selectedClientId < 1) {
			errorMessage = labels.selectaclienttocontinuemsg;
		}
	}, [selectedClientId, clientWorkspaces]);

	useEffect(() => {
		updateWizardFromComponent();
	}, [shouldUpdateWizard]);

	const setComponentValid = () => {
		if (selectedClientId > -1 && selectedWorkspaceId > 0) {
			isComponentValid(true);
		} else {
			isComponentValid(false);
		}
	};

	const updateWizardFromComponent = () => {
		// Pass on data to next step
		updateWizardData('domainClientId', selectedClientId);
		updateWizardData('domainClientName', clientName);
		updateWizardData('domainClientCode', clientCode);
		updateWizardData('domainLocation', location);
		updateWizardData('domainWorkspaceId', selectedWorkspaceId);
		updateWizardData('domainWorkspaceName', workspaceName);
		setComponentValid();
	};

	const dropdownChanged = (key, value) => {
		// Clearout workspaces if no client is selected
		if (value === null) {
			setSelectedClientId(-1);
			setSelectedWorkspaceId(-1);
			setClientName('');
			setWorkspaceName('');
			setClientCode('');
			setLocation('');
			setShowValidation('');
		}
		setSelectedClientId(value);
		setSelectedWorkspaceId(getDependencyData('domainWorkspaceId') || -1);
		setShouldUpdateWizard((prev) => prev + 1);

		// Getting selected client object out of array
		let selectedClient =
			clientData &&
			clientData.collections &&
			clientData.collections.clients &&
			clientData.collections.clients.filter(function (item) {
				if (item.id === value) {
					return item;
				}
			});

		selectedClient &&
			selectedClient.map(({data, collections}) => {
				setClientName(data.clientname);
				setClientCode(data.clientcode);
				setClientWorkspaces(collections.workspaces);
			});

		// Disabling client ddl if no workspaces are present
		if (!clientWorkspaces) {
			setDisableClient(true);
		}
	};

	const radioButtonClicked = (key, value, countryid) => {
		setSelectedWorkspaceId(key);
		setWorkspaceName(value);
		setLocation(countryid);
		setShowValidation(false);
		setShouldUpdateWizard((prev) => prev + 1);
	};

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<Selectexistingworkspace>
					<header>
						<h5>{stepHeaderText}</h5>
					</header>
					<form>
						<section>
							<section className="existingworkspacelist">
								<FormComboBox
									id="clients"
									name="clients"
									value={selectedClientId}
									is_required
									errormessage={labels.isRequired.replace('{0}', labels.client)}
									help
									searchable
									placeholder={labels.selectworkspaceclient}
									options={
										clientData &&
										clientData.collections &&
										clientData.collections.clients.map(({id, data}) => {
											return {
												value: id,
												label: data.clientname
											};
										})
									}
									onChange={dropdownChanged}
									{...clientDropdownAttributes}
								/>
							</section>

							{showValidation === true && (
								<section className="import-error">
									<section>
										<SVGICONS styleName="small" id="icon" name="error" />
										<span>{errorMessage}</span>
									</section>
								</section>
							)}

							<section className="selectexistingworkspace">
								{selectedClientId && selectedClientId < 0
									? showValidation == false
										? labels.selectclient
										: null
									: clientWorkspaces.length > 0
									? Workspaces()
									: labels.selectworkspacenoclientworkspaceexists}
							</section>
						</section>
					</form>
				</Selectexistingworkspace>
			)}
		</>
	);
};
export default SelectWorkspace;

const Selectexistingworkspace = styled.section`
	& .selectexistingworkspace {
		padding-top: var(--px-10);
		font-size: var(--px-14);

		& div.form-group .radio {
			margin: 0 0 var(--px-15) 0;
		}

		& .form-group label.control-label {
			display: inline;
		}
	}

	.radio.disabled label,
	input[type=radio][disabled] {
		cursor:default;
	}

	.radio.disabled {
		pointer-events: none;
		cursor: default;
	}
	& .import-error {
		font-size: var(--px-12);
		line-height: normal;
		height: var(--px-20);
		padding-top: var(--px-10);
		padding-bottom: var(--px-20);

		& svg {
			fill: var(--red-600);
			vertical-align: text-top;
			margin-right: 0.5em;
		}
	}

	& .form-group .radio {
		vertical-align: middle;
		margin: 0;

		& label input[type='radio'] {
			margin: 0 0 0 calc(var(--px-30) * -1);
		}
		& label {
			padding-left: var(--px-30);
			width: auto;
			word-wrap: break-word;
			font-weight: normal;
		}
		& .newengagement-eligible {
			display:inline-block;
			padding-left: var(--px-5);
			width: auto;
			font-weight: 300;
			font-size:var(--px-14);
			color: var(--neutrals-700);

		}
	}

	& .existingworkspacelist {
		display: inline-block;
		width: 95%;
		@media screen and (max-width: 1366px) {
			.Select {
				&.is-open .Select-menu-outer {
					max-height: 200px !important;
					& * {
						max-height: 200px !important;
					}
				}
			}
		}
		& div.form-group {
			display: inline-flex;
			width: 100%;
			font-size: var(--px-14);
			margin: 0;
			padding: 0 0 var(--px-20) 0;
			border-bottom: var(--px-1) solid #ddd;

			& > section {
				width: var(--px-400);
				float: left;

				& .select-label {
					line-height: normal;
					color: var(--neutrals-900);
					font-size: var(--px-14);
				}

				& .Select-control {
					margin: 0 var(--px-20) 0 0;
					vertical-align: middle;
					border: var(--px-1) solid var(--neutrals-300);
					line-height: var(--px-46);
				}
			}
		}
	}
`;
