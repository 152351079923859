import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@ey/motif-react-wrapper/Button';
import { labels } from '../../../util/uiconstants';
import Loader from '../../Common/Loaders/loadingindicator';
import styled from 'styled-components';
import EllipsesControl from '@ey/ellipses-control';
import Toast from '../../Common/Toast/Toast';
import { clearErrors } from '../../../actions/erroractions';
import { setIsModalOpen } from '../../../actions/modalstateactions';
import TicketField from '../common/ticketfield';

const DeleteMessageWrapper = styled.section`
	.loadingstyle {
		height: calc(100vh - var(--px-500)) !important;
	}
	
	.motif-loader {
		height: var(--px-183) !important;
	}

	.wrapper{
		padding: var(--px-20);	
		//ticketstart
			.ticketdetail{
				margin: var(--px-20) 0 0 0;
				padding-bottom: var(--px-20);
				h5{
					font-size: var(--px-14);
					font-weight: normal;
					color: var(--neutrals-900);
					border: 0!important;
                    margin-top: var(--px-29);
				}

				.motif-form-field{
					width: var(--px-290);
				}

				.motif-form-field:not(.motif-form-field-has-value),
				.motif-form-field:not(.motif-form-field-focused){
					.motif-label{
						margin-top: var(--px-6);
					}
				}
		
				.motif-form-field-has-value,
				.motif-form-field-focused{
					.motif-label{
						margin-top: var(--px-2)!important;
					}
				}
				
				
				.motif-input {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		//ticketend
	.messagelabel{
		h5{
			font-size: var(--px-14);
			font-weight: bold;
		}
	}

	.messagetitle{
		font-size: var(--px-14);
		font-weight: normal;
		.ellipses{	
			display: inline-flex;
		}
	}
	}

	.modal-footer {
		width: 100%;
		padding: var(--px-20);
		display: inline-flex;

		button {
			font-size: var(--px-14);
			padding: var(--px-12) var(--px-15);
			margin-right: var(--px-10);
			line-height: normal;
		}
	}
`;

function DeleteMessage(props) {
	const dispatch = useDispatch();

	const [ticketNumber, setTicketNumber] = useState('');
	const clearError = (isModelOpen) => {
		dispatch(clearErrors()).then(() => {
			dispatch(setIsModalOpen(isModelOpen));
		});
	};

	useEffect(() => {
		clearError(true);
	}, []);

	function handlerOnTicketEntered(e) {
		setTicketNumber(e.target.value);
	}

	return (
		<DeleteMessageWrapper>
			{props.isLoading ? (
				<Loader show fullscreen view="blockView" styleName="loadingstyle" />
			) : (
				<section className="deletewwrapper">
					<section className="delete">
						<section
							className="toastMessage"
							style={{
								display:
									props.errorMessage && props.errorMessage.length > 0
										? 'block'
										: 'none'
							}}
						>
							<Toast
								variant="error"
								onClose={() => props.clearErrorMessage()}
							>
								{props.errorMessage}
							</Toast>
						</section>
						<section className="wrapper">
							<section className="messagelabel" aria-label="message">
								<h5>{labels.messagingModalDeleteMessage}</h5>
							</section>

							<section className="messagetitle" aria-label="">
								<EllipsesControl
									content={
										props?.messageToDelete?.messageTitle
									}
									tooltip={
										props?.messageToDelete?.messageTitle
									}
									displayId={''}
									noOfLines={1}
									ellipsisClass=""
								/>
							</section>
							<section>
								<TicketField
									ticketNumber={ticketNumber}
									onTicketChanged={handlerOnTicketEntered}
								    />
							    </section>
							</section>
						</section>
					</section>
				)}
				<section className="modal-footer">
							<Button
								type="button"
								variant="primary-alt"
								disabled={ticketNumber.trim().length == 0 ? true : false}
								onClick={() => props.onDeleteClick(ticketNumber)}
							>
								{labels.messagingModalDeleteButton}
							</Button>
							<Button
								type="button"
								variant="secondary"
								onClick={() => props.onCancelClick()}
							>
								{labels.messagingModalDeleteCancel}
							</Button>
						</section>		
		</DeleteMessageWrapper>
	);
}

export default DeleteMessage;
