import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@ey/motif-react-wrapper/Button';
import { labels } from '../../../../util/uiconstants';
import Loader from '../../../Common/Loaders/loadingindicator';
import env from '../../../../util/env';
import { deleteTemplate } from '../../../../actions/admin/templatemanagementaction';
import styled from 'styled-components';
import TicketField from '../../common/ticketfield';
import { getLocalizedAPIError } from '../../common/util';
import Toast from '../../../Common/Toast/Toast';

const Deletetemplate = styled.section`
	.modal-footer {
		width: 100%;
		padding: var(--px-20);
		display: inline-flex;
		button {
			font-size: var(--px-14);
			padding: var(--px-12) var(--px-15);
			margin-right: var(--px-10);
			line-height: normal;
		}
	}

	.loadingstyle {
		height: var(--px-180) !important;
	}

	.deletetemplate {
		.message {
			padding: var(--px-10) var(--px-20);
		}

		//ticketstart
		.ticketdetail{
			margin: 0 var(--px-20) var(--px-20) var(--px-20);
			padding-bottom: var(--px-20);
			h5{
				font-size: var(--px-14);
				font-weight: normal;
				color: var(--neutrals-900);
				border: 0!important;
			}

			.motif-form-field{
				width: var(--px-290);
			}

			.motif-form-field:not(.motif-form-field-has-value),
			.motif-form-field:not(.motif-form-field-focused){
				.motif-label{
					margin-top: var(--px-6);
				}
			}

			.motif-form-field-has-value,
			.motif-form-field-focused{
				.motif-label{
					margin-top: var(--px-2)!important;
				}
			}
			
			
			.motif-input {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
		//ticketend

		.confirmationmessage {
			h5 {
				font-size: var(--px-14);
				font-weight: bold;
			}
		}

		.template {
			font-size: var(--px-14);
			font-weight: normal;
			margin-top: var(--px-20);
			h6 {
				font-size: var(--px-14);
				font-weight: normal;
				line-height: var(--px-18);
			}
		}
	}
`;

function TemplateDelete(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [ticketNumber, setTicketNumber] = useState('');
	const [isSubmitClicked, setIsSubmitClicked] = useState(false);
	const dispatch = useDispatch();

	const onTicketChanged = (e) => {
		setTicketNumber(e.target.value);
	}

	const onDeleteClick = () => {
		setIsSubmitClicked(true);
		if (ticketNumber.trim().length <= 0) {
			return;
		}

		let uriTicketNumber = encodeURIComponent(ticketNumber.trim());

		setIsLoading(true);
		let deleteTemplateUrl =
			env.getURL('serviceUrlV2') +
			'admin/engagementtemplates/' +
			props.deleteTemplateData?.copyRequestId +
			'?ticketNumber=' + uriTicketNumber;
		dispatch(deleteTemplate(deleteTemplateUrl))
			.then(() => {
				props.setIsTemplateDeleted(true);
			})
			.catch((e) => {
				setErrorMessage(getLocalizedAPIError(e));
			})
			.finally(() => {
				setIsLoading(false);
				setIsSubmitClicked(false);
				props.closeEditTemplateModal();
			});
	};

	return (
		<Deletetemplate>
			<section
				className="toastMessage"
				style={{
					display:
						errorMessage && errorMessage.length > 0
							? 'block'
							: 'none'
				}}
			>
				<Toast
					variant="error"
					onClose={() => setErrorMessage(false)}
				>
					{errorMessage}
				</Toast>
			</section>
			{isLoading ? (
				<Loader show view="blockView" styleName="loadingstyle" />
			) : (
				<section className="deletetemplate">
					<section className="message">
						<section className="confirmationmessage" aria-label="message">
							<h5>{labels.templateDeleteConfirmationMessage}</h5>
						</section>

						<section className="template name" aria-label="templatename">
							<h6>{props?.deleteTemplateData?.templateName}</h6>
						</section>
					</section>
					<TicketField
						ticketNumber={ticketNumber}
						onTicketChanged={onTicketChanged}
						showValidation={isSubmitClicked}
					/>
				</section>
			)}
			<section className="modal-footer">
				<Button
					type="button"
					variant="primary-alt"
					disabled={ticketNumber.trim().length <= 0 || isLoading}
					onClick={() => onDeleteClick()}
				>
					{labels.templateEditDelete}
				</Button>
				<Button
					type="button"
					variant="secondary"
					onClick={() => props.closeEditTemplateModal()}
					disabled={isLoading}
				>
					{labels.templateEditCancel}
				</Button>
			</section>
		</Deletetemplate>
	);
}

export default TemplateDelete;
