/**
 * @module CreateEditReviewCopy
 * Container Component CreateReviewCopy
 */

import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import styled from 'styled-components';
import {SVGICONS} from '@ey/icons';
import {font} from '@ey/styled-theme';
import env from '../../util/env';
import Button from '@ey/button';
import Loader from '@ey/loader';
import _ from 'lodash';
import {EYForm, FormTextInput, FormComboBox} from '@ey/form';
import {FormRadio} from '@ey/form';
import {Radio} from 'react-bootstrap';
import EllipsesControl from '@ey/ellipses-control';
import FormField from '@ey/motif-react-wrapper/FormField';
import Checkbox from '@ey/motif-react-wrapper/Checkbox';

import {
	labels,
	standardStrings,
	urls,
	currentResource,
	ERPUserType
} from '../../util/uiconstants';

import {EngagementSource} from '../../util/enumerations.js';

import * as QueryString from 'query-string';
import * as ArcFilesAction from '../../actions/engagementsaction';
import * as GenericAction from '../../actions/genericactions';
import * as ErrorActions from '../../actions/erroractions';
import * as CRPActions from '../../actions/crpactions';
import * as EYUsersActions from '../../actions/eyusersaction';

export class CreateEditReviewCopy extends React.Component {
	constructor(props) {
		super(props);

		const params = QueryString.parse(this.props.location.search);

		this.state = {
			engagementname: standardStrings.EMPTY,
			expiry: 90,
			txtExtUserEmail: standardStrings.EMPTY,
			externalMembersList: [],
			showArcFiles: false,
			selectedArcFileGuid: null,
			validationTriggerCounter: 0,
			isLoading: false,
			internalUserList: [],
			selectedUserList: [],
			txtSelInternalUser: null,
			isExtUserFound: true,
			isValidEmailExt: true,
			isDuplicateEntryExt: false,
			isDuplicateEntryInt: false,
			loginUserGui: standardStrings.EMPTY,
			isLoadingExternalUser: false,
			isLoadingInternalUser: false,
			addCount: 0,
			inputValue: '',
			openMenu: false,
			selectedOption: null,
			isUserSearch: false,
			isIntUserFound: true,
			engID: params.eid,
			fromTab: Number.parseInt(params.T),
			isInterimEng: JSON.parse(params.isInt) || false,
			isProtectedEngagement: true
		};

		this.mode = {
			activeEngagement: 1,
			archiveEngagement: 2,
			reviewEngagement: 3
		};

		let serviceUrl = env.getURL('serviceUrl') + '/';

		this.getCRPInProgressUrl =
			serviceUrl +
			urls.ENGAGEMENTS_URL +
			'/iscrpinprogress?id=' +
			this.state.engID +
			'&phase=crpcheckcopyinprogress';

		this.getArcFilesUrl =
			serviceUrl + urls.ENGAGEMENTS_URL + '/getarcfiles?id=' + this.state.engID;

		this.crpUrl = serviceUrl + urls.CRP_URL;
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps?.crp && nextProps?.crp?.data && this.state.fromTab === this.mode.reviewEngagement) {
			this.setState({expiry: nextProps.crp.data.expirationdays});
			let selUserList = Object.assign([], this.state.selectedUserList);
			let extUserLst = Object.assign([], this.state.externalMembersList);
			let isIntUser = ERPUserType.InternalUser;
			let engName = nextProps.crp.data.description;
			let engagementsourceid = nextProps.crp.data.engagementsourceid;
	
			nextProps.crp &&
			nextProps.crp?.collections &&
			nextProps.crp?.collections?.erpusers.map((item, index) => {
					let formatedDataItem = this.getFormattedUserData(item);
					Number.parseInt(item.data.erpusertypeid) === isIntUser
						? selUserList.push(formatedDataItem)
						: extUserLst.push(formatedDataItem);
				});
	
			this.setState({
				engagementname: engName,
				selectedUserList: selUserList,
				externalMembersList: extUserLst,
				isProtectedEngagement:
					engagementsourceid === EngagementSource.CeapV2Protected
			});
		  }
	}

	async componentDidMount() {
		await this.checkCRPPrerequisits();
		this.addLoginUserToInternalList();
	}

	checkCRPPrerequisits = async () => {
		this.setState({isLoading: true});

		try {
			// if new engagement then only make these checks
			// otherwise not needed
			if (this.state.fromTab === this.mode.activeEngagement) {
				await this.props.actions.getData(
					this.getCRPInProgressUrl,
					this.state.engID
				);

				// Check if CRP is already in progress for given eng.
				if (this.props.genericData === true) {
					this.handleError(5102);
					return;
				}
			}

			if (this.state.fromTab === this.mode.reviewEngagement) {
				await this.getCrpDetails();
			}

			this.setState({isLoading: false});
		} catch (ex) {
			console.log(ex);
			this.handleError(1504);
		}
	};

	getFormattedUserData = (userData) => {
		let data = userData.data;
		let item = {
			id: userData.id === undefined ? data.id : userData.id,
			data: {
				employeetype: data.employeetype,
				employmentstatus: data.employmentstatus,
				userfname: data.userfname || data.firstname,
				gui: data.gui,
				fullsamaccountname: data.fullsamaccountname,
				gpn: data.gpn,
				useremail: data.useremail || data.internetemailaddress,
				userlname: data.userlname || data.lastname,
				name: data.name,
				userprincipalname: data.userprincipalname,
				userlanguageid: data.userlanguageid || data.preferredlanguage,
				erpusertypeid: data.erpusertypeid
			}
		};
		return item;
	};

	getCrpDetails = async () => {
		try {
			await this.props.actions.getCRP(
				this.crpUrl + '?id=' + this.state.engID,
				this.state.engID
			);
		} catch (ex) {
			console.log(ex);
			this.handleError(1504);
			return;
		}
	};

	handleError = (errorCode) => {
		setTimeout(async () => {
			this.props.actions.raiseJSValidationError(errorCode);
		}, 200);

		this.handleCancel();
	};

	handleCancel = () => {
		this.props.actions.clearCRP();
		this.props.history.goBack();
	};

	updateState = (key, value) => {
		this.setState({[key]: value});
	};

	addLoginUserToInternalList = () => {
		let data = Object.assign([], this.props.userSettings.data);
		let userList = Object.assign([], this.state.selectedUserList);

		if (userList && data) {
			if (userList.filter((e) => e.data.gui === data.gui).length < 1) {
				let item = {
					id: data.id,
					data: {
						telephonenumber: data.userpreferredcontactphone,
						displayname: data.userfname + ' ' + data.userlname,
						employeetype: standardStrings.EMPTY,
						employmentstatus: 'A',
						userfname: data.userfname,
						gui: data.gui,
						fullsamaccountname: data.useremail,
						gpn: '',
						useremail: data.useremail,
						userlname: data.userlname,
						name: data.userfname + ' ' + data.userlname,
						userprincipalname: data.useremail,
						userlanguageid: data.userlanguageid,
						title: '',
						usertype: ERPUserType.InternalUser
					}
				};
				userList.push(item);
			}

			this.setState({selectedUserList: userList, loginUserGui: data.gui});
		}
	};

	handleSave = () => {
		if (this.isFormValid()) {
			if (
				this.state.fromTab === this.mode.activeEngagement ||
				this.state.fromTab === this.mode.archiveEngagement
			) {
				this.saveCRP();
			} else if (this.state.fromTab === this.mode.reviewEngagement) {
				this.updateCRP();
			}
		} else {
			this.setState((state) => ({
				validationTriggerCounter: state.validationTriggerCounter + 1
			}));
		}
	};
	
	isExpiryRequired = () => {
		if (this.state.fromTab === this.mode.reviewEngagement) {
			return false;
		} else {
			return true;
		}
	};

	isExpiryValid = () => {
		let isExpiryValid = false;
		const isReviewEngagementTab = this.state.fromTab === this.mode.reviewEngagement;
		if (isReviewEngagementTab) {
			isExpiryValid = true;
		} else if (!isReviewEngagementTab) {
			isExpiryValid = currentResource.ExternalReviewCopyExpiryDays.some(item => this.state.expiry == item.value);
		}
		return isExpiryValid;
	};

	isFormValid = () => {
		const isEngagementNameValid = this.state.engagementname.trim() != standardStrings.EMPTY;
		return isEngagementNameValid && this.isExpiryValid();
	};

	saveCRP = () => {
		this.setState({isLoading: true});
		let mdl = this.createCrpModel();
		this.props.actions
			.createCRP(this.crpUrl, mdl)
			.then(() => {
				this.handleCancel();
			})
			.catch((error) => {
				this.setState({isLoading: false});
			});
	};

	updateCRP = () => {
		this.setState({isLoading: true});
		let mdl = this.createCrpModel();
		this.props.actions
			.patchCRP(this.crpUrl + '?id=' + this.state.engID, mdl)
			.then(() => {
				this.handleCancel();
			})
			.catch((error) => {
				this.getCrpDetails();
				this.setState({isLoading: false});
			});
	};

	buildUserCollection = (membersCollection, reviewModel, isInternalUser) => {
		membersCollection.map((item, index) => {
			let data = item.data;
			if (item.id) {
				/*in case of edit */
				var useritem1 = {
					id: item.id,
					data: {
						gui: data.gui,
						useremail: data.useremail,
						userfname: data.userfname,
						userlname: data.userlname,
						userloginname: data.useremail,
						erpusertypeid: isInternalUser
							? ERPUserType.InternalUser
							: ERPUserType.ExternalUser
					}
				};
				reviewModel.collections.erpusers.push(useritem1);
			} else {
				var useritem = {
					id: '00000000-0000-0000-0000-000000000000',
					data: {
						gui: data.gui,
						useremail: data.useremail,
						userfname: data.userfname,
						userlname: data.userlname,
						userloginname: data.fullsamAccountName || data.useremail,
						erpusertypeid: isInternalUser
							? ERPUserType.InternalUser
							: ERPUserType.ExternalUser,
						employeetype: data.employeeType,
						employmentstatus: data.employmentstatus,
						gpn: data.gpn,
						userlanguageid: data.userlanguageid
					}
				};
				reviewModel.collections.erpusers.push(useritem);
			}
		});
	};

	createCrpModel = () => {
		let copyobjectCollection = [
			{
				id: 1,
				data: {
					copyobjectids: [13, 4, 5, 12, 7],
					copyobjectgrouptypeid: 1
				}
			},
			{
				id: 2,
				data: {
					copyobjectids: [1],
					copyobjectgrouptypeid: 6
				}
			},
			{
				id: 3,
				data: {
					copyobjectids: [14],
					copyobjectgrouptypeid: 6
				}
			},
			{
				id: 7,
				data: {
					copyobjectids: [17],
					copyobjectgrouptypeid: 8
				}
			},
			{
				id: 8,
				data: {
					copyobjectids: [16],
					copyobjectgrouptypeid: 8
				}
			},
			{
				id: 9,
				data: {
					copyobjectids: [18],
					copyobjectgrouptypeid: 8
				}
			},
			{
				id: 10,
				data: {
					copyobjectids: [15],
					copyobjectgrouptypeid: 8
				}
			},
			{
				id: 11,
				data: {
					copyobjectids: [19],
					copyobjectgrouptypeid: 9
				}
			},
			{
				id: 12,
				data: {
					copyobjectids: [20],
					copyobjectgrouptypeid: 10
				}
			},
			{
				id: 13,
				data: {
					copyobjectids: [21],
					copyobjectgrouptypeid: 11
				}
			},
			{
				id: 14,
				data: {
					copyobjectids: [22],
					copyobjectgrouptypeid: 12
				}
			},
			{
				id: 4,
				data: {
					copyobjectids: [2],
					copyobjectgrouptypeid: 7
				}
			},
			{
				id: 5,
				data: {
					copyobjectids: [3],
					copyobjectgrouptypeid: 7
				}
			},
			{
				id: 6,
				data: {
					copyobjectids: [14],
					copyobjectgrouptypeid: 7
				}
			}
		];

		let copyobjectCollectionForInterim = [
			{
				id: 1,
				data: {
					copyobjectids: [13, 4, 5, 12, 7],
					copyobjectgrouptypeid: 1
				}
			},
			{
				id: 2,
				data: {
					copyobjectids: [1],
					copyobjectgrouptypeid: 6
				}
			},
			{
				id: 3,
				data: {
					copyobjectids: [14],
					copyobjectgrouptypeid: 6
				}
			},
			{
				id: 7,
				data: {
					copyobjectids: [17],
					copyobjectgrouptypeid: 8
				}
			},
			{
				id: 9,
				data: {
					copyobjectids: [18],
					copyobjectgrouptypeid: 8
				}
			},
			{
				id: 10,
				data: {
					copyobjectids: [15],
					copyobjectgrouptypeid: 8
				}
			},
			{
				id: 11,
				data: {
					copyobjectids: [19],
					copyobjectgrouptypeid: 9
				}
			},
			{
				id: 12,
				data: {
					copyobjectids: [20],
					copyobjectgrouptypeid: 10
				}
			},
			{
				id: 13,
				data: {
					copyobjectids: [21],
					copyobjectgrouptypeid: 11
				}
			},
			{
				id: 14,
				data: {
					copyobjectids: [22],
					copyobjectgrouptypeid: 12
				}
			}
		];

		let model = {
			id: 0,
			data: {
				description: this.state.engagementname,
				expirationdays: this.state.expiry
					? Number.parseInt(this.state.expiry)
					: this.props.crp?.data?.expirationdays,
				sourceengagementId: this.state.engID,
				isactiveengagementreviewcopy:
					this.state.fromTab === this.mode.reviewEngagement
						? this.props.crp?.data?.isactiveengagementreviewcopy
							? 1
							: 0
						: this.state.fromTab === this.mode.activeEngagement
						? 1
						: 0,
				archivefileguid: this.state.selectedArcFileGuid,

				engagementsourceid: this.state.isProtectedEngagement
					? EngagementSource.CeapV2Protected
					: EngagementSource.CeapV2 // this will ne 9 or 10 based on checkbox
			},
			collections: {
				copyobjects: [],
				erpusers: []
			}
		};

		/* For external members list*/
		this.buildUserCollection(this.state.externalMembersList, model, false);

		/* For internal members list*/
		this.buildUserCollection(this.state.selectedUserList, model, true);

		/* Add copyobject collection*/
		if (this.isInterimEng) {
			/*Copyobject collection for Interim engagement*/

			model.collections.copyobjects = copyobjectCollectionForInterim;
		} else {
			model.collections.copyobjects = copyobjectCollection;
		}

		return model;
	};

	radioButtonClicked = (value) => {
		this.setState({selectedArcFileGuid: value});
	};

	onKeyDown = (e) => {
		if (
			!e.target.value ||
			e.target.value.length === 0 ||
			e.target.value.length > 255
		) {
			return;
		} else if (e.target.value.length >= 1 && e.keyCode === 13) {
			this.getInternalUsers(e.target.value);
		}
	};

	onInputChange = (inputValue, e) => {
		switch (e.action) {
			case 'input-change':
				this.setState({inputValue: inputValue});
				return;
			default:
				return;
		}
	};

	customFilter(option, searchText) {
		let userdata = option && option.data && option.data.data;
		if (
			userdata &&
			userdata.userfname.toLowerCase() +
				' ' +
				userdata.userfname.toLowerCase().includes(searchText.toLowerCase())
		) {
			return true;
		} else {
			return false;
		}
	}

	toggleDropdown(show) {
		this.setState({openMenu: show});
	}

	handleCheck = (checked) => {
		this.setState({isProtectedEngagement: checked});
	};

	render() {
		if (this.state.isLoading) {
			return <Loader view="fullscreen" />;
		}

		const {
			inputValue,
			openMenu,
			internalUserList,
			externalMembersList,
			selectedUserList,
			txtExtUserEmail,
			selectedOption,
			isUserSearch,
			isIntUserFound
		} = this.state;

		let showExpriationLabel =
			this.state.fromTab === this.mode.activeEngagement ||
			this.state.fromTab === this.mode.archiveEngagement
				? false
				: true;

		let daysList = currentResource.ExternalReviewCopyExpiryDays;
		let expirationdays =
			this.props.crp &&
			this.props.crp?.data &&
			this.props.crp?.data?.expirationdays;

		let isFirst = true;
		let arcfileRadioList = [];
		this.props.arcFiles &&
			this.props.arcFiles.attachments &&
			this.props.arcFiles.attachments.map((item, index) => {
				let firstName = item.submitteduserfirstname;
				let lastName = item.submitteduserlastname;

				arcfileRadioList.push(
					<Radio
						value={item.FileGUID}
						key={index}
						defaultChecked={isFirst}
						name="radio"
						onClick={() => {
							this.radioButtonClicked(item.FileGUID);
						}}
					>
						<section className="crparcfileusername">
							<EllipsesControl
								content={
									firstName && firstName.length > 0 ? (
										firstName + ' ' + lastName
									) : (
										<span>{labels.arcfileuserunavailable}</span>
									)
								}
								tooltip={
									firstName && firstName.length > 0 ? (
										firstName + ' ' + lastName
									) : (
										<span>{labels.arcfileuserunavailable}</span>
									)
								}
								isTooltipAvailable
							/>
						</section>
						<span>
							{item.UploadedDatetime.replace(' ', ' ' + labels.at + ' ')}
						</span>
					</Radio>
				);
				isFirst = false;
			});

		return (
			<CreateReviewCopyWrapper>
				<header>
					<h5 className="crpheader">
						{this.state.fromTab === this.mode.reviewEngagement
							? labels.crpEditHeader
							: labels.contextMenucrp}
					</h5>
					<h5 className="crpdescription">
						{this.state.fromTab === this.mode.reviewEngagement
							? labels.crpeditdescritpion
							: labels.crpdescription}
					</h5>
					{this.state.showArcFiles}
				</header>

				<form className="crpwrapper">
					<section className="crpholder">
						<EYForm
							id="form"
							onChange={this.updateState}
							onSubmit={(e) => {
								e.preventDefault();
							}}
							action="\"
							validationTriggerCounter={this.state.validationTriggerCounter}
						>
							<FormTextInput
								placeholder={labels.reviewengagementname}
								name="engagementname"
								value={this.state.engagementname}
								rules={[]}
								is_required
								help
								maxLength="255"
								onKeyDown={(e) => {
									if (e.keyCode === 13) {
										// enter key press
										// trigger save
										e.preventDefault();
									}
								}}
								errormessage={labels.isRequired.replace(
									'{0}',
									labels.reviewengagementname
								)}
							/>
							{this.state.showArcFiles ? (
								<section className="crparcfile">
									<section>
										<h5>{labels.selectarchiveheader} </h5>
									</section>

									<section className="crparcfileuserlist">
										<ul>
											<li className="crparcfileuserheader">
												<label className="submittedby">
													{labels.archivesubmittedby}
												</label>
												<label className="dateandtime">
													{labels.archivedateandtime}
												</label>
											</li>
											<li className="crparcfileuseritems">
												<FormRadio
													name="submittedByDetails"
													onChange={() => {}}
												>
													{arcfileRadioList}
												</FormRadio>
											</li>
										</ul>
									</section>
								</section>
							) : (
								<section />
							)}

							<FormComboBox
								id="expiry"
								name="expiry"
								value={
									this.state.fromTab !== this.mode.reviewEngagement
										? String(this.state.expiry)
										: ''
								}
								is_required={this.isExpiryRequired()}
								help
								placeholder={labels.expiry}
								options={daysList.map((item) => {
									return {
										value: String(item.value),
										label: item.label
									};
								})}
								errormessage={labels.isRequired.replace('{0}', labels.expiry)}
								clearable={false}
							/>
						</EYForm>
						{showExpriationLabel && expirationdays ? (
							<section className="expirydetail">
								<SVGICONS
									styleName="small"
									id=""
									name="alarm"
									hoverText="Expiry"
								/>
								{parseInt(expirationdays) < 0
									? labels.expired
									: labels.expiresIn + ' ' + expirationdays + ' ' + labels.days}
							</section>
						) : (
							<section />
						)}
						<section>
							{this.state.fromTab === this.mode.reviewEngagement ? (
								<section className="protected-engagement">
									<span className="motif-checkbox-label">
										{this.state.isProtectedEngagement ? (
											<span>
												<span>{labels.documentRrestriction}</span>{' '}
												<span className="on-off">{labels.on}</span>
												<span>{labels.protectedEngagementMessageOn}</span>
											</span>
										) : (
											<span>
												<span>{labels.documentRrestriction}</span>{' '}
												<span className="on-off">{labels.off}</span>
												<span>{labels.protectedEngagementMessageOff}</span>
											</span>
										)}
									</span>
								</section>
							) : (
								<FormField className="protected-engagement">
									<Checkbox
										id="protectedEngagement"
										name="protectedEngagement"
										disabled={this.state.fromTab === this.mode.reviewEngagement}
										checked={this.state.isProtectedEngagement}
										className="confirmation-checkbox"
										onChange={(event) => this.handleCheck(event.target.checked)}
									>
										{this.state.fromTab !== this.mode.reviewEngagement ? (
											labels.protectedEngagementMessage
										) : this.state.isProtectedEngagement ? (
											<span>
												<span>{labels.documentRrestriction}</span>{' '}
												<span className="on-off">{labels.on}</span>
												<span>{labels.protectedEngagementMessageOn}</span>
											</span>
										) : (
											<span>
												<span>{labels.documentRrestriction}</span>{' '}
												<span className="on-off">{labels.off}</span>
												<span>{labels.protectedEngagementMessageOff}</span>
											</span>
										)}
									</Checkbox>
								</FormField>
							)}
						</section>
					</section>
					<section className="crpbtn">
						<Button
							type="button"
							value="save"
							onClick={this.handleSave}
							buttonType="primary"
							name="formSaveButton"
							label={
								this.state.fromTab === this.mode.reviewEngagement
									? labels.save
									: labels.create
							}
						/>

						<Button
							type="button"
							value="cancel"
							onClick={this.handleCancel}
							buttonType="secondary"
							name="formCancelButton"
							label={labels.cancel}
						/>
					</section>
				</form>
			</CreateReviewCopyWrapper>
		);
	}
}

const mapStateToProps = (reduxStore) => {
	return {
		arcFiles: reduxStore.arcFiles,
		eyusers: reduxStore.eyusers,
		genericData: reduxStore.genericGet,
		crp: reduxStore.crp,
		userSettings: reduxStore.userSettings
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(
			Object.assign(
				{},
				ArcFilesAction,
				EYUsersActions,
				GenericAction,
				ErrorActions,
				CRPActions
			),
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreateEditReviewCopy);

const CreateReviewCopyWrapper = styled.section`
	height: calc(100vh - var(--px-65));
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: auto;

	& .mbrSrch {
		width: var(--px-400);
		display: inline-block;
		padding-top: 0;
		margin-bottom: 0;
	}

	& .loader {
		display: flex;
		align-items: center;
		margin: 0 50%;
	}

	.loaderstyle > section {
		margin: 0 auto;

		& > section {
			width: var(--px-10) !important;
			height: var(--px-10) !important;
			margin: var(--px-10) auto;
		}
	}

	& > header {
		width: 100%;

		& h5 {
			font-size: var(--px-20);
			font-weight: bold;
			margin: var(--px-11) auto;
			width: 100%;
			padding: 0 0 0 var(--px-40);
			max-width: 2048px;
		}

		& h5.crpdescription {
			font-size: var(--px-14);
			font-weight: normal;
		}
	}

	& .crpbtn {
		margin: var(--px-20) 0;

		& button {
			margin: 0 var(--px-20) 0 0;
		}
	}

	& .crpwrapper {
		margin: 0 auto;
		max-width: 2048px;
		width: 100%;
		padding: 0 var(--px-40);
		height: 100vh;

		& .form-group label {
			width: auto;
		}

		& .crpholder {
			background: var(--neutrals-00white);
			border: var(--px-1) solid var(--neutrals-300);
			box-shadow: 1px 2px 6px 0px var(--neutrals-300);
			padding: var(--px-40) var(--px-40) var(--px-29) var(--px-40);
			//min-height: calc(calc(var(--px-245) * -1) + 100vh);

			// select arc styles start

			& .crparcfile {
				margin-bottom: 1em;
				& h5 {
					font-size: var(--px-16);
					margin: 0 0 var(--px-10) 0;
					font-weight: bold;
				}
				& .crparcfileuserlist {
					& .form-group .control-label {
						display: none;
					}

					& .form-group .radio {
						margin: 0.5em 0;
						line-height: normal;
						padding-bottom: 0.5em;
					}

					.crparcfileuserheader {
						& label.submittedby {
							width: 42%;
							max-width: 42%;
							margin: 0 2% var(--px-5) 5%;
							display: inline-block;
						}
						& label.dateandtime {
							width: 33%;
							max-width: 35%;
							margin: 0 2% var(--px-5) 2%;
							display: inline-block;
						}
					}

					& li {
						list-style: none;

						& .radio {
							border-bottom: var(--px-1) solid
								var(--neutrals-200);

							&:last-child {
								border-bottom: 0;
							}

							& label > * {
								max-width: 42%;
								width: 42%;
								display: inline-block;
								margin: 0 2%;

								&.crparcfileusername .userunavailable {
									font-style: italic;
									color: var(--neutrals-700);
									width: 100%;
									display: inline-block;

									& div {
										width: 100%;
									}
								}

								&.crparcfileusername .ellipses {
									display: inline-flex;

									& div {
										width: 100%;
									}
								}
							}

							& label {
								padding: 0;
								width: 100%;
								font-weight: normal;
							}

							& input[type='radio'] {
								margin: 0;
								position: relative;
								vertical-align: middle;
								width: 3%;
							}
						}

						&.crparcfileuseritems,
						&.crparcfileuserheader {
							width: var(--px-720);
							font-size: var(--px-14);
						}

						&.crparcfileuseritems .form-group {
							display: inline-block;
						}

						&.crparcfileuserheader {
							border-bottom: var(--px-1) solid
								var(--neutrals-200);
							margin-bottom: 0;
							font-size: var(--px-15);
						}
					}
				}
			}
			// select arc styled end

			& div.form-group:nth-child(1) {
				& span.input-group {
					width: var(--px-720);
				}
			}

			& div.form-group:nth-child(3) {
				//display: inline;
			}

			& .expirydetail {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				width: var(--px-720);
				margin-top: calc(var(--px-70) * -1);
				margin-bottom: var(--px-45);
				font-size: var(--px-14);

				& svg {
					vertical-align: text-top;
					margin: 0 0.5em;
				}
			}
			.protected-engagement {
				.motif-checkbox-custom {
					width: var(--px-18);
					height: var(--px-18);
				}
				.motif-checkbox-label {
					.on-off {
						font-weight: bold;
					}
				}
			}

			& .memberholder {
				display: inline-block;
				width: 100%;
				margin-top: 2em;
			}

			//internal member react select css start

			& .internalmember .internaluser {
				display: inline-block;
				font-family: ${font(`primary`)};

				.react-select__control .react-select__placeholder {
					color: var(--neutrals-900);
					font-size: var(--px-14);
					top: var(--px-17);
				}

				.react-select__control.react-select__control--is-focused
					.react-select__placeholder {
					transition: 200ms ease all;
					-webkit-transition: 200ms ease all;
					top: var(--px-8);
					position: absolute;
					z-index: 2;
					font-size: var(--px-10);
					color: var(--neutrals-700);
				}

				.react-select__value-container {
					padding: 0;
				}

				.react-select__input input {
					width: var(--px-378) !important;
				}

				.react-select__control--menu-is-open,
				.react-select__control--menu-is-open:hover,
				.react-select__control--menu-is-open:visited,
				.react-select__control--is-focused {
					box-shadow: none;
					border: var(--px-1) solid var(--neutrals-300) !important;
				}

				.react-select__control {
					font-size: var(--px-14);
					margin-top: 0;
					border-radius: 0;
					width: var(--px-400);
					height: var(--px-48);
					box-shadow: none;
					padding: 0 var(--px-10);
					border: var(--px-1) solid var(--neutrals-300);
					background: var(--neutrals-00white);
				}

				.react-select__menu .react-select__menu-list {
					background: white;
					padding: 0;
					max-height: var(--px-285);
					cursor: pointer;
				}

				.react-select__menu .react-select__option--is-focused,
				.react-select__menu .react-select__option {
					line-height: var(--px-24) !important;
					padding: var(--px-6) var(--px-8);
					color: var(--neutrals-700);
					cursor: pointer;
				}

				.react-select__menu .react-select__option--is-focused:hover,
				.react-select__menu .react-select__option--is-focused {
					background: var(--neutrals-100);
				}

				.react-select__indicators {
					display: none;
				}

				.react-select__menu {
					width: var(--px-400);
					border: var(--px-1) solid var(--neutrals-300);
					border-radius: 0;
					font-size: var(--px-14);
					max-height: var(--px-290) !important;
					margin: 0;
				}
			}

			//internal member react select css end

			.externalmember label.control-label {
				margin: 0;
				padding-top: 0;
			}

			& .externalmember,
			& .internalmember {
				display: inline-flex;
				width: 50%;

				.memberholder_wrapper {
					display: block;
				}

				& h5 {
					font-size: var(--px-16);
					margin: 0 0 var(--px-5) 0;
					font-weight: bold;
				}

				& div.form-group {
					display: inline;
				}

				& .btn {
					margin-left: var(--px-15);
					height: var(--px-48);
					min-width: var(--px-80);
					margin: 0 0 0 var(--px-20);
					vertical-align: top;
				}

				& .result {
					display: block;
					margin-top: var(--px-10);
					font-size: var(--px-14);

					& .noresultfound {
						color: var(--neutrals-700);
						font-size: var(--px-12);
						display: inline-block;
						width: 100%;
						margin: var(--px-5) 0;
						line-height: normal;

						& svg {
							fill: var(--red-600);
							vertical-align: text-bottom;
							margin-right: 0.5em;
						}

						&.error {
							color: var(--neutrals-900);
						}
					}

					& > ul {
						display: inline-block;
						width: var(--px-500);

						& > li {
							list-style-type: none;
							border-bottom: var(--px-1) solid
								var(--neutrals-300);
							display: inline-block;
							width: 100%;
							margin: 0;
							padding: 0 var(--px-10);

							& .ellipses {
								width: 80%;
								max-width: 80%;
								vertical-align: middle;
								line-height: var(--px-42);

								& div {
									line-height: var(--px-42);
									vertical-align: middle;
								}
							}

							& span > svg {
								float: right;
								color: var(--neutrals-900);
								padding: 0;
								min-width: auto;
								text-decoration: none;
								height: var(--px-42);
								cursor: pointer;

								&:active {
									box-shadow: none;
								}
							}
						}
					}
				}
			}
		}

		// extenral member common form changes start
		& .form-title {
			border-bottom: var(--px-1) solid var(--neutrals-900);
			display: inline-block;
			width: 100%;
			margin-bottom: var(--px-30);

			& .form-group {
				margin: 0;
				padding: 0;
				display: inline;
			}

			& .form-group label.control-label {
				vertical-align: bottom;
				display: inline;
			}

			& > .form-group * {
				font-size: var(--px-16);
				line-height: normal;
				padding: 0;
				margin: 0;
			}

			& .form-control-static {
				float: right;
				line-height: var(--px-48);
				font-size: var(--px-14);
			}
		}

		& div.form-group {
			display: inline-flex;
			width: 100%;
			font-size: var(--px-14);
			height: var(--px-81);
			margin: 0 0 var(--px-13) 0;

			& .input-group .input-label {
				line-height: var(--px-20);
			}

			& span.input-group {
				width: var(--px-400);
				float: left;

				& input.form-control {
					margin: 0 var(--px-20) 0 0;
					vertical-align: middle;
					border: var(--px-1) solid var(--neutrals-300);
					font-size: var(--px-14);
				}
			}

			& > section {
				width: var(--px-400);
				float: left;

				& .select-label {
					line-height: normal;
					color: var(--neutrals-900);
				}

				& .Select-control {
					margin: 0 var(--px-20) 0 0;
					vertical-align: middle;
					border: var(--px-1) solid var(--neutrals-300);
					line-height: var(--px-46);
				}
			}

			& .select-value {
				width: var(--px-400);
				float: left;

				& .Select-control {
					border: var(--px-1) solid var(--neutrals-300);
				}
			}
		}

		// common form changes end
	}
`;
