import React from 'react';
import _ from 'lodash';
import TimelineSmallTooltip from '../Timeline/timelinetooltip_small';
import TimelineLargeTooltip from '../Timeline/timelinetooltip_large';
import styled from 'styled-components';

const Timelinetooltipwrapper = styled.section`
	font-size: var(--px-14);
	width: 100%;
	display: inline-block;
	max-height: 50vh;
	overflow-y: auto;
	padding-right: var(--px-10);
	& .timelinetooltip-contentwrapper {
		& .timelinetooltip-content {
			border-bottom: var(--px-1) solid var(--neutrals-200);
			padding: var(--px-12) var(--px-28) var(--px-12) var(--px-12);
			&:last-child {
				border-bottom: 0;
			}

			& .PSO-CRAdate {
				display: inline-block;
				color: var(--neutrals-700);
				font-weight: normal;
			}
		}
	}

	& .ellipses {
		font-size: var(--px-16);
		max-width: var(--px-350);
		width: 100%;
		display: inline-flex;
		margin-bottom: var(--px-2);
		display: block;
		color: var(--neutrals-700);
		& div {
			width: 100%;
			max-width: var(--px-365);
		}
	}

	& .task-status {
		font-size: var(--px-16);
		font-weight: normal;
		line-height: normal;
	}

	& .timeline-status {
		margin-top: var(--px-12);
	}

	& .timeline-status > section {
		display: inline-block;
		margin: 0 var(--px-8);
		font-weight: bold;

		&:first-child {
			margin-left: 0;
		}

		& .timeline-countstatus,
		& svg {
			margin: 0 var(--px-5) 0 0;
			vertical-align: middle;
		}
	}

	& .timeline-completion {
		font-weight: bold;
	}

	& .timeline-startenddate {
		color: var(--neutrals-700);
		width: 100%;
		float: left;
		font-weight: normal;
		margin: var(--px-11) 0;
		& > section {
			margin: 0 1.5em 0 0;
		}
	}

	& .timelinetooltip-uparrow {
		/* Stroke */
		&:before {
			content: '';
			display: block;
			position: absolute;
			left: var(--px-80);
			bottom: 100%;
			width: 0;
			height: 0;
			border: var(--px-10) solid transparent;
			border-bottom-color: var(--neutrals-700);
		}

		/* Fill */
		&:after {
			content: '';
			display: block;
			position: absolute;
			left: var(--px-81);
			bottom: 100%;
			width: 0;
			height: 0;
			border: var(--px-9) solid transparent;
			border-bottom-color: var(--neutrals-00white);
		}
	}

	& .timelinetooltip-downarrow {
		/* Stroke */
		&:before {
			content: '';
			display: block;
			position: absolute;
			left: var(--px-80);
			bottom: 100%;
			width: 0;
			height: 0;
			border: var(--px-10) solid transparent;
			border-bottom-color: var(--neutrals-700);
		}

		/* Fill */
		&:after {
			content: '';
			display: block;
			position: absolute;
			left: var(--px-81);
			bottom: 100%;
			width: 0;
			height: 0;
			border: var(--px-9) solid transparent;
			border-bottom-color: var(--neutrals-00white);
		}
	}
`;

class TimelineTooltip extends React.Component {
	constructor(props) {
		super(props);

		let options = {
			threshold: 0
		};

		// Hiding tooltip is using Intersection Observer API
		// https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
		// The implemented approach is a workaround.
		this.observer = new IntersectionObserver(this.handleIntersect, options);
	}

	componentDidMount() {
		this.startObserve();
	}

	componentWillUnmount() {
		this.stopObserve();
	}

	startObserve = () => {
		Array.from(document.querySelectorAll('.motif-tooltip-show')).forEach(
			(element) => {
				this.observer.observe(element);
			}
		);
	};

	stopObserve = () => {
		this.observer.disconnect();
	};

	handleIntersect = (entries, observer) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting === false) {
				if (entry.target.classList.contains('motif-tooltip-show')) {
					entry.target.parentElement.click(); // this will cause Motif timeline to hide tooltip
				}

				observer.unobserve(entry.target);
			}
		});
	};

	render() {
		return (
			<Timelinetooltipwrapper className="customScrollbar">
				<section className="timelinetooltip-contentwrapper">
					{this.props.tooltips &&
						this.props.tooltips.map((tooltip, index) =>
							tooltip.typeid === 1 ? (
								<TimelineLargeTooltip
									key={index}
									name={tooltip.name}
									openCount={tooltip.openCount}
									inprogressCount={tooltip.inprogressCount}
									reviewCount={tooltip.reviewCount}
									completedCount={tooltip.completedCount}
									startDate={tooltip.startDate}
									endDate={tooltip.endDate}
									progress={tooltip.progress}
								/>
							) : (
								<TimelineSmallTooltip
									key={index}
									name={tooltip.name}
									date={tooltip.date}
								/>
							)
						)}
				</section>
			</Timelinetooltipwrapper>
		);
	}
}

export default TimelineTooltip;
