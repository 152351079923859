import { displayGroupings, settingsPreferences } from '../../util/uiconstants';
import { useHistory, withRouter } from 'react-router-dom';

import Button from '@ey/motif-react-wrapper/Button';
import React from 'react';
import { SVGICONS } from '@ey/icons';
import { labels } from '../../util/uiconstants';
import { parse } from 'query-string';
import styled from 'styled-components';
import { updateGroupPrefUserSettings } from '../../actions/usersettingsaction';
import { useDispatch } from 'react-redux';
import ContentSwitcher from '@ey/motif-react-wrapper/ContentSwitcher';

const Toplevelcss = styled.div`
	float: right;
	margin-top: calc(var(--px-6) * -1);
	.motif-button.workspaceview_toggle {
		width: var(--px-40);
		padding: 0;
		//background: var(--neutrals-00white);
		//height: var(--px-32);
		& svg {
			height: var(--px-20);
			width: var(--px-20);
			//vertical-align: text-top;
		}

		//&:hover {
		//	fill: var(--neutrals-00white);
		//	background: var(--neutrals-900);
		//	& > svg {
		//		fill: var(--neutrals-00white);
		//	}
		//}

		//&.active {
		//	background: var(--neutrals-900);
		//	fill: var(--neutrals-00white);
		//	& > svg {
		//		fill: var(--neutrals-00white);
		//	}
		//}
	}
`;

const EngagementGrouping = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { location } = props;

	const displayGrouping = () => {
		const params = parse(location.search);
		return params.groupby ? displayGroupings.MODULE : displayGroupings.LIST;
	};

	let dynClassList =
		'' + displayGrouping() === displayGroupings.LIST
			? 'workspaceview_toggle active'
			: 'workspaceview_toggle';
	let dynClassModule =
		'' + displayGrouping() === displayGroupings.MODULE
			? 'workspaceview_toggle active'
			: 'workspaceview_toggle';

	const updateGroupPreferenceUserSettings = (settingPreference) => {
		dispatch(updateGroupPrefUserSettings(settingPreference));
		history.push({
			pathname: history.location.pathname,
			search: settingPreference == 'engagements' ? '' : '?groupby=clients'
		});
		props.resetPager();
	};

	return (
		<Toplevelcss className="workspaces_view">
			<ContentSwitcher>
				<Button
					className={dynClassList}
					name="view_list"
					//variant="primary"
					onClick={(e) => {
						updateGroupPreferenceUserSettings(settingsPreferences.EngagementList);
					}}
				>
					<span title={labels.engagementslist}>
						<SVGICONS
							className="view_list"
							hoverText={labels.engagementslist}
							name="view_list"
						/>
					</span>
				</Button>
				<Button
					className={dynClassModule}
					name="view_module"
					//variant="secondary"
					onClick={(e) => {
						updateGroupPreferenceUserSettings(settingsPreferences.WorkspaceList);
					}}
				>
					<span title={labels.workspacelist}>
						<SVGICONS
							className="view_module"
							hoverText={labels.workspacelist}
							name="view_module"
						/>
					</span>
				</Button>
			</ContentSwitcher>
		</Toplevelcss>
	);
};

export default withRouter(EngagementGrouping);
