import React from 'react';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { labels, gaRoleTypeConstants } from '../../util/uiconstants';
import Icon from '@ey/motif-react-wrapper/Icon';
import { contentIcLink24px } from '@ey/motif-react-wrapper/assets/icons/index';

const WorkspaceGroupAudit = (props) => {
	return (
		<>
			{(props.workspace && props.workspace.workspacedesc ? (
				<section
					id="workspacedesc"
					className="workspace-name"
					title={props.workspace.workspacedesc}
				>
					{' |  ' + props.workspace.workspacedesc}
				</section>
			) : (
				''
			)) || <Skeleton width={250} height={20} />}

			{props.engagement &&
				props.engagement.garoletypeid == gaRoleTypeConstants.Primary ? (
				<section
					className="engagement-type"
					title={labels.primaryEngagementLabel}
				>
					{' |  ' + labels.primaryEngagementLabel}
				</section>
			) : null}

			{props.engagement &&
				props.engagement.garoletypeid == gaRoleTypeConstants.Component ? (
				<>
					<section
						className="engagement-type"
						title={labels.componentEngagementLabel}
					>
						{' |  ' + labels.componentEngagementLabel}
					</section>
					{props.engagement.islinked ? (
						<>
							<section className="engagement-typelinked">
								<Icon src={contentIcLink24px} />
								<span
									className="engagementtype-linkedtxt"
									title={labels.linkedLabel}
								>
									{labels.linkedLabel}
								</span>
							</section>
						</>
					) : null}
				</>
			) : null}

			{props.engagement &&
				props.engagement.garoletypeid == gaRoleTypeConstants.Region ? (
				<>
					<section
						className="engagement-type"
						title={labels.regionalEngagementLabel}
					>
						{' |  ' + labels.regionalEngagementLabel}
					</section>
					{props.engagement.islinked ? (
						<>
							<section className="engagement-typelinked">
								<Icon src={contentIcLink24px} />
								<span title={labels.linkedLabel}>{labels.linkedLabel}</span>
							</section>
						</>
					) : null}
				</>
			) : null}
		</>
	);
};

export default WorkspaceGroupAudit;
