import React, { useState, useEffect } from 'react';
import Icon from '@ey/motif-react-wrapper/Icon';
import { imageIcTimer24px } from '@ey/motif-react-wrapper/assets/icons/index';
import moment from 'moment';
import { labels } from '../../util/uiconstants';
import styled from 'styled-components';

const ExpiryDateLabel = (props) => {
	const [expiryDays, setExpiryDays] = useState(-1);

	const getExpiryDatesCount = (expiryDate) => {
		if (expiryDate) {
			let startDate = moment(expiryDate.split('T')[0]).startOf('day');
			let currentDate = moment(new Date()).startOf('day');
			let diff = startDate.diff(currentDate, 'days');
			setExpiryDays(diff);
			if (diff > -1) {
				props.setHasCountdown(true);
			}
		}
	};

	useEffect(() => {
		getExpiryDatesCount(props.expiryDate);
	}, []);

	return (
		<ExpiryDateLabelStyle className="restore-expiry">
			{expiryDays > -1 && (
				<div className="expiry-label">
					<Icon src={imageIcTimer24px} />
					<span>
						{expiryDays === 0 || expiryDays === 1
							? labels.expirydatetodaytomorrowlabel
							: labels.expirydatelabel}
					</span>
					<span className="expiry-days-label">
						{expiryDays === 0
							? ` ${labels.today}.`
							: expiryDays === 1
								? ` ${labels.tomorrow}.`
								: ` ${expiryDays} ${labels.days}.`}
					</span>
				</div>
			)}
		</ExpiryDateLabelStyle>
	);
};

export default ExpiryDateLabel;

const ExpiryDateLabelStyle = styled.section`
	.expiry-label {
		line-height: normal;
		font-size: var(--px-14);
		.motif-icon {
			svg {
				width: var(--px-21);
				height: var(--px-21);
				vertical-align: sub;
				margin-right: var(--px-5);
			}
		}
		.expiry-days-label {
			font-weight: bold;
		}
	}
`;
