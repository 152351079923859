/**
 * @module Workspaces
 * Container Component Workspaces
 */

import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter, useHistory} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment';
import Button from '@ey/button';
import {
	urls,
	labels,
	apiPhases,
	dashboardConstants,
	standardStrings,
	dateFormatsListItems,
	dateFormats,
	permissionGroups
} from '../../util/uiconstants';
import {
	IndependenceStatus,
	EngagementAccessType
} from '../../util/enumerations';
import EllipsesControl from '@ey/ellipses-control';
import ModalControl from '@ey/modal-control';
import env from '../../util/env';
import ViewAccessList from '../ViewAccess/viewaccesslist';
import {postRequestAccess} from '../../actions/requestaccessaction';
import {setArchivedEngagementDetailsToRestore} from '../../actions/restoreengagementaction';
import {setRollForwardEngagementData} from '../../actions/rollforwardengagementaction';
import Loader from '@ey/loader';
import {lightColor} from '@ey/styled-theme';
import {MotifIcon} from '@ey-xd/motif-react';
import {actionIcInfoOutline24px} from '@ey-xd/motif-react/assets/icons';

const ArchiveEngagementItem = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const {clientId, clientName, workspace, workspaceID, id} = props;
	const {
		incountryuri,
		workspacecountryid,
		engagementdesc,
		engagementversion,
		phase,
		archivalrequestdate,
		haschildinprogress,
		independencestatusid,
		ispostarchiveaddition,
		isindependencetemplateavailable
	} = props.data;

	const userPermissions = useSelector((state) => state.userPermissions);

	const independenceStatusesIsEngagementNotAccessible = [
		IndependenceStatus.Incomplete,
		IndependenceStatus.MatterIdentifiedPendingAction,
		IndependenceStatus.MatterResolvedDeniedAccess,
		IndependenceStatus.Restored,
		IndependenceStatus.Override,
		IndependenceStatus.PriorNoMattersIdentified,
		IndependenceStatus.PriorMatterResolvedGrantedAccess,
		IndependenceStatus.PriorMatterResolvedDeniedAccess,
		IndependenceStatus.PriorMatterIdentifiedPendingAction
	];

	const [isLoading, setIsLoading] = useState(false);
	const [showAccessListModal, setShowAccessListModal] = useState(false);
	const [enguserstatus, setEnguserstatus] = useState(props.data.enguserstatus);
	let archiveDate = moment
		.utc(archivalrequestdate)
		.format(dateFormatsListItems[dateFormats].label);
	const isAllArchiveView = phase === apiPhases.ALLARCHIVES;

	const routeToManageAccess = () => {
		const urlToManageAccessPage =
			incountryuri +
			'/v1redirect/index?' +
			'countryid=' +
			workspacecountryid +
			'&engagementid=' +
			id +
			'&engagementdesc=' +
			encodeURIComponent(engagementdesc.substring(0, 50)) +
			'&clientid=' +
			clientId +
			'&clientname=' +
			encodeURIComponent(clientName) +
			'&workspacedesc=' +
			encodeURIComponent(workspace.workspacedesc.trim()) +
			'&workspaceid=' +
			workspaceID +
			'&engagementversion=' +
			Math.floor(parseInt(engagementversion)) +
			'&engagementversionfull=' +
			engagementversion +
			'&userPrefLang=en-us&grantarchiveaccess=true';

		window.location.href = urlToManageAccessPage;
	};

	const routeToRollforward = async () => {
		const engagementDetails = {
			state: {
				category: 'rollforward',
				oldPath: '/archives',
				engagementName: engagementdesc,
				engagementId: id
			}
		};
		await dispatch(setRollForwardEngagementData(engagementDetails));
		history.push('/rollforward');
	};

	const routeToRestore = async () => {
		const engagementDetails = {
			state: {
				category: 'restore',
				oldPath: '/archives',
				engagementName: engagementdesc,
				engagementId: id,
				workspaceId: workspaceID,
				restoreType: isGlobalRestoreApplicable() ? 2 : 1
			}
		};
		await dispatch(setArchivedEngagementDetailsToRestore(engagementDetails));
		history.push('/restore');
	};

	const checkUserIndependeceStatus = () => {
		return !(
			isindependencetemplateavailable &&
			_.includes(
				independenceStatusesIsEngagementNotAccessible,
				independencestatusid
			) &&
			!props.data.engagementaccesstypeid ===
				EngagementAccessType.IndependenceOnly
		);
	};

	/**
	 * check user has archive access request
	 * MyArchives- default will enable Rollforward,Restore,Manage Access,view access list
	 * All Archives-
	 * Case 1: if user has access(EngagementUserStatus=1) then will enable Rollforward,Restore,Manage Access,view access list
	 * Case 2: if user has noaccess then will enable viewaccesslist and Request Access
	 * Case 3: if user has requested access then will enable only viewaccesslist
	 */
	const checkUserArchiveAccessRequest = () => {
		let hasArchiveAccessRequest = false;

		if (
			props.data.engagementaccesstypeid ===
			EngagementAccessType.IndependenceOnly
		) {
			return false;
		}

		if (
			enguserstatus === dashboardConstants.ENGAGEMENT_STATUS_ACTIVE &&
			isindependencetemplateavailable &&
			_.includes(
				independenceStatusesIsEngagementNotAccessible,
				independencestatusid
			) &&
			!ispostarchiveaddition
		) {
			return false;
		} else if (
			enguserstatus === dashboardConstants.ENGAGEMENT_ARCHIVAL_REQUESTED
		) {
			return false;
		}

		hasArchiveAccessRequest =
			enguserstatus === dashboardConstants.ENGAGEMENT_STATUS_ACTIVE
				? true
				: phase === apiPhases.ALLARCHIVES &&
				  (enguserstatus === dashboardConstants.ENGAGEMENT_ARCHIVAL_NOACCESS ||
						enguserstatus === dashboardConstants.ENGAGEMENT_ARCHIVAL_REJECTED)
				? false
				: false;

		return hasArchiveAccessRequest;
	};

	const setArchivalRequestStatusMessage = () => {
		switch (enguserstatus) {
			case dashboardConstants.ENGAGEMENT_ARCHIVAL_REQUESTED:
				return <span className="access-label"> {labels.accessrequested} </span>;
			case dashboardConstants.ENGAGEMENT_ARCHIVAL_NOACCESS:
				return <span className="access-label"> {labels.noaccess} </span>;
			case dashboardConstants.ENGAGEMENT_ARCHIVAL_REJECTED:
				return <span className="access-label"> {labels.accessrejected} </span>;
			case dashboardConstants.ENGAGEMENT_STATUS_ACTIVE:
				if (
					props.data.engagementaccesstypeid ===
					EngagementAccessType.IndependenceOnly
				) {
					return standardStrings.EMPTY;
				}
				if (
					isindependencetemplateavailable &&
					_.includes(
						independenceStatusesIsEngagementNotAccessible,
						independencestatusid
					) &&
					!ispostarchiveaddition
				) {
					return <span className="access-label"> {labels.noaccess} </span>;
				}
				return standardStrings.EMPTY;
			default:
				return standardStrings.EMPTY;
		}
	};

	/**
	 * show request to access link
	 * All Archives-
	 * Case 1: if user has noaccess then will enable Request Access
	 * Case 2: if user has requested access then will disable Request Access
	 */
	const showArchiveAccessRequest = () => {
		if (
			props.data.engagementaccesstypeid ===
			EngagementAccessType.IndependenceOnly
		) {
			return false;
		}

		if (
			enguserstatus === dashboardConstants.ENGAGEMENT_STATUS_ACTIVE &&
			isindependencetemplateavailable &&
			_.includes(
				independenceStatusesIsEngagementNotAccessible,
				independencestatusid
			) &&
			!ispostarchiveaddition
		) {
			return true;
		} else if (
			enguserstatus === dashboardConstants.ENGAGEMENT_ARCHIVAL_REQUESTED
		) {
			return false;
		}

		return phase === apiPhases.ALLARCHIVES
			? enguserstatus === dashboardConstants.ENGAGEMENT_ARCHIVAL_NOACCESS
				? true
				: enguserstatus === dashboardConstants.ENGAGEMENT_ARCHIVAL_REJECTED
				? false
				: false
			: false;
	};

	const requestAccessToEngagement = async () => {
		let url = env.getURL('serviceUrl') + '/' + urls.ENGAGEMENTUSERS_URL;
		const model = {
			id: id,
			engagementUserStatusId: dashboardConstants.REQUEST_FOR_ARCHIVE_ACCESS
		};
		const requestedEngagements = {
			collections: {
				engagements: [{data: model, id: id}]
			},
			data: {
				countryid: workspacecountryid,
				operationType: 'requestaccess',
				workspaceId: workspaceID,
				clientid: clientId
			}
		};
		setIsLoading(true);

		try {
			await dispatch(postRequestAccess(url, requestedEngagements));
			setIsLoading(false);
			setEnguserstatus(dashboardConstants.ENGAGEMENT_ARCHIVAL_REQUESTED);
		} finally {
			setIsLoading(false);
		}
	};

	const loadViewAccessList = () => {
		setShowAccessListModal(true);
	};

	const closeAccessListModal = () => {
		setShowAccessListModal(false);
	};

	const isGlobalRestoreApplicable = () => {
		let perm = userPermissions && userPermissions.userpermissions;

		if (perm) {
			var item = perm.find(
				(x) => x.groupname === permissionGroups.Global_RestoreException
			);
			return item != null ? true : false;
		}
	};

	//Render Modal Component to Show View access list
	const renderModalComponent = () => {
		return (
			<section>
				<ModalControl
					title={labels.accesslist}
					closeTitle={labels.close}
					modalContainerClass="archiveaccesslist-modal"
					onHide={closeAccessListModal}
					show={showAccessListModal}
					showButtons={false}
					focusTrapActive={false}
				>
					<ViewAccessList engagementName={engagementdesc} engagementId={id} />
				</ModalControl>
			</section>
		);
	};

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<EngagementDetails>
					<section>
						<>
							<section className="eng-leftdetail">
								<h4>
									<EllipsesControl
										id="engagementname"
										content={engagementdesc}
										tooltip={engagementdesc}
										isTooltipAvailable
									/>
								</h4>
								<section className="engagementid">
									{labels.id}: {id || <Skeleton width={250} height={20} />}
								</section>
								{isAllArchiveView || !checkUserArchiveAccessRequest()
									? setArchivalRequestStatusMessage()
									: null}
								<section className="archiveinfo">
									{labels.archived}{' '}
									{archiveDate || <Skeleton width={250} height={20} />}
								</section>
							</section>
							<section className="eng-rightdetail">
								{checkUserArchiveAccessRequest() ? (
									<span>
										{!haschildinprogress &&
										props.data.engagementaccesstypeid !==
											EngagementAccessType.IndependenceOnly ? (
											<span>
												<Button
													id="rollforward"
													name="rollforward"
													value={labels.rollforward}
													buttonType="link"
													label={labels.rollforward}
													onClick={routeToRollforward}
												/>
												<Button
													id="restore"
													name="restore"
													value={labels.restore}
													buttonType="link"
													label={labels.restore}
													onClick={routeToRestore}
												/>
											</span>
										) : null}

										{checkUserIndependeceStatus() ? (
											<Button
												id="manageaccess"
												name="manageaccess"
												value={labels.manageaccess}
												buttonType="link"
												onClick={routeToManageAccess}
												label={labels.manageaccess}
											/>
										) : null}
									</span>
								) : null}
								{showArchiveAccessRequest() ? (
									<Button
										id="requestaccess"
										name="requestaccess"
										value={labels.requestaccess}
										buttonType="link"
										label={labels.requestaccess}
										onClick={requestAccessToEngagement}
									/>
								) : null}
								<Button
									id="viewaccesslist"
									name="viewaccesslist"
									value={labels.viewaccesslist}
									buttonType="link"
									onClick={loadViewAccessList}
									label={labels.viewaccesslist}
								/>
								{!haschildinprogress ? null : (
									<span className="engagementinprogress">
										{labels.childinprogress}
									</span>
								)}
							</section>
						</>
					</section>
					{props.data.engagementaccesstypeid ===
					EngagementAccessType.IndependenceOnly ? (
						<section className="noaccess-independenceonly-info">
							<MotifIcon src={actionIcInfoOutline24px} />
							<span>{labels.independenceOnlyNoAccessMessage}</span>
						</section>
					) : null}
					{renderModalComponent()}
				</EngagementDetails>
			)}
		</>
	);
};

export default withRouter(ArchiveEngagementItem);

const WSEdit = styled.div`
	flex-grow: 1;
	display: inline-block;
	float: right;
	margin-right: var(--px-10);
	line-height: normal;
	margin-top: var(--px-18);
`;

const EngagementDetails = styled.section`
	width: 100%;
	display: inline-block;
	background: var(--neutrals-100);
	border-bottom: var(--px-1) solid var(--neutrals-300);
	padding-left: var(--px-60);
	vertical-align: super;
	& .eng-leftdetail {
		width: 50%;
		float: left;

		& svg {
			vertical-align: middle;
			cursor: pointer;
			fill: var(--neutrals-700);
		}
	}

	& .eng-rightdetail {
		width: 50%;
		float: right;
		font-weight: bold;
		line-height: var(--px-60);

		& .engagementinprogress {
			background-color: var(--brand-500);
			color: var(--neutrals-900);
			margin: var(--px-17) var(--px-20) 0 0;
			float: right;
			line-height: normal;
			padding: var(--px-4);
		}

		& a.archive-links,
		& a.archive-links:hover {
			margin: 0;
			text-decoration: underline;
			line-height: normal;
			padding: 0;
		}

		& .btn,
		& .btn > * {
			background: transparent;
			color: var(--neutrals-900);
			text-decoration: none;
			font-weight: bold;
			margin: 0;

			&:focus,
			&:active {
				box-shadow: none;
				background: transparent;
			}

			&:hover {
				background: transparent;
				text-decoration: underline;
				cursor: pointer;
			}
		}
	}

	& h4 {
		display: inline-block;
		font-size: var(--px-14);
		line-height: normal;
		// max-width: 75%;
		// overflow: hidden;
		// text-overflow: ellipsis;
		// white-space: nowrap;
		vertical-align: top;
		font-weight: bold;

		& .ellipses {
			cursor: pointer;
			overflow: visible;
			color: var(--neutrals-900);
			& div {
				max-width: var(--px-360);
			}
		}
	}

	& .engagementid {
		display: inline-block;
		font-size: var(--px-14);
		line-height: normal;
		margin: 0 var(--px-50) 0 var(--px-10);
		color: var(--neutrals-700);
		vertical-align: sub;
		margin-top: var(--px-10);
		@media screen and (max-width: 1366px) {
			margin-top: var(--px-13);
		}
	}

	& .archiveinfo {
		display: block;
		font-size: var(--px-14);
		color: var(--neutrals-700);
		vertical-align: middle;
		line-height: normal;
	}

	& .anchor {
		color: var(--neutrals-300);
	}
	.access-label {
		margin-left: var(--px-10);
		padding: var(--px-5) var(--px-10) var(--px-7) var(--px-8);
		background: var(--neutrals-900);
		color: var(--neutrals-00white);
		line-height: var(--px-18);
		font-size: var(--px-12);
		vertical-align: bottom;
	}
	.noaccess-independenceonly-info {
		clear: both;
		font-size: var(--px-14);
		font-weight: 300;
		padding-top: var(--px-10);
		color: var(--neutrals-700);
		.motif-icon {
			margin-right: var(--px-5);\
			svg {
				width: var(--px-20);
				height: var(--px-20);
				vertical-align: sub;
			}
		}
	}
`;
