import React from 'react';
import styled from 'styled-components';
import WorkspaceList from '../Workspaces/workspacelist';
import EllipsesControl from '@ey/ellipses-control';
import {labels} from '../../util/uiconstants';

const Clientwrapper = styled.section`
	padding: 0;
	color:var(--neutrals-900);
	margin: 0 0 var(--px-20) 0;
	border: var(--border-width-small) solid var(--neutrals-200);
    border-radius: var(--border-radius-medium);
	
	& > h6{
		font-size:var(--px-18);
		padding-left:var(--px-20);
		border-bottom:var(--border-width-small) solid var(--neutrals-200);
		display:inline-block;
		line-height:normal;
		width:100%;
		height:auto;
		float:left;
		margin-bottom:var(--px-1);
		font-weight:bold;

		& .ellipses{
			display:inline-flex;
			margin:var(--px-20) 0;
			overflow:visible;
			color:var(--neutrals-900);
		}

		& .ellipses div{
			display:inline-block;
			cursor:pointer;
		}
	}

	& > .ellipses{
		border-bottom:var(--border-width-small) solid var(--neutrals-200);
		padding-left:var(--px-10);
		width:100%;
		max-width:100%;
	}

	& ul.Workspaceholder {
		padding:0;
		margin:0;
		display:inline-block;
		width:100%;

		& .eng-list.workspacelist-view {		
			& li:last-child .eng-listwrapper {
				border-bottom: none;
			}
			& .eng-listwrapper{
				padding: var(--px-10);
				margin: 0;
				border-bottom:var(--border-width-small) solid var(--neutrals-200);

				& .eng-listholder{			
					padding:0;	
				}
			}				
		}

		& .eng-list > li{		
			margin:0;
			padding:0;
			list-style:none;

			& > section{
				padding:var(--px-10) 0 var(--px-10) var(--px-100);
				width:100%;
				float:left;
			}
		}

		& .workspace-list{
			border-bottom:var(--border-width-small) solid var(--neutrals-200);
			padding-left:var(--px-40);
			display:inline-block;
			width:100%;

			& .workspaceedit{
				flex-grow: 1;
				display: inline-block;
				float: right;
				margin: var(--px-20) var(--px-20) 0 0;
			}

			& .exp-col-icon{
				float:left;
			}

			& .ellipses{
				display:block;	
				cursor:pointer;
				overflow:visible;
				color:var(--neutrals-900);
				& div{
					vertical-align:text-top;
				}
			}

			& > span svg{
				vertical-align:middle;
				cursor:pointer;
				margin:0 var(--px-10) 0 0;
			}

			& h6{
				font-size:var(--px-16);
				margin:var(--px-20) 0;
				display:inline-block;
				line-height:normal;
				max-width:90%;
				width:90%;
				font-weight:bold;			
				vertical-align:middle;
			}
		}

	}
`;

const ClientItem = (props) => {
	let clientName = props.data && props.data.clientname;
	return (
		<Clientwrapper>
			<h6>
				<EllipsesControl
					id="clientname"
					content={
						clientName === undefined || clientName === '!!!'
							? labels.invalidclientcode
							: clientName
					}
					tooltip={
						clientName === undefined || clientName === '!!!'
							? labels.invalidclientcode
							: clientName
					}
					isTooltipAvailable
				/>
			</h6>
			<WorkspaceList
				workspaces={props.collections.workspaces}
				clientId={props.id}
				clientName={props.data.clientname}
				clientCode={props.data.clientcode}
				refreshDashboard={props.refreshDashboard}
				archiveretryshortcutkey={props.archiveretryshortcutkey}
				archivedEngagementList={props.archivedEngagementList}
				isReviewWorksapces={props.isReviewWorksapces}
			/>
		</Clientwrapper>
	);
};

export default ClientItem;
