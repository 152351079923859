import React, {useEffect, useState} from 'react';
import {
	labels,
	engagementV2PageSizeOptions,
	engagementV2DefaultSkip,
	tabTypes,
	currentResource,
	engagementFilterTypes
} from '../../../util/uiconstants';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Pagination from '@ey/motif-react-wrapper/Pagination';
import PaginationSelect from '@ey/motif-react-wrapper/PaginationSelect';
import PaginationSelectItem from '@ey/motif-react-wrapper/PaginationSelectItem';
import FormField from '@ey/motif-react-wrapper/FormField';
import Search from '@ey/motif-react-wrapper/Search';
import Card from '@ey/motif-react-wrapper/Card';
import EngagementList from '../EngagementsV2/EngagementList';
import {getEngagementsV2} from '../../../actions/engagementsaction';
import styled from 'styled-components';
import FilterEngagements from '../FilterEngagements/FilterEngagements';
import Loader from '../../Common/Loaders/loadingindicator';
import {searchEngagementState} from '../../../actions/dashboardactions';
import {setToastError} from '../../../actions/engagementerroraction';
import Utility from '../../../util/utils';

const PortfolioView = (props) => {
	const {
		fullname,
		activeFilter,
		defaultTop,
		defaultSkip,
		isReviewEngagementAvailable
	} = props;

	const history = useHistory();
	const dispatch = useDispatch();
	const myEngagementsV2 = useSelector((store) => store.myEngagementsV2);
	const searchText = useSelector((store) => store.searchEngagementState || '');

	const checkActiveFilter = () => {
		switch (activeFilter) {
			case tabTypes.favoritesv2Filter:
				return engagementFilterTypes.MY_FAVORITES;
			case tabTypes.reviewengagementsv2Filter:
				return engagementFilterTypes.REVIEW_ENGAGEMENTS;
			default:
				return engagementFilterTypes.ALL;
		}
	};

	const [displayedEngagements, setDisplayedEngagements] = useState();
	const [skip, setSkip] = useState(defaultSkip);
	const [top, setTop] = useState(defaultTop);
	const [engagementType, setEngagmentType] = useState(() => checkActiveFilter());

	const [searchExpression, setSearchExpression] = useState(
		searchText?.searchText || ''
	);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		getEngagementsAsync();
	}, [engagementType]);

	useEffect(() => {
		setDisplayedEngagements(myEngagementsV2);
	}, [myEngagementsV2]);

	useEffect(() => {
		if (skip > displayedEngagements?.pagerModel?.totalpages) {
			setSkip(engagementV2DefaultSkip);
			setUpdatedURL(engagementV2DefaultSkip, top);
		} else {
			setUpdatedURL(skip, top);
		}
	}, [displayedEngagements]);

	useEffect(() => {
		getEngagementsAsync();
		setUpdatedURL(skip, top);
	}, [skip, top]);

	const setUpdatedURL = (skipValue, topValue) => {
		let searchTypeRoute = '';

		switch (engagementType) {
			case engagementFilterTypes.ALL:
				searchTypeRoute = '/engagementsv2/allengagements';
				break;
			case engagementFilterTypes.MY_FAVORITES:
				searchTypeRoute = '/engagementsv2/favorites';
				break;
			case engagementFilterTypes.REVIEW_ENGAGEMENTS:
				if (isReviewEngagementAvailable) {
					searchTypeRoute = '/engagementsv2/reviewengagements';
				} else {
					return;
				}
				break;
			default:
				searchTypeRoute = '/engagementsv2/allengagements';
				break;
		}
		history.push(
			`${searchTypeRoute}?pagenumber=${skipValue}&pagesize=${topValue}`
		);
	};

	const getEngagementsAsync = async (isSearchTextEmpty = false) => {
		await loadEngagements(isSearchTextEmpty);
	};

	const loadEngagements = async (isSearchTextEmpty) => {
		setIsLoading(true);
		const params = {
			top: top,
			skip: skip,
			searchType: 3, // contains search
			searchKey: isSearchTextEmpty
				? ''
				: Utility.getSanitizedText(searchExpression, {
						encode: true
				  }),
			engagementType:	engagementType 
		};

		if (
			engagementType === engagementFilterTypes.REVIEW_ENGAGEMENTS &&
			!isReviewEngagementAvailable
		) {
			await dispatch(
				setToastError(labels.engv2Errors.noReviewEngagementsPermission)
			);
			setEngagmentType(engagementFilterTypes.ALL);
			return;
		}
		//new Zues API call here
		await dispatch(getEngagementsV2(params));
		setIsLoading(false);
	};

	const onSizeOfPageClick = (topValue) => {
		if (topValue !== top) {
			setTop(topValue);
			setSkip(1);
		}
	};

	const onNextPageClick = (value) => {
		setSkip(value);
	};

	const onChange = (event) => {
		if (event.target.value.length <= 100) {
			setSearchExpression(event.target.value);
			dispatch(searchEngagementState(event.target.value));
		}
		if (event.type !== 'change') {
			getEngagementsAsync(true);
		}
	};

	const onSearchHandler = () => {
		setSearchExpression(searchText?.searchText || '');
		if (skip === 1) getEngagementsAsync();
		else setSkip(1);
	};

	return (
		<PortfolioViewStyles className="portfolio-tab">
			<section className="portfolio-wrapper">
				<section className="portfolio-info">
					<span className="information-text">{labels.informationText}</span>
					<h2 className="portfolio-user">{fullname}</h2>
				</section>
				<section className="portfolioselect-wrapper">
					<FilterEngagements
						setSearchType={setEngagmentType}
						searchType={engagementType}
						isReviewEngagementAvailable={isReviewEngagementAvailable}
					/>
					<FormField className="engsearch-wrapper">
						<Search
							className="engagement-search"
							value={searchExpression}
							id="search-engagements"
							onChange={(event) => {
								onChange(event);
							}}
							searchButtonTitle={labels.searchPlaceholder}
							placeholder={labels.searchEngagementsPlaceholder}
							onSearchButtonClick={() => {
								onSearchHandler();
							}}
							onEnter={() => {
								onSearchHandler();
							}}
							clearButtonTitle={labels.clearSearch}
						/>
					</FormField>
				</section>
				<section className="engagements-wrapper">
					{isLoading ? (
						<Loader show view="blockView" />
					) : displayedEngagements &&
					  (engagementType === engagementFilterTypes.REVIEW_ENGAGEMENTS
							? isReviewEngagementAvailable
							: true) &&
					  displayedEngagements?.engagementReadModel?.length > 0 ? (
						<EngagementList
							engagements={displayedEngagements}
							refreshDashboard={loadEngagements}
							searchType={engagementType}
						/>
					) : (
						<Card className="noengagements-template">
							<span className="noengagements-text">
								{searchExpression.length > 0
									? labels.nomatches +
									  ', ' +
									  labels.pleasetryagain.toLowerCase()
									: engagementType === engagementFilterTypes.ALL
									? labels.engv2noActiveEngagements
									: engagementType === engagementFilterTypes.MY_FAVORITES
									? labels.engv2noFavoriteEngagements
									: labels.engv2noReviewEngagements}
							</span>
						</Card>
					)}
				</section>
				<section className="pagination-wrapper">
					<Pagination
						currentPage={skip}
						onPageChange={onNextPageClick}
						firstButtonProps={{
							title: currentResource.common.engv2Pagination.iconHover.first
						}}
						lastButtonProps={{
							title: currentResource.common.engv2Pagination.iconHover.last
						}}
						prevButtonProps={{
							title: currentResource.common.engv2Pagination.iconHover.previous
						}}
						nextButtonProps={{
							title: currentResource.common.engv2Pagination.iconHover.next
						}}
						max={
							displayedEngagements?.pagerModel
								? displayedEngagements?.pagerModel.totalPages
								: engagementV2DefaultSkip
						}
					>
						<PaginationSelect>
							{engagementV2PageSizeOptions.map((option) => (
								<PaginationSelectItem
									key={`pagination-item-${option}`}
									selected={option === top}
									onClick={() => onSizeOfPageClick(option)}
								>
									{currentResource.common.engv2Pagination.show} {option}
								</PaginationSelectItem>
							))}
						</PaginationSelect>
					</Pagination>
				</section>
			</section>
		</PortfolioViewStyles>
	);
};

export default PortfolioView;

const PortfolioViewStyles = styled.section`
	height: calc(100% - var(--px-66));
	.portfolio-wrapper {
		height: 100%;
		.portfolio-info {
			padding-left: var(--px-37);
			width: 45%;
			height: var(--px-130);
			@media only screen and (min-width: 992px) and (max-width: 1366px) {
				height: var(--px-90);
			}
			.portfolio-user {
				font-size: var(--px-40);
				margin-top: var(--px-10);
				line-height:normal;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 99%;
			}
			.information-text {
				display: block;
				font-size: var(--px-14);
			}
		}
		.portfolioselect-wrapper {
			padding-left: var(--px-37);
			.engsearch-wrapper {
				display: inline-block;
				margin-left: var(--px-15);
				width: 16%;
				vertical-align: top;
				.engagement-search {
					width: 100%;
					.motif-input {
						height: var(--px-40);
						@media screen and (max-width: 1366px) {
							height: var(--px-47);
						}
						@media screen and (min-width: 1367px) and (max-width: 1600px) {
							height: var(--px-44);
						}
					}
					.motif-search-input-icon,
					.motif-typeahead-close-button {
						top: var(--px-12);
					}
				}
			}
		}
	}
	.engagements-wrapper {
		height: calc(100% - var(--px-250));
		margin-bottom: var(--px-20);
		margin-top: var(--px-20);
		border-bottom: var(--px-1) solid var(--neutrals-200);
		@media only screen and (min-width: 992px) and (max-width: 1366px) {
			height: calc(100% - var(--px-205));
		}
		.motif-card.noengagements-template {
			margin: var(--px-20) var(--px-40);
			height: var(--px-180);
			width: calc(100% - var(--px-80));;
			.noengagements-text {
				display: block;
				text-align: center;
			}
		}
	}
	.pagination-wrapper {
		padding-right: var(--px-37);
		.motif-pagination {
			justify-content: flex-end;
			@media screen and (max-width: 1366px) {
				margin-top: calc(var(--px-12) * -1);
			}
			@media screen and (min-width: 1367px) and (max-width: 1600px) {
				margin-top: calc(var(--px-5) * -1);
			}
			.motif-icon-button.motif-pagination-button:disabled {
			}
			.motif-pagination-select-wrapper
				.motif-pagination-select
				.motif-dropdown-menu {
				margin-top: calc(var(--px-151) * -1);
			}
			.ref-input-elem {
				font-size: var(--px-14);
			}
		}
	}
	.empty-message {
		align-items: center;
		justify-content: center;
		height: calc(100vh - 275px);
		color: var(--neutrals-900);
		font-size: var(--px-14);
		text-align: center;
		font-weight: normal;
		display: flex;
		font-size: var(--px-14);
		margin: calc(var(--px-4) * -1) 0 0 var(--px-17);

		& .norecords {
			margin-bottom: var(--px-105);
		}

		& p {
			float: left;
			width: 100%;
			margin: 0;
			line-height: var(--px-26);
			font-size: var(--px-14);
			color: var(--neutrals-700);

			&.try-message {
				font-size: var(--px-20);
			}
		}
	}
`;
