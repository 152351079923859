import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {lightColor} from '@ey/styled-theme';
import {EYLogo} from '@ey/canvascoreservices';
import {isEmpty} from 'lodash';
import {setLanguage} from '../../util/uiconstants';
import Dropdown from '@ey/motif-react-wrapper/Dropdown';
import {labels} from '../../util/uiconstants';

const Nav = (props) => {
	const user = useSelector((store) => store.user);
	const meInfo = useSelector((store) => store.userPermissions);

	useEffect(() => {
		setLanguage('en-us');
	}, [user]);

	let validUser = false;
	if (user && user.hasOwnProperty('id') && !isEmpty(user.id)) {
		validUser = true;
	}

	return (
		<StyledNavbar className="styled-nav-bar">
			<section className="nav-bar">
				<section className="nav-bar__ey-logo">
					{validUser ? (
						<Link
							className="LogoLink"
							to={{
								pathname: '/admin',
								state: {
									doNotReload: false
								}
							}}
						>
							<EYLogo logoType="" />
							<h1 className="nav-bar__ey-logo__text">
								<b>{labels.adminTool}</b>
							</h1>
						</Link>
					) : (
						<span>
							<Link
								className="LogoLink"
								to={{
									pathname: '/admin'
								}}
							>
								<EYLogo logoType="" />
								<h1 className="nav-bar__ey-logo__text">
									<b>{labels.adminTool}</b>
								</h1>
							</Link>
						</span>
					)}
				</section>
				<section className="nav-bar_right-wrapper">
					<section className="UserNav-Item ">
						{meInfo?.userInitials && (
							<Dropdown
								placement="bottom-right"
								className="user-dropdown"
								trigger={
									<section className="user-initials" title={'User options'}>
										<span className="initial">{meInfo?.userInitials}</span>
									</section>
								}
							></Dropdown>
						)}
					</section>
				</section>
			</section>
		</StyledNavbar>
	);
};

export default Nav;

const StyledNavbar = styled.section`
	height: var(--px-60);
	width: 100%;
	border: none;
	background-color: var(--neutrals-900);
	display: flex;
	position: relative;

	.nav-bar {
		max-width: 2048px;
		margin: 0 auto;
		display: flex;
		width: calc(100vw - 44px);
	}

	.mainResponsiveWrapper {
		display: flex;
	}

	.delegate-btn {
		display: inherit;

		.user-initials {
			border-radius: 50%;
			width: var(--px-30);
			height: var(--px-30);
			display: inline-block;
			vertical-align: middle;
			line-height: var(--px-30);
			text-align: center;
			background-color: var(--green-400);
			color: var(--neutrals-00white);
			font-size: var(--px-12);
			font-weight: 400;
		}
	}

	.nav-bar__ey-logo {
		vertical-align: top;
		display: inline-block;
		user-select: none;
		.LogoLink{
			margin: var(--px-11) 0 var(--px-16);
			display: block;
			height: var(--px-33);
		}
		figure {
			width: var(--px-31);
			height: var(--px-33);
			margin-right: var(--px-8);
		}
		.nav-bar__ey-logo__text {
			font-size: var(--px-24);
			display: inline-block;
			font-weight: 300;
			margin: var(--px-15) 0 0 0;
			color: var(--neutrals-00white);
			b{
				font-weight: 300;
			}
		}
	}

	.nav-bar_right-wrapper {
		margin-left: auto;
		.nav-bar__user-profile {
			position: relative;
			display: inline-flex;
			max-width: var(--px-300);
			width: auto;
			font-size: var(--px-14);
			height: 100%;
			align-items: center;
			color: var(--neutrals-00white);

			.motif-dropdown .motif-dropdown-menu {
				margin-right: var(--px-8);
				margin-top: var(--px-13);
				z-index: 9999;
			}

			.motif-dropdown .motif-dropdown-menu .motif-dropdown-item {
				height: var(--px-42);
			}

			& > a {
				color: var(--neutrals-00white);
				outline: none;
				display: flex;
				align-items: center;
				&:hover {
					text-decoration: none;
				}
				.nav-bar__user-profile__user-name {
					display: inline-block;
					max-width: var(--px-250);
					width: auto;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
				.caret {
					display: inline-block;
					width: 0;
					height: 0;
					vertical-align: middle;
					border-top: var(--px-4) solid;
					border-right: var(--px-4) solid transparent;
					border-left: var(--px-4) solid transparent;
					margin: var(--px-10) var(--px-10);
					transition: all 0.2s;
				}
			}
			&.nav-bar__user-profile--active {
				a .caret {
					transform: rotate(180deg);
				}
			}
		}

		.nav-bar__user-profile__menu {
			background-color: var(--neutrals-200);
			border: var(--px-1) solid var(--neutrals-300);
			box-shadow: 0 0 var(--px-20) calc(var(--px-10) * -1) #222;
			width: var(--px-210);
			position: absolute;
			right: var(--px-40);
			top: var(--px-50);
			overflow: hidden;
			transition: all 0.3s;
			z-index: 9;
			font-size: var(--px-14);

			.nav-bar__user-profile__menu__links {
				display: block;
				padding: var(--px-10) var(--px-15);
				color: var(--neutrals-900);
				border-top: var(--px-1) solid #e5e5e5;
				cursor: pointer;
				text-decoration: none;
				:hover {
					text-decoration: underline;
				}
				&.userProfilePhoto {
					background: var(--neutrals-00white);
					svg {
						width: 60%;
						height: 60%;
						max-width: 100%;
						max-height: 100%;
						margin: 0 auto;
						display: block;
					}
				}
			}
		}
	}

	.UserNav-Item {
		width: 50%;
		a {
			padding: var(--px-15) var(--px-20) var(--px-10) var(--px-20);
			margin: 0px var(--px-2);
			font-size: var(--px-15);
			font-weight: bold;
			display: inline-block;
			text-decoration: none;
			&:hover {
				text-decoration: none;
				border-bottom: var(--px-4) solid var(--brand-500);
			}
			&.selected {
				color: var(--brand-500);
				border-bottom: var(--px-4) solid var(--brand-500);
				background-color: var(--neutrals-900);
			}
			svg {
				fill: var(--neutrals-00white);
				width: var(--px-30);
				height: var(--px-30);
				max-width: var(--px-30);
				max-height: var(--px-30);
				cursor: pointer;
				margin-top: var(--px-3);
			}
		}
		.user-initials {
			width: var(--px-25);
			height: var(--px-25);
			line-height: var(--px-25);
			border-radius: 50%;
			margin-top: var(--px-18);
			background: var(--blue-500);
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			color: var(--neutrals-00white);
			.initial{
				color: var(--neutrals-00white);
				font-weight: 700;
				text-align: center;
				font-size: var(--px-10);
				display: inline-block;
			}
		}
		.user-dropdown {
			padding-right: var(--px-1);
			.motif-dropdown-menu {
				margin-top: var(--px-16);
				margin-right: calc(var(--px-19) * -1);
				@media screen and (max-width: 1366px) {
					margin-top: var(--px-11);
					margin-right: calc(var(--px-18) * -1);
				}
			}
		}
	}
`;
