import React, {useState} from 'react';
import Dropdown from '@ey/motif-react-wrapper/Dropdown';
import DropdownItem from '@ey/motif-react-wrapper/DropdownItem';
import Icon from '@ey/motif-react-wrapper/Icon';
import IconButton from '@ey/motif-react-wrapper/IconButton';
import { 
	alertIcError24px,
	hardwareIcKeyboardArrowDown24px 
} from '@ey/motif-react-wrapper/assets/icons/index';
import {currentResource} from '../../../util/uiconstants';

const EngagementAlerts = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const id = 'dropdown-1';
	const triggerId = 'dropdown-trigger-1';
	let issuesList = [];

	const handleToggle = () => {
		setIsOpen(!isOpen);
	};

	const handleClickOutside = () => {
		setIsOpen(false);
	};

	const dataFunctions = {
		hasPartnerInvited: {
			validate: function (data) {
				return !data.hasPartnerInvited;
			},
			createIssue: function () {
				return createAlertIssue(
					issueTypeEnum.One,
					currentResource.overdueIssues.partnerInvitationPending
				);
			}
		},
		hasEqrInvited: {
			validate: function (data) {
				return !data.hasEqrInvited;
			},
			createIssue: function () {
				return createAlertIssue(
					issueTypeEnum.One,
					currentResource.overdueIssues.eqrInvitationPending
				);
			}
		},
		hasValidEngagementCode: {
			validate: function (data) {
				return !data.hasValidEngagementCode;
			},
			createIssue: function () {
				return createAlertIssue(
					issueTypeEnum.One,
					currentResource.overdueIssues.invalidEngagementCode
				);
			}
		},
		overdueArchiveDeadline: {
			validate: function (data) {
				return data.overdueArchiveDeadline;
			},
			createIssue: function (data) {
				console.log('currentResource', currentResource);
				return createAlertIssue(
					issueTypeEnum.One,
					currentResource.overdueIssues.overdueArchiveDeadline
				);
			}
		},
		upcomingArchiveDeadline: {
			validate: function (data) {
				return data.upcomingArchiveDeadline;
			},
			createIssue: function () {
				return createAlertIssue(
					issueTypeEnum.Two,
					currentResource.overdueIssues.upcomingArchiveDeadline
				);
			}
		},
		hasContentDeliveryInProgress: {
			validate: function (data) {
				return data.hasContentDeliveryInProgress;
			},
			createIssue: function () {
				return createAlertIssue(
					issueTypeEnum.Two,
					currentResource.overdueIssues.contentDeliveryInProgress
				);
			}
		},
		overdueMilestones: {
			validate: function (data) {
				return data.overdueMilestones > 0;
			},
			createIssue: function (data) {
				const optional = {
					count: data.overdueMilestones
				};
				return createAlertIssue(
					issueTypeEnum.Three,
					currentResource.overdueIssues.overdueMilestones,
					optional
				);
			}
		},
		overduePartnerTasks: {
			validate: function (data) {
				return data.overduePartnerTasks > 0;
			},
			createIssue: function (data) {
				const optional = {
					count: data.overduePartnerTasks
				};
				return createAlertIssue(
					issueTypeEnum.Three,
					currentResource.overdueIssues.overduePartnerTasks,
					optional
				);
			}
		},
		overdueEqrTasks: {
			validate: function (data) {
				return data.overdueEqrTasks > 0;
			},
			createIssue: function (data) {
				const optional = {
					count: data.overdueEqrTasks
				};
				return createAlertIssue(
					issueTypeEnum.Three,
					currentResource.overdueIssues.overdueEqrTasks,
					optional
				);
			}
		},
		hasEQRInvited: {
			validate: function (data) {
				return !data.hasEqrInvited;
			},
			createIssue: function () {
				return createAlertIssue(
					issueTypeEnum.One,
					currentResource.overdueIssues.eqrInvitationPending
				);
			}
		},
		overdueEQRTasks: {
			validate: function (data) {
				return data.overdueEqrTasks > 0;
			},
			createIssue: function (data) {
				const optional = {
					count: data.overdueEqrTasks
				};
				return createAlertIssue(
					issueTypeEnum.Three,
					currentResource.overdueIssues.overdueEqrTasks,
					optional
				);
			}
		}
	};

	const IssuesListCreation = (metrics) => {
		let entries = Object.entries(metrics),
			issuesList = [];

		entries.forEach((property) => {
			let dataFunction = dataFunctions[property[0]];
			if (dataFunction && dataFunction.validate(metrics)) {
				issuesList.push(dataFunction.createIssue(metrics));
			}
		});

		return issuesList;
	};

	if (props.engagementAlerts) {
		issuesList = IssuesListCreation(props.engagementAlerts);
	}

	return issuesList && issuesList.length > 0 ? (
		<Dropdown
			open={isOpen}
			className="engagement-alertsdropdown"
			id={id}
			aria-labelledby={triggerId}
			handleClickOutside={handleClickOutside}
			trigger={
				<IconButton
					type="button"
					onClick={handleToggle}
					id={triggerId}
					aria-label="Fruits"
				>
					<Icon src={alertIcError24px} />
					<span className="alerts-count">{issuesList.length}</span>
					<Icon src={hardwareIcKeyboardArrowDown24px} />
				</IconButton>
			}
		>
			{issuesList?.map((alert) => (
				<DropdownItem>
					{getAlertType(alert.issueType) !== 'Count' ? (
						<Icon src={alertIcError24px} />
					) : (
						<span className="alerts-count">{alert.counter} </span>
					)}
					<span className="alert-name">{alert.name}</span>
				</DropdownItem>
			))}
		</Dropdown>
	) : (
		[]
	);
};
const getAlertType = (issueType) => {
	switch (issueType) {
		case 1:
			return 'alert red';
		case 2:
			return 'alert grey';
		case 3:
			return 'Count';
		default:
			return '';
	}
};

const createAlertIssue = (issueType, label, optional) => {
	const exclamationMarkIssueTypes = [issueTypeEnum.One, issueTypeEnum.Two];
	const counterIssueTypes = [issueTypeEnum.Three];
	let createdIssue = {};

	if (optional && optional.customFunction) {
		createdIssue = optional.customFunction(issuetype, label, optional);
	} else if (exclamationMarkIssueTypes.includes(issueType)) {
		createdIssue = createExclamationMarkIssue(issueType, label);
	} else if (
		counterIssueTypes.includes(issueType) &&
		typeof optional != 'undefined' &&
		typeof optional.count != 'undefined'
	) {
		createdIssue = createCounterIssue(issueType, label, optional.count);
	}

	return createdIssue;
};
const createExclamationMarkIssue = (issueType, label) => {
	return {
		issueType: issueType,
		name: label
	};
};

// This is the function that creates the Issue objects for Counter type Issues.
const createCounterIssue = (issueType, label, count) => {
	return {
		issueType: issueType,
		name: label,
		counter: count
	};
};

const issueTypeEnum = {
	One: 1,
	Two: 2,
	Three: 3
};

export default EngagementAlerts;
