import React, {useState} from 'react';
import Dropdown from '@ey/motif-react-wrapper/Dropdown';
import DropdownItem from '@ey/motif-react-wrapper/DropdownItem';
import Icon from '@ey/motif-react-wrapper/Icon';
import IconButton from '@ey/motif-react-wrapper/IconButton';
import {labels, permissionGroups} from '../../../util/uiconstants';
import {shallowEqual, useSelector} from 'react-redux';
import {EngagementStatus} from '../../../util/enumerations';
import { navigationIcMoreVert24px } from '@ey/motif-react-wrapper/assets/icons/index';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';
import { useHistory } from 'react-router-dom';
import EngagementRemoveModal from '../engagementRemoveModal';
import EngagementDeleteModal from '../EngagementDeleteModal';
import OverrideArchiveDeadlineDateModal from '../overrideArchiveDeadlineDateModal';
const EngagementOptions = (props) => {
	const {
		engagementID,
		engagementStatus,
		isEngagementProfileComplete,
		isEngagementAccessible,
		incomplete,
		userPermissions,
		data,
		workspaceID,
		doRefreshDashboard,
	} = props;

	const {
		isInterimReviewEngagement,
		herculesWebUri
	} = data;

	const optionsMenuID = `optionsMenuID_${uuidv4()}`;
	const triggerID = `optionsMenuTriggerID_${uuidv4()}`;
	const history = useHistory();
	const timeline = useSelector((store) => {
		let timelineData = {};
		if (store.timeline && Array.isArray(store.timeline.timeline)) {
			const timelineElement = store.timeline.timeline.find(
				(element) => element.id == engagementID /* loose comparison by intent */
			);

			timelineData = (timelineElement && timelineElement.data) || timelineData;
		}

		return timelineData;
	}, shallowEqual);

	const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
	const [showRemoveModalState, setshowRemoveModalState] = useState(false);
	const [showDeleteModalState, setshowDeleteModalState] = useState(false);
	const [showOverrideArchiveDeadlineDateModal, setShowOverrideArchiveDeadlineDateModal] = useState(false);

	const clickOutsideHandler = () => {
		setIsOptionsMenuOpen(false);
	};

	const onOptionMenuClickHandler = () => {
		setIsOptionsMenuOpen(!isOptionsMenuOpen);
	};

	const buildAvailableOptions = () => {
		const options = [];

		if (isEditEngagementApplicable()) {
			options.push(getEditEngagementOption(), getEditWorkspaceOption());
		}

		if (isManageTeamApplicable()) {
			options.push(getManageTeamOption());
		}

		if (isRemoveEngagementApplicable()) {
			options.push(getRemoveEngagementOption());
		}

		if (isDeleteEngagementApplicable()) {
			options.push(getDeleteEngagementOption());
		}

		if (isOverrideArchiveDateApplicable()) {
			options.push(getOverrideArchiveDateOption());
		}

		if (isCRPApplicable()) {
			options.push(getCRPOption());
		}

		return options;
	};

	const isEditEngagementApplicable = () => {
		return engagementStatus !== EngagementStatus.ContentDeliveryInProgress;
	};

	const isManageTeamApplicable = () => {
		return canAccessActiveAndSimilarEngagements();
	};

	const isRemoveEngagementApplicable = () => {
		return (
			engagementStatus !== EngagementStatus.ContentDeliveryInProgress &&
			isEngagementProfileComplete
		);
	};

	const isDeleteEngagementApplicable = () => {
		return (
			(engagementStatus !== EngagementStatus.MarkedForDeletion &&
				isEngagementAccessible) ||
			engagementStatus === EngagementStatus.ContentDeliveryInProgress ||
			!isEngagementProfileComplete
		);
	};

	const isOverrideArchiveDateApplicable = () => {
		return canAccessActiveAndSimilarEngagements();
	};

	const isCRPApplicable = () => {
		if (!incomplete || !isEngagementProfileComplete) {
			const hasPermissions = !_.isEmpty(
				userPermissions?.find(
					(permissions) =>
						permissions.groupname === permissionGroups.Global_ReviewPortal
				)
			);

			return hasPermissions;
		}
	};

	const isContentDeliveryInProgress = () => {
		return (
			(timeline &&
				timeline.metrics &&
				timeline.metrics.engagementAlerts &&
				timeline.metrics.engagementAlerts.hasContentDeliveryInProgress) === true
		);
	};

	const canAccessActiveAndSimilarEngagements = () => {
		if (!isEngagementProfileComplete) {
			return true;
		}

		if (incomplete) {
			return false;
		}

		if (isContentDeliveryInProgress()) {
			return false;
		}

		if (
			engagementStatus === EngagementStatus.Active ||
			engagementStatus === EngagementStatus.Restored ||
			engagementStatus === EngagementStatus.MarkedForDeletion
		) {
			return true;
		} else {
			return false;
		}
	};

	const getEditEngagementOption = () => {
		return (
			<DropdownItem onClick={() => handleOptionMenuItemClick(labels.contextMenuEditEngagement)}>
				{labels.contextMenuEditEngagement}
			</DropdownItem>
		);
	};

	const getEditWorkspaceOption = () => {
		return (
			<DropdownItem onClick={() => handleOptionMenuItemClick(labels.contextMenuEditWorkspace)}>
				{labels.contextMenuEditWorkspace}
			</DropdownItem>
		);
	};

	const getManageTeamOption = () => {
		return <DropdownItem onClick={() => handleOptionMenuItemClick(labels.manageTeam)}>
			{labels.manageTeam}
		</DropdownItem>;
	};

	const getRemoveEngagementOption = () => {
		return (
			<DropdownItem onClick={showRemoveModal}>
				{labels.contextMenuRemoveEngagement}
			</DropdownItem>
		);
	};

	const getDeleteEngagementOption = () => {
		return (
			<DropdownItem onClick={showDeleteModal}>
				{labels.contextMenuDeleteEngagement}
			</DropdownItem>
		);
	};

	const getOverrideArchiveDateOption = () => {
		return (
			<DropdownItem onClick={showOverrideArchiveDateModal}>
				{labels.contextMenuOverrideArchiveDate}
			</DropdownItem>
		);
	};

	const handleOptionMenuItemClick = (type) => {
		switch (type) {
			case labels.contextMenuEditEngagement: {
				let link = '/editengagement?workspaceid=' + workspaceID + '&engagementid=' + engagementID;
				history.push(link);
				break;
			}
			case labels.contextMenuEditWorkspace: {
				let link = '/editworkspace?workspaceid=' + workspaceID;
				history.push(link);
				break;
			}
			case labels.manageTeam: {
				let link = herculesWebUri + '/manageteam?' + 'engagementid=' + engagementID;
				window.location.href = link;
				break;
			}
			case labels.contextMenucrp: {
				let link = '/crp?eid=' + engagementID + '&T=1' + '&isInt=' + isInterimReviewEngagement;
				history.push(link);
				break;
			}
		}
	}
	const showRemoveModal = () => {
		setshowRemoveModalState(true);
	};
	const showDeleteModal = () => {
		setshowDeleteModalState(true);
	};
	const hideRemoveModal = () => {
		setshowRemoveModalState(false);
		doRefreshDashboard();
	};
	const hideDeleteModal = () => {
		setshowDeleteModalState(false);
		doRefreshDashboard();
	};
	const quitRemoveModal = () => {
		setshowRemoveModalState(false);
	};
	const quitDeleteModal = () => {
		setshowDeleteModalState(false);
	};
	const showOverrideArchiveDateModal = () => {
		setShowOverrideArchiveDeadlineDateModal(true);
	};

	const getCRPOption = () => {
		return <DropdownItem onClick={() => handleOptionMenuItemClick(labels.contextMenucrp)}>
			{labels.contextMenucrp}
		</DropdownItem>;
	};

	return (
		<>
			<EngagementOptionsDropdown>
				<Dropdown
					open={isOptionsMenuOpen}
					className="engagement-optionsdropdown"
					id={optionsMenuID}
					aria-labelledby={triggerID}
					handleClickOutside={clickOutsideHandler}
					trigger={
						<IconButton
							type="button"
							onClick={onOptionMenuClickHandler}
							id={triggerID}
							aria-label="Options Menu"
						>
							<Icon src={navigationIcMoreVert24px} />
						</IconButton>
					}
				>
					{buildAvailableOptions().map((option) => option)}
				</Dropdown>
			</EngagementOptionsDropdown>
			<EngagementRemoveModal
				show={showRemoveModalState}
				hide={hideRemoveModal}
				quit={quitRemoveModal}
				id={engagementID}
				{...props}
			/>
			<EngagementDeleteModal
				show={showDeleteModalState}
				hide={hideDeleteModal}
				quit={quitDeleteModal}
				refreshDashboard={doRefreshDashboard}
				id={engagementID}
				{...props}
			/>
			<OverrideArchiveDeadlineDateModal
				show={showOverrideArchiveDeadlineDateModal}
				closeModal={setShowOverrideArchiveDeadlineDateModal}
				engagementId={engagementID}
				workspaceId={workspaceID}
				refreshDashboard={doRefreshDashboard}
			/>
		</>

	);
};

export default EngagementOptions;

const EngagementOptionsDropdown = styled.section`
	display: inline-block;
`;
