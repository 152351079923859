import React, { useState, useEffect } from 'react';
import Icon from '@ey/motif-react-wrapper/Icon';
import { imageIcTimer24px } from '@ey/motif-react-wrapper/assets/icons/index';
import moment from 'moment';
import { labels } from '../../util/uiconstants';
import styled from 'styled-components';
import { expiryDaysTopCount } from '../../util/uiconstants';

const EngagementArchiveCountdownIndicator = (props) => {
	const { expectedreportreleasedate, archivaldeadlinedate } = props;

	const [expiryDays, setExpiryDays] = useState(-1);
	const [type, settype] = useState('reportReleaseDate'); // archivalDeadlinedate

	const getexpectedreportreleasedateCount = (expectedreportreleasedate) => {
		if (expectedreportreleasedate) {
			let startDate = moment(expectedreportreleasedate.split('T')[0]).startOf(
				'day'
			);
			let currentDate = moment(new Date()).startOf('day');
			let diff = startDate.diff(currentDate, 'days');
			return diff;
		}

		return -1;
	};

	useEffect(() => {
		let archivalDeadlinedate =
			getexpectedreportreleasedateCount(archivaldeadlinedate);
		let reportReleaseDate = getexpectedreportreleasedateCount(
			expectedreportreleasedate
		);

		if (archivalDeadlinedate <= expiryDaysTopCount) {
			if (archivalDeadlinedate <= reportReleaseDate || reportReleaseDate < 0) {
				// archival deadline date is earlier than report deadline then show archivaldead line
				setExpiryDays(archivalDeadlinedate);
				settype('archivalDeadlinedate');
				return;
			}
		}

		if (reportReleaseDate > -1 && reportReleaseDate <= expiryDaysTopCount) {
			setExpiryDays(reportReleaseDate);
			settype('reportReleaseDate');
		}
	}, []);

	const getCountDownLabel = () => {
		// need to find out its release date or archive date countdown
		let label = '';

		if (expiryDays <= 1) {
			let textToBold = '';
			label =
				type === 'reportReleaseDate'
					? labels.EngagementArchiveCountdownIndicator.reportreleasedate
					: labels.EngagementArchiveCountdownIndicator.archivedeadlinedate;

			switch (expiryDays) {
				case 0:
					textToBold = labels.EngagementArchiveCountdownIndicator.today;
					break;
				case 1:
					textToBold = labels.EngagementArchiveCountdownIndicator.tommorow;
					break;
				default:
					textToBold = labels.EngagementArchiveCountdownIndicator.overdue;
					break;
			}

			return (
				<>
					{label} <b>{textToBold}</b>
				</>
			)
		}

		label =
			type === 'reportReleaseDate'
				? labels.EngagementArchiveCountdownIndicator.reportreleasedays
				: labels.EngagementArchiveCountdownIndicator.archivedeadlinedays;

		return (
			<>
				<b>{expiryDays} {labels.EngagementArchiveCountdownIndicator.days}</b> {label}
			</>
		);
	};

	return (
		<DeadllineIndicatorStyle>
			{(expiryDays > -1 || type === 'archivalDeadlinedate') && (
				<div className="expiry-label">
					<Icon
						className={
							type === 'reportReleaseDate'
								? 'releasedate-icon'
								: 'deadlinedate-icon'
						}
						src={imageIcTimer24px}
					/>
					{getCountDownLabel()}
				</div>
			)}
		</DeadllineIndicatorStyle>
	);
};

export default EngagementArchiveCountdownIndicator;

const DeadllineIndicatorStyle = styled.section`
	padding-right: var(--px-30);
	display: inline-block;
	float: right;
	.expiry-label {
		line-height: normal;
		font-size: var(--px-14);
		@media screen and (max-width: 1366px) {
			margin-top: var(--px-4);
		}
		.motif-icon {
			&.releasedate-icon {
				color: var(--blue-600);
			}
			svg {
				width: var(--px-21);
				height: var(--px-21);
				vertical-align: sub;
				margin-right: var(--px-5);
			}
		}
		.expiry-days-label {
			font-weight: bold;
		}
	}
`;
